import React, { Component } from 'react';
import { Row, Col, Input, FormGroup } from 'reactstrap';
import { connect } from 'react-redux';
import { Link, withRouter, browserHistory } from 'react-router';
import ContractLeaseInfo from './ContractLeaseInfo';
import { ListLoader } from '../../../shared';
import { getLocalStorageInfo, getCookie } from '../../../utils';
import { getMsnSwitcherAc, downloadInventoryAc } from '../../../shared/actionCreators';
import { FIXED_POSITIONS_VALUE } from '../../../shared/actions';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { imgStoragePath } from '../../../constants';
import techSpecIcon from '../../../shared/assets/img/tech_specs_icon.svg';
import Switcher from '../../../shared/components/Switcher';
class ShortHead extends Component{
  constructor(props){
    super(props);
    this.state = {
      height: 0
    }
  }
  componentDidMount() {
    if(this.props.msnSwitcher.length <= 0){
				this.props.getMsnSwitcher();
		}
    let height = document.getElementById('contract-header').clientHeight;
		this.setState({
      height: height + 20
    });
    let cookieVal = getCookie();
		let headerHeight = document.body.contains(document.getElementById('header')) ? document.getElementById('header').clientHeight : 0 ;
    let maintenanceHeight = document.body.contains(document.getElementById('maintenance')) ? document.getElementById('maintenance').clientHeight : 0 ;
    if(cookieVal){
			this.props.fixedPositionFn('closeMaintenance',false);
      this.props.fixedPositionFn('maintenanceBarHeight',0);
      this.props.fixedPositionFn('subHeaderTop', headerHeight);
    }else{
			this.props.fixedPositionFn('closeMaintenance',true);
      this.props.fixedPositionFn('maintenanceBarHeight',maintenanceHeight);
      this.props.fixedPositionFn('subHeaderTop',maintenanceHeight + headerHeight);

    }
  }
  render(){
    const { contrAssetBasicInfo, headingText, msnSwitcher, downloadLoader, subHeaderTop, subHeaderTopMargin } = this.props;
    var activeAsset = contrAssetBasicInfo.asset_type==1?msnSwitcher.filter(item => item.asset_type == 1 && item.msn == contrAssetBasicInfo.msn): contrAssetBasicInfo.asset_type== 2 ? msnSwitcher.filter(item => item.asset_type == 2 && item.esn == contrAssetBasicInfo.esn) : contrAssetBasicInfo.asset_type== 3 ? msnSwitcher.filter(item => item.asset_type == 3 && item.serial_number == contrAssetBasicInfo.serial_number) : contrAssetBasicInfo.asset_type== 4 ? msnSwitcher.filter(item => item.asset_type == 4 && item.serial_number == contrAssetBasicInfo.serial_number) : contrAssetBasicInfo.asset_type === 5 ? msnSwitcher.filter(item => item.asset_type == 5 && item.serial_number == contrAssetBasicInfo.serial_number) : contrAssetBasicInfo.asset_type === 6 ? msnSwitcher.filter(item => item.asset_type == 6 && item.esn == contrAssetBasicInfo.esn) : contrAssetBasicInfo.asset_type === 7 ? msnSwitcher.filter(item => item.asset_type == 7 && item.esn == contrAssetBasicInfo.esn) : contrAssetBasicInfo.asset_type === 8 ? msnSwitcher.filter(item => item.asset_type == 8 && item.esn == contrAssetBasicInfo.esn) : msnSwitcher.filter(item => item.asset_type == 9 && item.esn == contrAssetBasicInfo.esn)
    return(
      <div className="sub-headder-info">
        <Row className="sub-headder-fixed" id="contract-header" style = {subHeaderTopMargin ? {top: subHeaderTopMargin} : {top: subHeaderTop}}>
          <Col sm="6" className="switcher-container" style={{maxWidth:'45%'}}>
            <div className="left-side-info">
              <ul className="list-inline msn-switcher flex-centered">
                <li className="list-inline-item">
                  <Link to="/assets-listing"><img src={imgStoragePath+"back_arrow.png"} style={{cursor:"pointer"}} alt="img" width="16"/></Link>
                </li>
                <li className="list-inline-item">
                  <Select
                    name="form-field-name"
                    placeholder="Select Asset"
                    className="custom-select-block"
                    value={activeAsset[0]}
                    options = {msnSwitcher}
                    clearable={true}
                    valueKey="asset"
                    labelKey="value"
                    onChange = {(evt) => this.props.changeMsn(evt)}
                    />
                </li>
                { headingText ?
                  <li className="list-inline-item" style={{ marginRight: 0, marginLeft: '15px'}}>
                    <ul className="list-inline other-links">
                        <li className="list-inline-item">
                        {getLocalStorageInfo().user.user_role != 'lessee' && getLocalStorageInfo().user.permission['technical_specs'] ?
                            <Link to={"https://sparta.aero/technical-specification/"+this.props.params.type+"/" +contrAssetBasicInfo.slug} target="_blank">
                              <img width="15" src={techSpecIcon} alt="tech summery" />
                              Technical Specification
                            </Link>:null
                          }
                            { getLocalStorageInfo() && getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.id !== 348 && headingText == 'Records' ?
                            <Link  onClick={() => this.props.downloadInventory(contrAssetBasicInfo)}> <span className="inventory-link"><img src={imgStoragePath+"export_icon.png"} />Inventory Report</span></Link>
                            : getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.type && getLocalStorageInfo().user.type.value === 1 && headingText == 'Records' ?
                            <Link  onClick={() => this.props.downloadInventory(contrAssetBasicInfo)}> <span className="inventory-link"><img src={imgStoragePath+"export_icon.png"} />Inventory Report</span></Link>
                            : null}
                        </li>

                    </ul>

                  </li> :null
                }
              </ul>

            </div>
          </Col>
          <Col sm="6">
            <div className="contract-lease-info">
              {Object.keys(contrAssetBasicInfo.contract).length ?
                <ContractLeaseInfo leaseInfo={contrAssetBasicInfo.contract} />:null
              }
            </div>
          </Col>
        </Row>
        { downloadLoader ? <ListLoader/>:null }
      </div>
    )
  }
}


const mapStateToProps = state => ({
  msnSwitcher: state.sharedReducers.msnSwitcher,
  downloadLoader: state.sharedReducers.downloadLoader,
  closeMaintenance: state.sharedReducers.closeMaintenance,
  maintenanceBarHeight: state.sharedReducers.maintenanceBarHeight,
	subHeaderTop: state.sharedReducers.subHeaderTop
});

const mapDispatchToProps = (dispatch, ownProps ) => {
	return {
    getMsnSwitcher: () => dispatch(getMsnSwitcherAc()),
    downloadInventory:(msn)=>dispatch(downloadInventoryAc(ownProps.params,msn)),
    fixedPositionFn: (type, position) => dispatch({
      type: FIXED_POSITIONS_VALUE,
      payload: {type: type, position: position}
    })
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShortHead));

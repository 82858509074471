import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { Row, Col,TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { AssemblySideBar, UpcomingNotFound, EventsHeader, EventItemRow } from '../../Elements';
import { ListLoader, NoRecordFound, LicenceModal } from '../../../../shared';
import LLPReplaceModal from '../components/LLPReplaceModal';
import { BUY_LICENCE } from '../../../../shared/actions'
import { getLocalStorageInfo } from '../../../../utils'
import { getMaintenanceUsageAc } from '../../AirframeAssembly/actionCreators'
import { EditSidebar, ShortHead, ContractHeadertext, ContractBarLoader } from '../../../contracts/Elements';
import { engineUpcomingEventsAc, engineEventHistoryAc, engineShopVisitCrudAc, addEditEngineShopVisitAc } from '../actionCreators';
import UpcomingEvents from '../../AirframeAssembly/components/UpcomingEvents';
import EventHistory from '../../AirframeAssembly/components/EventHistory';
import AddEditShopVisit from '../../AirframeAssembly/components/AddEditShopVisit';
import { eventTypeAc, assetInfoAc } from '../../AirframeAssembly/actionCreators';
import { ENGINE_SHOP_VISIT_CRUD } from '../actions';
import { assetsType, displayDateFormat, imgStoragePath, prettyNumberParamsL } from '../../../../constants';
import Switcher from '../../../../shared/components/Switcher';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { trackActivity } from '../../../../utils/mixpanel';
class EngineAssembly extends Component{
  constructor(props){
    super(props);
    this.state = {
      activeTab:"1",
      upcomingEventTab:0
    };
  }
  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  shopVisitCrud = (type, id) =>{
    if(getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.is_saas){
      if(this.props.maintenanceUsage.available == this.props.maintenanceUsage.used){
        if(this.props.maintenanceUsage.available!=0 && !this.props.maintenanceUsage.is_new){
          this.props.shopVisitCrud(type, id)
        }else{
        this.props.buyLicence({
          flag: true,
          action: '/licenses/maintenance',
          content: 'License to add maintenance event is expired.'
        })
        return;
      }
    }
    }
    this.props.shopVisitCrud(type, id)
  }

  componentDidMount(){
    this.props.eventLists();
    this.props.eventHistoryList();
    this.props.fetchAssetInfo();
    this.props.eventType();
    if(getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.is_saas){
			this.props.getMaintenanceUsage()
		}
    trackActivity('Page Visited',{
      application_module: 'Maintenance',
      page_title: 'Major Assemblies Engine',
      item_type: 'Event Listing'
    })
  }
  changeMsn =(evt) =>{
    let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/maintenance/engines';
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
    this.componentDidMount();
  }
  render(){
    const { engineEventList, engineEventHistory, engineShopVisit, eventTypes, assetInfoObj, engineLoader } = this.props;
    return(
      <div className="mr-rate-wrapper maintainance-container" style={{marginLeft: '215px', background: '#f0f2f3', marginBottom: '40px', marginTop: '94px'}}>
        {engineLoader?<ListLoader/>:null}
        { Object.keys(assetInfoObj).length ?
          <ShortHead contrAssetBasicInfo={assetInfoObj} changeMsn={this.changeMsn} headingText={'Maintenance' && <Switcher app="Maintenance"   />} />:<ContractBarLoader />
        }
        <AssemblySideBar params={this.props.params} />
        <div className="mr-rate-inner-wrapper">
          <div className="assembly-view-layout">
            <Nav tabs>
              <NavItem>
                <NavLink className={classnames({ active: this.state.activeTab === '1' })}
                onClick={() => { this.toggleTab('1'); trackActivity('Item Clicked', { page_title: 'Major Assemblies Engine', application_module: 'Maintenance', item_type:'Shop Visits History Tab' })}}
                  >
                    Shop Visits History
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink className={classnames({ active: this.state.activeTab === '2' })}
                onClick={() => { this.toggleTab('2'); trackActivity('Item Clicked', { page_title: 'Major Assemblies Engine', application_module: 'Maintenance', item_type:'Upcoming Shop Visits Tab' })}}
                  >
                    Upcoming Shop Visits
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                {
                  getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance && getLocalStorageInfo().user.permission.maintenance.engines && getLocalStorageInfo().user.permission.maintenance.engines.indexOf('C') !== -1 ?
                  <ContractHeadertext
                    title=""
                    desc="All major shop visits performed on Engines"
                    addNew = "+ Add Shop Visit"
                    toggleAddEvent={() => this.shopVisitCrud('add', {})}
                   />
                  :<ContractHeadertext
                    title=""
                    desc="All major shop visits performed on Engines"
                   />
                }
                <div style={{marginBottom:'60px'}} className="shopvisit-listing-wrapper maintainenace-listing-blk">
                  <div className="table-container">
                   <table>
                     <EventsHeader eventType="Shop Visit" />
                     <tbody>
                       { engineEventHistory && engineEventHistory.length ? engineEventHistory.map((shopVisit,index) =>
                         <EventItemRow previewDetail={() => browserHistory.push(`/${this.props.params.type}/${this.props.params.aircraft_slug}/maintenance/engine/view/${shopVisit.id}`)} params={this.props.params} assemblyType="engine" key={index} item={shopVisit} />) : null
                       }
                     </tbody>
                   </table>
                  </div>

                  {!engineLoader && engineEventHistory && !engineEventHistory.length ? <NoRecordFound description="Get Started by clicking '+ Add Shop Visit' button above"/>:null}
                </div>
              </TabPane>
              <TabPane tabId="2">

                <ContractHeadertext
                  title=""
                  desc="Projected upcoming Shop Visits based on data available for engines"
                 />
                 <div className="engine-sv-card">
                   { engineEventList && engineEventList.length ?
                     <Nav tabs>
                      { engineEventList.map((engine, index) =>
                        <NavItem>
                          <NavLink className={classnames({ active: this.state.upcomingEventTab === index })}
                          onClick={() =>  this.setState({upcomingEventTab:index})}
                            >
                              {engine.name}
                          </NavLink>
                        </NavItem>
                      )}
                     </Nav>:null
                   }
                   { engineEventList && engineEventList.length ?
                      <TabContent activeTab={this.state.upcomingEventTab}>
                        { engineEventList.map((engine, index) =>
                          <TabPane tabId={index}>
                            <Row className="inner-row">
                              <Col md="3">
                                <span className="text-label">Engine Type</span>
                                <p className="para-ui">{engine.type && engine.type.name?engine.type.name:'--'}</p>
                              </Col>
                              <Col md="3">
                                <span className="text-label">Region of Operation</span>
                                <p className="para-ui">{engine.region && engine.region.name?engine.region.name:'--'}</p>
                              </Col>
                            </Row>
                            <Row>
                              {engine.workscopes.map((wk, wkIndex) =>
                                <Col md="4">
                                  <div className="workscope-wrapper-block">
                                    <div className="run-type-blk">
                                      <Row className="inner-row">
                                        <Col md="12">
                                          <h6>{wk.run_id === 1 ? '1st ': wk.run_id === 2 ? '2nd ':'Mature '}Run</h6>
                                        </Col>
                                      </Row>
                                      <Row className="inner-row">
                                        <Col md="6">
                                          <span className="text-label">Life On Wing</span>
                                          <p className="para-ui">{Math.round(wk.life_on_wings)} Hrs</p>
                                        </Col>
                                        <Col md="6">
                                          <span className="text-label">Shop Visit Date</span>
                                          <p className="para-ui">{wk.LLP.eventDate ? moment(wk.LLP.eventDate).format(displayDateFormat):'--'}</p>
                                        </Col>
                                      </Row>
                                    </div>
                                    <div className="workscope-blk">
                                      <Row className="inner-row">
                                        <Col md="12">
                                          <span className="text-label">Workscope modules for shop visit</span>
                                          <Row className="workScope-select-block">
                                            <Col md="4">
                                              <label>Core</label>
                                            </Col>
                                            { wk.workscope.includes(2) ?
                                              <Col md="4">
                                                <label>LPT</label>
                                              </Col>:null
                                            }
                                            { wk.workscope.includes(3) ?
                                              <Col md="4">
                                                <label>FAN</label>
                                              </Col>:null
                                            }
                                          </Row>
                                        </Col>
                                      </Row>
                                      <Row className="inner-row">
                                        <Col md="12">
                                          <span className="text-label">LLPs to be replaced in shop visit </span>
                                          <p className="para-ui">{wk.llps_replaced} LLP's { wk.llps_list.length ? <LLPReplaceModal llps={wk.llps_list} />:null} </p>
                                        </Col>
                                      </Row>
                                    </div>
                                    <div className="restoration-blk">
                                      <Row className="inner-row">
                                        <Col md="12">
                                          <h6>Performance Restoration</h6>
                                        </Col>
                                      </Row>
                                      <Row className="inner-row">
                                        <Col md="6">
                                          <span className="text-label">MR Rate</span>
                                          <p className="para-ui">
                                            { wk.PRSV.mr ?
                                              <NumericLabel params={prettyNumberParamsL}>
                                                {wk.PRSV.mr}
                                              </NumericLabel>
                                              :'--'
                                            }
                                          </p>
                                        </Col>
                                        <Col md="6">
                                          <span className="text-label">Shop Visit Cost</span>
                                          <p className="para-ui">
                                            { wk.PRSV.eventCost ?
                                              <NumericLabel params={prettyNumberParamsL}>
                                                {wk.PRSV.eventCost}
                                              </NumericLabel>
                                              :'--'
                                            }
                                          </p>
                                        </Col>
                                      </Row>
                                      <Row className="inner-row">
                                        <Col md="6">
                                          <span className="text-label">Funds Collected</span>
                                          <p className="para-ui">
                                            { wk.PRSV.fundCollected ?
                                              <NumericLabel params={prettyNumberParamsL}>
                                                {wk.PRSV.fundCollected}
                                              </NumericLabel>
                                              :'--'
                                            }
                                          </p>
                                        </Col>
                                        <Col md="6">
                                          <span className="text-label">Funds Difference</span>
                                          <p className="para-ui">
                                            { wk.PRSV.surPlus ?
                                              <NumericLabel params={prettyNumberParamsL}>
                                                {wk.PRSV.surPlus}
                                              </NumericLabel>
                                              :'--'
                                            }
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                    <div className="llp-replacement-blk">
                                      <Row className="inner-row">
                                        <Col md="12">
                                          <h6>LLP Replacement</h6>
                                        </Col>
                                      </Row>
                                      <Row className="inner-row">
                                        <Col md="6">
                                          <span className="text-label">Bulk LLP MR Rate</span>
                                          <p className="para-ui">
                                            { wk.LLP.mr ?
                                              <NumericLabel params={prettyNumberParamsL}>
                                                {wk.LLP.mr}
                                              </NumericLabel>
                                              :'--'
                                            }
                                          </p>
                                        </Col>
                                        <Col md="6">
                                          <span className="text-label">LLP Replacement Cost</span>
                                          <p className="para-ui">
                                            { wk.LLP.eventCost ?
                                              <NumericLabel params={prettyNumberParamsL}>
                                                {wk.LLP.eventCost}
                                              </NumericLabel>
                                              :'--'
                                            }
                                          </p>
                                        </Col>
                                      </Row>
                                      <Row className="inner-row">
                                        <Col md="6">
                                          <span className="text-label">Bulk LLP MR Paid Collected</span>
                                          <p className="para-ui">
                                            { wk.LLP.fundCollected ?
                                              <NumericLabel params={prettyNumberParamsL}>
                                                {wk.LLP.fundCollected}
                                              </NumericLabel>
                                              :'--'
                                            }
                                          </p>
                                        </Col>
                                        <Col md="6">
                                          <span className="text-label">Funds Difference</span>
                                          <p className="para-ui">
                                            { wk.LLP.surPlus ?
                                              <NumericLabel params={prettyNumberParamsL}>
                                                {wk.LLP.surPlus}
                                              </NumericLabel>
                                              :'--'
                                            }
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                    <div className="total-amt-blk">
                                      <Row className="inner-row">
                                        <Col md="6">
                                          <span className="text-label">Total Paid Collected</span>
                                          <p className="para-ui">
                                            { wk.total_funds ?
                                              <NumericLabel params={prettyNumberParamsL}>
                                                {wk.total_funds}
                                              </NumericLabel>
                                              :'--'
                                            }
                                          </p>
                                        </Col>
                                        <Col md="6">
                                          <span className="text-label">Total Shop Visit Cost</span>
                                          <p className="para-ui">
                                            { wk.total_sv_cost ?
                                              <NumericLabel params={prettyNumberParamsL}>
                                                {wk.total_sv_cost}
                                              </NumericLabel>
                                              :'--'
                                            }
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>
                                </Col>
                              )}
                            </Row>
                          </TabPane>
                        )}
                      </TabContent>:null
                    }
                 </div>
                {!engineLoader && !( engineEventList && engineEventList.length) ? <UpcomingNotFound /> :null }
              </TabPane>
            </TabContent>
          </div>
          <EditSidebar
            title={
              engineShopVisit.type != '' ?
              (engineShopVisit.type == 'add') ? 'Add Shop Visit for Engine':
              (engineShopVisit.type == 'edit') ? 'Edit Shop Visit for Engine': 'View Shop Visit for Engine' : ''
              }
            editSideBar={(engineShopVisit.flag && engineShopVisit.type == 'add')}
            toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}
            >
            {
              Object.keys(engineShopVisit.data).length && engineShopVisit.flag && engineShopVisit.type == 'add' ?
              <div>
                <AddEditShopVisit
                  type={engineShopVisit.type}
                  shopVisitObj={engineShopVisit.data}
                  eventTypes={eventTypes}
                  addEditShopVisit={this.props.addEditShopVisit}
                  assemblyType="engine"
                  toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}
                />
              </div>:null
            }
          </EditSidebar>
        </div>
        {
          this.props.buyLicenceModal.flag ?
          <LicenceModal
            toggleLicence={() => this.props.buyLicence({
              flag: false,
              action: '',
              content: ''
            })}
            planId="7"
            licenceModal={this.props.buyLicenceModal}
            />
          :null
        }
      </div>
    )
  }
}
const mapStateToProps = (state, ownProps) => ({
  engineEventList: state.EngineAssemblyReducer.engineEventList,
  engineEventHistory: state.EngineAssemblyReducer.engineEventHistory,
  engineShopVisit: state.EngineAssemblyReducer.engineShopVisit,
  eventTypes: state.AirframeAssemblyReducer.eventTypes,
  assetInfoObj: state.AirframeAssemblyReducer.assetInfoObj,
  engineLoader: state.EngineAssemblyReducer.engineLoader,
  maintenanceUsage: state.AirframeAssemblyReducer.maintenanceUsage,
  buyLicenceModal: state.sharedReducers.buyLicenceModal,
});

const mapStateToDispatch = (dispatch, ownProps) => {
  return {
    eventLists:() => dispatch(engineUpcomingEventsAc(ownProps.params)),
    eventHistoryList: () => dispatch(engineEventHistoryAc(ownProps.params)),
    addEditShopVisit: (data, type) => dispatch(addEditEngineShopVisitAc(ownProps.params, data, type)),
    shopVisitCrud: (type, id) => dispatch(engineShopVisitCrudAc(ownProps.params, type, id)),
    eventType: () => dispatch(eventTypeAc(ownProps.params, 'engine')),
    fetchAssetInfo: () => dispatch(assetInfoAc(ownProps.params, 'Engine Maintenance')),
    toggleEditBar: (data) => dispatch({
      type: ENGINE_SHOP_VISIT_CRUD,
      payload: data
    }),
    buyLicence: (modalObj) => dispatch({
      type: BUY_LICENCE,
      payload: modalObj
    }),
    getMaintenanceUsage: () => dispatch(getMaintenanceUsageAc(ownProps.params))
  }
};
export default connect(mapStateToProps, mapStateToDispatch)(RoleBasedAccess(EngineAssembly, ['maintenance', 'engines', 'R']));

import React, { Component } from 'react';
import { Link ,browserHistory } from 'react-router';
import { Row, Col } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { displayDateFormat, fieldDateFormat, backendDateFormat, imgStoragePath } from '../../../../constants';
import NumericLabel from 'react-pretty-numbers';
import '../../../../shared/assets/styles/CommonHeaderBlock.scss';
export default class MRFinanaceGraphWrapper extends Component{
  constructor(props){
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }
  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  handlecase=()=>{
    if(this.props.checkFlag){
     this.props.monthlytoggleModal(true)
     // browserHistory.push('/mr-calculator/cash-flow/'+this.props.id);
    }else{
      browserHistory.push('/mr-calculator/cash-flow/'+this.props.id);
    }
  }
  render() {
    const { mrFinanceGraph, id, simulationType } = this.props;
    const prettyNumberParams = {cssClass:["pretty-number-override-css"], justification:'L', precision: 2, currency: true, commafy: true};
    let url = '/log/'+ id;
    return(
      <div className="mr-graph-block" style={simulationType == 0 ? {marginBottom: '70px', marginLeft: '55px'}: {marginBottom: '100px',  marginLeft: '55px'}}>
        <div className="generate-finance-heading" style={{paddingBottom:"20px"}}>
          <h2 style={{float:"none"}} className="beta gengraph-text">
            <Link to={url}><img src={imgStoragePath+"back_arw.png"} alt="" width="16"/> MR Finance Report</Link>
            <span style={{display:'none'}} onClick={() => this.props.downloadGraphSummery(id)}><img width="12" src={imgStoragePath+"export_icon.png"}  alt="export"/>Export</span>
              <ul className="list-inline tab-right flex-centered">
                <li className="list-inline-item download-block">
                  <Link className="cashflow-link" onClick={()=> this.handlecase() } >Generate Monthly Cash Outflow</Link>
                </li>
              </ul>
          </h2>
          {
            mrFinanceGraph.data.general_info ?
            <div className="review-wrapper-block">
              <ul style={{float:"none"}} className="list-inline">
                <li className="list-inline-item">
                  <span>Log Id</span>
                  <p className="para-ui">{id}</p>
                </li>
                <li className="list-inline-item">
                  <span>Aircraft Type</span>
                  <p className="para-ui">{mrFinanceGraph.data.general_info.aircraft_type}</p>
                </li>
                <li className="list-inline-item">
                  <span>Date of Manufacture</span>
                  <p className="para-ui">
                    {moment(mrFinanceGraph.data.general_info.dateOfManufacture).format(displayDateFormat)}
                  </p>
                </li>
                <li className="list-inline-item">
                  <span>Maintenance Program</span>
                  <p className="para-ui">{mrFinanceGraph.data.general_info.program_group ? mrFinanceGraph.data.general_info.program_group : mrFinanceGraph.data.general_info.events.join('/') + ' Years' }</p>
                </li>
                {
                  mrFinanceGraph.data.general_info.airframe_tsn ?
                  <li className="list-inline-item">
                    <span>TSN</span>
                    <p className="para-ui">{mrFinanceGraph.data.general_info.airframe_tsn}</p>
                  </li>:null
                }

                {
                  mrFinanceGraph.data.general_info.airframe_csn ?
                  <li className="list-inline-item">
                    <span>CSN</span>
                    <p className="para-ui">{mrFinanceGraph.data.general_info.airframe_csn}</p>
                  </li>:null
                }

              </ul>
            </div>:null
          }

          <div className="finance-graph-widget">
            <h5>
              Paid Status as of date:
              <div className="date-filter">
                <DatePicker
                  selected={moment(mrFinanceGraph.currentDate)}
                  dateFormat = {fieldDateFormat}
                  onChange= {(e) => this.props.updateGraphSum(moment(e).format(backendDateFormat))}
                  showYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                 />
              </div>
            </h5>
            <ul className="list-inline flex-centered">
              <li className="list-inline-item total-funds">
                <span>Total Paid</span>
                <h6>
                  <NumericLabel params={prettyNumberParams}>{mrFinanceGraph.totalSum}</NumericLabel>
                </h6>
              </li>
              { mrFinanceGraph.data.engines?
                <li className="list-inline-item engines-funds">
                  <div className="row">
                    {mrFinanceGraph.enginesSumArray.map((amt,index) => {
                      return(
                        <Col key={index}>
                          <span>Engine #{index+1}</span>
                          <h6><NumericLabel params={prettyNumberParams}>{amt}</NumericLabel></h6>
                        </Col>
                      )
                    })}
                  </div>
                </li>:null
              }

              { mrFinanceGraph.data.landing_gear ?
                <li className="list-inline-item lg-funds">
                  <span>Landing Gears</span>
                  <h6><NumericLabel params={prettyNumberParams}>{mrFinanceGraph.leangingGearSum}</NumericLabel></h6>
                </li>:null
               }

              { mrFinanceGraph.data.apu ?
                <li className="list-inline-item apu-funds">
                  <span>APU</span>
                  <h6><NumericLabel params={prettyNumberParams}>{mrFinanceGraph.apuSum}</NumericLabel></h6>
                </li>:null
              }

              { mrFinanceGraph.data.airframe ?
                <li className="list-inline-item airframe-funds">
                  <div className="row">
                    {mrFinanceGraph.airframeSumArray.map((prg,index) => {
                      return(
                        <Col key={index}>
                          <span>{prg.name} yr</span>
                          <h6><NumericLabel params={prettyNumberParams}>{prg.amount}</NumericLabel></h6>
                        </Col>
                      )
                    } )}
                  </div>
                </li>:null
              }

            </ul>
          </div>
        </div>
        {this.props.children}
      </div>
    )
  }
}

  import React, { Component } from 'react';
  import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { Link, withRouter } from 'react-router';
import NumericLabel from 'react-pretty-numbers';
import { prettyNumberParamsL, imgStoragePath } from '../../../constants';
import moment from 'moment';
import HeaderInfo from './HeaderInfo';
import { getCookie, getLocalStorageInfo } from '../../../utils';
import ContractLeaseInfo from '../../contracts/Elements/ContractLeaseInfo';
import { FIXED_POSITIONS_VALUE } from '../../../shared/actions';

class ShortHead extends Component{
  constructor(props){
    super(props);
    this.state = {
      height: 0,
      positionHeight: 0
		}
  }
  componentDidMount() {
    let cookieVal = getCookie();
    let headerHeight = document.body.contains(document.getElementById('header')) ? document.getElementById('header').clientHeight : 0 ;
    let maintenanceHeight = document.body.contains(document.getElementById('maintenance')) ? document.getElementById('maintenance').clientHeight : 0 ;
    if(cookieVal){
			this.props.fixedPositionFn('closeMaintenance',false);
      this.props.fixedPositionFn('maintenanceBarHeight',0);
      this.props.fixedPositionFn('subHeaderTop', headerHeight);
      this.setState({
        positionHeight: 0
      })
    }else{
			this.props.fixedPositionFn('closeMaintenance',true);
      this.props.fixedPositionFn('maintenanceBarHeight',maintenanceHeight);
      this.props.fixedPositionFn('subHeaderTop',maintenanceHeight + headerHeight);
      this.setState({
        positionHeight: 10
      })
    }
  }
  render(){
    const { contrAssetBasicInfo, headingText,portfolio,addFlag,assetIncluded,compareList,addAssetList,type,totalValue,totalAsset,backUrl, subHeaderTop } = this.props;
    let top = parseInt(subHeaderTop) + parseInt(this.state.positionHeight);
    if(this.props.type != 'lease'){
      return(
        <div>
          <Row className="generate-finance-heading flex-centered portfolio-fixed-header" id="contract-header" style={ top > 0 ? {left: '80px', marginTop: '-9px', top: top, position : 'fixed', zIndex: 1}:{left: '80px', marginTop: '-9px', top: top, position : 'fixed',  zIndex: 1}}>
            <Col md="6" style={{position: "relative",top: "-20px"}}>
              { type == "new" ?
                  <h2 className="beta">
                    <Link to={backUrl}>
                      <img src={imgStoragePath+"back_arw.png"} alt="img" width="16"/>
                    </Link>
                    Add Asset To <span>"{portfolio.name}"</span> Portfolio
                  </h2>
                :<h2 className="beta" style={{position: 'relative', top: 0}}>
                  <Link to={backUrl}>
                    <img src={imgStoragePath+"back_arw.png"} alt="img" width="16"/>
                  </Link>
                  {portfolio.name}
                </h2>
              }
              <br/>
              {
                type != "new" ?
                <p className="portfolio-description" style={this.props.style}>
                  {
                    (portfolio.description == '' || portfolio.description == undefined || portfolio.description == null) ?
                    '': portfolio.description.length > 60 ? portfolio.description.substr(0,57) + "...": portfolio.description
                  }
                </p> : null
              }
            </Col>
            <Col md="6">
              <ul style={{marginTop:'-15px'}} className="list-inline generate-select-boxes flex-centered">
                { (addFlag!="true" && totalAsset != null && totalAsset != '' && totalAsset != undefined) ?
                    <HeaderInfo title="Total Assets">
                      <span>
                        {totalAsset}
                      </span>
                  </HeaderInfo>:null
                }
                <HeaderInfo title="Assets Included">
                  <span>{assetIncluded}
                    {addFlag=="true" ?
                      <Link to={`/fleet-cashflow/portfolios/${portfolio.slug}/add/asset`}><img src={imgStoragePath+"add_portfolio_icon.png"} className="action-icon" width="24" alt='add asset'/></Link>:null
                    }
                  </span>
                </HeaderInfo>
                {type == 'view' ?
                 <button className="remove-portfolio-assets add-new" type="button" style={compareList.length > 0 ? {} :{background: 'rgba(249, 216, 209, 1)', color:'#fff', cursor: 'no-drop', border: '1px solid #ff5e5e'}} disabled={compareList.length > 0 ? '' :'disabled'} onClick={ () => this.props.deleteAssetsFromList(this.props.compareList)}>
                  <span style={compareList.length > 0 ? { color: '#ff5e5e'} :{}} ><i className="fa fa-trash"></i>  Remove ({compareList.length}) Assets</span>
                 </button>:null
                 }
                {
                  type == "new" ?
                  <HeaderInfo title=" ">
                    <button disabled={addAssetList.length > 0 ? false : true} type="button" onClick={ () => this.props.addAssetsToList(addAssetList)} className="add-new add-asset-button">
                    ({addAssetList.length}) Assets Selected
                    </button>
                  </HeaderInfo>:null
                }
              </ul>
            </Col>
          </Row>
        </div>
      )
    }else {
      return(
        <div className="sub-headder-info">
          <div className="subheader-height" style={{height: this.state.height}}></div>
          <Row className="sub-headder-fixed" id="contract-header" style = {{top: subHeaderTop}}>
            <Col md="6">
              <div className="left-side-info">
                <ul className="list-inline msn-switcher flex-centered" style={{ width: '28%', float: 'left'}}>
                  <li className="list-inline-item">
                    { (backUrl != null && backUrl != '' && backUrl != undefined) ?
                      <Link to={backUrl}><img src={imgStoragePath+"back_arw.png"} style={{cursor:"pointer"}} alt="img" width="16"/></Link>
                      :<Link to={'/asset- /aircraft/'+contrAssetBasicInfo.slug}><img src={imgStoragePath+"back_arw.png"} style={{cursor:"pointer"}} alt="img" width="16"/></Link>
                   }
                  </li>
                  <li className="list-inline-item" style={{marginBottom: "20px"}}>
                    <h5 style={{fontSize: "14px!important"}} className="para-ui">{portfolio.name}</h5>
                  </li>
                  <li className="list-inline-item" style={{fontFamily: 'Conv_IBMPlexSans-Bold',position: 'absolute',top: '15px',left:'40px'}}>
                    {
                      contrAssetBasicInfo.asset_type == 1 ? <span>MSN {contrAssetBasicInfo.msn}<span className="model-info" style={{fontFamily: 'Conv_IBMPlexSans-Regular',marginLeft: '10px', fontSize: '12px'}}>{contrAssetBasicInfo.model}</span></span> : <span>ESN {contrAssetBasicInfo.msn}<span className="model-info">{contrAssetBasicInfo.model}</span></span>
                    }
                  </li>
                </ul>
                <ul className="list-inline other-links" style={{ marginLeft: '25px'}}>
                  {getLocalStorageInfo().user.user_role != 'lessee' ?
                    <li>
                      {this.props.fleetFlag ?null:
                        <Link target="_blank" to={"https://sparta.aero/technical-specification/aircraft/"+contrAssetBasicInfo.slug} style={{ position: 'relative','top' : '13px'}}>
                          <img width="15" src={imgStoragePath+"aircraft.png"} alt="tech summary"style={{ marginRight: '4px'}} />
                          Technical Specification
                        </Link>
                      }
                    </li>
                    :<li style={{opacity: 0, cursor: 'default'}}  style={{ position: 'relative','top' : '13px', left: '-10px'}}>
                          Technical Specifications
                    </li>
                  }
                </ul>
              </div>
            </Col>
            <Col md="6">
              <div className="contract-lease-info">
                {Object.keys(contrAssetBasicInfo.contract).length ?
                  <ContractLeaseInfo leaseInfo={contrAssetBasicInfo.contract} />:null
                }
              </div>
            </Col>
          </Row>
        </div>
      )
    }

  }
}

const mapStateToProps = state => ({
	closeMaintenance: state.sharedReducers.closeMaintenance,
  maintenanceBarHeight: state.sharedReducers.maintenanceBarHeight,
	subHeaderTop: state.sharedReducers.subHeaderTop
});
const mapDispatchToProps = (dispatch, ownProps ) => {
	return {
		fixedPositionFn: (type, position) => dispatch({
      type: FIXED_POSITIONS_VALUE,
      payload: {type: type, position: position}
    })
	}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ShortHead));

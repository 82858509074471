import React from 'react';
import { imgStoragePath } from '../../../constants';
import editIcon from '../../../shared/assets/img/edit_icon.svg';
import viewIcon from '../../../shared/assets/img/view_icon.svg';
import revisionHistoryIcon from '../../../shared/assets/img/revision_history_icon.svg';
const FormModeType = (props) => {
  if(props.type !=  'add'){
    return(
      <div>
        {props.pendingReview?
        <span className="edit-audit-history" style={{right:'225px',color:'#999999'}} >{props.rentalView.requested_data && Object.keys(props.rentalView.requested_data).length ? '( Pending Review )':''}</span>:null}
        <span onClick={props.auditLogMode} className="edit-audit-history">{props.auditLog ?  <img style={{ marginRight: '5px'}} src={revisionHistoryIcon} width="13" alt="img"/>   : ' '} {props.auditLog ? 'Revision History' : ' '}</span>
        {
          props.editAble == undefined || props.editAble  ?
          <span onClick={props.changeViewMode} style={{position: 'absolute', right: '50px', top: '26px', cursor: 'pointer'}}>
            {props.type == 'view' ?
              <img className="blue-icon" src={editIcon} width="12" alt="icon" /> :
              <img className="blue-icon" src={viewIcon} width="16" alt="icon" />
            }
            <span className="blue-action">{props.type == 'view' ? ' EDIT': 'VIEW'}</span>
          </span>:null
        }
      </div>
    )
  }else{
    return null
  }
}

export default FormModeType;

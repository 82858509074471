import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router';
import { FIXED_POSITIONS_VALUE } from '../../../shared/actions';
import { getLocalStorageInfo, getCookie } from '../../../utils';
class RecordsSideBar extends Component{
  constructor(props){
    super(props);
  }
  getQuery=()=>{
    if(getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.action_dock !=null){
      if(getLocalStorageInfo().user.permission.records['action_dock'].indexOf('INQTAB') != -1){
        return '?quetype=1&status=status';

      }else if(getLocalStorageInfo().user.permission.records['action_dock'].indexOf('RENTAB') != -1){
        return '?quetype=2&status=status';

      }else if(getLocalStorageInfo().user.permission.records['action_dock'].indexOf('OCRTAB')){
        return '?quetype=1&status=ocr_status';

      }else if(getLocalStorageInfo().user.permission.records['action_dock'].indexOf('REJTAB')){
        return '?quetype=4&status=status';

      }
    }

   }

  render(){
    const { params } = this.props;
    const pathname = this.props.location.pathname;
    return(
      <div className="mr-calc-sidebar" style={{paddingTop: '26px'}}>
        <div>
          {
            getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.id !== 348 ?
            <ul className="">
              <li>
                <Link activeClassName="active"
                  className={pathname.includes('/records/dashboard/') ? 'active':'' }
                  to={`/${this.props.params.type}/${this.props.params.aircraft_slug}/records/dashboard/`}>
                  Dashboard
                </Link>
              </li>
              {
                getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.data_room && getLocalStorageInfo().user.permission.records.data_room.indexOf('R') != - 1 ?
                <li>
                  <Link activeClassName="active"
                    className={(pathname.includes('data-room') || pathname.includes('bucket') || pathname.includes('dataRoom')) ? 'active':'' }
                     to={`/${this.props.params.type}/${this.props.params.aircraft_slug}/records/dataRoom/`}>
                    Data Room
                  </Link>
                </li>
                :null
              }
              {
                getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records['action_dock'] && (getLocalStorageInfo().user.permission.records['action_dock'].indexOf('INQTAB') != -1  ||
                getLocalStorageInfo().user.permission.records['action_dock'].indexOf('RENTAB') != -1 ||
                getLocalStorageInfo().user.permission.records['action_dock'].indexOf('OCRTAB') != -1 ||
                getLocalStorageInfo().user.permission.records['action_dock'].indexOf('REJTAB') != -1) ?
                <li>
                  <Link activeClassName="active"
                    className={pathname.includes('/records/action-dock') ? 'active':'' }
                    to={`/${this.props.params.type}/${this.props.params.aircraft_slug}/records/action-dock/`+this.getQuery()}>
                    Action Dock
                  </Link>
                </li>:null
              }

              <li>
                <Link activeClassName="active"
                  className={pathname.includes('/records/resources') ? 'active':'' }
                  to={`/${this.props.params.type}/${this.props.params.aircraft_slug}/records/resources/`}>
                  Resources
                </Link>
              </li>
              <li>
                <Link activeClassName="active"
                  className={pathname.includes('/records/reports') ? 'active':'' }
                  to={`/${this.props.params.type}/${this.props.params.aircraft_slug}/records/report/`}>
                  Report
                </Link>
              </li>
              <li>
                <Link activeClassName="active"
                  className={pathname.includes('/records/recycle-bin') ? 'active':'' }
                  to={`/${this.props.params.type}/${this.props.params.aircraft_slug}/records/recycle-bin/`}>
                  Recycle Bin
                </Link>
              </li>
              {
                this.props.params.type == 'aircraft' && getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records['data_room'] && getLocalStorageInfo().user.permission.records['data_room'].indexOf('UP') != -1 && getLocalStorageInfo().user.permission.auto_bulk_organizer ?
                <li>
                  <Link activeClassName="active"
                    className={pathname.includes('/records/auto-organiser-and-renaming') ? 'active':'' }
                    to={`/${this.props.params.type}/${this.props.params.aircraft_slug}/records/auto-organiser-and-renaming`}>
                    Auto Organiser & Renaming
                  </Link>
                </li>
                :null
              }
            </ul>
            :<ul className="">
              {
                getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.data_room && getLocalStorageInfo().user.permission.records.data_room.indexOf('R') != - 1 ?
                <li>
                  <Link activeClassName="active"
                    className={(pathname.includes('data-room') || pathname.includes('bucket') || pathname.includes('dataRoom')) ? 'active':'' }
                     to={`/${this.props.params.type}/${this.props.params.aircraft_slug}/records/dataRoom/`}>
                    Data Room
                  </Link>
                </li>
                :null
              }
              <li>
                <Link activeClassName="active"
                  className={pathname.includes('/records/resources') ? 'active':'' }
                  to={`/${this.props.params.type}/${this.props.params.aircraft_slug}/records/resources/`}>
                  Resources
                </Link>
              </li>
            </ul>
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  maintenanceBarHeight: state.sharedReducers.maintenanceBarHeight,
	subHeaderTop: state.sharedReducers.subHeaderTop
});

const mapDispatchToProps = (dispatch, ownProps ) => {
	return {
    fixedPositionFn: (type, position) => dispatch({
      type: FIXED_POSITIONS_VALUE,
      payload: {type: type, position: position}
    })
  }
}
export default withRouter(RecordsSideBar);

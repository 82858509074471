import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import { displayDateFormat } from '../../../../constants';
export default class ContractTimeline extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { contrAssetBasicInfo } = this.props;
    const contractTimeWidth = 100 - (Math.round(moment([moment(contrAssetBasicInfo.contract.contractual_end_date).format('YYYY,MM,DD')]).diff([moment(new Date()).format('YYYY,MM,DD')], 'months', true)) / Math.round(moment([moment(contrAssetBasicInfo.contract.contractual_end_date).format('YYYY,MM,DD')]).diff([moment(contrAssetBasicInfo.contract.contractual_start_date).format('YYYY,MM,DD')], 'months', true)))*100;
    return(
      <div className="db-contract-timeline">
        <h5 className="para contract-timeline">
          Lease Timeline
        </h5>
        <p className="status-timeline">
          <span style={{width: contractTimeWidth + '%'}}></span>
        </p>
        <Row className="">
          <Col md="3">
            <h6 className="in-blue-bg">Starts</h6>
            <p className="in-blue-bg">{moment(contrAssetBasicInfo.contract.contractual_start_date).format(displayDateFormat)}</p>
          </Col>
          <Col md="3">
            <h6 className="in-blue-bg">Ends</h6>
            <p className="in-blue-bg">{moment(contrAssetBasicInfo.contract.contractual_end_date).format(displayDateFormat)}</p>
          </Col>
          <Col md="3">
            <h6 className="in-blue-bg">Time Remaining</h6>
            <p className="in-blue-bg">
              {
                Math.round(moment([moment(contrAssetBasicInfo.contract.contractual_end_date).format('YYYY,MM,DD')]).diff([moment(new Date()).format('YYYY,MM,DD')], 'months', true)) + ' Months'
              }
            </p>
          </Col>
        </Row>
      </div>
    )
  }
}

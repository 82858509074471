import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col, Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
import '../../assets/styles/records_v.scss';
import { mediaBaseUrl } from '../../../../constants';
import { getFileSize, getAccessToken } from '../../../../utils';
import ToolTipHover from '../../../../shared/components/ToolTipHover';
import { getLocalStorageInfo } from '../../../../utils';
import { imgStoragePath, displayDateTimeFormat } from '../../../../constants';
import moment from 'moment';
export default class BatchFileList extends Component{
  constructor(props) {
    super(props);
    this.state = {
      deleteFile: false,
      popoverOpen: false,
      modalFlag: false,
      previewCard: {},
      fileType: 'organised'
    };
  }

  renameFile = (item, type) =>{
    this.setState({
      modalFlag: !this.state.modalFlag,
      previewCard: item,
      error: '',
      fileType: type
    });
  }

  deleteFile = () => {
    this.setState(prevState => ({
      ...prevState,
      deleteFile: !this.state.deleteFile
    }));
  }


  deleteBatchFile = () =>{
    this.deleteFile()
    if(this.props.type != 'dataroom'){
      this.props.deleteBatchFile(this.props.listItem,'unorganised')
    }else{
      this.props.deleteBatchFile(this.props.listItem,'')
    }
  }

  updateField = (evt) =>{
    if(evt.target.value != '' && evt.target.value != null && evt.target.value != undefined){
      this.setState({
        previewCard:{...this.state.previewCard,name: evt.target.value},
        error: '',
      });
    }else {
      this.setState({
        error: 'File Name can not be empty'
      })
    }
  }

  previewImage = (path) =>{
    let url = ''
    url = decodeURIComponent(`${path}`);
    window.open(url, '_blank').focus();
  }

  render(){
    const { listItem } = this.props;
    return(
      <div className="asset-list" style={this.props.style} onClick={listItem.type == 'folder' ? this.props.changeDirectory : null}>
      {
        this.props.type == 'dataroom' ?
        <Row className="flex-centered">
          <Col md="8">
            <div className="flex-centered msn-block record-preview">
            {
              listItem.type == "folder" ?
                <img src={this.props.fileIcons.folder} alt="img" width="15"/>
              :<img src={listItem.extension ? this.props.fileIcons[listItem.extension] : ""} alt="img" width="15"/>
            }
            {
              listItem.type != 'folder' && listItem.extension != 'pdf' ?
                <Link onClick={() => this.previewImage(listItem.path)}><span>{listItem.name}</span></Link>
              : listItem.type != 'folder' ? <Link to={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${listItem.path}&fileName=${listItem.name}`} target='_blank'><span>{listItem.name}</span></Link> : <Link><span>{listItem.name}</span></Link>
            }
            </div>
          </Col>
          <Col md="2" className="flex-centered msn-block record-preview"><Link><span>{listItem.file_count > 0 ? listItem.file_count + ' Files' : ''}</span></Link></Col>
          <Col md="2" className="flex-centered msn-block record-preview">
            <span>{listItem.size > 0 ? getFileSize(listItem.size) : ''}</span>
            {
              listItem.type == 'file' ?
              <span>
                <img src={imgStoragePath + 'record_ml_move.png'} id={`info_${this.props.index}`} onClick={this.props.popoverOpen} className="ml-actions" style={{marginLeft: '10px'}}/>
                <img src={imgStoragePath + 'record_ml_delete.png'} onClick={() => this.setState({deleteFile: true})} className="ml-actions"/>
                <img src={imgStoragePath + 'record_ml_edit.png'} id={`organised_${this.props.index}`} onClick={() => this.renameFile(listItem, 'organised')} className="ml-actions"/>
                <ToolTipHover placement="top" tagetId={`organised_${this.props.index}`}>
                  Rename file
                </ToolTipHover>
              </span>
              :null
            }
          </Col>
        </Row>
        :<Row className="flex-centered">
          <Col md="5">
            <div className="flex-centered msn-block record-preview">
            {
              listItem.type == "folder" ?
                <img src={this.props.fileIcons.folder} alt="img" width="15"/>
              :<img src={listItem.extension ? this.props.fileIcons[listItem.extension] : ""} alt="img" width="15"/>
            }
            {
              listItem.type != 'folder' && listItem.extension != 'pdf' ?
                <Link onClick={() => this.previewImage(listItem.path)}><span>{listItem.name}</span></Link>
              : listItem.type != 'folder' ? <Link to={`https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${listItem.path}&fileName=${listItem.name}`} target='_blank'><span>{listItem.name}</span></Link> : <Link><span>{listItem.name}</span></Link>
            }
            </div>
          </Col>
          <Col md="1" className="flex-centered msn-block record-preview"><span>{listItem.size > 0 ? getFileSize(listItem.size) : ''}</span></Col>
          <Col md="2" className="flex-centered msn-block record-preview">
            <img className="user-logo" src={ listItem.created_by.profile_pic != null ? `${listItem.created_by.profile_pic}` : `${imgStoragePath}user.png`} alt="img" />
            <Link><span>{listItem.created_by ? `${listItem.created_by.first_name} ${listItem.created_by.last_name}` : '--'}</span></Link>
          </Col>
          <Col md="2" className="flex-centered msn-block record-preview"><Link><span>{listItem.modified ? moment(listItem.modified).format(displayDateTimeFormat) : '--'}</span></Link></Col>
          <Col md="2" className="flex-centered msn-block record-preview">
            <img src={imgStoragePath + 'record_ml_move.png'} id={`info_${this.props.index}`} onClick={this.props.popoverOpen} className="ml-actions"/>
            <img src={imgStoragePath + 'record_ml_delete.png'} onClick={() => this.setState({deleteFile: true})} className="ml-actions"/>
            <img src={imgStoragePath + 'record_ml_edit.png'} id={`unorganised_${this.props.index}`} onClick={() => this.renameFile(listItem, 'unorganised')} className="ml-actions"/>
              <ToolTipHover placement="top" tagetId={`unorganised_${this.props.index}`}>
                Rename file
              </ToolTipHover>
          </Col>
        </Row>
      }
      {
        this.state.deleteFile ?
        <Modal isOpen={this.state.deleteFile} centered={true} toggle={this.deleteFile} className="modal-dialog-centered">
          <ModalHeader toggle={this.deleteFile}>Delete</ModalHeader>
          <ModalBody>
            <div className="delete-logs">
              <h4>Are you sure you want to delete "{listItem.name}"</h4>
                <ul className="list-inline">
                <li className="list-inline-item">
                  <button className="btn btn-danger" onClick={() => this.deleteBatchFile()}>Yes</button>
                </li>
                <li className="list-inline-item">
                  <button className="btn btn-secondary" onClick={() => this.setState({deleteFile: false})}>No</button>
                </li>
              </ul>
            </div>
          </ModalBody>
        </Modal>
        :null
      }
      {
        <Modal isOpen={this.state.modalFlag} centered={true} toggle={this.renameFile} className={this.props.className}>
          <ModalHeader toggle={this.renameFile}>Rename {this.state.previewCard.type}</ModalHeader>
          <ModalBody>
            <div className="delete-logs">
              <Input type="text" ref="file_name" defaultValue={this.state.previewCard.name} onChange={(evt) => this.updateField(evt)}/>
              <p style={{fontSize:"10px",textAlign:"left",marginLeft:"5px",marginTop:"3px"}}>{this.state.previewCard.folder && this.state.previewCard.folder.file_naming_conventions ? "Format:": null}</p>
                  <ul className="list-unstyled">
                  {this.state.previewCard.folder && this.state.previewCard.folder.file_naming_conventions ? this.state.previewCard.folder.file_naming_conventions.map(format => <li style={{fontSize:"10px",textAlign:"left",marginLeft:"5px",marginTop:"3px"}}>{format}</li> ) : null}
                  </ul>
                <ul className="list-inline">
                <li className="list-inline-item">
                  <button className="btn btn-success" disabled={this.state.error != '' ? true : false} onClick={() => {this.props.renameRecord(this.state.previewCard, {page: this.props.currentPage}, this.state.fileType); this.renameFile(this.state.previewCard);}}>Save</button>
                  <h6 className="error-msg">{this.state.error}</h6>
                </li>
              </ul>
            </div>
          </ModalBody>
        </Modal>
      }
      </div>
    )
  }
}

import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import NumericLabel from 'react-pretty-numbers';
import { monthsObj, utilizationFormat, imgStoragePath } from '../../../../constants';
import { getLocalStorageInfo } from '../../../../utils';
import editIcon from '../../../../shared/assets/img/edit_icon.svg';
export default class RentalList extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { index, rental } = this.props;
    return(
      <div className="asset-list">
        <Row key={index} className="flex-centered cashflow-row">
          <Col className="cash-blk cash-info para-ui border-col">
            {monthsObj[rental.month.slice(4,6)]} {rental.month.slice(0,4)}
          </Col>
          <Col className="cash-blk cash-info para-ui border-col">
            <NumericLabel params={utilizationFormat}>
              {rental.hours}
            </NumericLabel>
          </Col>
          <Col className="cash-blk cash-info para-ui border-col">
            <NumericLabel params={utilizationFormat}>
              {rental.cycles}
            </NumericLabel>
          </Col>
          <Col className="cash-blk cash-info para-ui border-col">
            <NumericLabel params={utilizationFormat}>
              {rental.block_hour}
            </NumericLabel>

          </Col>
          <Col className="cash-blk cash-info para-ui border-col">
              {rental.remarks}
              {
                getLocalStorageInfo().user.permission['contracts']['utilization'].indexOf('U') != -1 ?
                <span className="hidden-xs" style={{cursor:"pointer", display: 'block'}}>
                  {
                    getLocalStorageInfo().user.user_role != 'lessee' ?
                    rental.editable ? <img onClick={() => this.props.editUtil()} style={{width:'16px', position: 'absolute', right: '10px', bottom:"5px"}} src={editIcon} alt="" /> : null
                  :null
                  }
                </span>:null
              }
          </Col>
        </Row>
      </div>
    )
  }
}

import React, { Component, Fragment } from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import { Row, Col } from 'reactstrap';
import Select , { Creatable } from 'react-select';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../Elements';
import { leaseTypeDrpdwn, imgStoragePath } from '../../../../constants';
import { checkString, checkValidDate, checkNumber, checkDateDiff } from '../../../../formValidator';
import { uploadLesseeLogoAc } from '../../../../shared/actionCreators';
import { toastFlashMessage } from '../../../../utils';
export default class AddContractForm extends Component {
  constructor(props){
    super(props);
    this.state = {
      newLessee:false,
      contract: {
        asset_type: this.props.params.type == 'engine' ? 2 : this.props.params.type === 'aircraft' ? 1 : this.props.params.type === 'apu' ? 3 : this.props.params.type === 'propeller' ? 5 : this.props.params.type === 'lg' ? 4 : this.props.params.type === 'fan' ? 6 : this.props.params.type === 'hpc' ? 7 : this.props.params.type === 'hpt' ? 8 : 9 ,
        lease_type: '',
        contractual_start_date:null,
        contractual_end_date: null,
        lessee_id: '',
        operator_id: '',
        lease_term : '',
        same_operator_lessee : false,
        contact_address:'',
        country:'',
        logo:''
      },
      error:{},
    }
    this.uploadLesseeLogoAc = uploadLesseeLogoAc.bind(this)
  }

  updateForm = (type, value) => {
    if(type =='contractual_start_date' || type == 'contractual_end_date'){
      this.updateDate(type, value);
    }else{
      this.setState(prevState => ({
        ...prevState,
        contract: {
          ...prevState.contract,
          [type]: value
        },
        error:{
          ...prevState.error,
          [type]:''
        }
      }));
    }
  }
  updateEmailForm = (value) => {
    if(value){
      if(typeof(value.id) == "string") {
        this.setState(prevState => ({
          ...prevState,
          contract:{
            ...prevState.contract,
            lessee_id:{
              name:value.name,
              logo:'',
              contact_address:'',
              country:''
            },
          },
          newLessee:true
        }))
      }else {
        this.setState(prevState => ({
          ...prevState,
          contract:{
            ...prevState.contract,
            lessee_id:value.id
          },
          newLessee:false
        }))
      }
    }else {
      this.setState(prevState => ({
        ...prevState,
        contract:{
          ...prevState.contract,
          lessee_id:''
        },
        newLessee:false
      }))
    }
  }
  updateNewLessee = (type, value) => {
    this.setState(prevState => ({
      ...prevState,
      contract:{
        ...prevState.contract,
        lessee_id:{
          ...prevState.contract.lessee_id,
          [type]:value
        }
      }
    }))
  }
  uploadLogo = (file) => {
    let formData = new FormData()
    formData.append('file', file)
    this.uploadLesseeLogoAc(formData)
    .then(response => {
      if(response.data.statusCode == 200){
        this.setState(prevState => ({
          ...prevState,
          contract:{
            ...prevState.contract,
            lessee_id:{
              ...prevState.contract.lessee_id,
              logo:response.data.data.path
            }
          }
        }))
        toastFlashMessage(response.data.message, 'success')
      }else {
        toastFlashMessage(response.data.message, 'error')
      }
    })
  }

  updateDate = (type,value) => {
    if(value != 'Invalid date'){
      let leaseTerm = this.state.lease_term;
      let startDate = '';
      let endDate = '';
      if(type == 'contractual_start_date'){
        leaseTerm = moment(this.state.contract.contractual_end_date).diff(moment(value), 'months', true);
      }else{
        leaseTerm = moment(value).diff(moment(this.state.contract.contractual_start_date), 'months', true);
      }
      this.setState(prevState => ({
        ...prevState,
        contract:{
          ...prevState.contract,
          [type]: value,
          lease_term: Math.round(leaseTerm)
        },
        error: {
          ...prevState.error,
          [type]: '',
          lease_term: ''
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        contract:{
          ...prevState.contract,
          [type]: value,
          lease_term: ''
        }
      }))
    }
  }
 createContract = (e) => {
    e.preventDefault();
    let data = Object.assign({}, this.state.contract);
    if(data.same_operator_lessee){
      data = {
        ...data,
        operator_id: data.lessee_id
      };
    }
    data = {
      ...data,
      asset_slug: this.props.slug,
    }
    if(!data.same_operator_lessee){
      var validateNewInput = {
        lease_type: checkString({value: data.lease_type, required: true, minLength: '', maxLength: '', message: 'Please select Lease Type'}),
        lessee_id: checkString({value: data.lessee_id, required: true, minLength: '', maxLength: '', message: 'Please select Lessee'}),
        contractual_start_date: checkValidDate({value: data.contractual_start_date, required: true, minLength: '', maxLength: '', message: 'Please enter Lease Start Date'}),
        contractual_end_date: checkValidDate({value: data.contractual_end_date, required: true, minLength: '', maxLength: '', message: 'Please enter Lease End Date'}),        operator_id: checkNumber({value: data.operator_id, required: true, message: 'Please select operator'}),
      };
      if(data.contractual_end_date && data.contractual_end_date.trim() != '' && parseInt(moment(data.contractual_end_date).diff(moment(data.contractual_start_date), 'days')) < 0){
        validateNewInput = {
          ...validateNewInput,
          contractual_end_date: 'Lease end date can not be before start date'
        }
      }
      if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
        this.props.toggleEditSideBar();
        this.props.createContract(data);
      }else{
        this.setState({
          error: validateNewInput
        });
      }
    }else{
      var validateNewInput = {
        lease_type: checkString({value: data.lease_type, required: true, minLength: '', maxLength: '', message: 'Please select Lease Type'}),
        lessee_id: checkString({value: data.lessee_id, required: true, minLength: '', maxLength: '', message: 'Please select Lessee'}),
        contractual_start_date: checkValidDate({value: data.contractual_start_date, required: true, minLength: '', maxLength: '', message: 'Please enter Lease Start Date'}),
        contractual_end_date: checkValidDate({value: data.contractual_end_date, required: true, minLength: '', maxLength: '', message: 'Please enter Lease End Date'}),
      };
      if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
        this.props.toggleEditSideBar();
        this.props.createContract(data);
      }else{
        this.setState({
          error: validateNewInput
        });
      }
    }
  }

  render() {

    const { operators, lessees, type, techConstants, } = this.props;
    const { contract, error, newLessee } = this.state;
    return (
      <form onSubmit={(e) => this.createContract(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
          <Row>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Lease Type
                  <sup className="reqStar"> * </sup>
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Lease Type'
                  keyParam="lease_type"
                  value={contract.lease_type}
                  type={type}
                  options={techConstants.filter(item => item.type=="lease_type")}
                  updateField={this.updateForm}
                  labelKey= 'label'
                  valueKey='value'
                />
              <h6 className="error-msg">{error.lease_type}</h6>
              </FieldGroup>
            </FieldCol>
            <div style={newLessee ? {width: '100%', background: '#f3f3f3', margin: '45px', paddingTop: '20px'} : {width: '100%'}}>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Lessee <sup className="reqStar"> * </sup></FieldLabel>
                    <Creatable
                      value={contract.lessee_id}
                      options={lessees}
                      isClearable={true}
                      onChange={this.updateEmailForm}
                      className="custom-select-block"
                      placeholder="Add or Select Lessee"
                      valueKey="id"
                      labelKey="name"
                      multi={false}
                   />
                  <h6 className="error-msg">{error.lessee_id}</h6>
                </FieldGroup>
              </FieldCol>
              {
                newLessee ?
                <Fragment>
                  <FieldCol md="12">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label">
                        Contact Address
                      </FieldLabel>
                      <FieldInput
                        value={contract.lessee_id ? contract.lessee_id.contact_address:'' }
                        type={type}
                        keyParam="contact_address"
                        updateField={this.updateNewLessee}
                      />
                    <h6 className="error-msg">{error.lease_term}</h6>
                    </FieldGroup>
                  </FieldCol>
                  <FieldCol md="12">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label">
                        Country
                      </FieldLabel>
                      <FieldInput
                        value={contract.lessee_id ? contract.lessee_id.country:'' }
                        type={type}
                        keyParam="country"
                        updateField={this.updateNewLessee}
                      />
                    <h6 className="error-msg">{error.lease_term}</h6>
                    </FieldGroup>
                  </FieldCol>
                  <FieldCol md="12">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label" id="upload-image" style={{position:'relative', cursor:'pointer'}}>
                        Upload Logo <img src={imgStoragePath+'phase-2/upload_template.png'} width={20} />
                        <input type="file" accept="image/*" onChange={(e)=>this.uploadLogo(e.target.files[0])} style={{position:'absolute', left:'0px', opacity:'0'}}/>
                      </FieldLabel>
                    <h6 className="error-msg">{error.lease_term}</h6>
                    </FieldGroup>
                  </FieldCol>

                </Fragment> : null
              }
            </div>
            <Col md="12">
              <div className="form-group">
               <label className="label">
                  <input type="checkbox"
                   value={contract.same_operator_lessee}
                   onChange = {(e) => this.updateForm('same_operator_lessee', e.target.checked)}
                   style={{ marginRight: '4px'}}
                  />
                <span>Operator is same as Lessee</span>
               </label>
              </div>
            </Col>
            {
              !contract.same_operator_lessee ?
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Operator
                  <sup className="reqStar"> * </sup>
                  </FieldLabel>
                  <FieldSelect
                    placeholder='Select Operator'
                    keyParam="operator_id"
                    value={contract.operator_id}
                    type={type}
                    options={operators}
                    updateField={this.updateForm}
                    labelKey="name"
                    valueKey="id"
                  />
                  <h6 className="error-msg">{error.operator_id}</h6>
                </FieldGroup>
              </FieldCol>:null
            }
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Start Date
                  <sup className="reqStar"> * </sup>
                </FieldLabel>
                <FieldDate
                  value={contract.contractual_start_date}
                  type={type}
                  keyParam="contractual_start_date"
                  updateField={this.updateForm}
                />
              <h6 className="error-msg">{error.contractual_start_date}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  End Date
                  <sup className="reqStar"> * </sup>
                </FieldLabel>
                <FieldDate
                  value={contract.contractual_end_date}
                  type={type}
                  keyParam="contractual_end_date"
                  updateField={this.updateForm}
                  minDate={moment(contract.contractual_start_date).add(1, 'months')}
                />
              <h6 className="error-msg">{error.contractual_end_date}</h6>
              </FieldGroup>
            </FieldCol>

            <FieldCol md="12">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Lease Term (Months)
                </FieldLabel>
                <FieldInput
                  value={contract.lease_term ? contract.lease_term:'' }
                  type={type}
                  keyParam="lease_term"
                  updateField={this.updateForm}
                  disable = {true}
                  style={{background: "#FFF"}}
                />
              <h6 className="error-msg">{error.lease_term}</h6>
              </FieldGroup>
            </FieldCol>
          </Row>
        </div>
        <div className="submit-block">
          <input type="submit" className="primary-btn" value={type === 'edit' ? "Save Changes" : "Save"}/>
          <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
        </div>
      </form>
    )
  }
}

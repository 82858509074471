import * as actions from '../actions';
import { downloadFileType, toastFlashMessage } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel'
import { globalGetService, globalPutService, globalPostService, globalDeleteService,globalExportService, globalStaticGetService, globalDownloadGetService } from '../../../../globalServices/';


export const fetchProjectTrackerAc = (props, queryParam = {}) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalGetService(`/technical/project-tracker/`, queryParam)
		.then(response => {
			dispatch(triggerLoader(false));
			if(response.data.statusCode == 200){
				trackActivity('Page Visited', {
					application_module: 'Inspection',
					page_title: 'Project Tracker'
				})
				dispatch({
					type: actions.GET_PRJ_TRACKER_DETLS,
					payload: response.data.data
				})
			}
		})
	}
}

export const updateRemarksAc = (projectslug,value) => {
	return (dispatch) => {
		dispatch({
			type: actions.TRACKER_EXPORT_LOADER,
			payload: true
	  });
		globalPostService(`technical/project/${projectslug}/update-remark/`,{remarks:value})
		.then(response => {
			dispatch({
				type: actions.TRACKER_EXPORT_LOADER,
				payload: false
			});
			if(response.data.statusCode == 200){
				dispatch({
					type: actions.UPDATE_REMARKS,
					payload: {slug:projectslug,remarks:value}
				})
			}
		})
	}
}
export const fetchProjectTrackerExpansionAc = (props, queryParam = {}) => {
	return (dispatch) => {
		dispatch({
			type: actions.INFINITE_LOADER,
			payload: true
		});
		globalGetService(`/technical/project-tracker/`, queryParam)
		.then(response => {
			dispatch({
				type: actions.INFINITE_LOADER,
				payload: false
			});
			if(response.data.statusCode == 200){
				dispatch({
					type: actions.GET_PRJ_TRACKER_DETLS_EXPANSION,
					payload: response.data.data.projects
				})
			}
		})
	}
}

export const fetchCorTrackerAc = (props, queryParam = {}) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		let filterObj = Object.assign({}, queryParam);
    if(filterObj.date){
      let issueDate = queryParam.date.split(',');
      delete filterObj['date'];
      issueDate.map((date, index) => {
        if(index == 0 && date != null && date != 'null'){
          filterObj = {
            ...filterObj,
            startDate:date
          }
        }
        if(index == 1 && date != null && date != 'null'){
          filterObj = {
            ...filterObj,
            endDate:date
          }
        }
      })
    }
		globalGetService('technical/cor-tracker/', filterObj)
		.then(response => {
			dispatch(triggerLoader(false));
			if(response.data.statusCode == 200){
				trackActivity('Page Visited', {
					application_module: 'Inspection',
					page_title: 'COR Tracker'
				})
				dispatch({
					type: actions.GET_COR_TRACKER_DETLS,
					payload: response.data.data
				})
			}
		})
	}
}
export const fetchCorTrackerExpAc = (props, queryParam = {}) => {
	return (dispatch) => {
		dispatch({
			type: actions.INFINITE_LOADER,
			payload: true
		});
		let filterObj = Object.assign({}, queryParam);
    if(filterObj.date){
      let issueDate = queryParam.date.split(',');
      delete filterObj['date'];
      issueDate.map((date, index) => {
        if(index == 0 && date != null && date != 'null'){
          filterObj = {
            ...filterObj,
            startDate:date
          }
        }
        if(index == 1 && date != null && date != 'null'){
          filterObj = {
            ...filterObj,
            endDate:date
          }
        }
      })
    }
		globalGetService('technical/cor-tracker/', filterObj)
		.then(response => {
			dispatch({
				type: actions.INFINITE_LOADER,
				payload: false
			});
			if(response.data.statusCode == 200){
				dispatch({
					type: actions.GET_COR_TRACKER_DETLS_EXP,
					payload: response.data.data.projects
				})
			}
		})
	}
}

export const exportCorProjectDetailAc = (projectSlug, queryParams) => {
	return (dispatch) => {
		dispatch(triggerLoader(true));
		globalExportService('technical/project/'+projectSlug+'/cor/', queryParams)
	  .then(response => {
			dispatch(triggerLoader(false));
			downloadFileType(response, 'Cor_Tracker.', queryParams.download)
	  })
	}
}

export const fetchManPowerTrackerAc = (props, queryParam = {}) => {

	return (dispatch) => {
		dispatch(triggerLoader(true));
		let filterObj = Object.assign({}, queryParam);
    if(filterObj.date){
      let issueDate = queryParam.date.split(',');
      delete filterObj['date'];
      issueDate.map((date, index) => {
        if(index == 0 && date != null && date != 'null'){
          filterObj = {
            ...filterObj,
            startDate:date
          }
        }
        if(index == 1 && date != null && date != 'null'){
          filterObj = {
            ...filterObj,
            endDate:date
          }
        }
      })
    }

		globalGetService('technical/manpower-tracker/', filterObj)
		.then(response => {
			dispatch(triggerLoader(false));
			if(response.data.statusCode == 200){
				trackActivity('Page Visited', {
					application_module: 'Inspection',
					page_title: 'Man Power Tracker'
				})
				dispatch({
					type: actions.GET_MAN_POWER_TRACKER_DETLS,
					payload: response.data.data
				})
			}
		})
	}
}
export const fetchManPowerTrackerExpansionAc = (props, queryParam = {}) => {

	return (dispatch) => {
		dispatch({
			type: actions.INFINITE_LOADER,
			payload: true
		});
		let filterObj = Object.assign({}, queryParam);
    if(filterObj.date){
      let issueDate = queryParam.date.split(',');
      delete filterObj['date'];
      issueDate.map((date, index) => {
        if(index == 0 && date != null && date != 'null'){
          filterObj = {
            ...filterObj,
            start_date:date
          }
        }
        if(index == 1 && date != null && date != 'null'){
          filterObj = {
            ...filterObj,
            end_date:date
          }
        }
      })
    }

		globalGetService('technical/manpower-tracker/', filterObj)
		.then(response => {
			dispatch({
				type: actions.INFINITE_LOADER,
				payload: false
			});
			if(response.data.statusCode == 200){
				dispatch({
					type: actions.GET_MAN_POWER_TRACKER_DETLS_EXPANSION,
					payload: response.data.data
				})
			}
		})
	}
}


export const fetchManPowerTrackerUtilizationAc = (filter) => {
	let filterParam = filter;
  filterParam = {
    ...filterParam,
    utilization : true
  }
	return (dispatch) => {
		dispatch({
			type: actions.TRACKER_EXPORT_LOADER,
			payload: true
		});
		globalGetService('technical/manpower-tracker/', filterParam)
		.then(response => {
			dispatch({
				type: actions.TRACKER_EXPORT_LOADER,
				payload: false
			});
			if(response.data.statusCode == 200){
				dispatch({
					type: actions.UTILIZATION_GRAPH,
					payload: response.data.data
				})
			}
		})
	}
}

export const downLoadTrackerFormAc = (url,fileType,fileName,queryParam) => {
	let filterObj = Object.assign({}, queryParam);
  filterObj = {
    ...filterObj,
    download : fileType
  }
	if(filterObj.date){
		let issueDate = queryParam.date.split(',');
		delete filterObj['date'];
		issueDate.map((date, index) => {
			if(index == 0 && date != null && date != 'null'){
				filterObj = {
					...filterObj,
					startDate:date
				}
			}
			if(index == 1 && date != null && date != 'null'){
				filterObj = {
					...filterObj,
					endDate:date
				}
			}
		})
	}
	return (dispatch) => {
	  dispatch({
			type: actions.TRACKER_EXPORT_LOADER,
			payload: true
	  });
	  if(url.includes('project')){
			globalGetService('technical/'+url+'/', filterObj)
		  .then(response => {
				dispatch({
			  	type: actions.TRACKER_EXPORT_LOADER,
			  	payload: false
				});
				trackActivity('Report Exported',{
					file_name: 'Project Tracker',
					file_type: fileType.toUpperCase(),
					application_module: 'Inspection'
				})
				toastFlashMessage(response.data.message, 'success')
		  })
		}else{
			globalGetService('technical/'+url+'/', filterObj)
		  .then(response => {
				trackActivity('Report Exported',{
					file_name: fileName,
					file_type: fileType.toUpperCase(),
					application_module: 'Inspection'
				})
				toastFlashMessage(response.data.message, 'success')
				dispatch({
			  	type: actions.TRACKER_EXPORT_LOADER,
			  	payload: false
				});
		  })
		}
	}
  }





export const triggerLoader = (flag) => {
	return (dispatch) => {
		dispatch({
			type: actions.TRACKER_LOADER,
			payload: flag
		});
	}
}

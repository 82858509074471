import React, { Component, Fragment } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { globalGetService } from '../../../globalServices';
import { toastFlashMessage } from '../../../utils';
import moment from 'moment';
import Select from 'react-select';
import {ListLoader } from '../../../shared';
import 'react-select/dist/react-select.css';
import { backendDateFormat, fieldDateFormat } from '../../../constants';
import { FieldDate, FieldGroup, FieldLabel} from '../../contracts/Elements';
import { checkValidDate } from '../../../formValidator';
class CustAcctStatement extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checked:'xlsx',
      start_date:'',
      end_date:'',
      updateFormCust: false,
      error:{},
      lessor_name_id:[],
      pageLoader:false
    }
  }
  custAcctStatement = () => {
    const { checked, start_date, end_date, lessor_name_id } = this.state
    let validateNewInput = {
      start_date: checkValidDate({value: start_date, required:true, minLength:'', maxLength:'', message:'Please enter From Date'}),
      end_date: checkValidDate({value: end_date, required:true, minLength:'', maxLength:'', message:'Please enter To Date'})
    }
    let lessorStr = lessor_name_id.map(item => (`(${item.id},${item.lessor_level})`)).toString()
    if(Object.keys(validateNewInput).every((k) => {return validateNewInput[k] == ''})){
      this.setState({
        pageLoader:true
      })
      globalGetService(`/console/export-statement-report/aircraft/?file_type=${checked}&start_date=${moment(start_date).format(backendDateFormat)}&end_date=${moment(end_date).format(backendDateFormat)}&statement_type=account&lessor_name_id=${lessorStr}`)
      .then(response => {
        this.setState({
          pageLoader:false
        })
        if(response.data.statusCode == 200){
          toastFlashMessage(response.data.message, 'success')
          this.setState({
            error:{}
          })
        }else {
          toastFlashMessage(response.data.message, 'error')
        }
      })
    }else {
      this.setState({
        error:validateNewInput
      })
    }

  }
  onChangeFromDate = (key, value) => {
    this.setState(prevState => ({
      ...prevState,
      [key]:value,
      end_date:''
    }))
  }
  onChangeToDate = (key, value) => {
    this.setState(prevState => ({
      ...prevState,
      [key]:value,
    }))
  }
  updateOwner = (key, value) => {
    this.setState((prevState => ({
      ...prevState,
      [key]:value,
    })))

  }
  render() {
    const { checked, start_date, end_date, updateFormCust , error, lessor_name_id, pageLoader} = this.state
    const { ownerList } = this.props
    return (
      <Fragment>
        {pageLoader ? <ListLoader /> : null}
        <div className="export-module-card cust-cashflow-card" style={{height:updateFormCust ? '500px': '340px'}} >
          <div className="rpt-card-header">
            <h2>Customer Account Statement
              <Button color="primary" size="sm" style={{float:'right', marginTop:'-4px', backgroundColor:'#3f51b5', borderColor:'#3f51b5'}} onClick={() =>  this.custAcctStatement()}>Export</Button>
            </h2>
          </div>
          <div className="rpt-card-body">
            <div className="export-items">
              <div className="">
                <Row className="flex-centered">
                  <Col xs="5">
                    <div className="export-avial">
                      <h6>Export Format</h6>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <label >
                              <input
                                name="CustAcctStatement"
                                checked={checked == 'xlsx' ?  true : false}
                                type="radio"
                                onChange={() =>  this.setState({checked:'xlsx'})}

                              />
                              <span>XL</span>
                            </label>
                          </li>
                          <li className="list-inline-item">
                            <label >
                              <input
                                name="CustAcctStatement"
                                checked={checked == "csv" ?  true :false}
                                type="radio"
                                onChange={() =>  this.setState({checked:'csv'})}
                              />
                              <span>CSV</span>
                            </label>
                          </li>
                        </ul>
                    </div>
                  </Col>
                </Row>
                <div className="filter-avail">
                  <ul className="list-inline flex-centered">
                    <li className="list-inline-item">
                   <FieldGroup className="form-group">
                     <FieldLabel className="label" >From Date</FieldLabel>
                     <FieldDate
                       value={start_date}
                       updateField={this.onChangeFromDate}
                       keyParam="start_date"
                       focus={() => this.setState({updateFormCust:true, error:{...error, start_date:''}})}
                       blur={() =>  this.setState({updateFormCust:false})}
                       maxDate={moment()}
                    />
                  <h6 className="error-msg">{error.start_date}</h6>
                   </FieldGroup>
                    </li>
                    <li className="list-inline-item">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label">To Date</FieldLabel>
                      <FieldDate
                        value={end_date}
                        updateField={this.onChangeToDate}
                        keyParam="end_date"
                        focus={() => this.setState({updateFormCust:true, error:{...error, end_date:''}})}
                        blur={() =>  this.setState({updateFormCust:false})}
                        disable={!start_date ?  true : false}
                        minDate={moment(start_date)}
                        maxDate={moment(start_date).diff(moment(), 'month') === 0 ? moment(new Date()) :moment(start_date).add(120, 'M')}
                      />
                    <h6 className="error-msg">{error.end_date}</h6>
                    </FieldGroup>
                    </li>
                  </ul>
                  <Row>
                    <Col md="12" >
                      <FieldGroup className="form-group">
                        <FieldLabel className="label">Lessor</FieldLabel>
                          <Select
                            name="form-field-name"
                            placeholder={<p style={{marginLeft:'10px'}}>Select Lessors</p>}
                            className="custom-select-block"
                            value={lessor_name_id}
                            labelKey="name"
                            valueKey="id"
                            options = {ownerList}
                            multi={true}
                            onChange={(target) => this.updateOwner('lessor_name_id', target)}
                            style={{width:'500px', background:'#fff'}}
                            onBlur={() =>  this.setState({updateFormCust:false })}
                            onFocus={() =>  this.setState({updateFormCust:true})}
                          />
                        <h6 className="error-msg">{error.asset_type_id}</h6>
                      </FieldGroup>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>

    )
  }
}
export default CustAcctStatement;

import React, {Component} from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { SidebarMntcCalc, ListLoader } from '../../../../shared/';
import '../assets/styles/MRFinance.scss';
import { TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalBody,   Card, CardBody } from 'reactstrap';
import { validMRFinanceGenGraph, engineValidator } from '../../../../formValidator/validMRFinanceGenGraph';
import classnames from 'classnames';
import { DeRatePopUp} from '../../MRFinanceGraph/components';
import { UPDATE_DE_RATE_OP } from '../../MRFinanceGraph/actions';
import { getCustomeVariableAc } from '../actionCreators';
import { FIXED_POSITIONS_VALUE } from '../../../../shared/actions';
import { getLocalStorageInfo, getCookie } from '../../../../utils';
import {
  AirframeMRDetail,
  EngineMRDetail,
  LandingGearMRDetail,
  ReviewFinanceRptWrapper,
  BreakUpLLPMRCostModal,
  CustomCycleRemainingModal,
  LLPToBeReplacedModal,
  SelectLLPToReplaceModal,
  BreakUpEngineMRCostModel,
  EngineMRWrapper,
  LLPStatusCard,
  RegionSidebar
 } from '../components';
import {
  engineWorkScopeCalAc,
  fetchMrLogAc,
  selectLLPToReplaceAc,
  downLoadLLPAc,
  customCycleRemainAc,
  carryOverNSVAc,
  replacedTheLLPSelectedAc,
  breakUpEngineCostAc,
  genMRFinanceGraphAc,
  changeWorkScopeAc
 } from '../actionCreators';
 import {
 deRateVariableAc,
 exportDeRateAc,
 calculateDeRateAc

 } from '../../MRFinanceGraph/actionCreators';
import {
  BREAKUP_LLP_MR_COST,
  BREAKUP_ENGINE_MR_COST,
  LLP_TO_BE_REPLACED,
  UPDATE_SELECT_LLP_TO_REPLACE,
  UPDATE_CUSTOM_THE_LLP_CYCLE,
  COMPARE_CUSTOM_CR,
  UPDATE_BREAKUP_ENGINE_MR_COST,
  REVIEW_MR_LOADER,
  CLEAR_MR_REVIEW_RPT
 } from '../actions';
 import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
class ReviewFinanceRpt extends Component{
  constructor(props){
      super(props);
      this.state = {
        modal: false,
        activeTab: 0,
        enginesError: {},
        mrReportError: {},
        navActiveCls: 'engines',
        flag: false,
        fixedBar: false,
        regionOpen: false,
      };
      this.toggleTab = this.toggleTab.bind(this);
    }

  componentDidMount(){
      this.props.fetchMrLog(this.props.params.id);
      this.props.fetchDeRate();
      window.addEventListener('scroll', this.handleScroll);
      let cookieVal = getCookie();
      let headerHeight = document.body.contains(document.getElementById('header')) ? document.getElementById('header').clientHeight : 0 ;
      let maintenanceHeight = document.body.contains(document.getElementById('maintenance')) ? document.getElementById('maintenance').clientHeight : 0 ;
      let contractHeaderHeight = document.body.contains(document.getElementById('contract-header')) ? document.getElementById('contract-header').clientHeight : 0;
      if(cookieVal){
        this.props.fixedPositionFn('closeMaintenance',false);
        this.props.fixedPositionFn('maintenanceBarHeight',0);
        this.props.fixedPositionFn('subHeaderTop', headerHeight);
        this.props.fixedPositionFn('recordNavTop', contractHeaderHeight);


      }else{
        this.props.fixedPositionFn('closeMaintenance',true);
        this.props.fixedPositionFn('maintenanceBarHeight',maintenanceHeight);
        this.props.fixedPositionFn('subHeaderTop',maintenanceHeight + headerHeight);
        this.props.fixedPositionFn('recordNavTop', contractHeaderHeight);

      }
    }

    componentWillMount(){
      window.location.assign(`https://mr-calculator.sparta.aero/mr-calculator/intermediate/${this.props.params.id}`)
    }

  componentWillUnmount(){
      window.removeEventListener('scroll', this.handleScroll);
      this.props.clearReport();
    }
  toggleModal = (engineNo) => {
      if(!this.state.modal){
        this.props.updateDeRateOp({
          engineNo: engineNo,
          flag: false,
          data: []
        })
      }else{

        this.props.updateDeRateOp({
          engineNo: '',
          flag: false,
          data: []
        })
      }
      this.setState(prevState => ({
        ...prevState,
        modal: !prevState.modal
      }));

    }
  calculateDeRate = (data, type) => {
      let deRateObj = Object.assign({}, data);
      deRateObj = {
        ...deRateObj,
        engine_no: parseInt(this.props.deRateMatrix.engineNo)
      }
      delete deRateObj['incRatio'];
      if(type == 'table'){
        this.props.calculateDeRate(deRateObj, this.props.params.id);
      }else{
        this.props.exportDeRate(deRateObj, this.props.params.id);
      }
    }
  updateErrorCode = (type, keyParam, innerKey) => {
      if(type == 'airframe'){
        this.setState(prevState => ({
          ...prevState,
          mrReportError: {
            ...prevState.mrReportError,
            airframe: {
              ...prevState.mrReportError.airframe,
              [keyParam]: {
                ...prevState.mrReportError.airframe[keyParam],
                [innerKey]: ''
              }
            }
          }
        }));
      }else{
        this.setState(prevState => ({
          ...prevState,
          mrReportError: {
            ...prevState.mrReportError,
            [type]: {
              ...prevState.mrReportError[type],
              [keyParam]: ''
            }
          }
        }));
      }

    }
  toggleRegion = () => {
      this.setState(prevState => ({
        regionOpen: !prevState.regionOpen
      }));
    }
  handleScroll = (e) => {
      let tabsArray = [];
      if(this.props.reviewMRReport.engines){
        tabsArray.push('engines');
      }
      if(this.props.reviewMRReport.airframe){
        tabsArray.push('airframe');
      }
      if(this.props.reviewMRReport.landingGear){
        tabsArray.push('lg');
      }
      if(this.props.reviewMRReport.apu){
        tabsArray.push('apu');
      }
      let topOffsetVal = (this.props.reviewMRReport && this.props.reviewMRReport.generalInfo&&this.props.reviewMRReport.generalInfo.use_acumen_algorithm && getLocalStorageInfo().defaultLessor.id == 23) ? 98 :123

      if(window.scrollY > topOffsetVal){
        this.setState({
          fixedBar: true
        });
      }else{
        this.setState({
          fixedBar: false
        });
      }
      if(!this.state.flag && tabsArray.length){
        tabsArray.map(value => {
          let top = document.getElementById(value).offsetTop - 86;
          let bottom = document.getElementById(value).offsetTop;
          if(window.scrollY > top && window.scrollY < bottom ){
            this.setState({
              navActiveCls: value
            });
          }
        });
      }
    }
  handleTabClick = (e) => {
      e.preventDefault();
      this.setState({
        navActiveCls: e.target.getAttribute('href'),
        flag: true
      });
      window.scroll({
        top: document.getElementById(e.target.getAttribute('href')).offsetTop - 116,
        behavior: "smooth"
      });
      setTimeout(
        function() {
          this.setState({
            flag: false
          });
        }.bind(this),1000
      );
    }
  toggleTab(tab) {
      if (this.state.activeTab !== tab) {
        this.setState({
          activeTab: tab
        });
      }
    }
  breakUpEngineCostApiFn = () => {
      const breakUpEngineMRCost = this.props.breakUpEngineMRCost;
      let totalPercentage = 0;
      if(Object.keys(breakUpEngineMRCost.module_percentages.Core).length){
        Object.keys(breakUpEngineMRCost.module_percentages.Core).map(label => {
          totalPercentage += parseFloat(breakUpEngineMRCost.module_percentages.Core[label].percentage);
        });
      }
      if(Object.keys(breakUpEngineMRCost.module_percentages.LPT).length){
        Object.keys(breakUpEngineMRCost.module_percentages.LPT).map(label => {
          totalPercentage += parseFloat(breakUpEngineMRCost.module_percentages.LPT[label].percentage);
        });
      }
      if(Object.keys(breakUpEngineMRCost.module_percentages.Fan).length){
        Object.keys(breakUpEngineMRCost.module_percentages.Fan).map(label => {
          totalPercentage += parseFloat(breakUpEngineMRCost.module_percentages.Fan[label].percentage);
        });
      }
      if(totalPercentage == 100){
        delete breakUpEngineMRCost['modalStatus'];
        this.props.breakUpEngineCostApiFnAc(breakUpEngineMRCost);
      }else{
        this.props.updateEngineMRCostFn('','','');
      }

    }
  replaceLLPListFn = () => {
      let obj = {};
      let current_replaced_llps = [];
      this.props.selectLLPToReplace.list.map(llp => {
        if(llp.replaced == 1){
          current_replaced_llps.push(llp.id);
        }
      });
      obj = {
        ...obj,
        shop_visit_no: this.props.selectLLPToReplace.shop_visit_no,
        log_id: this.props.selectLLPToReplace.log_id,
        engine_no: this.props.selectLLPToReplace.engine_no,
        current_replaced_llps: current_replaced_llps
      }
      this.props.replacedTheLLPSelected(obj);
    }
  fetchWorkScope = (engineNo) => {
      let reviewMRReport = Object.assign({}, this.props.reviewMRReport.engines[engineNo]);
      delete reviewMRReport['workscopes'];
      reviewMRReport = {
        ...reviewMRReport,
        region_id: this.props.reviewMRReport.generalInfo.regionOfOperation.id,
        ratio: this.props.reviewMRReport.generalInfo.ratio,
        engine_type_id: this.props.reviewMRReport.generalInfo.engineType.id,
        engine_no: engineNo,
        lessor_id: this.props.reviewMRReport.generalInfo.lessorId,
        log_id: this.props.params.id,
        engine_escalation:this.props.engine_escalation?this.props.engine_escalation:0,
        prsv_cost_escalation:this.props.prsv_cost_escalation?this.props.prsv_cost_escalation:0,
        llp_escalation:this.props.llp_escalation?this.props.llp_escalation:0,
        llp_cost_escalation:this.props.llp_cost_escalation?this.props.llp_cost_escalation:0,
      }
      this.props.loadEngineWorkScope(reviewMRReport);
    }
  genMRFinanceGraph = () => {
      const validateNewInput = validMRFinanceGenGraph(this.props.reviewMRReport);
      let errorFlag = false;
      if(validateNewInput.apu){
        if(!Object.keys(validateNewInput.apu).every( k => { return validateNewInput.apu[k] === '' } )){
          errorFlag = true;
        }
      }
      if(validateNewInput.landingGear){
        if(!Object.keys(validateNewInput.landingGear).every( k => { return validateNewInput.landingGear[k] === '' } )){
          errorFlag = true;
        }
      }
      if(validateNewInput.airframe){
        Object.keys(validateNewInput.airframe).map(label => {
          if(!Object.keys(validateNewInput.airframe[label]).every( k => { return validateNewInput.airframe[label][k] === '' } )){
            errorFlag = true;
          }
        })
      }
      if(errorFlag){
        this.setState({
          mrReportError: validateNewInput
        });
      }else{
        this.props.reviewMrLoader(true);
        this.setState({
          mrReportError: {}
        });
        let data = Object.assign({}, this.props.reviewMRReport);
        data = {
          ...data,
          log_id: this.props.params.id
        }
        this.props.genMRFinanceGraphFn(data);
      }
    }
  render() {
    const { reviewMRLoader, regionCondition, regionsList, subHeaderTop, recordNavTop } = this.props;
    const { airframe, landingGear, apu, engines, generalInfo } = this.props.reviewMRReport;
    const { deRateList, deRateMatrix, logsInfo } = this.props;
    let totalComp = 0;
    let enigneGeneralInfo = {};
    let topFixedPos = subHeaderTop + recordNavTop;
    if(Object.keys(this.props.reviewMRReport).length){
      totalComp = 0 + parseInt(engines ? 1:0) + parseInt(airframe?1:0) + parseInt(landingGear?1:0) + parseInt(apu?1:0);
      enigneGeneralInfo['utilization_ratio'] = generalInfo.ratio;
      enigneGeneralInfo['utilization_min_ratio'] = generalInfo.min_ratio;
      enigneGeneralInfo['utilization_max_ratio'] = generalInfo.max_ratio;
      topFixedPos = (generalInfo.use_acumen_algorithm && getLocalStorageInfo().defaultLessor.id == 24) ? parseInt(topFixedPos) + parseInt(27) + 'px': topFixedPos;

    }
    return(
      <div className="genFinanceRpt-blk">
        { reviewMRLoader ? <ListLoader/>:null }
        <SidebarMntcCalc path={this.props.route.path} />
        { Object.keys(this.props.reviewMRReport).length ?
          <ReviewFinanceRptWrapper logId={this.props.params.id} topFixedPos= {subHeaderTop} mrReportError={this.state.mrReportError} generalInfo={generalInfo} engines={engines} genMRFinanceGraph={this.genMRFinanceGraph} reviewMRLoader={this.props.reviewMRLoader}   pageNo={logsInfo && logsInfo.pagination && logsInfo.pagination.current_page ? logsInfo.pagination.current_page: ''}>
            <div className="fixed-component-bar" id="bar" style={ this.state.fixedBar ?{ position: 'fixed', top: topFixedPos, left: '270px', right: '0', zIndex: 99 }:{ marginRight: '-15px', marginTop: '-15px'}}>
              <ul className="list-inline">
                { engines && Object.keys(engines).length ?
                  <li className="list-inline-item" style={{width: 100/totalComp + '%'}}>
                    <a className={ this.state.navActiveCls == 'engines' ? 'current-menu': '' } href="engines" onClick={this.handleTabClick}>Engine</a>
                  </li>:null
                }
                { airframe && Object.keys(airframe).length ?
                  <li className="list-inline-item" style={{width: 100/totalComp + '%'}}>
                    <a className={ this.state.navActiveCls == 'airframe' ? 'current-menu': '' } href="airframe" onClick={this.handleTabClick}>Airframe</a>
                  </li>:null
                }
                { landingGear && Object.keys(landingGear).length ?
                  <li className="list-inline-item" style={{width: 100/totalComp + '%'}}>
                    <a className={ this.state.navActiveCls == 'lg' ? 'current-menu': '' } href="lg" onClick={this.handleTabClick}>LG</a>
                  </li>:null
                }
                { apu && Object.keys(apu).length ?
                  <li className="list-inline-item" style={{width: 100/totalComp + '%'}}>
                    <a className={ this.state.navActiveCls == 'apu' ? 'current-menu': '' } href="apu" onClick={this.handleTabClick}>APU</a>
                  </li>:null
                }
              </ul>
            </div>
            <div className="review-module-wrapper" style={this.state.fixedBar ? {marginTop: '80px'}:{marginTop: '0'}}>
              { engines && Object.keys(engines).length ?
                <section id="engines">
                  <RegionSidebar
                    toggleRegion = {this.toggleRegion}
                    toggleStatus={this.state.regionOpen}
                    regionsList={regionsList}
                    regionCondition = {regionCondition}
                    mode="view"
                  />
                  <EngineMRWrapper>
                    <Card>
                      <CardBody>
                        <ul className="list-inline engine-detail-block">
                          <li className="list-inline-item">
                            <span>Engine Type </span>
                            <p className="para-ui">{generalInfo.engineType.name}</p>
                          </li>
                          <li className="list-inline-item">
                            <span>Region Of Operation</span>
                            <p>{generalInfo.regionOfOperation.name}</p>
                            <p>{generalInfo.custom_variable_conditions ? <a onClick={() => this.toggleRegion()} className="adjust-link">Custom Variables Applied</a> : null}</p>
                          </li>
                          <li className="list-inline-item">
                            <span>Country Of Operation</span>
                            <p>{generalInfo.region_country ? generalInfo.region_country : '--'}</p>
                          </li>
                          <li className="list-inline-item">
                            <span>Utilization Ratio</span>
                            <p>{generalInfo.ratio}:1</p>
                          </li>
                        </ul>
                        <Nav tabs>
                          {Object.keys(engines).map((item,index) =>
                            <NavItem key={index}>
                              <NavLink
                                className={classnames({ active: this.state.activeTab === index })}
                                onClick={() => { this.toggleTab(index); }}
                                >
                               Engine {parseInt(item) + 1}
                              </NavLink>
                            </NavItem>
                           )}
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                          {Object.keys(engines).map((item,index) =>  {
                            return(
                              <TabPane tabId={index} key={index}>
                                <EngineMRDetail enginesError={this.state.enginesError} engineType={generalInfo.engineType} toggleModal={() => this.toggleModal(item)} customCycleRemainFn={this.props.customCycleRemainFn} engines={engines} item={item} fetchWorkScope={this.fetchWorkScope} blendedMR={engines[item]['blended_mr']}>
                                  {Object.keys(engines[item]['workscopes']).map((workscope,index) => <LLPStatusCard
                                    logId={this.props.params.id}
                                    key={index}
                                    index={index+1}
                                    changeWorkScopeFn={this.props.changeWorkScopeFn}
                                    carryOverNSVFn={this.props.carryOverNSVFn}
                                    breakUpEngineMRCostFn={this.props.breakUpEngineMRCostFn}
                                    selectLLPToReplaceFn={this.props.selectLLPToReplaceFn}
                                    breakUpLLPMRCostFn={this.props.breakUpLLPMRCostFn}
                                    llpToBeReplacedFn={this.props.llpToBeReplacedFn}
                                    engineNo={item}
                                    runType={workscope}
                                    selectLLPToReplace= {this.props.selectLLPToReplace}
                                    workScope= {engines[item]['workscopes'][workscope]} />)}
                                </EngineMRDetail>
                              </TabPane>
                            )
                          })}
                        </TabContent>
                      </CardBody>
                    </Card>
                  </EngineMRWrapper>
                </section>:null
              }
              { airframe && Object.keys(airframe).length ?
                <section id="airframe">
                  <AirframeMRDetail
                    airframeConst={airframe}
                    errors={this.state.mrReportError}
                    updateErrorCode ={ this.updateErrorCode }
                  />
                </section>:null
              }
              { landingGear && Object.keys(landingGear).length ?
                <section id="lg">
                  <LandingGearMRDetail
                    lgAndApu={landingGear}
                    title = "Landing Gears"
                    type="landingGear"
                    errors = {this.state.mrReportError}
                    updateErrorCode ={ this.updateErrorCode }
                  />
                </section>:null
              }
              { apu && Object.keys(apu).length ?
                <section id="apu">
                  <LandingGearMRDetail
                    lgAndApu={apu}
                    title = "APU"
                    type="apu"
                    errors = {this.state.mrReportError}
                    updateErrorCode ={ this.updateErrorCode }
                  />
                </section>:null
              }
              <BreakUpEngineMRCostModel breakUpEngineCostApiFn={this.breakUpEngineCostApiFn} updateEngineMRCostFn={this.props.updateEngineMRCostFn} breakUpEngineMRCost={this.props.breakUpEngineMRCost} breakUpEngineMRCostFn={this.props.breakUpEngineMRCostFn} />
              <BreakUpLLPMRCostModal breakUpLLPMRCost={this.props.breakUpLLPMRCost} breakUpLLPMRCostFn={this.props.breakUpLLPMRCostFn}  />
              <LLPToBeReplacedModal llpToBeReplaced={this.props.llpToBeReplaced} llpToBeReplacedFn={this.props.llpToBeReplacedFn} />
              <CustomCycleRemainingModal compareCustomCRFn={this.props.compareCustomCRFn} updateCustomCycleRemainFn={this.props.updateCustomCycleRemainFn} customCycleRemain={this.props.customCycleRemain} customCycleRemainFn={this.props.customCycleRemainFn} />
              <SelectLLPToReplaceModal
                llpExpLoader={this.props.llpExpLoader}
                replaceLLPListFn={this.replaceLLPListFn}
                updateLLPToReplaceFn={this.props.updateLLPToReplaceFn}
                selectLLPToReplace={this.props.selectLLPToReplace}
                selectLLPToReplaceFn={this.props.selectLLPToReplaceFn}
                downLoadLLPFn={this.props.downLoadLLPFn}
                costOfYears= {this.props.costOfYears}
              />
            </div>
            { deRateList && deRateList.length ?
              <div>
                <Modal size="lg" isOpen={this.state.modal} toggle={this.toggleModal} centered={true} className={this.props.className}>
                  <ModalBody>
                    <DeRatePopUp toggleModal={this.toggleModal}
                      exportDeRate={this.props.exportDeRate}
                      updateDeRateOp={this.props.updateDeRateOp}
                      calculateDeRate={this.calculateDeRate}
                      deRateMatrix={deRateMatrix}
                      deRateList={deRateList}
                      engineInfo={enigneGeneralInfo ? enigneGeneralInfo :{} }
                      />
                  </ModalBody>
                </Modal>
              </div>:null
            }
            </ReviewFinanceRptWrapper>:<ListLoader />
          }
      </div>
    )
  }
}
const mapStateToProps = state => ({
  reviewMRReport: state.MRFinanaceRptReducer.reviewMRReport,
  breakUpLLPMRCost: state.MRFinanaceRptReducer.breakUpLLPMRCost,
  llpToBeReplaced: state.MRFinanaceRptReducer.llpToBeReplaced,
  llpExpLoader: state.MRFinanaceRptReducer.llpExpLoader,
  customCycleRemain: state.MRFinanaceRptReducer.customCycleRemain,
  selectLLPToReplace: state.MRFinanaceRptReducer.selectLLPToReplace,
  breakUpEngineMRCost: state.MRFinanaceRptReducer.breakUpEngineMRCost,
  reviewMRLoader: state.MRFinanaceRptReducer.reviewMRLoader,
  deRateList: state.MRFinanceGraphReducer.deRateList,
  deRateMatrix: state.MRFinanceGraphReducer.deRateMatrix,
  logsInfo: state.MRFinanaceRptReducer.logsInfo,
  regionsList: state.MRFinanaceRptReducer.regionsList,
  regionCondition: state.MRFinanaceRptReducer.regionCondition,
  subHeaderTop: state.sharedReducers.subHeaderTop,
  recordNavTop: state.sharedReducers.recordNavTop,
  costOfYears : state.MRFinanaceRptReducer.costOfYears
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    breakUpLLPMRCostFn: (engineNo, runType) => dispatch({
      type: BREAKUP_LLP_MR_COST,
      payload: {engineNo, runType}
    }),
    llpToBeReplacedFn: (engineNo, runType, svd, runId) => dispatch({
      type: LLP_TO_BE_REPLACED,
      payload: {engineNo, runType, svd, runId}
    }),

    breakUpEngineMRCostFn: (shop_visit_no, log_id, engine_no, workscope, runType) => dispatch({
      type: BREAKUP_ENGINE_MR_COST,
      payload: {shop_visit_no, log_id, engine_no, workscope, runType}
    }),
    updateEngineMRCostFn: (value, outerKey, innerKey) => dispatch({
      type: UPDATE_BREAKUP_ENGINE_MR_COST,
      payload: {value, outerKey, innerKey}
    }),
    breakUpEngineCostApiFnAc: (data) => dispatch(breakUpEngineCostAc(data)),
    customCycleRemainFn:(modalStatus, engineTypeId, engineNo) => dispatch(customCycleRemainAc(modalStatus, engineTypeId, engineNo)),
    fetchDeRate: () => dispatch(deRateVariableAc()),
    exportDeRate: (data, id) => dispatch(exportDeRateAc(data,id)),
    calculateDeRate: (data, id) => dispatch(calculateDeRateAc(data,id)),
    updateCustomCycleRemainFn: (value, keyParam, label) => dispatch({
      type: UPDATE_CUSTOM_THE_LLP_CYCLE,
      payload: {value, keyParam, label}
    }),
    compareCustomCRFn: () => dispatch({
      type: COMPARE_CUSTOM_CR,
      payload: ''
    }),
    selectLLPToReplaceFn: (modalStatus, shop_visit_no, log_id, engine_no, name) => dispatch(selectLLPToReplaceAc(modalStatus, shop_visit_no, log_id, engine_no, name)),
    downLoadLLPFn:(fileName,data)=>dispatch(downLoadLLPAc(fileName,data)),
    updateLLPToReplaceFn: (value, index) => dispatch({
      type: UPDATE_SELECT_LLP_TO_REPLACE,
      payload: {value, index}
    }),
    replacedTheLLPSelected: (obj) => dispatch(replacedTheLLPSelectedAc(obj)),
    carryOverNSVFn: (data) => dispatch(carryOverNSVAc(data)),
    changeWorkScopeFn : (data) => dispatch(changeWorkScopeAc(data)),
    fetchMrLog: (id) => dispatch(fetchMrLogAc(id)),
    loadEngineWorkScope: (data) => dispatch(engineWorkScopeCalAc(data)),
    genMRFinanceGraphFn: (data) => dispatch(genMRFinanceGraphAc(data)),
    reviewMrLoader: (flag) => dispatch({
      type: REVIEW_MR_LOADER,
      payload:flag
    }),
    updateEngineRation: (value) => dispatch({
      type: 'UPDATE_ENGINE_REVIEW_REPORT_RATIO',
      payload: value
    }),
    clearReport: () => dispatch({
      type: CLEAR_MR_REVIEW_RPT,
      payload: {}
    }),
    updateDeRateOp: (data) => dispatch({
      type: UPDATE_DE_RATE_OP,
      payload: data
    }),
    fixedPositionFn: (type, position) => dispatch({
      type: FIXED_POSITIONS_VALUE,
      payload: {type: type, position: position}
    })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(ReviewFinanceRpt, ['mr_calculator', 'dashboard','R']));

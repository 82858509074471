import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { withRouter } from 'react-router';
import { AppCard } from '../../shared';
import { getLocalStorageInfo } from '../../utils'
import '../assets/styles/assetSwitcher.scss';
import projectManagementIcon from '../assets/img/project_mgmt_icon.png';
import recordDataroomIcon from '../assets/img/record_dataroom_icon.png';
import maintenanceManagementIcon from '../assets/img/maintenance_mgmt_icon.png';
import mrCalcIcon from '../assets/img/mr_calc_icon.png';
import aircraftValuationIcon from '../assets/img/aircraft_valuation.png';
class ApplicationSwitcher extends Component{
  constructor(props){
    super(props);
    this.state={
        modal: true,
    }
    this.toggle = this.toggle.bind(this);
  }
  toggle(){
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }
  render(){
    const closeBtn = <button className="close" onClick={this.props.toggle}>&times;</button>;
    return(
      <Modal isOpen={this.props.open} toggle={this.props.toggle} className="app-switcher-modal">
        <ModalHeader toggle={this.props.toggle} close={closeBtn}>Applications</ModalHeader>
        <ModalBody>
        <section className="application-switcher">
          <Row className="app-card-row">
            <AppCard
                  label="Projects Management"
                  content="Comprehensive solution for planning, executing and managing Technical Projects - from Pre-Purchase to Redeliveries."
                  icon={<img width="50" src={projectManagementIcon} alt="Icon"/>}
                  enabled={getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.technical}
                  url={"https://projects.sparta.aero/fleet/technical/dashboard"}
              />
              <AppCard
                  label="Records Dataroom"
                  content="Centralised, secure online Documents Management System (DMS) for digitised aircraft
                  records storage retrieval and collaboration"
                  icon={<img width="50" src={recordDataroomIcon} alt="Icon"/>}
                  enabled={getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.records}
                  url={"https://records.sparta.aero/dashboard"}
              />
              <AppCard
                  label="Maintenance Management"
                  content="Seamlessiy track, execute and manage maintenance events across the fleet, forecast future events and process commercial claims."
                  icon={<img width="50" src={maintenanceManagementIcon} alt="Icon"/>}
                  enabled={getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.maintenance}
                  url={"https://maintenance.sparta.aero/fleet/maintenance/dashboard"}
                  />
              <AppCard
                  label="MR Calculator"
                  content="A potent decision tool for computing commercial exposure and simulating complex scenarios on ~200 aircraft - engine combinations."
                  icon={<img width="50" src={mrCalcIcon} alt="Icon"/>}
                  enabled={getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.mr_calculator}
                  url={"https://mr-calculator.sparta.aero"}
              />
              <AppCard
                  label="Valuations"
                  content="Access values for a wide variety of aircraft, powered by expertise and experience of ISTAT and ASA Certified Appraisers."
                  icon={<img width="50" src={aircraftValuationIcon} alt="Icon"/>}
                  enabled={getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.valuation}
                  url="https://valuation.sparta.aero"
                  tag={<span style={{fontSize:'13px', color:'#f10909', position: 'relative', top:'-11px'}}>BETA</span>}
              />
          </Row>
        </section>
        </ModalBody>
      </Modal>
    )
  }
}
export default withRouter(ApplicationSwitcher);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, browserHistory } from 'react-router';
import { Row, Col } from 'reactstrap';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { authorizationAc } from '../../User/actionCreators';
import { inviteUserAc,reportTheBugAc,sHeaderConstantAc, getNotificationBarDataAc,fetchAllFaqsAc } from '../actionCreators';
import { imgStoragePath } from '../../constants';
import { getLocalStorageInfo } from '../../utils';
import { BUG_REPORT, BUY_LICENCE } from '../actions';
import { EditFormBar } from '../../applications/technicalInspection/Elements';
import ReportBug from './ReportBug';
import ShoppingCartIcon from '../../shared/assets/img/cart_icon.svg';
import Faqs from './Faqs';
class MobileHeader extends Component {
	constructor(props) {
    super(props);
    this.state = {
      changeLessor: false,
      toggleFaq:false
    }
  }
  changeLessor = () => {
    this.setState(prevState => ({
      changeLessor: !prevState.changeLessor
    }));
  }
  updateLessor = (option) => {
    if(option){
      this.props.updateAuthToken(option)
    }
  }
  toggleBugReportModal = () => {
    this.props.toggleBugReport();
  }
  toggleFaqs = () =>{
    this.setState({
      toggleFaq:!this.state.toggleFaq,
    });

  }
render() {
  const userInfo = getLocalStorageInfo();
  const lessors = getLocalStorageInfo().lessor_list.map(item => item.lessor_type && item.lessor_type.value == 3 && item.owner && item.owner.name ?{...item, name:item.owner.name}:item)
  const { bugReport, bugLoader, notifications, faqsList,notificationsGif,techConstantsHeader} = this.props;
    return(
      <Row className="mobile-header flex-centered hidden-md">
        <Col xs="12">
          <ul className="list-inline header-logo-search">
              <li className="list-inline-item col-sm-1 col-2">
                <Link to="/">
                  <img width="30" className="main-logo" src={imgStoragePath+"sparta_logo.png"} alt="logo" />
                </Link>
              </li>
              <li className="list-inline-item col-6">
                <ul className="list-inline header-logo-search header-action-items" style={{margin: '0px 10px 0px 0px'}}>
                  <li className="list-inline-item" >
                      <a onClick={this.toggleBugReportModal}>
                        <img width='20px' src={`${imgStoragePath}phase-2/support.png`} style={{cursor: 'pointer'}} />
                      </a>
                  </li>
                  <li className="list-inline-item" >
                    <img width='20px' src={`${imgStoragePath}phase-2/help.png`}  onClick={() => this.toggleFaqs()} style={{cursor: 'pointer'}}/>
                  </li>
                  <li className="list-inline-item" style={browserHistory.getCurrentLocation().pathname.includes('notifications') ? {borderBottom: '4px solid #2f8cff'} : {}}>
                  <img width='23px' onClick={() => browserHistory.push('/notifications')} src={ notifications.notification_count && notificationsGif ? browserHistory.getCurrentLocation().pathname.includes('notifications')?`${imgStoragePath}phase-2/notification_static.png`:`${imgStoragePath}phase-2/notification_animi.gif`:`${imgStoragePath}phase-2/notification_static.png`} style={{cursor: 'pointer'}}/>
                  {notifications.notification_count ? <span className="cart-count">{notifications.notification_count}</span> : null}
                </li>
                  {
                    userInfo.user.type && userInfo.user.type.value != 1 && userInfo.user.permission.console && userInfo.user.permission.console.cart ?
                    <li className="list-inline-item" style={browserHistory.getCurrentLocation().pathname.includes('cart') ? { borderBottom: '4px solid #2f8cff'} : {}}>
                      <img width='20px' onClick={() => browserHistory.push('/license/cart')} src={ShoppingCartIcon} style={{cursor: 'pointer'}}/> {notifications.card_count ? <span className="cart-count">{notifications.card_count}</span> : null}
                    </li>
                    :null
                  }
                </ul>
              </li>
              {
                userInfo.defaultLessor.lessor_type != 3 ?
                !this.state.changeLessor ?
                <li className="list-inline-item flex-centered lessor-info col-4 text-right col-sm-5" style={{position: 'relative',cursor:'pointer'}} onClick={this.changeLessor} >
                  { userInfo.defaultLessor.logo ? <img src={userInfo.defaultLessor.logo} alt={userInfo.defaultLessor.name} /> : userInfo.defaultLessor.name.length > 20 ? <span id="TooltipExample" onMouseOver={() => this.setState({lessorName: true})} className="lessor-name">{userInfo.defaultLessor.name.substr(0, 15) + '...'}</span> : userInfo.defaultLessor.name }
                  {userInfo.lessor_list.length > 1 ? <span style={{position: 'absolute', right: '15px'}} ><i style={{ fontStyle:"normal",color: '#2f8cff', fontSize: '16px', position: 'relative', top:'-2px', left: '2px'}}>&#9662;</i></span>:null}
                </li>:
                <li className="list-inline-item col-4 col-sm-5 text-right" style={{padding: '0 15px', borderRight: '1px solid #ddd'}}>
                   <Select
                     name="form-field-name"
                     value={userInfo.defaultLessor.id}
                     onChange={this.updateLessor}
                     options={lessors}
                     placeholder = 'Select Lessor'
                     className="custom-select-block"
                     labelKey = "name"
                     valueKey = "id"
                   />
                </li>
                :
                !this.state.changeLessor ?
                <li className="list-inline-item flex-centered lessor-info text-right col-4 col-sm-5" style={{position: 'relative',cursor:'pointer'}} onClick={this.changeLessor} >
                  {userInfo.defaultLessor.logo ? <img src={userInfo.defaultLessor.logo} alt={userInfo.defaultLessor.name} /> : userInfo.defaultLessor.owner.name.length > 20 ? <span id="TooltipExample" onMouseOver={() => this.setState({lessorName: true})} className="lessor-name">{userInfo.defaultLessor.owner.name.substr(0, 15) + '...'}</span> : userInfo.defaultLessor.owner.name }
                  {userInfo.lessor_list.length > 1 ? <span style={{position: 'absolute', right: '15px'}} ><i style={{ fontStyle:"normal",color: '#2f8cff', fontSize: '16px', position: 'relative', top:'-2px', left: '2px'}}>&#9662;</i></span>:null}
                </li>:
                <li className="list-inline-item text-right col-4 col-sm-5" style={{padding: '0 15px', borderRight: '1px solid #ddd'}}>
                   <Select
                     name="form-field-name"
                     value={userInfo.defaultLessor.id}
                     onChange={this.updateLessor}
                     options={lessors}
                     placeholder = 'Select Lessor'
                     className="custom-select-block"
                     labelKey = "name"
                     valueKey = "id"
                   />
                </li>
              }
            </ul>
        </Col>

        <EditFormBar
          titleContent= {<div><h3 style={{textTransform: 'capitalize'}}> FAQs </h3></div>}
           toggleEditSideBar={this.toggleFaqs} isOpen={this.state.toggleFaq} style={this.state.toggleFaq ? {width: '580px'} : {width: '0px'}}>
          { this.state.toggleFaq ?
          <Faqs
          faqsList={faqsList}
          />
          : null}
        </EditFormBar>
        <EditFormBar
          titleContent= {
            <div>
              <h3 style={{ textTransform: 'capitalize'}}> Report an issue </h3>
              <p style={{fontSize: '12px', padding: '4px 4px 4px 0'}}>Please report only technical issues / bugs encountered while operating the SPARTA system. Issue will be reported to SPARTA IT team for action.</p>
            </div>
          }
           toggleEditSideBar={this.toggleBugReportModal} isOpen={bugReport.flag} style={bugReport.flag ? {width: '680px'} : {width: '0px'}}>
          { bugReport.flag ?
          <ReportBug
            type={'add'}
            data={bugReport.data}
            reportTheBug = {this.props.reportTheBug}
            toggleEditSideBar = {this.toggleBugReportModal}
            techConstants={techConstantsHeader}
            bugLoader={bugLoader}
          />
          :null}
        </EditFormBar>

      </Row>

    )
	}
}
const mapStateToProps = state => ({
  loginLoader: state.userAssetReducer.loginLoader,
  notifications: state.sharedReducers.notifications,
  faqsList:state.sharedReducers.faqsList,
  bugReport: state.sharedReducers.bugReport,
  notificationsGif:state.sharedReducers.notificationsGif,
  techConstantsHeader: state.sharedReducers.techConstantsHeader,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateAuthToken:(id, pageType) => dispatch(authorizationAc(id, pageType)),
    fetchFaqs:() => dispatch(fetchAllFaqsAc()),
    getNotificationBarData: () => dispatch(getNotificationBarDataAc()),
    toggleBugReport:()=> dispatch({
      type: BUG_REPORT,
      payload: {}
    }),
    reportTheBug:(data)=>dispatch(reportTheBugAc(data)),
    fetchTechConstant : (constantTypes) => dispatch(sHeaderConstantAc(constantTypes)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileHeader));

export const sectionIds = [2,5,6,7,8,9,10,14,15,16,17,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35];
export const gapReportHd = [
  {label:'Item', sortKey: ''},
  {label:'Action', sortKey: ''},
  {label:'Description*', sortKey: ''},
  {label:'Files', sortKey: ''},
  {label:'Priority', sortKey: ''},
  {label:'Disposition', sortKey: ''},
  {label:'Started Date', sortKey: ''},
  {label:'Completed Date', sortKey: ''},
  {label:'Discrepancy', sortKey: '',child:[{label:'Requirement'},{label:'Background', style: {borderRight:'none' }}]},
  {label:'Comments', sortKey: '', child:[{label:'Project Lead/Engineer'},{label:'Current Lessee'}, {label: 'Next Lessee'}]},
  {label:'Assignee', sortKey: ''},
];
export const gapReporATAtHd = [
  {label:'Item', sortKey: ''},
  {label:'Action', sortKey: ''},
  {label:'ATA', sortKey: ''},
  {label:'Description*', sortKey: ''},
  {label:'Files', sortKey: ''},
  {label:'Priority', sortKey: ''},
  {label:'Disposition', sortKey: ''},
  {label:'Started Date', sortKey: ''},
  {label:'Completed Date', sortKey: ''},
  {label:'Discrepancy', sortKey: '',child:[{label:'Requirement'},{label:'Background', style: {borderRight:'none' }}]},
  {label:'Comments', sortKey: '', child:[{label:'Project Lead/Engineer'},{label:'Current Lessee'}, {label: 'Next Lessee'}]},
  {label:'Assignee', sortKey: ''},
];
export const tableContentHd = [
  {label:'Sr No.', sortKey: ''},
  {label:'Contents', sortKey: ''},
  {label:'Items Open', sortKey: '' },
  {label:'Items Closed', sortKey: ''},
  {label:'Items Reopened', sortKey: ''},
  {label:'Total Item', sortKey: ''}
];
export const gapSecReportHd = [
  {label:'Item', sortKey: ''},
  {label:'Action', sortKey: ''},
  {label:'Description*', sortKey: ''},
  {label:'Files', sortKey: ''},
  {label:'Priority', sortKey: ''},
  {label:'Disposition', sortKey: ''},
  {label:'P/N', sortKey: ''},
  {label:'S/N', sortKey: ''},
  {label:'Started Date', sortKey: ''},
  {label:'Completed Date', sortKey: ''},
  {label:'Install Date', sortKey: ''},
  {label:'Position', sortKey: ''},
  {label:'Discrepancy', sortKey: '',child:[{label:'Requirement'},{label:'Background', style: {borderRight:'none' }}]},
  {label:'Comments', sortKey: '', child:[{label:'Project Lead/Engineer'},{label:'Current Lessee'}, {label: 'Next Lessee'}]},
  {label:'Assignee', sortKey: ''},
];
export const gapSecReportATAHd = [
  {label:'Item', sortKey: ''},
  {label:'Action', sortKey: ''},
  {label:'ATA', sortKey: ''},
  {label:'Description*', sortKey: ''},
  {label:'Files', sortKey: ''},
  {label:'Priority', sortKey: ''},
  {label:'Disposition', sortKey: ''},
  {label:'P/N', sortKey: ''},
  {label:'S/N', sortKey: ''},
  {label:'Started Date', sortKey: ''},
  {label:'Completed Date', sortKey: ''},
  {label:'Install Date', sortKey: ''},
  {label:'Position', sortKey: ''},
  {label:'Discrepancy', sortKey: '',child:[{label:'Requirement'},{label:'Background', style: {borderRight:'none' }}]},
  {label:'Comments', sortKey: '', child:[{label:'Project Lead/Engineer'},{label:'Current Lessee'}, {label: 'Next Lessee'}]},
  {label:'Assignee', sortKey: ''},
];
export const gapSecReportCyclesHd = [
  {label:'Item', sortKey: ''},
  {label:'Action', sortKey: ''},
  {label:'ATA', sortKey: ''},
  {label:'Description*', sortKey: ''},
  {label:'Files', sortKey: ''},
  {label:'Priority', sortKey: ''},
  {label:'Disposition', sortKey: ''},
  {label:'P/N', sortKey: ''},
  {label:'S/N', sortKey: ''},
  {label:'Cycles Remaining', sortKey: ''},
  {label:'Started Date', sortKey: ''},
  {label:'Completed Date', sortKey: ''},
  {label:'Install Date', sortKey: ''},
  {label:'Position', sortKey: ''},
  {label:'Discrepancy', sortKey: '',child:[{label:'Requirement'},{label:'Background', style: {borderRight:'none' }}]},
  {label:'Comments', sortKey: '', child:[{label:'Project Lead/Engineer'},{label:'Current Lessee'}, {label: 'Next Lessee'}]},
  {label:'Assignee', sortKey: ''},
];

export const T011SectionCrud = {
  description:'',
  background_remarks:'',
  ata: '',
  disposition: 0,
  estimated_completion_date: null,
  image_url: null,
  install_date: null,
  operator_comments: null,
  part_number: "",
  cycles_remaining: '',
  position: null,
  priority: null,
  requirement_remarks: null,
  serial_number: "",
  started_date: null,
  acumen_comments:'',
  assignee: 0
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, browserHistory } from 'react-router';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Modal, ModalBody } from 'reactstrap';
import classnames from 'classnames';
import FilterBar from '../../../../shared/FilterBar';
import {  EditSidebar, ShortHead,ContractSideBar, FormModeType, ContractBarLoader} from '../../Elements';
import Select from 'react-select';
import NumericLabel from 'react-pretty-numbers';
import { utilizationFormat, float2DecRegx, intRegx } from '../../../../constants';
import 'react-select/dist/react-select.css';
import moment from 'moment';
import { checkString } from '../../../../formValidator';
import UtilsFields from '../components/UtilsFields';
import Pagination from "react-js-pagination";
import UtilizationRow from '../components/UtilizationRow';
import UtilizationList from '../components/UtilizationList';
import UtilsListInfo from '../components/UtilsListInfo';
import UtilsList from '../components/UtilsList';
import RentalList from '../components/RentalList';
import UtilsGraph from '../components/UtilsGraph';
import EditUtils from '../components/EditUtils';
import { ListLoader, NoRecordFound } from '../../../../shared';
import { globalGetService, globalPostService, globalPutService } from '../../../../globalServices';
import { assetsType } from '../../../../constants';
import { FieldSelect } from '../../Elements';
import { UtilsAssetViewAc, editUtilsInfoAc,  utilsListAc, exportUtilizationAc, exportRentalUtilizationAc, utilFnCrudAc, getRentalUtilizationAc } from '../actionCreators';
import { removeEmptyKey, getLocalStorageInfo, toastFlashMessage } from '../../../../utils';
import { CHANGE_UTIL_GRAPH, CLOSE_UTILS_BAR, TOGGLE_VIEW_EDIT, UTIL_CRUD_OPERN,TOGGLE_TAB } from '../actions';
import '../../../maintenance/MRFinanceGraph/assets/styles/CashFlow.scss';
import ExportUtilSummery from '../components/ExportUtilSummery';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
const months = [
  {label: 'Jan',value: '01'},
  {label: 'Feb',value: '02'},
  {label: 'Mar',value: '03'},
  {label: 'Apr',value: '04'},
  {label: 'May',value: '05'},
  {label: 'Jun',value: '06'},
  {label: 'Jul',value: '07'},
  {label: 'Aug',value: '08'},
  {label: 'Sep',value: '09'},
  {label: 'Oct',value: '10'},
  {label: 'Nov',value: '11'},
  {label: 'Dec',value: '12'}
];
class Utilization extends Component {
  constructor(props){
    super(props);
    this.state = {
      editUtils: false,
      saveing: false,
      activeTab: '0',
      activeRental: 0,
      appliedFilter: {},
      error: {},
      page:1,
      rentalPage: 1,
      year: '',
      months:months,
      monthsss:months,
      month:'',
      addUtil: false,
      utilsObj: {
        asset_location: "",
        hours: 1,
        cycles: 3,
        block_hour: 27109.00,
        remarks: "Nik"
      },
      editable: false
    }
    this.toggle = this.toggle.bind(this);
  }
  componentDidMount() {
    this.props.fetchUtilsInfo('list');
  }

  saveRentalUtils = () => {
    const { utilsObj, month, year } = this.state;
    var validateNewInput = {
      year: checkString({value: year, required: true, minLength: '', maxLength: '', message: 'Please select Year'}),
      month: checkString({value: month, required: true, minLength: '', maxLength: '', message: 'Please select Month'}),
      hours: parseFloat(utilsObj.hours) >= 0 ? '' : 'Please enter Flight Hours',
      cycles:parseInt(utilsObj.cycles) >= 0 ? '' : 'Please enter Flight cycles',
      // block_hour:parseFloat(utilsObj.block_hour) >= 0 && parseFloat(utilsObj.block_hour) <= parseFloat(utilsObj.hours) ? 'Block Hours should be greater than or equal to Flight Hours' : ''

    };
    if(this.props.ctrShortInfo.contract && this.props.ctrShortInfo.contract.unit_of_measure === 4){
      validateNewInput = {
        ...validateNewInput,
        // block_hour: parseFloat(utilsObj.block_hour) > 0 && parseFloat(utilsObj.block_hour) <= parseFloat(utilsObj.hours) ? 'Block Hours should be greater than or equal to Flight Hours' : '',
      }
    }
    if(Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })){
      if(this.state.utilsObj.type === 'edit'){
        let data = this.state.utilsObj
        data = {
          ...data,
          year: this.state.year,
          month: this.state.month,
          asset_type: this.props.ctrShortInfo.asset_type
        }
        this.setState({saving: true})
        globalPutService(`/contracts/${this.props.ctrShortInfo.contract.slug}/rental-utilization/${this.state.utilsObj.id}/`,data)
        .then(response => {
          this.setState({saving: false})
          if(response.data.statusCode === 200){
            this.setState({addUtil: false, utilsObj: {}, month: '', year: ''})
            this.props.getRentalUtilization(this.props.ctrShortInfo.contract.slug, {page: 1})
            toastFlashMessage(response.data.message, 'success')
          }
        })
      }else{
        let data = {
          year: this.state.year,
          month: this.state.month,
          assets: [
            {
              asset_id: this.props.ctrShortInfo.id,
              hours: this.state.utilsObj.hours,
              cycles: this.state.utilsObj.cycles,
              block_hour: this.state.utilsObj.block_hour,
              asset_location: this.state.utilsObj.asset_location,
              remarks: this.state.utilsObj.remarks,
              asset_type: this.props.ctrShortInfo.asset_type
            }
          ]
        }
        this.setState({saving: true})
        globalPostService(`/contracts/${this.props.ctrShortInfo.contract.slug}/rental-utilization/`,data)
        .then(response => {
          this.setState({saving: false})
          if(response.data.statusCode === 200){
            this.setState({addUtil: false, utilsObj: {}, month: '', year: ''})
            toastFlashMessage(response.data.message, 'success')
            this.props.getRentalUtilization(this.props.ctrShortInfo.contract.slug, {page: 1})
          }
        })
      }
    }else{
      this.setState({
        error: validateNewInput
      })
    }
  }

  updateUtilField = (value, key) =>{
    if(['hours', 'block_hour'].includes(key)){
      if(float2DecRegx.test(value)){
        let error = this.state.error
        delete error[key]
        this.setState({
          utilsObj: {
            ...this.state.utilsObj,
            [key]: value
          },
          error
        })
      }
    }else if(key === 'cycles'){
      if(intRegx.test(value)){
        let error = this.state.error
        delete error.cycles
        this.setState({
          utilsObj: {
            ...this.state.utilsObj,
            [key]: value
          },
          error
        })
      }else if(value === '' || value === null){
        let error = this.state.error
        delete error.cycles
        this.setState({
          utilsObj: {
            ...this.state.utilsObj,
            [key]: value
          },
          error
        })
      }
    }else{
      let error = this.state.error
      delete error[key]
      this.setState({
        utilsObj: {
          ...this.state.utilsObj,
          [key]: value
        },
        error
      })
    }
  }

  yearMonth = (value, type) => {
    if(type == 'year'){

      if(value){
        if(this.state.activeRental === 0){
          if(value == parseInt(moment().format('YYYY')) && moment().format('YYYY') == moment(this.props.utilsInitiate.utilization.start_month).format('YYYY')){
            // Current Year == Contract year
            let currentDate = false;
            let mt = [];
            if(moment(this.props.utilsInitiate.utilization.start_month).format('YYYYMM') == moment().format('YYYYMM')){
              if(this.props.utilsInitiate.utilization.is_contract_start_date){
                currentDate = true;
              }
            }
            if(this.props.utilsInitiate.utilization.is_contract_start_date){
              currentDate = true;
            }
            if(currentDate){
              mt = months.filter((month,index) => parseInt(moment(this.props.utilsInitiate.utilization.start_month).format('M')) <= (index+1) && (index+1) <= parseInt(moment().format('M')));
            }else{
              mt = months.filter((month,index) => parseInt(moment(this.props.utilsInitiate.utilization.start_month).format('M')) < (index+1) && (index+1) <= parseInt(moment().format('M')));
            }
            this.setState(prevState => ({
              ...prevState,
              year: value,
              months:mt
            }));
          }else if(value == parseInt(moment().format('YYYY'))){
            // Checking Current Year
            this.setState(prevState => ({
              ...prevState,
              year: value,
              months: months.filter((month,index) => (index+1) <= parseInt(moment().format('M')))
            }));
          }else if(value == moment(this.props.utilsInitiate.utilization.start_month).format('YYYY')){
            // Checking Year as Contract year
            let sameMonth = false, monthlist = [];
            if(this.props.utilsInitiate.utilization.is_contract_start_date){
              sameMonth = true;
            }
            if(sameMonth){
              monthlist = months.filter((month,index) => (index+1) >= parseInt(moment(this.props.utilsInitiate.utilization.start_month).format('M')))
            }else{
              monthlist = months.filter((month,index) => (index+1) > parseInt(moment(this.props.utilsInitiate.utilization.start_month).format('M')))
            }
            this.setState(prevState => ({
              ...prevState,
              year: value,
              months:monthlist
            }));
          }else{
            // Any Other Year
            this.setState(prevState => ({
              ...prevState,
              year: value,
              months: months
            }));
          }
        }else{
          if(value == moment(this.props.utilsInitiate.rental_utilization.start_month).format('YYYY')){
            this.setState(prevState => ({
              ...prevState,
              year: value,
              months: months.filter((month,index) => (index+1) >= parseInt(moment(this.props.utilsInitiate.rental_utilization.start_month).format('M')))
            }));
          }else if(value == moment(this.props.utilsInitiate.rental_utilization.end_month).format('YYYY')){
            this.setState(prevState => ({
              ...prevState,
              year: value,
              months: months.filter((month,index) => (index+1) <= parseInt(moment(this.props.utilsInitiate.rental_utilization.end_month).format('M')))
            }));
          }else{
            this.setState(prevState => ({
              ...prevState,
              year: value,
              months: months
            }));
          }
        }
      }else{
        this.setState(prevState => ({
          ...prevState,
          year: value,
          months: [],
          month: ''
        }));
      }
    }else{
      this.setState(prevState => ({
        ...prevState,
        month: value
      }));
    }

    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [type]: ''
      }
    }))
  }

  changeMsn =(evt) =>{
    if(evt.contracts == false){
      let url = '/asset-view/'+assetsType[evt.asset_type]+'/'+evt.slug
      browserHistory.push(url)
    }else if(evt.contract_slug == null || evt.contract_slug == undefined || evt.contract_slug == ''){
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
      browserHistory.push(url);
    }else{
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contract/utilization';
      browserHistory.push(url);
      this.props.params.type = assetsType[evt.asset_type];
      this.props.params.aircraft_slug = evt.slug;
      this.props.fetchUtilsInfo('list');
    }
  }

  toggle(tab) {
   if (this.state.activeTab !== tab) {
     this.setState({
       activeTab: tab
     });
   }
  }



  toggleSidebar = (type) => {
    this.setState(prevState => ({
      [type]: !prevState[type]
    }))
  }

  clearFilterValue = (label) => {
    let filterParams = Object.assign({}, this.state.appliedFilter);
    delete filterParams[label];
    let getQueryStr = removeEmptyKey(this.getQueryStrfn(filterParams));
    if(this.state.activeRental === 1){
      this.props.getRentalUtilization(this.props.ctrShortInfo.contract.slug , getQueryStr);
    }else{
      this.props.utilizationList(this.props.ctrShortInfo.contract.slug , getQueryStr);
    }
    this.setState({
      appliedFilter: filterParams,
      page: 1
    });
  }
  changeFilterFn = (...args) => {
    let filterParams = Object.assign({}, this.state.appliedFilter);
    switch (args[0]) {
      case 'major_assembly':
        if(args[1]){
          filterParams = {
            ...filterParams,
            [args[0]]: {
              name: 'Major Assembly Account: ', value: args[1].name,
              asset_id: args[1].asset_id,
              asset_type: args[1].asset_type
            }
          }
        }
        break;
      case 'date':
        let date = (args[1] ? args[1]: '--') + ' To ' + (args[2]?args[2]:'--');
        filterParams = {
          ...filterParams,
          [args[0]]: {
            name:'Date',
            value: date,
            startDate: args[1] ? args[1]:'',
            endDate: args[2]?args[2]:''
          }
        }
      default:
       break;
    }
    this.setState({
      appliedFilter: filterParams,
      selectedFilterTitle: '',
      page: 1
    });

    let getQueryStr = removeEmptyKey(this.getQueryStrfn(filterParams));
    if(this.state.activeRental === 1){
      this.props.getRentalUtilization(this.props.ctrShortInfo.contract.slug , getQueryStr);
    }else{
      this.props.utilizationList(this.props.ctrShortInfo.contract.slug , getQueryStr);
    }
  }

  utildata = (slug, data, type) => {
    this.setState({
      editable: data.editable
    });
    this.props.utilFnCrud(slug, data, type);
  }

  getQueryStrfn = (params) => {
    let queryFilter = {};
    Object.keys(params).map(key => {
      if(key == 'date'){
        queryFilter['startDate'] = params[key].startDate
        queryFilter['endDate'] = params[key].endDate
      }else if(key == 'major_assembly'){
        queryFilter['asset_id'] = params[key].asset_id
        queryFilter['asset_type'] = params[key].asset_type
      }else{
        queryFilter[key] = params[key].filterData
      }
    });
    return queryFilter;
  }
  handlePageChange = (page) => {
    let getQueryStr = removeEmptyKey(this.getQueryStrfn(this.state.appliedFilter));
    getQueryStr['page'] = page;
    this.setState(prevState => ({
      ...prevState,
      page: page
    }));
    this.props.utilizationList(this.props.ctrShortInfo.contract.slug , getQueryStr);
  }
  render() {
    const { ctrShortInfo, rentalUtils, utilsListInfo, utilsTrendsInfo, utlisAverage, viewUtils, utilsInitiate, utilsLoader, utilsMajorAssembly, utilCrud,activeTab } = this.props;
    const utilsTrends = utilsTrendsInfo.data;
    const monthsss = this.state.monthsss
    const years = [], months= [];
    const currentYear = parseInt(moment().format('YYYY'));
    let contractStart = parseInt(moment(utilsInitiate.utilization.start_month).format('YYYY'));
    let rentalContractStart
    if(utilsInitiate.rental_utilization && Object.keys(utilsInitiate.rental_utilization).length){
      rentalContractStart = parseInt(moment(utilsInitiate.rental_utilization.start_month).format('YYYY'));
    }else{
      rentalContractStart = parseInt(moment().format('YYYY'));
    }
    if(moment(utilsInitiate.utilization.start_month).format('M') == '12' && !utilsInitiate.utilization.is_contract_start_date){
      contractStart++;
    }
    while(parseInt(rentalContractStart) <= currentYear){
      years.push({label:rentalContractStart, value: rentalContractStart})
      rentalContractStart += 1
    }
    const filterMenu = {
      'Major Assembly': {
        'inputType': 'dropdown',
        'keyParam': 'major_assembly',
        'placeholder': 'Select Major Assembly',
        'options': utilsMajorAssembly,
        'labelKey': 'name',
        'valueKey': 'id',
      },
      'Date': {
        'inputType': 'date',
        'keyParam': 'date'
      }
    };
    const rentalFilterMenu = {
      'Date': {
        'inputType': 'date',
        'keyParam': 'date'
      }
    };
    return (
      <div className="contract-container">
        <ContractSideBar application={`Utilization`} type="shorter" params={this.props.params} />
        { Object.keys(ctrShortInfo).length ?
          <ShortHead headingText="Lease Management" changeMsn={this.changeMsn} contrAssetBasicInfo={ctrShortInfo} />:<ContractBarLoader />
        }
        {utilsLoader ? <ListLoader /> : null}
        {
          getLocalStorageInfo().user.user_role != 'lessee' && Object.keys(utilsTrendsInfo.data).length ?
          <div className="util-heading-block">
             <h5 className="uti-heading margin-bottom-8-xs" style={{ paddingTop: '0' }}>Utilization Trend</h5>
             <div className="edit-view-tech-spec" style={{margin:0}}>
            <Nav tabs>
              {
                utilsTrends.airframe ?
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.props.activeTab === '0' })}
                    onClick={() => this.props.toggle('0') }
                  >

                    Airframe
                  </NavLink>
                </NavItem>:null
              }
              {
                utilsTrends.apu ?
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.props.activeTab === '1' })}
                    onClick={() => this.props.toggle('1') }
                  >

                    APU
                  </NavLink>
                </NavItem>:null
              }
              {
                utilsTrends.landing_gears ?
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.props.activeTab === '2' })}
                    onClick={() => this.props.toggle('2') }
                  >

                    Landing Gears
                  </NavLink>
                </NavItem>:null
              }
              {
                utilsTrends.engines ?
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.props.activeTab === '3' })}
                    onClick={() => this.props.toggle('3') }
                  >

                    Engines
                  </NavLink>
                </NavItem>:null
              }
              {
                utilsTrends.fan ?
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.props.activeTab === '4' })}
                    onClick={() => this.props.toggle('4') }
                  >

                    Fan
                  </NavLink>
                </NavItem>:null
              }
              {
                utilsTrends.hpc ?
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.props.activeTab === '5' })}
                    onClick={() => this.props.toggle('5') }
                  >

                    HPC
                  </NavLink>
                </NavItem>:null
              }
              {
                utilsTrends.hpt ?
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.props.activeTab === '6' })}
                    onClick={() => this.props.toggle('6') }
                  >

                    HPT
                  </NavLink>
                </NavItem>:null
              }
              {
                utilsTrends.lpt ?
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.props.activeTab === '7' })}
                    onClick={() => this.props.toggle('7') }
                  >

                    LPT
                  </NavLink>
                </NavItem>:null
              }
            </Nav>
            <TabContent activeTab={this.props.activeTab}>
              {
                utilsTrends.airframe?
                <TabPane tabId='0'>
                  <UtilsGraph ctrShortInfo={ctrShortInfo} avgData={utlisAverage.filter(item => item.name === utilsTrends.airframe.name).length ? utlisAverage.filter(item => item.name === utilsTrends.airframe.name)[0] : {}} hour_plots={utilsTrends.airframe.hour_plots} cycle_plots={utilsTrends.airframe.cycle_plots} />
                </TabPane>:null
              }
              {
                utilsTrends.apu?
                <TabPane tabId='1'>
                  <UtilsGraph ctrShortInfo={ctrShortInfo} avgData={utlisAverage.filter(item => item.name.toLowerCase().includes('apu')).length ? utlisAverage.filter(item => item.name.toLowerCase().includes('apu'))[0] : {}} hour_plots={utilsTrends.apu.hour_plots} cycle_plots={utilsTrends.apu.cycle_plots} />
                </TabPane>:null
              }
              {
                utilsTrends.lpt !== undefined?
                <TabPane tabId='7'>
                  <UtilsGraph ctrShortInfo={ctrShortInfo} avgData={utlisAverage.filter(item => item.name.toLowerCase().includes('lpt')).length ? utlisAverage.filter(item => item.name.toLowerCase().includes('lpt'))[0] : {}} hour_plots={utilsTrends.lpt.hour_plots} cycle_plots={utilsTrends.lpt.cycle_plots} />
                </TabPane>:null
              }
              {
                utilsTrends.hpc !== undefined?
                <TabPane tabId='5'>
                  <UtilsGraph ctrShortInfo={ctrShortInfo} avgData={utlisAverage.filter(item => item.name.toLowerCase().includes('hpc')).length ? utlisAverage.filter(item => item.name.toLowerCase().includes('hpc'))[0] : {}} hour_plots={utilsTrends.hpc.hour_plots} cycle_plots={utilsTrends.hpc.cycle_plots} />
                </TabPane>:null
              }
              {
                utilsTrends.hpt !== undefined?
                <TabPane tabId='6'>
                  <UtilsGraph ctrShortInfo={ctrShortInfo} avgData={utlisAverage.filter(item => item.name.toLowerCase().includes('hpt')).length ? utlisAverage.filter(item => item.name.toLowerCase().includes('hpt'))[0] : {}} hour_plots={utilsTrends.hpt.hour_plots} cycle_plots={utilsTrends.hpt.cycle_plots} />
                </TabPane>:null
              }
              {
                utilsTrends.fan !== undefined?
                <TabPane tabId='4'>
                  <UtilsGraph ctrShortInfo={ctrShortInfo} avgData={utlisAverage.filter(item => item.name.toLowerCase().includes('fan')).length ? utlisAverage.filter(item => item.name.toLowerCase().includes('fan'))[0] : {}} hour_plots={utilsTrends.fan.hour_plots} cycle_plots={utilsTrends.fan.cycle_plots} />
                </TabPane>:null
              }
              {
                utilsTrends.landing_gears?
                <TabPane tabId='2'>
                  <ul className="list-inline lg-checkbox" style={{ marginBottom : '10px' }}>
                    {
                      Object.keys(utilsTrends.landing_gears).map((label,index) =>
                      <li className="list-inline-item" key={index}>
                        <label>
                          <input
                            type="radio"
                            name="lg"
                            checked={label == utilsTrendsInfo.lgSelected ? true:false}
                            onChange={() => this.props.updateUtilsGraph('lgSelected', label)}
                          />
                        <span>{utilsTrends.landing_gears[label].name} </span></label>
                      </li>
                      )
                    }
                  </ul>
                  <UtilsGraph avgData={utlisAverage.filter(item => item.name.toLowerCase().includes(utilsTrends.landing_gears[utilsTrendsInfo.lgSelected].name.toLowerCase())).length ? utlisAverage.filter(item => item.name.toLowerCase().includes(utilsTrends.landing_gears[utilsTrendsInfo.lgSelected].name.toLowerCase()))[0] : {}} ctrShortInfo={ctrShortInfo} hour_plots={utilsTrends.landing_gears[utilsTrendsInfo.lgSelected].hour_plots} cycle_plots={utilsTrends.landing_gears[utilsTrendsInfo.lgSelected].cycle_plots} />
                </TabPane>:null
              }
              {
                utilsTrends.engines?
                <TabPane tabId='3'>
                  <ul className="list-inline lg-checkbox" style={{ marginBottom : '10px' }}>
                    {
                      Object.keys(utilsTrends.engines).map((label,index) =>
                      <li className="list-inline-item" key={index}>
                        <label>
                          <input
                            type="radio"
                            name="engine"
                            checked={label == utilsTrendsInfo.engineSelected ? true:false}
                            onChange={() => this.props.updateUtilsGraph('engineSelected', label)}
                          />
                        <span>{utilsTrends.engines[label].name}</span></label>
                      </li>
                      )
                    }
                  </ul>
                  <UtilsGraph avgData={utlisAverage.filter(item =>item.name.toLowerCase().includes(utilsTrends.engines[utilsTrendsInfo.engineSelected].name.toLowerCase())).length ? utlisAverage.filter(item => item.name.toLowerCase().includes(utilsTrends.engines[utilsTrendsInfo.engineSelected].name.toLowerCase()))[0] : {}} ctrShortInfo={ctrShortInfo} hour_plots={utilsTrends.engines[utilsTrendsInfo.engineSelected].hour_plots}  cycle_plots={utilsTrends.engines[utilsTrendsInfo.engineSelected].cycle_plots} />
                </TabPane>:null
              }
            </TabContent>
          </div></div>:null
        }
        {
          getLocalStorageInfo().user.user_role != 'lessee' && utlisAverage && utlisAverage.length ?
          <div className="utilization-table-block">
            <h5 className="uti-heading" style={{ padding : "15px 0", background : "#f0f2f3" }}>Utilization Summary
              <ExportUtilSummery util_type="utilization_summary" contract={ctrShortInfo.contract} ctrShortInfo={ctrShortInfo} exportUtils={(contract, type,util_type,aircraft, ctrShortInfo) => this.props.exportUtilsFile(contract, type,util_type,aircraft, removeEmptyKey(this.getQueryStrfn(this.state.appliedFilter)),ctrShortInfo)} aircraft={ctrShortInfo}/>
              <label className="flex-centered" style={{display: 'none'}}>Viewing  Till Month
                <Select
                    name="form-field-name"
                    placeholder = 'Month'
                    className="custom-select-block"
                    name="form-field-name"
                    labelKey= 'name'
                    valueKey='id'
                    value=''
                    onChange={this.dropDownChange}
                    options={[]}
                  />
              </label>
            </h5>
            <div className="utilization-wrapper">
            <table>
              <UtilizationRow />
              <tbody>
                {
                  utlisAverage.map((utilAv,index) => !utilAv.is_fitted ? <UtilizationList key={index} utilAv={utilAv} /> : null)
                }
              </tbody>
            </table>
            </div>
          </div>:null
        }
        <div className="edit-view-tech-spec" style={{marginTop: '40px', marginLeft: '75px'}}>
        <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeRental === 0 })}
                onClick={() => this.setState({activeRental: 0, error: {}}) }
              >

                Utilization
              </NavLink>
            </NavItem>
        </Nav>
      </div>
      <div className="util-heading-block">
        <TabContent activeTab={this.state.activeRental}>
            <TabPane tabId={0}>
              <div className="cash-flow-wrapper" >
                <div className="utilization-outer-wrapper ">
                  <h5 className="para uti-heading flex-centered" style={{position:"relative"}} >
                    Utilization History
                    { getLocalStorageInfo().user.permission['contracts']['utilization'].indexOf('C') != -1 ?
                      <span  style={{position:"absolute", top:"0px", left:"405px"}}>
                        { moment(utilsInitiate.utilization.start_month).format('YYYYMM') == moment().format('YYYYMM') ?
                          utilsInitiate.utilization.is_contract_start_date ?
                          <Link to={"/" + this.props.params.type + "/" +this.props.params.aircraft_slug+ "/contract/utilization/add"} className="primary-btn caption-ui" style={{height:"27px",width:"141px"}}>Add Utilization</Link>: '' :
                          <Link to={"/" + this.props.params.type + "/" +this.props.params.aircraft_slug+ "/contract/utilization/add"} className="primary-btn caption-ui" style={{height:"27px",width:"141px"}}>Add Utilization</Link>
                        }
                      </span>:null
                    }
                    {getLocalStorageInfo().user.user_role != 'lessee' && utilsListInfo && utilsListInfo.list && utilsListInfo.list.length ?
                      <ExportUtilSummery util_type="utilization_history"  contract={ctrShortInfo.contract} ctrShortInfo={ctrShortInfo} exportUtils={(contract, type,util_type,aircraft, ctrShortInfo) => this.props.exportUtilsFile(contract, type,util_type,aircraft, removeEmptyKey(this.getQueryStrfn(this.state.appliedFilter)), ctrShortInfo)}  aircraft={ctrShortInfo}/>
                      :null
                    }
                  </h5>
                </div>
                <div className="asset-list-filter-sort">
                  <FilterBar appliedFilter={this.state.appliedFilter} clearFilterValue={this.clearFilterValue} filterMenu={filterMenu} changeFilterFn={this.changeFilterFn}/>
                </div>
                <UtilsListInfo>
                  { utilsListInfo.list.map((utils,index) =>
                    <UtilsList editUtils={() => this.utildata(ctrShortInfo.contract.slug, utils, utils.editable ? 'edit' : 'view')} key={index} utils={utils} utilLists={utilsListInfo.list} index={index} />)}
                </UtilsListInfo>
                {
                  !utilsLoader && utilsListInfo.list && !utilsListInfo.list.length ? <NoRecordFound style={{marginLeft: '0px'}} description="Get Started by clicking 'Add Utilization' button above" />:null
                }
                {
                  !utilsLoader && !utilsListInfo.list.length ? null:
                  <div style={{padding: '0 0 40px 0'}}>
                     <Pagination
                      activePage={this.state.page}
                      itemsCountPerPage={20}
                      totalItemsCount={utilsListInfo.pagination.total ? utilsListInfo.pagination.total:0}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}

                    />
                  </div>
                }
              </div>
            </TabPane>
            <TabPane tabId={1}>
              <div className="cash-flow-wrapper" >
                <div className="utilization-outer-wrapper ">
                  <h5 className="para uti-heading flex-centered" style={{position:"relative"}} >
                    Rental Utilization History
                    { getLocalStorageInfo().user.permission['contracts']['utilization'].indexOf('C') != -1 ?
                      <span  style={{position:"absolute", top:"0px", left:"430px"}}>
                          <Link onClick={() => this.setState({addUtil: true, utilsObj: {}}, () => this.yearMonth('' ,'year'))} className="primary-btn caption-ui" style={{height:"27px",width:"141px"}}>Add Utilization</Link>
                      </span>:null
                    }
                    {getLocalStorageInfo().user.user_role != 'lessee' && rentalUtils && rentalUtils.utilization && rentalUtils.utilization.length ?
                      <ExportUtilSummery util_type="utilization_history"  contract={ctrShortInfo.contract} ctrShortInfo={ctrShortInfo} exportUtils={(contract, type,util_type,aircraft, ctrShortInfo) => this.props.exportRentalUtilsFile(contract, type,util_type,aircraft, removeEmptyKey(this.getQueryStrfn(this.state.appliedFilter)), ctrShortInfo)}  aircraft={ctrShortInfo}/>
                      :null
                    }
                  </h5>
                </div>
                <div className="asset-list-filter-sort">
                  <FilterBar appliedFilter={this.state.appliedFilter} clearFilterValue={this.clearFilterValue} filterMenu={rentalFilterMenu} changeFilterFn={this.changeFilterFn}/>
                </div>
                <div className="mobile-asset-list">

                <div className="asset-listing-wrap utilization-history" style={{marginLeft:'0px'}} >
                  <div className="asset-list-filter-sort">
                    <Row className="filter-block">
                      <Col><h6>Month</h6></Col>
                      <Col><h6>Hours</h6></Col>
                      <Col><h6>Cycles</h6></Col>
                      <Col><h6>Block Hours</h6></Col>
                      <Col><h6>Remarks</h6></Col>
                    </Row>
                  </div>
                  {
                    !utilsLoader && rentalUtils && rentalUtils.utilization && rentalUtils.utilization.length ?
                      rentalUtils.utilization.map((rental, index) =>
                        <RentalList editUtil={() => this.setState({addUtil: true, month: rental.month.substring(4,6), year: rental.month.substring(0,4), utilsObj: {...rental, type: 'edit'}}, () => this.yearMonth(rental.month.substring(0,4) ,'year'))} rental={rental} index={index}/>
                      )
                    :null
                  }
                </div>
                </div>
                {
                  !utilsLoader && !rentalUtils.utilization.length ? <NoRecordFound style={{marginLeft: '0px'}} description="Get Started by clicking 'Add Utilization' button above" />:null
                }
                {
                  !utilsLoader && !rentalUtils.utilization.length ? null:
                  <div style={{padding: '0 0 40px 0'}}>
                     <Pagination
                      activePage={this.state.rentalPage}
                      itemsCountPerPage={20}
                      totalItemsCount={rentalUtils.pagination.total ? rentalUtils.pagination.total:0}
                      pageRangeDisplayed={5}
                      onChange={(page) => this.setState({rentalPage: page}, () => this.props.getRentalUtilization(ctrShortInfo.contract.slug, {page: page}))}

                    />
                  </div>
                }
              </div>
            </TabPane>
        </TabContent>
      </div>
      <Modal isOpen={this.state.addUtil} id="addRentalUtilForm" toggle={() => this.setState({addUtil: false, error: false})} className="transfer-modal">
        <ModalBody>
          <Row className="transfer-modal-body">
            <Col md="12">
              <ul className="list-inline">
                <li className="list-inline-item">{this.state.utilsObj.type === 'edit' ? 'Edit' : "Add"} Rental Utilization</li>
              </ul>
            </Col>
            <Row className="apps-container">
              <Col md={12}>
                <ul className="list-inline flex-centered select-util-block">
                  <li className="list-inline-item" style={{width: '210px'}}>
                  <label className="label">Select Year <sup className="reqStar"> * </sup></label>
                    <Select
                      name="form-field-name"
                      placeholder="Select Year"
                      className="custom-select-block"
                      value={this.state.year}
                      options = {years}
                      disabled={this.state.utilsObj.type === 'edit' ? true : false}
                      onChange = {(target) => this.yearMonth(target?target.value:'', 'year')}
                      />
                      {
                        this.state.error.year ?
                        <h6 className="error-msg">{this.state.error.year}</h6>
                        :<h6 className="error-msg">&nbsp;</h6>
                      }
                  </li>
                  <li className="list-inline-item" style={{width: '210px'}}>
                  <label className="label">Select Month  <sup className="reqStar"> * </sup></label>
                    <Select
                      name="form-field-name"
                      placeholder="Select Month"
                      className="custom-select-block"
                      value={this.state.month}
                      disabled={this.state.utilsObj.type === 'edit' ? true : false}
                      options = {this.state.utilsObj.type !== 'edit' ?  this.state.months: this.state.month ? [...this.state.months, monthsss.filter(month => month.value === this.state.month)[0]] : this.state.months}
                      onChange = {(target) => this.yearMonth(target?target.value:'', 'month')}
                      />

                      {
                        this.state.error.month ?
                        <h6 className="error-msg">{this.state.error.month}</h6>
                        :<h6 className="error-msg">&nbsp;</h6>
                      }
                  </li>
                </ul>
              </Col>
              <Col md={12} style={{marginTop: '20px'}}>
                <ul className="list-inline flex-centered select-util-block">
                  <li className="list-inline-item" style={{width: '210px'}}>
                  <label className="label">Hours <sup className="reqStar"> * </sup></label>
                    <div className="form-group">
                      <input
                        type="text"
                        value={this.state.utilsObj.hours}
                        onChange={(e) => this.updateUtilField( e.target.value, 'hours')}
                        style={{width: '210px'}}
                      />
                      {
                        this.state.error.hours ?
                        <h6 className="error-msg">{this.state.error.hours}</h6>
                        :<h6 className="error-msg">&nbsp;</h6>
                      }
                    </div>
                  </li>
                  <li className="list-inline-item" style={{width: '210px'}}>
                  <label className="label">Cycles <sup className="reqStar"> * </sup></label>
                    <div className="form-group">
                      <input
                        type="text"
                        value={this.state.utilsObj.cycles}
                        onChange={(e) => this.updateUtilField( e.target.value, 'cycles')}
                        style={{width: '210px'}}
                      />
                      {
                        this.state.error.cycles ?
                        <h6 className="error-msg">{this.state.error.cycles}</h6>
                        :<h6 className="error-msg">&nbsp;</h6>
                      }
                    </div>
                  </li>
                </ul>
              </Col>
              <Col md={12} style={{marginTop: '20px'}}>
                <ul className="list-inline flex-centered select-util-block">
                  <li className="list-inline-item" style={{width: '210px'}}>
                  <label className="label">Block Hours {ctrShortInfo.contract && ctrShortInfo.contract.unit_of_measure === 4 ? <sup className="reqStar"> * </sup> : null}</label>
                    <div className="form-group">
                      <input
                        type="text"
                        value={this.state.utilsObj.block_hour ? this.state.utilsObj.block_hour:''}
                        onChange={(e) => this.updateUtilField( e.target.value, 'block_hour')}
                        style={{width: '210px'}}
                      />
                      {
                        this.state.error.block_hour ?
                        <h6 className="error-msg">{this.state.error.block_hour}</h6>
                        :<h6 className="error-msg">&nbsp;</h6>
                      }
                    </div>
                  </li>
                  <li className="list-inline-item" style={{width: '210px'}}>
                  <label className="label">Remarks</label>
                    <div className="form-group">
                      <input
                        type="text"
                        maxLength={255}
                        value={this.state.utilsObj.remarks ? this.state.utilsObj.remarks:''}
                        onChange={(e) => this.updateUtilField( e.target.value, 'remarks')}
                        style={{width: '210px'}}
                      />
                      {
                        this.state.error.remarks ?
                        <h6 className="error-msg">{this.state.error.remarks}</h6>
                        :<h6 className="error-msg">&nbsp;</h6>
                      }
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
          </Row>
          <Row className="transfer-modal-footer">
            <Col md="12">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button type="button" disabled={this.state.saving} onClick={() => this.saveRentalUtils()} style={{backgroundColor:'#3f51b5', borderColor:'#3f51b5'}} className="btn btn-primary">{this.state.saving ? 'Processing...' : 'Save'}</button>
                </li>
                <li className="list-inline-item">
                  <Link style={{color:'#3f51b5'}} onClick={() => this.setState({addUtil: false, error: {}})}>CANCEL</Link>
                </li>

              </ul>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
        <EditSidebar
          title={ utilCrud.type != '' ?
              (utilCrud.type == 'edit') ? 'Edit Utilization': 'View Utilization' : ''
             }
           editSideBar={utilCrud.flag}
           toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})} >

          {Object.keys(utilCrud.data).length && utilCrud.flag ?
            <div>
              {this.state.editable ?
                <FormModeType
                  type={utilCrud.type}
                  changeViewMode={()=>this.props.changeViewMode(utilCrud.type)}
                />
                :null
              }
              <EditUtils
              type={utilCrud.type}
              contract_slug={ctrShortInfo.contract.slug}
              utilsObj={utilCrud.data}
              editUtilsInfo={(slug, data) => this.props.editUtilsInfo(slug, data, this.props.assetViewId)}
              toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})} />
            </div>
            : null
          }
        </EditSidebar>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  utilsInitiate: state.UtilsReducer.utilsInitiate,
  utilsListInfo: state.UtilsReducer.utilsListInfo,
  utilsTrendsInfo: state.UtilsReducer.utilsTrendsInfo,
  utlisAverage: state.UtilsReducer.utlisAverage,
  viewUtils: state.UtilsReducer.viewUtils,
  utilsLoader: state.UtilsReducer.utilsLoader,
  utilsMajorAssembly: state.UtilsReducer.utilsMajorAssembly,
  utilCrud: state.UtilsReducer.utilCrud,
  activeTab: state.UtilsReducer.activeTab,
  rentalUtils: state.UtilsReducer.rentalUtils,
  assetViewId: state.userAssetReducer.assetViewId
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchUtilsInfo: (type) => dispatch(UtilsAssetViewAc(ownProps.params, type)),
    utilizationList: (contract_slug, filterParams) => dispatch(utilsListAc(contract_slug, filterParams)),
    // utilsView: (slug,id) => dispatch(utilsViewAc(slug,id)),
    utilFnCrud: (slug,data, type) => dispatch(utilFnCrudAc(slug,data, type)),
    editUtilsInfo: (slug, data, assetViewId) => dispatch(editUtilsInfoAc(slug, data, assetViewId, ownProps.params)),
    exportUtilsFile:(contract, fileType, util_type, aircraft, appliedFilter, ctrShortInfo) => dispatch(exportUtilizationAc(contract, fileType, util_type,aircraft, appliedFilter, ctrShortInfo)),
    exportRentalUtilsFile:(contract, fileType, util_type, aircraft, appliedFilter) => dispatch(exportRentalUtilizationAc(contract, fileType, util_type,aircraft, appliedFilter)),
    updateUtilsGraph:(type,value) => dispatch({
      type: CHANGE_UTIL_GRAPH,
      payload: {type, value}
    }),
    closeUtilsBar: (data) => dispatch({
      type: CLOSE_UTILS_BAR,
      payload: data
    }),
    toggle: (data) => dispatch({
      type: TOGGLE_TAB,
      payload: data
    }),
    toggleEditBar: (data) => dispatch({
      type: UTIL_CRUD_OPERN,
      payload: data
    }),
    changeViewMode: (type) => dispatch({
      type: TOGGLE_VIEW_EDIT,
      payload: type
    }),
    getRentalUtilization:(slug, filters) => dispatch(getRentalUtilizationAc(slug, filters))
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(Utilization, ['contracts','utilization','R'])));

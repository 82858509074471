import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ShortHead, ContractSideBar, ContractBarLoader } from '../../Elements';
import { NoRecordFound, ListLoader  } from '../../../../shared';
import { withRouter, browserHistory } from 'react-router';
import { Row, Col, Modal, ModalHeader, ModalBody, UncontrolledTooltip } from 'reactstrap';
import LeaseHistory from '../components/LeaseHistory';
import MajorAssemblyCard from '../components/MajorAssemblyCard';
import FittedAssembly from '../components/FittedAssembly';
import ContractTimeline from '../components/ContractTimeline';
import DashboardInvoiceInfo from '../components/DashboardInvoiceInfo';
import DashboardInvoiceHd from '../components/DashboardInvoiceHd';
import DashboardInvoiceRow from '../components/DashboardInvoiceRow';
import DashboardCashFlowWrap from '../components/DashboardCashFlowWrap';
import DashboardCashFlowList from '../components/DashboardCashFlowList';
import DashBoardFlaggedCond from '../components/DashBoardFlaggedCond';
import ReturnVsDeliveryPop from '../components/ReturnVsDeliveryPop';
import '../../assets/styles/Dashboard.scss';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LEASE_HISTORIES, UPDATE_LEASE_CARD_POS, CONTR_MAJOR_ASSEMBLY_SLIDER, CLEAR_RETURN_COND } from '../actions';
import { loadAssetViewAc, contrDbFlaggedCondAc, exportReturnCondnAc, exportLeaseHistoryAc } from '../actionCreators';
import { sGlobalConstantAc } from '../../../../shared/actionCreators';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import {ToolTipHover} from '../../../../shared'
import { imgStoragePath, assetsType } from '../../../../constants';
import { relative } from 'path';
import exportIconWhite from '../../../../shared/assets/img/export_icon_white.svg';
import compareIcon from '../../../../shared/assets/img/compare_icon.svg';
import Switcher from '../../../../shared/components/Switcher';
import exportIcon from '../../../../shared/assets/img/export_icon.svg';
import infoIcon from '../../../../shared/assets/img/info_icon.svg';
class ContractDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filesList: [],
      loader: false
    }
  }

  uploadFiles = (files) => {
    this.setState({loader: true})
    let filesList = []
    let index = 0
    for(let file of files){
      filesList = [...filesList, {name: file.name, id: index}]
      index++
    }
    setTimeout(() => {
      this.setState({filesList: filesList, loader: false})
    }, 5000)
  }

  componentDidMount(){
    this.props.fetchAssetInfo();
    this.props.fetchTechConstant({constant_types:['lease_type_accounting','lease_type','insurance_type','lease_option_type','interval','escalation_interval','mr_rate_range_condition','party_role','security_deposit_type']});
  }
  toggle = () => {
    this.props.closeReturnCondModal();
  }


  changeMsn =(evt) =>{
    if(evt.contracts){
    if(evt.contract_slug == null || evt.contract_slug == undefined || evt.contract_slug == ''){
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
      browserHistory.push(url);
    }else{
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contract/dashboard';
      browserHistory.push(url);
      this.props.params.type = assetsType[evt.asset_type];
      this.props.params.aircraft_slug = evt.slug;
      this.componentDidMount();
    }
  }else{
    let url = '/asset-view/'+assetsType[evt.asset_type]+'/'+evt.slug;
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
    this.componentDidMount();
  }
  }
  render() {
    const {
      leaseHistories,
      leaseInfoCardSettings,
      majorAssemblyList,
      ctrShortInfo,
      returnCondFlagged,
      contrDbInvoices,
      contrDbCashFlows,
      returnCondCompare,
      invoiceWidgetLoader,
      cashFlowLoader,
      flaggedWidgetLoader,
    } = this.props;


    if(window.innerWidth <= 767.98){
      var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1 ,
        slidesToScroll: 1,
      };
    }else{
      var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: majorAssemblyList.length <= 2 ? majorAssemblyList.length : 4 ,
        slidesToScroll: 1,
      };
    }
    if(window.innerWidth <= 767.98){
      var settings1 = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1 ,
        slidesToScroll: 1,
      };
    }else{
      var settings1 = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: majorAssemblyList.length <= 2 ? majorAssemblyList.length : 4 ,
        slidesToScroll: 1,
      };
    }
    return (
      <div className="contract-container">
        {flaggedWidgetLoader || this.state.loader ? <ListLoader /> :null}
        { Object.keys(ctrShortInfo).length ?
          <ShortHead  changeMsn={this.changeMsn} contrAssetBasicInfo={ctrShortInfo} headingText={'Lease Management' && <Switcher app="Lease"  />} />:<ContractBarLoader />
        }
        <ContractSideBar application="Lease Dashboard" type="shorter" params={this.props.params} />
        <div className="contract-db-wrapper">
          <Row>
            <DashboardCashFlowWrap contrDbCashFlows={contrDbCashFlows} params={this.props.params}>
              <div className="cashflow-blk-list">
                <h5 className="last-month-title">Latest 3 Month Cash Flow</h5>
                {
                  Object.keys(contrDbCashFlows).length ?
                  !cashFlowLoader && !Object.keys(contrDbCashFlows.last_three_month_records).length ?
                  <NoRecordFound />:
                  <div className="cashflow-bottom-bar">
                    <p className="cashflow-track-bar"></p>
                    <ul className="list-unstyled">
                      {
                        Object.keys(contrDbCashFlows).length ?
                        Object.keys(contrDbCashFlows.last_three_month_records).map((label, index) => <DashboardCashFlowList key={index} label={label} cashFlow={contrDbCashFlows.last_three_month_records[label]}  /> )
                        :'Loading...'
                      }
                    </ul>
                  </div>:
                  <div style={{height: '264px', position: 'relative'}}>
                    <p style={{position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}} >Loading...</p>
                  </div>
                }


              </div>
            </DashboardCashFlowWrap>
            <Col md="8" className="invoice-summary-spacing">
              <DashboardInvoiceInfo contrDbInvoices={contrDbInvoices} params={this.props.params}>
                <Col md="12" className="db-invoice-table-block">
                  <p className="last-month-title">Latest 3 Month Invoices</p>
                  <table>
                    <DashboardInvoiceHd />
                    <tbody>
                      {
                        contrDbInvoices.list.map((invoice, index) => <DashboardInvoiceRow params={this.props.params} contract_slug={ctrShortInfo.contract.slug} key={index} invoice={invoice} /> )
                      }
                    </tbody>
                  </table>
                  {
                    !invoiceWidgetLoader && !contrDbInvoices.list.length ?
                    <NoRecordFound description="Get Started by creating invoices" />:null
                  }
                </Col>
              </DashboardInvoiceInfo>
            </Col>
          </Row>
          {majorAssemblyList.length ?
            <div>
            <h5 className="para avergare-monthly-heading" >Average Monthly Utilization
            <span className="as-of-date">
              { majorAssemblyList[0].max_period && majorAssemblyList[0].min_period ? 'As of '+majorAssemblyList[0].min_period + ' to ' + majorAssemblyList[0].max_period:''}
            </span>
            </h5>
            </div>:null
          }

          {
            majorAssemblyList.length && majorAssemblyList.filter(item => item.is_fitted === false).length ?
            <Slider {...settings} className="airframe-cards flex-not-centered">
              {
                majorAssemblyList.map((majorAssembly, index) => !majorAssembly.is_fitted ? <MajorAssemblyCard key={index} majorAssembly={majorAssembly}  /> : null)
              }
            </Slider>:null
          }
          {majorAssemblyList.length && majorAssemblyList.filter(item => item.is_fitted).length ?
            <div>
            <h5 className="para avergare-monthly-heading" >Fitted Major Assembly
            </h5>
            </div>:null
          }
          {
            majorAssemblyList.length && majorAssemblyList.filter(item => item.is_fitted).length ?
            <div  className="airframe-cards flex-not-centered">
              {
                majorAssemblyList.filter(item => item.is_fitted).map((majorAssembly, index) =>
                  <FittedAssembly key={index + 'titlte'} majorAssembly={majorAssembly}/>
                )
              }
            </div>:null
          }
          {
            leaseHistories.length ?
            <div className="timeline-block">
              <div className="timeline-heading-block">
                <h5 className="para contract-timeline">
                Lease History
                <span  onClick={() => this.props.exportLeaseHistory(ctrShortInfo)} style={{paddingLeft: '16px', cursor: 'pointer', fontSize:'14px', color:'#fff'}}><img style={{marginRight: '4px', textTransform: 'uppercase'}} width="18" src={exportIconWhite} alt="export" />Export</span>
                </h5>
                <div className="nav-btn">
                  {
                    leaseInfoCardSettings.rightCount > 0 ?
                    <img src={imgStoragePath+"timeline_prev.png"} alt="icon" onClick={() => this.props.leaseCardPos('prev')} />:<img style={{opacity: '0.4'}} src={imgStoragePath+"timeline_prev.png"} alt="icon" />
                  }
                  {
                    parseInt(leaseInfoCardSettings.leftCount) > 0 ?
                    <img src={imgStoragePath+"timeline_next.png"} alt="icon" onClick={() => this.props.leaseCardPos('next')} />:<img style={{opacity: '0.4'}} src={imgStoragePath+"timeline_next.png"} alt="icon" />
                  }

                </div>
              </div>
              <div className="timeline-inner-block flex-centered">
                <ul className="timeline-bar list-inline" style={{width: leaseInfoCardSettings.totalWidth, 'left': leaseInfoCardSettings.leftPos }}>
                  {
                    leaseHistories.map((leaseHistory, index) => <LeaseHistory leaseHistory={leaseHistory} key={index} /> )
                  }
                </ul>
              </div>
            </div>:null
          }
          <div className="db-upcoming-event flagged-condition">
            <div className="cashflow-top-bar">
              <h5 className="para" style={{position:"relative"}}>Flagged Return Conditions</h5>
                    <span style={{position: 'absolute', top: '12px', left: '207px', fontSize: '18px', color:'#808080'}} >
                       <img src={infoIcon} id='UncontrolledTooltipExample10' alt="Icon" className="info-icon" />
                         <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample10">
                            Summary of differences in delivery and return conditions
                         </UncontrolledTooltip>
                    </span>

              <span style={{color:'#3f51b5'}} onClick={() => this.props.compareReturnCond(ctrShortInfo.contract.slug,{})}>
                <img src={compareIcon} alt="compare" /> Return Vs Delivery
              </span>
            </div>
            <div className="flagged-condition-wrapper">
              <table width="100%">
                <thead>
                  <tr>
                    <th>Component</th>
                    <th>Return Conditions</th>
                    <th>Delivery Conditions</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    returnCondFlagged.map((returnCond,index) => <DashBoardFlaggedCond key={index} returnCond={returnCond} />)
                  }
                </tbody>
              </table>
              {
                !flaggedWidgetLoader && !returnCondFlagged.length ?
                <NoRecordFound description="Get Started by adding Return & Delivery conditions under contract" />:null
              }
            </div>
          </div>
          <Modal isOpen={returnCondCompare.flag} size="lg" toggle={this.toggle} className="return-condition-modal">
            <ModalHeader toggle={this.toggle}>Return Vs Delivery Conditions
            { returnCondCompare.returnConds.length && ctrShortInfo && ctrShortInfo.contract && Object.keys(ctrShortInfo.contract).length ?
              <span  onClick={() => this.props.exportReturnCondn(ctrShortInfo)} style={{paddingLeft: '16px', cursor: 'pointer', fontSize:'14px'}}><img style={{marginRight: '4px'}} width="18" src={exportIcon} alt="export" />EXPORT</span>
              : null
            }

            </ModalHeader>
            <ModalBody>
              <ReturnVsDeliveryPop returnCondCompare={returnCondCompare} />
            </ModalBody>
          </Modal>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  leaseInfoCardSettings: state.ContrDashboardReducer.leaseInfoCardSettings,
  leaseHistories: state.ContrDashboardReducer.leaseHistories,
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  majorAssemblyList: state.ContrDashboardReducer.majorAssemblyList,
  returnCondFlagged: state.ContrDashboardReducer.returnCondFlagged,
  contrDbCashFlows: state.ContrDashboardReducer.contrDbCashFlows,
  contrDbInvoices: state.ContrDashboardReducer.contrDbInvoices,
  returnCondCompare: state.ContrDashboardReducer.returnCondCompare,
  invoiceWidgetLoader: state.ContrDashboardReducer.invoiceWidgetLoader,
  cashFlowLoader:state.ContrDashboardReducer.cashFlowLoader,
  flaggedWidgetLoader: state.ContrDashboardReducer.flaggedWidgetLoader,
  techSpecsLoader: state.TechSummaryReducer.techSpecsLoader,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchAssetInfo: () => dispatch(loadAssetViewAc(ownProps.params)),
    compareReturnCond:(contract_slug, queryParams) => dispatch(contrDbFlaggedCondAc(contract_slug, queryParams)),
    exportReturnCondn: (ctrShortInfo) => dispatch(exportReturnCondnAc(ctrShortInfo)),
    exportLeaseHistory: (ctrShortInfo) => dispatch(exportLeaseHistoryAc(ctrShortInfo)),
    closeReturnCondModal: () => dispatch({
      type: CLEAR_RETURN_COND,
      payload: ''
    }),
    fetchTechConstant : (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
    leaseCardPos: (dir) => dispatch({
      type: UPDATE_LEASE_CARD_POS,
      payload: dir
    })
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(ContractDashboard, ['contracts', 'dashboard', 'R'])))

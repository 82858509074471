import React, { Component } from 'react';
import FilterList from '../components/FilterList';
import { withRouter } from 'react-router';
import { NoRecordFound } from '../../../../shared';
import { imgStoragePath } from '../../../../constants';

class FilterSideBar extends Component{
  constructor(props){
    super(props)
  }
  render(){
    const { recordDirTree } = this.props;
    return(
      <div>
        <div className="filter-list-wrapper" style={this.props.style}>
          <div className="filter-heading">
            <h4>Filter <span className="closeFilter" onClick={() => this.props.toggleFilterBar()}>X</span></h4>
          </div>
          {
            recordDirTree && recordDirTree.navigation && recordDirTree.navigation.length ?
            <div className="filter-navigation" style={{padding: '10px 35px', marginTop: '-30px'}}>
              {
                recordDirTree && recordDirTree.navigation && recordDirTree.navigation.length > 1 ?
                <img onClick={() => this.props.fetchDirTree({name: this.props.currentFolder.name,uuid: this.props.currentFolder.uuid,type: this.props.params.type, aircraft_slug: this.props.params.aircraft_slug})} style={{width: "30px", cursor: 'pointer'}} src={imgStoragePath+"popover_back.png"}/>:null
              }
              <span>{this.props.folderName}</span>
            <span className="selectAll" onClick={this.props.selectAll} checked={recordDirTree.list.length == this.props.exportList.length && recordDirTree.list.length > 0 ? true : false}>{recordDirTree.list.length == this.props.exportList.length && recordDirTree.list.length > 0 ? 'Uncheck All' : recordDirTree.list.length > 0 ? 'Select All' : null}</span>
            </div>
            : null
          }
          <div className="filter-list-wrap">
            {
              this.props.filterLoader == false && recordDirTree && recordDirTree.list && recordDirTree.list.length ?
              recordDirTree.list.map((item) => <FilterList level={this.props.level} exportList={this.props.exportList} addToExportList={this.props.addToExportList} key={item.uuid} fetchDirTree={this.props.fetchDirTree} item={item}/>)
              :!this.props.filterLoader ? <NoRecordFound/> : null
            }
            {
              this.props.filterLoader ?
              <div><img src={imgStoragePath+"page-loader.gif"} style={{width: '100px', margin: '20% 40%'}}/>Loading...</div>:null
            }
          </div>
          <div className='filter-list-footer'>
            <ul className="list-inline">
              <li className="list-inline-item">
                <button disabled={ this.props.exportList.length ? '' : 'disabled'} className="btn btn-primary btn-sm" onClick={() => {this.props.viewReport(this.props.exportList); this.props.toggleFilterBar(); this.props.clearExportList();}}>Apply Filter</button>
              </li>
              <li className="list-inline-item">
                <button className="btn btn- btn-sm" onClick={this.props.clearExportList}>Clear Filter</button>
              </li>
            </ul>
          </div>
        </div>
        {
          this.props.active ? <div className="overlays" style={ this.props.active ? { position: 'fixed', top: '0', left: '0', right: '0', bottom: '0', zIndex: '999', background: 'rgba(0,0,0,0.4)' } : {}}></div>: null
        }
      </div>
    )
  }
}

export default withRouter(FilterSideBar)

import React, {Component, Fragment} from 'react';
import {Row} from 'reactstrap';
import {Link} from 'react-router';
class Profile extends Component {
  constructor(props){
    super(props)
  }
  render() {

    return(
    <div>
        <h1>Profile</h1>
    </div>
    )
  }
}
export default Profile;

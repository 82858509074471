import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDom from 'react-dom';
import { withRouter, Link, browserHistory } from 'react-router';
import * as actions from '../actions';
import { loadAssetViewAc } from '../../Dashboard/actionCreators';
import { getUsersSelectedAc } from '../actionCreators'
import { ShortHead, ContractBarLoader } from '../../../contracts/Elements';
import { ListLoader } from '../../../../shared'
import { displayDateTimeFormatShort, imgStoragePath, assetsType } from '../../../../constants'
import { RecordsSideBar } from '../../Elements';
import { DataRoomTile } from '../components';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { getLocalStorageInfo, toastFlashMessage } from '../../../../utils';
import { globalPutService, globalGetService, globalPostService } from '../../../../globalServices';
import '../../assets/styles/records_a.scss';
import Chips, { Chip } from 'react-chips'
import moment from 'moment'
import { Input, Row, Col, Modal, ModalBody, ModalFooter, ModalHeader, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
class DataRoomDash extends Component{
  constructor(props) {
    super(props);
    this.state = {
      ModalFlag: false,
      renameCard: {},
      shareUrl: false,
      shareDuration: 24,
      shareType: 'hour',
      customDuration: false,
      shareValidity: '',
      userstateEmails:[],
      chips: [],
      changeShareDuration: false,
      folderUUid:'',
      shareDurationError: false,
      toggleDropDown:false,
      canEdit:true,
      emails:[],
      checkedKeys: [],
      checkedIds:[],
      isChecked: false,
      shareKey: '',
      selectedEmail: [],
      newEmails: [],
      reasonForCreation: '',
      reasonModal: false,
      newEmail: false
    }
  }

  closeReasonModal = () => {
    this.setState({
      newEmail: false,
      reasonModal: false,
      reasonForCreation: '',
      chips: this.state.chips.pop()
    })
  }

  shareUsers=(selectedEmails)=>{
    var ids=[];
    var emails= Object.assign([], this.state.userstateEmails);
    emails=[...emails,...this.props.userSelectedEmailLIst];
    selectedEmails.map(email => {
      if(emails.filter(item => item.email == email).length && emails.filter(item => item.email == email)[0].id ){
        ids.push({id: emails.filter(item => item.email == email)[0].id, reason: null})
      }
    })
    this.setState({selectedEmail: ids})
    var newEmails=this.state.newEmails;
    let company_domain = getLocalStorageInfo().defaultLessor.company_domain
    company_domain = company_domain ? company_domain.substr(company_domain.lastIndexOf('/') + 1, company_domain.length - 1) : ''
    if(getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.is_saas){
      if(this.props.usersUsage.available < this.props.usersUsage.used + newEmails.filter(email => company_domain && company_domain != '' && email.email.includes(company_domain)).length){
        this.props.buyLicence({
          flag: true,
          action: '/licenses/user',
          content: 'License for adding new users is expired. Please upgrade your license to add more users.'
        })
        return;
      }
    }
    this.shareUserEmailsAc(ids,this.state.renameCard.uuid,newEmails,this.state.canEdit);
    this.toggle();
  }

  shareUserEmailsAc = (ids,folderId,emails,flag) => {
    globalPostService('/records/user-folder-share/'+folderId+'/', {user_id_list: ids,new_user_email:emails,can_edit:flag})
    .then(response => {

      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success');
      }
    })
  }

  copyFileUrl(){
    let file_url = document.querySelector("#file_url");
    file_url.select();
    document.execCommand("copy");
    this.setState({
      shareUrl: true
    });
  }

  setShareDuration = (type,duration,flag) =>{
    this.setState({
      shareDuration: duration,
      shareType: type,
      customDuration: flag,
      isChecked: true,
      shareDurationError: false
    });
    var shareDuration = type == "days" ? parseInt(duration * 24) : parseInt(duration);
    if(flag != true){
      this.shareRecordsAc(this.state.renameCard,true,shareDuration)
    }
  }
  setCustomDuration = (type,duration,flag) =>{
    if(duration != null && duration != undefined && duration != '' && duration != 0){
      this.setState({
        shareDuration: duration,
        shareType: type,
        customDuration: flag,
        isChecked: true,
      });
      var shareDuration = type == "days" ? parseInt(duration * 24) : parseInt(duration);
      this.shareRecordsAc(this.state.renameCard,true,shareDuration)
    }else{
      this.setState({
        shareDurationError: true
      })
    }
  }

  changeMsn =(evt) =>{
    let url = "";
    if(evt.records){
      url = "/"+assetsType[evt.asset_type]+'/'+evt.slug+'/records/dataRoom/';
    }else{
      url = '/asset-view/'+assetsType[evt.asset_type]+'/'+evt.slug;
    }
    this.props.params.aircraft_slug = evt.slug;
    this.props.params.type = assetsType[evt.asset_type];
    browserHistory.push(url);
    this.props.fetchAssetInfo('DataRoom Dashboard');
  }

  toggleDrop = () =>{
    this.setState({
      toggleDropDown: !this.state.toggleDropDown
    })
  }

  changeShareStatus(evt){
    this.setState({
      isChecked: !this.state.isChecked,
      shareDuration: 24,
      shareUrl: false,
      customDuration: false,
      shareDurationError: false,
    });
    this.shareRecordsAc(this.state.renameCard, evt.target.checked, 24);
  }

  fetchSuggestions=(value)=>{
    if(value.length>0){
     return  new Promise((resolve) => {
      globalGetService('/records/user-folder-share/'+this.state.renameCard.uuid + '/',{email: value,add_users: true})
      .then(response => {
        if(response.data.statusCode == 200){
        let emails=[]
        response.data.data.map(item => {
        emails.push(item.email);
        })
        this.setState(prevState=>({
          ...prevState,
          userstateEmails:[...prevState.userstateEmails, ...response.data.data]
             }))
       if(emails.length>0){
        resolve(emails);
          }else{
        emails.push('+ '+value);
        resolve(emails);
            }
      }else{
        toastFlashMessage("Something went wrong", "error");
      }
      })
      });
    }
  }

  shareReason = () => {
    var emails= Object.assign([], this.state.userstateEmails);
    emails=[...emails,...this.props.userSelectedEmailLIst];
    if(this.state.newEmail){
      this.setState({
        newEmails: [...this.state.newEmails, {email: this.state.chips[this.state.chips.length - 1], reason: this.state.reasonForCreation}],
        newEmail: false,
        reasonModal: false,
        reasonForCreation: ''
      },() => this.props.updateSelectedEmails(this.state.chips))
    }else{
      let id = emails.filter(item => item.email == this.state.chips[this.state.chips.length - 1])[0].id
      this.setState({
        selectedEmail: [...this.state.selectedEmail, {id: id, reason: this.state.reasonForCreation}],
        newEmail: false,
        reasonModal: false,
        reasonForCreation: ''
      },() => this.props.updateSelectedEmails(this.state.chips))
    }
  }

  onChange = chips => {
    var emails= Object.assign([], this.state.userstateEmails);
    emails=[...emails,...this.props.userSelectedEmailLIst];
    let selectedEmail = this.state.selectedEmail
    this.setState({chips: chips, selectedEmail: this.props.userSelectedEmails.map(user =>{ return {id: user.id, reason: selectedEmail.filter(email => email.id == user.id).length ? selectedEmail.filter(email => email.id == user.id) : null}})}, () => {
      if(chips.length == 0){
        this.setState({
          chips: [],
          newEmails: [],
          selectedEmail: [],
        })
        this.props.updateSelectedEmails([])
      }else if(chips.length < this.state.chips.length){
        this.setState({
          chips,
          newEmails: this.state.newEmails.filter(email => !chips.includes(email.email)),
          selectedEmail: this.state.selectedEmail.filter(email => emails.filter(item => item.id == email.id).length),
        })
        this.props.updateSelectedEmails(chips)
      }else{
        if(chips.length > 0 && chips[chips.length-1].startsWith('+ ')){
         let email = chips[chips.length-1].substring(2);
         chips.pop();
         chips=[...chips,email]
         this.setState({ chips }, () => this.setState({reasonModal: true, newEmail: true}));
        }else{
          this.setState({chips}, () => {
            if(emails.filter(item => item.email == this.state.chips[this.state.chips.length - 1]).length){
              this.setState({selectedEmail: [...this.state.selectedEmail, {id: emails.filter(item => item.email == this.state.chips[this.state.chips.length - 1])[0].id, reason: null}]}, () => this.props.updateSelectedEmails(chips))
            }else{
              this.setState({reasonModal: true, newEmail: false});
            }
          }
          )
        }
      }
    })
  }

  shareRecordsAc = (data, checked, duration) => {
    const shareData ={
      type: this.state.renameCard.type,
      object_uuid: this.state.renameCard.uuid,
      share: checked,
      hour: duration
    };
    globalPutService('records/'+this.props.params.type+'/'+this.props.params.aircraft_slug+'/data-room/share/', shareData)
    .then(response => {
      if(response.data.statusCode == 200){
        this.setState({shareKey: response.data.data.share_key, shareValidity: response.data.data.valid_till, isChecked: checked, shareDuration: ![24,72,168,240].includes(duration) && duration % 24 == 0 ? duration / 24 : duration, customDuration: ![24,72,168,240].includes(duration), shareType: duration % 24 == 0 ? 'days' : 'hours' }, () => this.props.fetchAssetInfo('DataRoom Dashboard'))
      }
    })
  }

  toggle = () => {
    this.setState(prevState => ({
      ...prevState,
      ModalFlag: !prevState.ModalFlag,
      shareUrl: false,
      shareKey: '',
      shareDuration: 24,
      shareType: 'hour',
      userstateEmails:[],
      customDuration: false,
      shareDurationError: false,
      canEdit: true,
      newEmails: [],
      selectedEmail: []
    }));

  }

  updateEdit=(value)=>{
    this.setState({
      canEdit: value
    })
    this.props.updateSelectedPermission(value);
  }

  componentWillReceiveProps = (nextProps, state) => {
    if( nextProps.params.aircraft_slug != this.props.params.aircraft_slug){
      this.props.fetchAssetInfo('DataRoom Dashboard');
    }
  }

  componentDidMount(){
    this.props.fetchAssetInfo('DataRoom Dashboard');
  }
  render(){
    const { userSelectedPermission, ctrShortInfo, directoryLoader, notificationOpen, userSelectedEmails } = this.props;
    let isChecked = this.state.isChecked

    return(
      <div className="records-resource-container">
      {
        directoryLoader ?
        <ListLoader/> : null
      }

        <RecordsSideBar />
        { Object.keys(ctrShortInfo).length ?
          <ShortHead
            headingText="Records"
            changeMsn={this.changeMsn}
            subHeaderTopMargin={notificationOpen == true ? 74 : 40}
            contrAssetBasicInfo={ctrShortInfo} />: <ContractBarLoader />
        }

        <div className="folder-row">
          <Row>
            <Col md="4" style={getLocalStorageInfo().user.permission['records'].data_room && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('TE_R') != -1 ? (Object.keys(ctrShortInfo).length && ctrShortInfo.records && ctrShortInfo.records.technical_records ? {} : {opacity: '0.6'}):{opacity: '0.6'}}>
            <Link to={getLocalStorageInfo().user.permission['records'].data_room && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('TE_R') != -1 ? ( Object.keys(ctrShortInfo).length && ctrShortInfo.records && ctrShortInfo.records.technical_records ? `/${this.props.params.type}/${this.props.params.aircraft_slug}/records/data-room/technical/${ctrShortInfo.records.technical_records.uuid}` : ''):''}>
              <div className="folder-card" >
                <img src={imgStoragePath+"folder_icn2.png"} alt="" />
                <div className="folder-info">
                  <h4>Technical Records { ctrShortInfo.records && ctrShortInfo.records.technical_records && ctrShortInfo.records.technical_records.share_enabled ? <img style={{float: 'none', width: '14px', height: '14px'}} src={imgStoragePath + 'share_icon.png'} /> : null }</h4>
                  <p>Files:&nbsp;
                    { ctrShortInfo.records && ctrShortInfo.records.technical_records ?
                        getLocalStorageInfo().user.permission['records'].action_dock && (getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1  || getLocalStorageInfo().user.permission['records'].action_dock && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('NPWC') != -1) ?
                        ctrShortInfo.records.technical_records.file_count ? ctrShortInfo.records.technical_records.file_count:0
                        :
                        ctrShortInfo.records.technical_records.published_file_count ? ctrShortInfo.records.technical_records.published_file_count:0
                      :
                      0
                    }
                    {
                      getLocalStorageInfo().user.permission['records'].data_room && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('TE_R') != -1 && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('SH') != -1  && (ctrShortInfo.records && ctrShortInfo.records.technical_records) ?
                        <span style={{position: 'absolute', right: '10px', bottom: '5px', color: '#1479f1'}} onClick={(evt) => {evt.preventDefault();evt.stopPropagation();this.setState({ModalFlag: true, renameCard: {type: 'folder', title: 'Technical Records', uuid: ctrShortInfo.records.technical_records.uuid}}, () => {this.shareRecordsAc(this.state.renameCard, ctrShortInfo.records.technical_records.share_enabled, ctrShortInfo.records.technical_records.share_duration ? ctrShortInfo.records.technical_records.share_duration : 24);this.props.getUsersSelected(this.state.renameCard.uuid)})}}>Share</span>
                        :null
                    }
                  </p>
                </div>
              </div>
            </Link>
            </Col>
            <Col md="4" style={getLocalStorageInfo().user.permission['records'].data_room && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('INS_R') != -1 && !['fan','hpc','hpt','lpt'].includes(this.props.params.type)  ?(ctrShortInfo.records && ctrShortInfo.records.inspection_records ? {} : {opacity: '0.6'}):{opacity: '0.6'}}>
              <Link to={getLocalStorageInfo().user.permission['records'].data_room && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('INS_R') != -1 ? (Object.keys(ctrShortInfo).length && ctrShortInfo.records.inspection_records ? `/${this.props.params.type}/${this.props.params.aircraft_slug}/records/data-room/inspections/${ctrShortInfo.records.inspection_records.uuid}` : ''):''}>
              <div className="folder-card">
                <img src={imgStoragePath+"folder_icn2.png"} alt="" />
                <div className="folder-info">
                  <h4>Inspections { ctrShortInfo.records && ctrShortInfo.records.inspection_records && ctrShortInfo.records.inspection_records.share_enabled ? <img style={{float: 'none', width: '14px', height: '14px'}} src={imgStoragePath + 'share_icon.png'} /> : null }</h4>
                    <p>Files:&nbsp;
                      { ctrShortInfo.records && ctrShortInfo.records.inspection_records ?
                          getLocalStorageInfo().user.permission['records'].action_dock && (getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1  || getLocalStorageInfo().user.permission['records'].action_dock && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('NPWC') != -1) ?
                          ctrShortInfo.records.inspection_records.file_count ? ctrShortInfo.records.inspection_records.file_count:0
                          :
                          ctrShortInfo.records.inspection_records.published_file_count ? ctrShortInfo.records.inspection_records.published_file_count:0
                        :
                        0
                      }
                      {
                        getLocalStorageInfo().user.permission['records'].data_room && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('INS_R') != -1 && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('SH') != -1 && (ctrShortInfo.records && ctrShortInfo.records.inspection_records) ?
                          <span style={{position: 'absolute', right: '10px', bottom: '5px', color: '#1479f1'}} onClick={(evt) => {evt.preventDefault();evt.stopPropagation();this.setState({ModalFlag: true, renameCard: {type: 'folder', title: 'Inspections', uuid: ctrShortInfo.records.inspection_records.uuid}}, () => {this.shareRecordsAc(this.state.renameCard, ctrShortInfo.records.inspection_records.share_enabled, ctrShortInfo.records.inspection_records.share_duration ? ctrShortInfo.records.inspection_records.share_duration : 24);this.props.getUsersSelected(this.state.renameCard.uuid)})}}>Share</span>
                        :null
                      }
                    </p>
                </div>
              </div>
            </Link>
            </Col>
            <Col md="4" style={getLocalStorageInfo().user.permission['records'].data_room && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('CT_R') != -1 ?(ctrShortInfo.records && ctrShortInfo.records.contract_records ? {} : {opacity: '0.6'}):{opacity: '0.6'}}>
              <Link to={ getLocalStorageInfo().user.permission['records'].data_room && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('CT_R') != -1 ?(Object.keys(ctrShortInfo).length && ctrShortInfo.records.contract_records ? `/${this.props.params.type}/${this.props.params.aircraft_slug}/records/data-room/contracts/${ctrShortInfo.records.contract_records.uuid}` : ''):''}>
              <div className="folder-card">
                <img src={imgStoragePath+"folder_icn2.png"} alt="" />
                <div className="folder-info">
                  <h4>Contracts { ctrShortInfo.records && ctrShortInfo.records.contract_records && ctrShortInfo.records.contract_records.share_enabled ? <img style={{float: 'none', width: '14px', height: '14px'}} src={imgStoragePath + 'share_icon.png'} /> : null }</h4>
                    <p>Files:&nbsp;
                      { ctrShortInfo.records && ctrShortInfo.records.contract_records ?
                          getLocalStorageInfo().user.permission['records'].action_dock && (getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1  || getLocalStorageInfo().user.permission['records'].action_dock && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('NPWC') != -1) ?
                          ctrShortInfo.records.contract_records.file_count ? ctrShortInfo.records.contract_records.file_count:0
                          :
                          ctrShortInfo.records.contract_records.published_file_count ? ctrShortInfo.records.contract_records.published_file_count:0
                        :
                        0
                      }
                      {
                        getLocalStorageInfo().user.permission['records'].data_room && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('CT_R') != -1 && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('SH') != -1 && (ctrShortInfo.records && ctrShortInfo.records.contract_records) ?
                          <span style={{position: 'absolute', right: '10px', bottom: '5px', color: '#1479f1'}} onClick={(evt) => {evt.preventDefault();evt.stopPropagation();this.setState({ModalFlag: true, renameCard: {type: 'folder', title: 'Contracts', uuid: ctrShortInfo.records.contract_records.uuid}}, () => {this.shareRecordsAc(this.state.renameCard, ctrShortInfo.records.contract_records.share_enabled, ctrShortInfo.records.contract_records.share_duration ? ctrShortInfo.records.contract_records.share_duration : 24);this.props.getUsersSelected(this.state.renameCard.uuid)})}}>Share</span>
                          :null
                      }
                    </p>
                </div>
              </div>
            </Link>
            </Col>
            <Col md="4" style={{opacity:'0.6'}}>
              <div className="folder-card">
                <img src={imgStoragePath+"folder_icn2.png"} alt="" />
                <div className="folder-info">
                  <h4>Maintenance </h4>
                  <p>Files: 0
                    <span style={{display: 'none' , position: 'absolute', right: '10px', bottom: '5px', color: '#1479f1'}}>Share</span>
                  </p>
                </div>
              </div>
            </Col>
            <Col md="4" style={ getLocalStorageInfo().user.permission['records'].data_room && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('DC_R') != -1 ?(ctrShortInfo.records && ctrShortInfo.records.discrepancies ? {} : {opacity: '0.6'}):{opacity: '0.6'}}>
              <Link to={ getLocalStorageInfo().user.permission['records'].data_room && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('DC_R') != -1 ? (Object.keys(ctrShortInfo).length && ctrShortInfo.records.discrepancies ? `/${this.props.params.type}/${this.props.params.aircraft_slug}/records/data-room/discrepancies/${ctrShortInfo.records.discrepancies.uuid}` : ''):''}>
              <div className="folder-card">
                <img src={imgStoragePath+"folder_icn2.png"} alt="" />
                  <div className="folder-info">
                    <h4>Discrepancy { ctrShortInfo.records && ctrShortInfo.records.discrepancies && ctrShortInfo.records.discrepancies.share_enabled ? <img style={{float: 'none', width: '14px', height: '14px'}} src={imgStoragePath + 'share_icon.png'} /> : null }</h4>
                      <p>Files:&nbsp;
                        { ctrShortInfo.records && ctrShortInfo.records.discrepancies ?
                            getLocalStorageInfo().user.permission['records'].action_dock && (getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1  || getLocalStorageInfo().user.permission['records'].action_dock && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('NPWC') != -1) ?
                            ctrShortInfo.records.discrepancies.file_count ? ctrShortInfo.records.discrepancies.file_count:0
                            :
                            ctrShortInfo.records.discrepancies.published_file_count ? ctrShortInfo.records.discrepancies.published_file_count:0
                          :
                          0
                        }
                        {
                          getLocalStorageInfo().user.permission['records'].data_room && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('DC_R') != -1 && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('SH') != -1 && (ctrShortInfo.records && ctrShortInfo.records.discrepancies) ?
                            <span style={{position: 'absolute', right: '10px', bottom: '5px', color: '#1479f1'}} onClick={(evt) => {evt.preventDefault();evt.stopPropagation();this.setState({ModalFlag: true, renameCard: {type: 'folder', title: 'Discrepancy', uuid: ctrShortInfo.records.discrepancies.uuid}}, () => {this.shareRecordsAc(this.state.renameCard, ctrShortInfo.records.discrepancies.share_enabled, ctrShortInfo.records.discrepancies.share_duration ? ctrShortInfo.records.discrepancies.share_duration : 24);this.props.getUsersSelected(this.state.renameCard.uuid)})}}>Share</span>
                            :null
                        }
                      </p>
                  </div>
              </div>
            </Link>
            </Col>
          </Row>
        </div>
        <Modal isOpen={this.state.ModalFlag} size="lg" toggle={this.toggle} className="share-record-modal">
          <ModalHeader toggle={this.toggle}>
            Share <strong>"{this.state.renameCard.title}"</strong>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12">
                <input onChange={(evt) => this.changeShareStatus(evt) } ref="switch" checked={ isChecked } type="checkbox" />
                <span className="share-link-checkbox">Share Link</span>
              </Col>
            </Row>
            { isChecked == true ?
              <div>
                <Row>
                  <Col md="12">
                    <span>
                      Anyone with the url below will be able to view and download shared records.
                      Updating the sharing duration will generate a new URL below.
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Input id="file_url" readOnly type="text" disabled={ isChecked == true ? false : true} className="share-text" value={ isChecked == true ? this.state.shareKey ? window.location.protocol+'//'+window.location.hostname+'/records/view-shared/' + this.state.shareKey +'/':'':''} placeholder="https://"/>
                    <span className="share-button" onClick={() => this.copyFileUrl()}>Copy Link</span>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    {directoryLoader}
                    <h2> {this.state.shareValidity != null ? 'Share Link Validity' : null}   {this.state.shareValidity != null ? <span>(Valid Till: {this.state.shareValidity != null && this.state.shareValidity != undefined && this.state.shareValidity != '' ? moment.utc(this.state.shareValidity).format(displayDateTimeFormatShort) + ' UTC' : '--'})</span> : null} {this.state.changeShareDuration ? <Link onClick={() => this.setState({changeShareDuration: false, shareUrl: false})} className="change-share-duration">Update Share Duration</Link> : null}</h2>
                    {
                      this.state.changeShareDuration == false ?
                      <ul className="list-inline">
                        <li className={this.state.shareDuration == 24 && this.state.customDuration == false ? "list-inline-item active" : "list-inline-item"}>
                          <span onClick={() => this.setShareDuration("hour",24, false)}><input type="radio" checked={this.state.shareDuration == 24 ? 'checked' : ''} name="share_duration" /> 1 Day</span>
                        </li>
                        <li className={this.state.shareDuration == 72 && this.state.customDuration == false ? "list-inline-item active" : "list-inline-item"}>
                          <span onClick={() => this.setShareDuration("hour",72, false)}><input type="radio" checked={this.state.shareDuration == 72 ? 'checked' : ''} name="share_duration" /> 3 Days</span>
                        </li>
                        <li className={this.state.shareDuration == 168 && this.state.customDuration == false ? "list-inline-item active" : "list-inline-item"}>
                          <span><input type="radio" onClick={() => this.setShareDuration("hour",168, false)} checked={this.state.shareDuration == 168 ? 'checked' : ''} name="share_duration"/> 7 Days</span>
                        </li>
                        <li className={this.state.shareDuration == 240 && this.state.customDuration == false ? "list-inline-item active" : "list-inline-item"} >
                          <span onClick={() => this.setShareDuration("hour",240, false)}><input type="radio" checked={this.state.shareDuration == 240 ? 'checked' : ''} name="share_duration" /> 10 Days</span>
                        </li>
                        <li className={this.state.customDuration ? "list-inline-item active" : "list-inline-item"}>
                          <span onClick={() => this.setCustomDuration("hour",6, true)}><input type="radio" checked={this.state.customDuration ? 'checked' : ''} name="share_duration"/> Custom</span>
                          <input type="number" min='1' step='1' value={this.state.customDuration ? this.state.shareDuration : 6} onChange={(evt) => this.setShareDuration(this.state.shareType, evt.target.value, true)} onBlur={(evt) => this.setCustomDuration(this.state.shareType, evt.target.value, true)}/>
                          <select onChange={(evt) => this.setCustomDuration(evt.target.value, this.state.customDuration ? this.state.shareDuration : 6, true)}>
                            <option value="hour" selected={this.state.shareType === 'hours'}>Hours</option>
                            <option value="days" selected={this.state.shareType === 'days'}>Days</option>
                          </select>
                        </li>
                      </ul>
                      :null
                    }
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    {this.state.shareUrl ?
                      <span className="copy-link-success">Link Copied Successfully</span>:null
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      {this.state.shareDurationError  ?
                        <span style={{marginLeft: '175px'}} className="copy-link-success">Custom share duration can not be 0 or empty.</span>:null
                      }
                    </Col>
                  </Row>
                </div>
                :null
              }
              <Row>
                <Col md="12">
                  { isChecked == false?
                    <span style={{marginLeft: '200px'}} className="copy-link-success">Sharing link is disabled for this record</span>:null
                  }
                </Col>
              </Row>
              {
             this.state.renameCard.type != 'file' && getLocalStorageInfo().user.permission.records['data_room'] && getLocalStorageInfo().user.permission.records['data_room'].indexOf('SHU') != -1 ?
              <Row style={{borderTop:'1px solid #eeeeee',marginTop:'20px'}}>
              <Col md="11" style={{paddingRight:'5px'}}>
                   <span style={{fontSize:'15px',marginTop:'10px'}}>Share with Others</span>
                   <p style={{fontSize:'12px',marginTop:'2px'}}>Add Email to invite the user to collaborate the folder</p>
                   <div className="email-chips" style={{marginTop:'7px'}}>
                   <Chips
                    value={userSelectedEmails}
                    onChange={this.onChange}
                    fetchSuggestions={(value) => this.fetchSuggestions(value)}
                   />
                   </div>
                   <span className="done-button" style={{color:'#ffffff'}  } onClick={() => this.shareUsers(userSelectedEmails)}>Save</span>
              </Col>
              <Col md="1" style={{paddingLeft:'5px'}}>
              <div className="user-share-modal" style={userSelectedEmails.length ?{height:'40px'}:{height:'33px'}}>
                <Dropdown isOpen={this.state.toggleDropDown} toggle={this.toggleDrop}>
                  <DropdownToggle caret>
                  <div className="share-blk">
                      <div>
                        <h4>{userSelectedPermission == 1 ?<img width='15px' src={imgStoragePath+"phase-2/share_edit.png"}/>:<img width='15px' src={imgStoragePath+"phase-2/share_view.png"}/>}</h4>
                      </div>
                    </div>
                  </DropdownToggle>
                  <DropdownMenu>
                  <DropdownItem onClick={()=>this.updateEdit(1)}>Can edit, add &amp; delete</DropdownItem>
                  <DropdownItem onClick={()=>this.updateEdit(2)}>Can view only</DropdownItem>
                  <DropdownItem onClick={()=>this.updateEdit(0)}>Can view and download</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              </Col>
              </Row>:null}
            </ModalBody>
          </Modal>
          <Modal isOpen={this.state.reasonModal} toggle={this.closeReasonModal} className={this.props.className}>
          <ModalHeader toggle={this.closeReasonModal}>Reason For Creation ({this.state.chips[this.state.chips.length - 1]})</ModalHeader>
        <ModalBody style={{height: '200px'}}>
              <textarea rows="20" cols="70" style={{padding: '10px', border: '1px solid rgb(204, 204, 204)', height: '175px'}} value={this.state.reasonForCreation} onChange={(evt) => this.setState({reasonForCreation: evt.target.value})} ></textarea>
          </ModalBody>
          <ModalFooter>
            <ul className="list-inline">
              <li className="list-inline-item">
                <button disabled={this.state.reasonForCreation.trim().length == 0} className="btn btn-primary add-new btn-sm" onClick={() => this.shareReason()}>Create</button>
              </li>
              <li className="list-inline-item">
                <button className="btn btn-secondary add-new btn-sm" onClick={() => this.closeReasonModal()}>Cancel</button>
              </li>
            </ul>
          </ModalFooter>
        </Modal>
      </div>

    );
  }
}

const mapStateToProps = state => ({
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  directoryLoader: state.DataRoomReducer.directoryLoader,
  notificationOpen:state.sharedReducers.notificationOpen,
  userSelectedEmails:state.DataRoomReducer.userSelectedEmails,
  userSelectedPermission:state.DataRoomReducer.userSelectedPermission,
  userSelectedEmailLIst:state.DataRoomReducer.userSelectedEmailLIst,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchAssetInfo: (application) => dispatch(loadAssetViewAc(ownProps.params, application)),
    updateSelectedEmails: (chips) => dispatch({
      type: actions.UPDATE_SELECTED_EMAILS,
      payload: chips
    }),
    getUsersSelected: (uuid) => dispatch(getUsersSelectedAc(uuid)),
    updateSelectedPermission: (flag) => dispatch({
      type: actions.UPDATE_SELECTED_PERMISSION,
      payload: flag
    }),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(DataRoomDash, ['records','data_room','R'])))

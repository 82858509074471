import React, { Component } from 'react';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from 'moment';
import { ContractStats, InvoiceCard, InvoiceWidgetList, CashflowPieChart, CashSummaryGraph, ContractExpiryCard, UtilsHourCycls, UtilsBlock, DbGraphCard, AssetWidget, EngineAndLgUtil, DbBackCom, ContractDateFilter } from '../components';
import { contractCardWidgetAc, invoiceSummaryWidgetAc, contractExpiryWidgetAc, contractBasedMsnAc, cashflowPaymentAc, utilizationSummaryWidgetAc, cashflowSummaryWidgetAc, assetsInfoWidgetAc, detailInvExportAc, setDefaultUrlAc } from '../actionCreators';
import NoRecordFound  from '../../../shared/components/NoRecordFound';
import RoleBasedAccess from '../../../utils/RoleBasedAccess'
import { getLocalStorageInfo } from '../../../utils'
import { trackActivity } from '../../../utils/mixpanel';
class ContractsFleetDb extends Component {
  constructor(props){
    super(props);
    this.toggleUtilizationTrend = this.toggleUtilizationTrend.bind(this);
    this.state = {
      utilizationTrendTab: '1',
      startDate: moment(),
      endDate: moment().add(5, 'day'),
      msnUtilSlug: '',
      msnCashflowSlug: '',
    };
  }
  componentDidMount(){
    this.props.fetchContractWidget();
    this.props.fetchContractExpiry();
    this.props.fetchInvoiceWidget();
    this.props.fetchMsnList();
    this.props.fetchCashflowPayment();
    this.props.fetchAssetInfo({application: 3});
    trackActivity('Page Visited',{
      application_module: 'Dashboards',
      asset: 'All',
      page_title: 'Contract Dashboard'
    })
  }
  toggleUtilizationTrend(tab) {
    if (this.state.utilizationTrendTab !== tab) {
      this.setState({
        utilizationTrendTab: tab
      });
    }
  }
  tabIndexLayout = (label, index) => {
    return (
      <NavItem>
        <NavLink
          className={classnames({ active: this.state.utilizationTrendTab === index })}
          onClick={() => { this.toggleUtilizationTrend(index); }}
        >
          {label}
        </NavLink>
      </NavItem>
    )
  }
  render(){
    const { contractMsn, contractCardWidget, assetsInfoWidget, cashflowPayment, cashflowSummaryWidget, invoiceSummaryWidget, contractExpiryWidget, utilsSummaryWidget, msnCashflowSlug, msnUtilSlug, dbContractLoader, dbfleetGraphLoader, dbInvoiceLoader, utilSummaryLoader, dbAssetLoader, utilSumLoader } = this.props;
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: contractExpiryWidget.length <= 3 ? contractExpiryWidget.length : 4 ,
      slidesToScroll: 1,
      variableWidth: true,
    };
    let assetWidgetCard
    if(getLocalStorageInfo().defaultLessor.id === 348){
      assetWidgetCard = [
        { title: 'TOTAL ASSETS', managed: assetsInfoWidget.filter(asset => asset.status != 4 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status != 4 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.length-assetsInfoWidget.filter(asset => asset.status == 4).length, url: '/assets-listing' , ownedUrl: '/assets-listing?ownership_type=1', managedUrl: '/assets-listing?ownership_type=2', classes: 'widget-cards marginRight flex-centered total-count'},
        { title: 'OPERATIONAL',  managed: assetsInfoWidget.filter(asset => asset.status == 1 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 1 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 1).length, url: '/assets-listing?status=1', ownedUrl: '/assets-listing?status=1&ownership_type=1',managedUrl: '/assets-listing?status=1&ownership_type=2', classes: 'widget-cards marginRight marginLeft flex-centered on-lease'},
        { title: 'AOG', ownedUrl: '/assets-listing?status=3&ownership_type=1',managedUrl: '/assets-listing?status=3&ownership_type=2',  managed: assetsInfoWidget.filter(asset => asset.status == 3 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 3 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 3).length, url: '/assets-listing?status=3', classes: 'widget-cards marginRight marginLeft flex-centered on-ground'},
      ]
    }else {
      assetWidgetCard = [
        { title: 'TOTAL ASSETS', managed: assetsInfoWidget.filter(asset => (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.length, url: '/assets-listing' , ownedUrl: '/assets-listing?ownership_type=1', managedUrl: '/assets-listing?ownership_type=2', classes: 'widget-cards marginRight flex-centered total-count'},
        { title: 'ON LEASE',  managed: assetsInfoWidget.filter(asset => asset.status == 1 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 1 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 1).length, url: '/assets-listing?status=1', ownedUrl: '/assets-listing?status=1&ownership_type=1',managedUrl: '/assets-listing?status=1&ownership_type=2', classes: 'widget-cards marginRight marginLeft flex-centered on-lease'},
        { title: 'OFF LEASE', ownedUrl: '/assets-listing?status=2&ownership_type=1',managedUrl: '/assets-listing?status=2&ownership_type=2', managed: assetsInfoWidget.filter(asset => asset.status == 2 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 2 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 2).length, url: '/assets-listing?status=2', classes: 'widget-cards marginRight marginLeft flex-centered off-lease'},
        { title: 'ON GROUND', ownedUrl: '/assets-listing?status=3&ownership_type=1',managedUrl: '/assets-listing?status=3&ownership_type=2',  managed: assetsInfoWidget.filter(asset => asset.status == 3 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 3 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 3).length, url: '/assets-listing?status=3', classes: 'widget-cards marginRight marginLeft flex-centered on-ground'},
        { title: 'WRITTEN OFF / SOLD', ownedUrl: '/assets-listing?status=4&ownership_type=1',managedUrl: '/assets-listing?status=4&ownership_type=2',  managed: assetsInfoWidget.filter(asset => asset.status == 4 && (asset.ownership_type && asset.ownership_type == 2)).length, owned: assetsInfoWidget.filter(asset => asset.status == 4 && (asset.ownership_type && asset.ownership_type == 1)).length, value: assetsInfoWidget.filter(asset => asset.status == 4).length, url: '/assets-listing?status=4', classes: 'widget-cards marginLeft flex-centered written-off'}
      ]
    }
    const contractWidgetCard = [
      { title: 'Contracts Total', value: contractCardWidget.totalContract, classes: ' paddingRight', style: {background: '#1c79eb'}},
      { title: 'Active / Published', value: contractCardWidget.published, classes: ' paddingLeft paddingRight', style: {background: '#3785fb'}},
      { title: 'In Draft Status', value: contractCardWidget.draft, classes: ' paddingLeft paddingRight', style: {background: '#6613b2'}},
      { title: 'Expiry in the next 6 months', value: contractCardWidget.aboutToExpire, classes: ' paddingLeft', style: {background: '#f4211f'}},
    ]

    var showSplitTabs = false
    assetWidgetCard.map(item => {
      showSplitTabs = (item.managed > 0) ? true : showSplitTabs
      return item
    })
    return(
      <div className="fleet-contracts-db">
        <Row className="assets-row " style={{marginBottom: '10px'}}>
          { assetWidgetCard.map((asset, index) =>
            <AssetWidget
              className={asset.classes}
              statusGrpTitle={asset.title}
              value={asset.value}
              url={asset.url}
              managedUrl={asset.managedUrl}
              dbAssetLoader={dbAssetLoader}
              dbAssetLoader={dbAssetLoader}
              key={index}
              index={index}
              ownedUrl={asset.ownedUrl}
              owned={asset.owned}
              managed={asset.managed}
              showManaged={showSplitTabs}
            />
          )}
        </Row>
        <div className="contract-card-widget">
          <Row>
            { contractWidgetCard.map((contract, index) =>
              <ContractStats
                dbContractLoader={dbContractLoader}
                classAtr={contract.classes}
                label={contract.title}
                value={contract.value}
                style={contract.style}
              />
            )}
          </Row>
        </div>
        <div className="cashflow-summary-widget">
          <h4 className="contract-title-bar">Cashflow Summary</h4>
          <Row>
            <Col md="3" className="pie-chart-card flex-centered">
              { Object.keys(cashflowPayment).length ?
                <CashflowPieChart cashflowPayment={cashflowPayment} /> :
                <span style={{color: '#ffffff', justifyContent: 'center', dispaly:'block', textAlign:'center', width: '100%'}}>Loading...</span>
              }
            </Col>
            <Col md="9" className="padding-left-0">
              <ContractDateFilter
                 data= {cashflowSummaryWidget}
                 contractMsn = {contractMsn}
                 startDateParam = 'fleetstartDateErr'
                 endDateParam = 'fleetEndDateErr'
                 filterCallParam = 'fleetSummaryWidget'
                 fetchCashflow = {this.props.fetchCashflow}
                 disabledAttr = { !cashflowSummaryWidget.plots ?  true : false }
                 assetSlug = {msnCashflowSlug}
                 slugType = 'msnCashflowSlug'
                 text = "Cashflow"
               />
               { !dbfleetGraphLoader  ?
                 <DbGraphCard
                   graphType='area'
                   graphTitle=''
                   graphxLabel=''
                   graphyLabel=''
                   cashflowSum = {true}
                   lessorDetail={!utilSumLoader ? contractMsn.filter(item => item.slug == msnCashflowSlug)[0] : {}}
                   contractMsn = {!utilSumLoader && contractMsn.filter(item => item.slug == msnCashflowSlug).length ? contractMsn.filter(item => item.slug == msnCashflowSlug)[0].msn :''}
                   headingText='Cashflow Summary'
                   series = {
                     [{
                       showInLegend: false,
                       name: 'Cashflow',
                       color: '#5A91FA',
                       lineColor: '#1C72E3',
                       data: cashflowSummaryWidget.plots && Object.keys(cashflowSummaryWidget.plots).map(year => { return {x: new Date(year.slice(4,6) + '/01/' + year.slice(0,4)).getTime(), y: cashflowSummaryWidget.plots[year]} })
                     }]
                   }
                 />:null
               }
               { dbfleetGraphLoader  ?
                 <span style={{color: '#000', justifyContent: 'center', dispaly:'block', textAlign:'center', width: '100%', display: 'block', padding: '138px 0px'}}>Loading...</span>
                 :!(cashflowSummaryWidget.plots && Object.keys(cashflowSummaryWidget.plots).length) ? null  : null
               }
            </Col>
          </Row>
        </div>
        <div className="invoice-summary-widget">
          <h4 className="contract-title-bar">Invoice Summary</h4>
          <Row>
            <InvoiceCard dbInvoiceLoader={dbInvoiceLoader} invoiceSummaryWidget={invoiceSummaryWidget}  />
            <Col md="9" className="padding-left-0">
              { invoiceSummaryWidget.list && invoiceSummaryWidget.list.length && !dbInvoiceLoader ?
                <div className="table-responsive table-height" style={{ height: '312px'}}>
                  <table className="table-sticky">
                    <thead>
                      <tr>
                        {['MSN', 'Lessee/Operator', 'Invoice No.', 'Type', 'Amount ( USD )', 'Due Date', 'Invoice Date', 'Invoice Status','Export'].map((label, index) => <th key={index}>{label}</th>)}
                      </tr>
                    </thead>
                    { invoiceSummaryWidget.list && invoiceSummaryWidget.list.length && !dbInvoiceLoader ?
                      <tbody>
                        { invoiceSummaryWidget.list.map((invoice, index) =>
                          <InvoiceWidgetList key={index} invoice={invoice} exportInvoice={() => this.props.detailInvExport(invoice)}/>
                        )}
                      </tbody>
                      :null
                    }
                  </table>
                </div> : null
              }
              {(!dbInvoiceLoader ) && (!invoiceSummaryWidget.list || invoiceSummaryWidget.list && !invoiceSummaryWidget.list.length)  ?
                <NoRecordFound/>: !invoiceSummaryWidget.list  ?
                <span style={{color: '#000', justifyContent: 'center', dispaly:'block', textAlign:'center', width: '100%', display: 'block', padding:' 180px 0'}}>Loading...</span>
                : null
              }
            </Col>
          </Row>
        </div>
        { contractExpiryWidget.length ?
          <div className="contract-expiry-widget">
            <p className="expiry-title">Contracts expiring in the <span>next 6 months</span></p>
            <Slider {...settings} className="airframe-cards flex-not-centered">
              { contractExpiryWidget.map((contract, index) =>
                <ContractExpiryCard  contract={contract} key={index} />
              )}
            </Slider>
          </div> : null
        }
        <div className="utilization-widget-cn">
          <h4 className="contract-title-bar">Utilization Summary</h4>
          <Row style={{clear: 'both'}}>
            <Col md="12" className="utils-trend-cn">
              <ContractDateFilter
                data= {utilsSummaryWidget}
                assetSlug = {msnUtilSlug}
                contractMsn = {contractMsn}
                startDateParam = 'utilStartDateErr'
                endDateParam = 'utilEndDateErr'
                filterCallParam = 'utilsSummaryWidget'
                fetchUtilisationSummary = {this.props.fetchUtilisationSummary}
                disabledAttr =   { utilsSummaryWidget.airframe ||  utilsSummaryWidget.engine ||  utilsSummaryWidget.landing_gears ||  utilsSummaryWidget.apu ? false : true }
                slugType = 'msnUtilSlug'
                text = "Utilization"
              />
              <div className="graphical-tabs">
                <Nav tabs>
                  {this.tabIndexLayout('Airframes', '1')}
                  {this.tabIndexLayout('Engines', '2')}
                  {this.tabIndexLayout('APU', '3')}
                  {this.tabIndexLayout('Landing Gears', '4')}
                </Nav>
                <TabContent activeTab={this.state.utilizationTrendTab}>
                  <TabPane tabId="1">
                    { !utilSummaryLoader && utilsSummaryWidget.airframe && Object.keys(utilsSummaryWidget.airframe).length?
                      <div>
                        <UtilsHourCycls>
                          { utilsSummaryWidget.airframe.lastMonthHours ?
                            <UtilsBlock label="FH"  dateOfManu = {utilsSummaryWidget.airframe.utilizationDate }  value={utilsSummaryWidget.airframe.lastMonthHours + ' HRS'} />
                            :null
                          }
                          { utilsSummaryWidget.airframe.lastMonthCycles ?
                            <UtilsBlock label="FC"  dateOfManu = {utilsSummaryWidget.airframe.utilizationDate }  value={utilsSummaryWidget.airframe.lastMonthCycles + ' Cycles'} />
                            :null
                          }
                          <UtilsBlock label="Average FH" value={utilsSummaryWidget.airframe.avgFlightHours + ' HRS'} />
                          <UtilsBlock label="Average FC" value={utilsSummaryWidget.airframe.avgFlightCycles + ' Cycles'} />
                        </UtilsHourCycls>
                        <div className="utiles-graph">
                          {
                            !utilSumLoader ?
                            <DbGraphCard
                              graphType='area'
                              graphTitle=''
                              graphxLabel=''
                              graphyLabel=''
                              utilSum = {true}
                              lessorDetail={!utilSumLoader && contractMsn.filter(item => item.slug == msnCashflowSlug).length ? contractMsn.filter(item => item.slug == msnCashflowSlug)[0] : {}}
                              contractMsn = {!utilSumLoader && contractMsn.filter(item => item.slug == msnCashflowSlug).length ? contractMsn.filter(item => item.slug == msnCashflowSlug)[0].msn :''}
                              headingText='Airframe Utilization Summary'
                              series = {
                                [{
                                  showInLegend: true,
                                  name: 'Hours',
                                  color: '#0964b5',
                                  lineColor: '#243796',
                                  data: Object.keys(utilsSummaryWidget.airframe.hours).map(year => { return {x: new Date(year.slice(4,6) + '/01/' + year.slice(0,4)).getTime(), y: utilsSummaryWidget.airframe.hours[year]} })
                                },
                                {
                                  showInLegend: true,
                                  name: 'Cycles',
                                  color: '#8bc8f9',
                                  lineColor: '#0c75cc',
                                  data: Object.keys(utilsSummaryWidget.airframe.cycles).map(year => { return {x: new Date(year.slice(4,6) + '/01/' + year.slice(0,4)).getTime(), y: utilsSummaryWidget.airframe.cycles[year]} })
                                  }
                                ]
                              }
                            />
                            :null
                          }
                        </div>
                      </div>
                      : null
                    }
                    { utilSummaryLoader  ?
                      <span style={{color: '#000', justifyContent: 'center', dispaly:'block', textAlign:'center', width: '100%', display: 'block', padding: '138px 0px'}}>Loading...</span>
                      :!(utilsSummaryWidget.airframe && Object.keys(utilsSummaryWidget.airframe).length) ? <NoRecordFound/>  : null
                    }
                  </TabPane>
                  <TabPane tabId="2">
                    { utilsSummaryWidget.engines && !utilSummaryLoader?
                      <EngineAndLgUtil
                        lessorDetail={contractMsn.filter(item => item.slug == msnCashflowSlug).length ? contractMsn.filter(item => item.slug == msnCashflowSlug)[0] : {}}
                        label="engine"
                        utilSumLoader={utilSumLoader}
                        data={utilsSummaryWidget.engines}
                        contractMsn = {contractMsn.filter(item => item.slug == msnCashflowSlug).length ? contractMsn.filter(item => item.slug == msnCashflowSlug)[0].msn :''}
                      />
                      :null
                    }
                    { utilSummaryLoader ?
                      <span style={{color: '#000', justifyContent: 'center', dispaly:'block', textAlign:'center', width: '100%', display: 'block', padding: '138px 0px'}}>Loading...</span>
                      :!(utilsSummaryWidget.engines && Object.keys(utilsSummaryWidget.engines).length) ? <NoRecordFound/>  : null
                    }
                  </TabPane>
                  <TabPane tabId="3">
                    { utilsSummaryWidget.apu && !utilSummaryLoader?
                      <div>
                        <UtilsHourCycls>
                          { utilsSummaryWidget.apu.lastMonthHours ?
                              <UtilsBlock  dateOfManu = {utilsSummaryWidget.apu.utilizationDate }  label="FH" value={utilsSummaryWidget.apu.lastMonthHours + ' HRS'} />
                            :null
                          }
                          { utilsSummaryWidget.apu.utilizationDate ?
                            <UtilsBlock dateOfManu = {utilsSummaryWidget.apu.utilizationDate }  label="FC" dateOfManu = {utilsSummaryWidget.apu.utilizationDate } value={utilsSummaryWidget.apu.lastMonthCycles + ' Cycles'} />
                            : null
                          }
                          <UtilsBlock label="Average FH" value={utilsSummaryWidget.apu.avgFlightHours + ' HRS'} />
                          <UtilsBlock label="Average FC" value={utilsSummaryWidget.apu.avgFlightCycles + ' Cycles'} />
                        </UtilsHourCycls>
                        <div className="utiles-graph">
                          {
                            !utilSumLoader ?
                            <DbGraphCard
                              graphType='area'
                              graphTitle=''
                              graphxLabel=''
                              graphyLabel=''
                              utilSum = {true}
                              lessorDetail={!utilSumLoader && contractMsn.filter(item => item.slug == msnCashflowSlug).length ? contractMsn.filter(item => item.slug == msnCashflowSlug)[0] : {}}
                              contractMsn = {!utilSumLoader && contractMsn.filter(item => item.slug == msnCashflowSlug).length ? contractMsn.filter(item => item.slug == msnCashflowSlug)[0].msn :''}
                              headingText='APU Utilization Summary'
                              series = {
                                [{
                                  showInLegend: true,
                                  name: 'hours',
                                  color: '#378FE0',
                                  lineColor: '#4F61C5',
                                  data: Object.keys(utilsSummaryWidget.apu.hours).map(year => { return {x: new Date(year.slice(4,6) + '/01/' + year.slice(0,4)).getTime(), y: utilsSummaryWidget.apu.hours[year]} })
                                },
                                {
                                  showInLegend: true,
                                  name: 'cycles',
                                  color: '#67AEFF',
                                  lineColor: '#1E7DB5',
                                  data: Object.keys(utilsSummaryWidget.apu.cycles).map(year => { return {x: new Date(year.slice(4,6) + '/01/' + year.slice(0,4)).getTime(), y: utilsSummaryWidget.apu.cycles[year]} })
                                  }
                                ]
                              }
                            />
                            :null
                          }
                      </div>
                    </div>
                      : null
                  }
                  { utilSummaryLoader  ?
                    <span style={{color: '#000', justifyContent: 'center', dispaly:'block', textAlign:'center', width: '100%', display: 'block', padding: '138px 0px'}}>Loading...</span>
                    :!(utilsSummaryWidget.apu && Object.keys(utilsSummaryWidget.apu).length) ? <NoRecordFound/>  : null
                  }
                </TabPane>
                <TabPane tabId="4">
                  { utilsSummaryWidget.landing_gears && !utilSummaryLoader?
                    <EngineAndLgUtil
                      lessorDetail={contractMsn.filter(item => item.slug == msnCashflowSlug).length ? contractMsn.filter(item => item.slug == msnCashflowSlug)[0] : {}}
                      label="apu"
                      utilSumLoader={utilSumLoader}
                      data={utilsSummaryWidget.landing_gears}
                      contractMsn = {contractMsn.filter(item => item.slug == msnCashflowSlug).length ? contractMsn.filter(item => item.slug == msnCashflowSlug)[0].msn :''}
                    />
                    :  null
                  }
                  { utilSummaryLoader  ?
                    <span style={{color: '#000', justifyContent: 'center', dispaly:'block', textAlign:'center', width: '100%', display: 'block', padding: '138px 0px'}}>Loading...</span>
                    :!(utilsSummaryWidget.landing_gears && Object.keys(utilsSummaryWidget.landing_gears).length) ? <NoRecordFound/>  : null
                  }
                </TabPane>
              </TabContent>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    )
  }
}
const mapStateToProps = state => ({
  assetsInfoWidget: state.DashboardReducer.assetsInfoWidget,
  utilSumLoader: state.DashboardReducer.utilSumLoader,
  contractMsn: state.DashboardReducer.contractMsn,
  contractCardWidget: state.DashboardReducer.contractCardWidget,
  cashflowSummaryWidget: state.DashboardReducer.cashflowSummaryWidget,
  cashflowPayment: state.DashboardReducer.cashflowPayment,
  invoiceSummaryWidget: state.DashboardReducer.invoiceSummaryWidget,
  contractExpiryWidget: state.DashboardReducer.contractExpiryWidget,
  utilsSummaryWidget: state.DashboardReducer.utilsSummaryWidget,
  utilsTrendWidget: state.DashboardReducer.utilsTrendWidget,
  msnCashflowSlug: state.DashboardReducer.msnCashflowSlug,
  msnUtilSlug: state.DashboardReducer.msnUtilSlug,
  dbContractLoader: state.DashboardReducer.dbContractLoader,
  dbfleetGraphLoader: state.DashboardReducer.dbfleetGraphLoader,
  dbInvoiceLoader: state.DashboardReducer.dbInvoiceLoader,
  utilSummaryLoader: state.DashboardReducer.utilSummaryLoader,
  dbcashFLowTotalLoader: state.DashboardReducer.dbcashFLowTotalLoader,
  dbAssetLoader: state.DashboardReducer.dbAssetLoader,
});
const mapDispatchToProps  = (dispatch, ownProps) => {
  return {
    fetchAssetInfo :(queryParam) => dispatch(assetsInfoWidgetAc(queryParam)),
    fetchMsnList: () => dispatch(contractBasedMsnAc()),
    fetchUtilisationSummary: (slug={}) => dispatch(utilizationSummaryWidgetAc(slug)),
    fetchContractWidget: () => dispatch(contractCardWidgetAc()),
    fetchContractExpiry: () => dispatch(contractExpiryWidgetAc()),
    fetchInvoiceWidget: () => dispatch(invoiceSummaryWidgetAc()),
    fetchCashflowPayment: () => dispatch(cashflowPaymentAc()),
    fetchCashflow: (slug={}) => dispatch(cashflowSummaryWidgetAc(slug)),
    detailInvExport: (invoice) => dispatch(detailInvExportAc(invoice)),

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(ContractsFleetDb,['console','dashboard','R']));

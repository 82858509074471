import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory, withRouter, Link } from 'react-router';
import Onboarding from '../phase2/auth/containers/Onboarding'
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Row, Col } from 'reactstrap'
import { setGlobalCookie, getGlobalCookie, getLocalStorageInfo, authorizeAutoLogin, checkEnvironment, getLessorsList } from './index'
import { imgStoragePath } from '../constants'
import TrialExpired from '../shared/containers/TrialExpired'
import restrictIcon from '../shared/assets/img/restrict_icon.svg';
window.addEventListener('visibilitychange', checkLogin)

function checkLogin () {
    if(document.visibilityState || document.hasFocus()) {
      let userDetail = JSON.parse(localStorage.getItem('userInfo'));
      let location = browserHistory.getCurrentLocation().pathname
      let lessorAccess = ''
      let redirectURILease = browserHistory.getCurrentLocation().pathname
      if(redirectURILease && redirectURILease != '' && redirectURILease !== '/login' && redirectURILease !== '/select-lessor' && redirectURILease !== '/signup'){
        setGlobalCookie('redirectURILease', window.location.href, 3)
      }
      if(getGlobalCookie('lessorAccess')){
        lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'));
      }
      setTimeout(() =>{
        if(!lessorAccess){
          localStorage.clear();
          sessionStorage.removeItem('initial_pop');
          if(!location.includes('login') && !location.includes('signup') && !location.includes('password/create') && !location.includes('create-successfully') && !location.includes('reset-successfully') && !location.includes('password/reset') && !location.includes('termscondition') && !location.includes('data-policy') && !location.includes('user/activate') && !location.includes('otp-verification') && !location.includes('forgot') && !location.includes('view-shared')){
            browserHistory.push('/login');
            window.location.reload()
          }
        }else{
          if(checkEnvironment() === lessorAccess.environment){
            if(lessorAccess.id){
              if(!getLocalStorageInfo() || (getLocalStorageInfo() && !getLocalStorageInfo().defaultLessor)){
                 authorizeAutoLogin(lessorAccess)
               }else if(!location.includes('select-lessor') && !location.includes('otp')){
                 if(userDetail && userDetail.defaultLessor && parseInt(lessorAccess.id) !== userDetail.defaultLessor.id){
                   authorizeAutoLogin(lessorAccess)
                 }
               }
            }else {
              if((userDetail && userDetail.defaultLessor == undefined) || (!userDetail && lessorAccess)){
                // getLessorsList()
              }
            }
          }
        }
      })
    }
}
function Authenticate(ComposedComponent, extraInfo) {
 class authenticateApp extends Component {
   constructor(props) {
     super(props);
     this.state = {
       videoDialog: false
     }
   }

   componentDidMount(){
    //  if((getGlobalCookie('BtMessageIntro') === undefined || getGlobalCookie('BtMessageIntro') === null)){
    //   this.setState({videoDialog: true})
    // }
   }

   componentWillMount(){
     checkLogin()
   }
   render() {

     let userDetail = JSON.parse(localStorage.getItem('userInfo'));
     let location = browserHistory.getCurrentLocation().pathname
     let { notifications } = this.props
     if(localStorage.getItem('userInfo')) {
       {
         if((notifications.overdue == 3) && !location.includes('billing') && !location.includes('user-profile') && !location.includes('notifications') ){
           return(<TrialExpired/>)
         }else {
          return(
            <React.Fragment>
              {
                getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.contracts ?
                  <ComposedComponent {...this.props}/>
                :location.includes('select-lessor') ?
                  <ComposedComponent {...this.props}/>
                :<Row>
                  <Col xs="12" style={{minHeight: '90vh', position: 'relative'}}>
                  <div className="page-not-found">
                      <img src={restrictIcon} alt="Icon"/>
                      <h2>You’re not permitted to see this.</h2>
                      <p>The page you’re trying to access has restricted access,<br/>
                         please contact <a style={{color:'#3F51B5', textDecoration:'auto'}} href="mailto:support@sparta.aero">support@sparta.aero</a> to access this application.
                       </p>
                      <Link className="start-over btn btn-default return-home" onClick={() => window.location.assign("https://sparta.aero")}>Return Home</Link>
                    </div>
                  </Col>
                </Row>
              }
                <Modal
                  isOpen={this.state.videoDialog}
                  toggle={() => this.setState({videoDialog: false}, () => setGlobalCookie('BtMessageIntro', true, 730))}
                  size="lg"
                >
                  <ModalHeader>Live Chat</ModalHeader>
                  <ModalBody style={{padding:'20px'}}>
                    <span>
                      Greetings from the SPARTA Team. <br/>
                    To offer our support to you, we have enabled a LIVE CHAT option in SPARTA. Seek assistance from our operators by clicking on the icon <img src={imgStoragePath + 'chatIcon.svg'} style={{width: '25px'}}/> on corner of your SPARTA window. We would be happy to help. <br/><br/><br/>
                      Thank you.
                    </span>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary" variant="contained"  onClick={() => this.setState({videoDialog: false}, () => setGlobalCookie('BtMessageIntro', true, 730))}>Okay</Button>
                  </ModalFooter>
                </Modal>
            </React.Fragment>
          )
         }
       }
     }else if(!userDetail && getGlobalCookie('lessorAccess') && window.location.href.includes('select-lessor')) {
       return <Onboarding/>
     }else{
       return null
     }
   }
 }

 const mapStateToProps = (state) =>({
   notifications: state.sharedReducers.notifications,
 })
  return withRouter(connect(mapStateToProps, null)(authenticateApp));
}
export default Authenticate;

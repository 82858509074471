import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment'
import NumericLabel from 'react-pretty-numbers';
import { displayDateFormat } from '../../../../constants';
import { showCurrencyFormat } from '../../../../utils';
import {ToolTipHover} from '../../../../shared';
export default class DashboardCashFlowWrap extends Component {
  constructor(props){
    super(props);
  }
  render(){
    const { contrDbCashFlows, params } = this.props;
    return(
      <Col md="4" className="db-left-bar">
        <div className="db-cashflow">
          <div className="cashflow-top-bar">
            <h5 className="para" style={{position:"relative"}}>
              Cash Flow Summary
              <Link to={'/' + params.type + '/' + params.aircraft_slug + '/contract/cash-flow'}>View All</Link>
           </h5>
           <Row>
             <Col>
               <p>MR Total Paid</p>
               {
                 contrDbCashFlows.mr ?
                 <NumericLabel params={showCurrencyFormat()}>{contrDbCashFlows.mr}</NumericLabel>:'$0.00'
               }
               <span className="as-of-date">As of {contrDbCashFlows.mr_detail_as_of_date? moment(contrDbCashFlows.mr_detail_as_of_date).format(displayDateFormat) : 'date --'}</span>
             </Col>
             <Col>
               <p>Rental Total Paid</p>
                { contrDbCashFlows.rental_new ?
                  contrDbCashFlows.rental_new.length?
                  contrDbCashFlows.rental_new.map((rental, index) =>
                  <div>
                  <NumericLabel params={showCurrencyFormat(rental.currency)}>{rental.total_amount}</NumericLabel>
                  </div>
                  ): '$0.00': '$0.00'
                }
               <span className="as-of-date">As of {contrDbCashFlows.rental_detail_as_of_date? moment(contrDbCashFlows.rental_detail_as_of_date).format(displayDateFormat) : 'date --'}</span>
             </Col>
           </Row>
          </div>
          {this.props.children}
        </div>
      </Col>
    )
  }
}

import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link, withRouter } from 'react-router';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { monthsObj, displayDateFormat} from '../../../../constants';
import { showCurrencyFormat } from '../../../../utils';
class CashFlowList extends Component{
  constructor(props){
    super(props)
  }
  invLink = (cashDetail, id) => {
    if(cashDetail.split(/[\[\]]+/).length > 1){
      let str = cashDetail.split(/[\[\]]+/);
      return (
        <span>
          {str[0]}
          <Link to={'/' + this.props.params.type + '/' + this.props.params.aircraft_slug + '/contract/' + this.props.contract_slug + '/invoice/view/' + id}>{str[1]}</Link>
          {str[2]}
        </span>
      )
    }else{
      return cashDetail;
    }
  }
  render(){
    const { index, cashflow, cashflows } = this.props;
    return(
      <div className="asset-list">
        <Row key={index} className="flex-not-centered cashflow-row">
          <Col md="2" xs="2" className={ index != 0 ? cashflows[index].for_month != cashflows[index-1].for_month ? 'para-ui month-block ' :' ' : 'para-ui month-block' }  >
            {
              index != 0 ? cashflows[index].for_month != cashflows[index - 1].for_month ? monthsObj[cashflow.for_month.slice(4,6)] + ' ' + cashflow.for_month.slice(0,4):'':monthsObj[cashflow.for_month.slice(4,6)] + ' ' + cashflow.for_month.slice(0,4)
            }
            {
              index != 0 ? cashflows[index].for_month != cashflows[index - 1].for_month ?

              <p className="cashflow-monthly-total">
                <span className="cashflow-monthly-total-label">Month Total Cash Outflow</span>
                {cashflow.monthly_total.length?
                cashflow.monthly_total.map((cashflow, index) =>
                <NumericLabel params={showCurrencyFormat(cashflow.currency)}>{cashflow.total_amount}</NumericLabel> )
                :
                <NumericLabel params={showCurrencyFormat(cashflow.currency)}>0.00</NumericLabel>
                }
              </p>:'':
              <p className="cashflow-monthly-total">
                <span className="cashflow-monthly-total-label">Month Total Cash Outflow</span>
                {cashflow.monthly_total.length?
                cashflow.monthly_total.map((cashflow, index) =>
                <NumericLabel params={showCurrencyFormat(cashflow.currency)}>{cashflow.total_amount}</NumericLabel> )
                :
                <NumericLabel params={showCurrencyFormat(cashflow.currency)}>0.00</NumericLabel>
                }
              </p>
            }
          </Col>
          <Col md="2" xs="2" className="cash-blk cash-info para-ui border-col">
            {cashflow.name}
          </Col>
          <Col md="2" xs="2" className="cash-blk cash-info para-ui border-col">
            {
              // cashflow.credit_or_debit ?
              // <NumericLabel params={showCurrencyFormat(cashflow.currency)}>
              //   {cashflow.amount}
              // </NumericLabel>
              //  :
              // '--'
            }
          </Col>
          <Col md="2" xs="2" className="cash-blk cash-info para-ui border-col">
            {
              // cashflow.credit_or_debit ? '--':
              // <NumericLabel params={showCurrencyFormat(cashflow.currency)}>
              //   {cashflow.amount}
              // </NumericLabel>
            }
            {
              cashflow.credit_or_debit ?
              <NumericLabel params={showCurrencyFormat(cashflow.currency)}>
                {cashflow.amount}
              </NumericLabel>
               :
              '--'
            }
          </Col>
          <Col md="2" xs="2" className="cash-blk cash-info para-ui border-col">
            {cashflow.balance ?
              <NumericLabel params={showCurrencyFormat(cashflow.currency)}>
                {cashflow.balance}
              </NumericLabel>
               :
              '--'
            }
          </Col>
          <Col md="2" xs="2" className="cash-blk cash-info para-ui border-col">
            { cashflow.transaction_details ? this.invLink(cashflow.transaction_details, cashflow.invoice_id) : '--' }
          </Col>
        </Row>
      </div>
    )
  }
}

export default withRouter(CashFlowList);

import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import { imgStoragePath } from '../../../constants';
export default class Features extends Component{
    constructor(props){
        super(props);
        this.state = {
            lessorFeatures: true,
        }
      }
      render(){
        const{ signupType } = this.props;
        return(
            <div className="features-wrapper" style={signupType == '1' ? {paddingTop: '200px'} : {paddingTop: '70px'}}>
                <div className="container">
                    <div className="heading-block">
                        <h2 id="features">FEATURES</h2>
                    </div>
                    <div className="feature-content-block">
                        <ul className="list-inline hidden" style={{ display: 'none'}}>
                            <li><Link onClick={() => this.setState({lessorFeatures :true})} className={this.state.lessorFeatures ? "tab-active" : "" }>Lessors/Asset Managers</Link></li>
                            <li><Link onClick={() => this.setState({lessorFeatures :false})} className={!this.state.lessorFeatures ? "tab-active" : "" }>Inspectors/Servicers</Link></li>
                        </ul>
                        <div className="tab-content lessor-features">


                            <Row className="flex-centered">
                                <Col md="5" className="order-md-7">
                                    <img src={imgStoragePath+'phase-2/contracts.png'} alt="contracts image" />
                                </Col>
                                <Col md="7" className="col-sm-12 col-md-pull-5">
                                    <h4 id="contractsFeatures">Lease Management</h4>
                                    <ul className="list-inline">
                                        <li>Complete fleet management; aircraft, engines, APUs and landing gears.</li>
                                        <li>End-to-end lease and contract management for all assets</li>
                                        <li>Management of lease terms and conditions including delivery and redelivery requirements</li>
                                        <li>Unified view of the assets</li>
                                    </ul>
                                </Col>
                            </Row>

                            <Row className="flex-centered background-difference">
                                <Col md="5" >
                                    <img src={imgStoragePath+'phase-2/maintenance.png'} alt="maintenance image" />
                                </Col>
                                <Col md="7" className="col-sm-12 col-md-pull-5">
                                    <h4 id="cashflowAnalysis">MR Calculator</h4>
                                    <ul className="list-inline">
                                        <li>Maintenance related cash flow analysis</li>
                                        <li>Capability to undertake fleet wide cash flow analysis for different scenarios including Asset Backed Securitization (ABS)</li>
                                        <li style={{width:'100%'}} >Maintenance event tracking, status of findings, workscope approvals, Life Limited Parts (LLP) status, Airworthiness Directives (AD) and Service Bulletins (SB) status
                                            <div className="download-links">
                                              <span style={{float:'right',marginTop:'20px'}}>
                                              <a href="https://itunes.apple.com/za/app/empower/id1209983674" target="_blank" style={{display:'inline',padding:'0px',border:'none'}} >
                                              <img src={imgStoragePath+'phase-2/app-store-apple.png'}  width="100px" style={{width:'110px'}} alt="maintenance image" /> </a>
                                              <a href="https://play.google.com/store/apps/details?id=com.emis.acumen"  target="_blank" style={{display:'inline',padding:'0px',border:'none'}}>
                                              <img src={imgStoragePath+'phase-2/google-play-badge.png'} width="100px" style={{width:'125px'}} alt="maintenance image" /> </a> </span>
                                              <h4 style={{fontSize:'19px',fontWeight:'700'}}>Download EMPower iOS and Android apps</h4>
                                              <p>Maintenance reserve calculations &amp; analysis of <br/> over 100 aircraft and engine types</p>
                                            </div>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>

                            <Row className="flex-centered">
                                <Col md="5" className="order-md-7">
                                    <img src={imgStoragePath+'phase-2/records.png'} alt="records image" />
                                </Col>
                                <Col md="7">
                                    <h4 id="recordFeatures">Records Dataroom</h4>
                                    <ul className="list-inline">
                                        <li>Interconnected data links to other modules for seamless access to scanned records for assets</li>
                                        <li>Robust "search" features optimized using Natural Language Processing (NLP), Artificial Intelligence (AI) and Machine Learning (ML)</li>
                                        <li>Intuitive interface and data indexing</li>
                                    </ul>
                                </Col>
                            </Row>

                            <Row className="flex-centered background-difference last-background">
                                <Col md="5" className={!this.state.lessorFeatures ? "order-md-7" : null}>
                                    <img src={imgStoragePath+'phase-2/technical.png'} alt="technical image" />
                                </Col>
                                <Col md="7">
                                    <h4 id="techFeatures">Projects Management</h4>
                                    <ul className="list-inline">
                                        <li>A single platform to schedule, plan and execute fleet-wide inspections</li>
                                        <li>Interconnected data links to other modules for seamless access to inspection history, reports and findings</li>
                                        <li  style={{width:'100%'}} >An intuitive view of data points from inspection findings
                                         <div className="download-links">
                                              <span style={{float:'right',marginTop:'20px'}}>
                                              <a href="https://itunes.apple.com/us/app/sparta-acumen-aviation/id1270942972?mt=8" target="_blank" style={{display:'inline',padding:'0px',border:'none'}} >
                                              <img src={imgStoragePath+'phase-2/app-store-apple.png'}  width="100px" style={{width:'110px'}} alt="maintenance image" /> </a>
                                              <a href="https://play.google.com/store/apps/details?id=acumenaviation.sparta.app"  target="_blank" style={{display:'inline',padding:'0px',border:'none'}}>
                                              <img src={imgStoragePath+'phase-2/google-play-badge.png'} width="100px" style={{width:'125px'}} alt="maintenance image" /> </a> </span>
                                              <h4 style={{fontSize:'19px',fontWeight:'700'}}>Download SPARTA iOS and Android apps</h4>
                                              <p>Inspect aircraft &amp; records using mobile and web apps</p>
                                            </div>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>



                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

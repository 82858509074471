import React, { useRef, useEffect, useState } from 'react'
import { globalGetService } from '../../../globalServices'
import { Row, Col, DropdownToggle, DropdownMenu, DropdownItem, ButtonDropdown, } from 'reactstrap';
import { imgStoragePath, displayDateTimeFormatShort } from '../../../constants';
import { ToolTipHover } from '../../../shared';
import exportIcon from '../../../shared/assets/img/export_icon.svg';
import { trackActivity } from '../../../utils/mixpanel';
import moment from 'moment';
import maximizeIcon from '../../../shared/assets/img/maximize.svg';
const { tableau } = window
const updateInterval = {
    1: "Daily",
    2: "Weekly",
    3: "Monthly",
    4: "Quarterly",
    5: "Half Yearly",
    6: "Yearly",
}
export default function TableauDashboard({item} ){
  const ref = useRef(null)
  const [dropdownOpen, toggleDropdownOpen] = useState(false)
  const [tableauContainer, setTableauContainer] = useState(null)
  const options = {
    hideToolbar: true,
  };


  function toggleFullscreen() {
    if (document.querySelector('iframe').requestFullscreen) {
            document.querySelector('iframe').requestFullscreen();
        } else if (document.querySelector('iframe').msRequestFullscreen) {
            document.querySelector('iframe').msRequestFullscreen();
        } else if (document.querySelector('iframe').mozRequestFullScreen) {
            document.querySelector('iframe').mozRequestFullScreen();
        } else if (document.querySelector('iframe').webkitRequestFullscreen) {
            document.querySelector('iframe').webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
}

  function initViz(){
    globalGetService('/console/get-tableau-token/')
    .then(response => {
      var url = `https://tableau.analytics.sparta.aero/trusted/${response.data.data.token}/views/${item.path}`
      setTableauContainer(new tableau.Viz(ref.current, url, options))
    })
  }

  function undoAsync(){
    tableauContainer.undoAsync()
  }

  useEffect(() =>{
    initViz();
  }, [])
  return(
    <div>
      <Row>
        <Col md="10">
          <div className="title-block">
          <p style={{fontSize: '13px', color: '#000', paddingLeft:'6px'}}>{item.description ? item.description : ''}</p>
          </div>
          {/* <h4 className="tableau-header">{name}</h4> */}
        </Col>
        <Col md="2">
          <ul className="list-inline tab-right flex-centered export-list">
            <li className="list-inline-item download-block" onClick={() => toggleFullscreen()} style={{cursor: 'pointer', padding: '3px 10px 5px 10px'}}>
              <img id="maximize" src={maximizeIcon} style={{width: '20px', height: '20px'}}/>
                <ToolTipHover placement="bottom" tagetId='maximize'>
                  Click to view full screen mode.
                </ToolTipHover>
            </li>
            <li className="list-inline-item download-block" style={{borderLeft: '1px solid #2e8cff'}}>
              <ButtonDropdown isOpen={dropdownOpen} toggle={() => toggleDropdownOpen(!dropdownOpen)}>
                <DropdownToggle caret size="sm">
                  <span><img width="12" src={exportIcon}  alt="export"/>EXPORT</span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => {tableauContainer.showExportPDFDialog(); trackActivity('Tableau Exported',{application_module: 'Tableu Analytics', page_title: item.title, file_name: `${item.title}`, file_type: 'PDF'})}}><img src={imgStoragePath+"pdf_icon.png"} alt="img"/>PDF</DropdownItem>
                  {/* <DropdownItem onClick={() => tableauContainer.exportCrossTabToExcel()}><img src={imgStoragePath+"excel_icon.png"} alt="img"/>Excel</DropdownItem> */}
                  <DropdownItem onClick={() => {tableauContainer.showExportCrossTabDialog(); trackActivity('Tableau Exported',{application_module: 'Tableu Analytics', page_title: item.title, file_name: `${item.title}`, file_type: 'CSV'})}}><img src={imgStoragePath+"excel_icon.png"} alt="img"/>CSV</DropdownItem>
                  <DropdownItem onClick={() => {tableauContainer.showExportImageDialog(); trackActivity('Tableau Exported',{application_module: 'Tableu Analytics', page_title: item.title, file_name: `${item.title}`, file_type: 'Image'})}}><img src={imgStoragePath+"default_file_icon.png"} alt="img"/>Image</DropdownItem>
                  {/* <DropdownItem onClick={() => tableauContainer.showExportPowerPointDialog()}><img src={imgStoragePath+"bulk_report-icon.svg"} alt="img"/>Powerpoint</DropdownItem> */}
                </DropdownMenu>
              </ButtonDropdown>
            </li>
          </ul>
        </Col>
        <Col md="3">
          <div className="card-wrapper" style={{height: window.innerHeight - 220 + 'px'}}>
            <Row className="flex-not-centered">
              {
                <React.Fragment style={{display: 'none'}}>
                  <Col md="5" style={{display: 'none'}}><p className="label">Data Source:</p></Col>
                  <Col md="7" style={{display: 'none'}}><p className="data">{item.source}</p></Col>
                </React.Fragment>
              }
              <Col md="5"><p className="label">Last Updated At:</p></Col>
              <Col md="7"><p className="data">{moment(item.last_updated_at).format(displayDateTimeFormatShort)} UTC</p></Col>
              <Col md="5"><p className="label">Data Update Frequency:</p></Col>
              <Col md="7"><p className="data">{updateInterval[item.update_interval]}</p></Col>
                {
                  item.instructions !== undefined && item.instructions !== null && item.instructions.trim() !== "" ?
                    <Col md="12"><p className="label">How to Interact:</p></Col>
                  :null
                }
                {
                  item.instructions !== undefined && item.instructions !== null && item.instructions.trim() !== "" ?
                <Col md="12"><div dangerouslySetInnerHTML={{__html: item.instructions}} style={{height: window.innerHeight -450, overflow: 'auto'}}></div></Col>:null
                }
            </Row>
          </div>
        </Col>
        <Col md="9">
          <div className="tableau-container" ref={ref}>
            <span style={{position: 'absolute', top: '10px', right: '30px',color:'#3f51b5', fontSize: '14px', cursor: 'pointer',}} onClick={() => undoAsync()}><i className="fa fa-undo"></i> Undo</span>
          </div>
        </Col>
      </Row>
    </div>
  )
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDom from 'react-dom';
import { withRouter, Link, browserHistory } from 'react-router';
import Tree, { TreeNode } from 'rc-tree';
import 'rc-tree/assets/index.css';
import Select from 'react-select';
import InfiniteScroll from 'react-infinite-scroll-component';
import 'react-select/dist/react-select.css';
import * as actions from '../actions';
import { ContextMenuProvider, Separator } from 'react-contexify';
import { Row, Col, Modal,Popover, PopoverHeader, PopoverBody, ModalHeader, ModalBody, ModalFooter, Input, Button, Progress, Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import { loadAssetViewAc } from '../../Dashboard/actionCreators';
import { usageDetailAc, captureActivityLogAc, dirTreeExpandAc, dirTreeAc, shareRecordsAc, deleteRecordAc, getFolderMoveRecordAc, moveRecordToDestAc,moveRecordToDestBulkAc,moveRecordBulkAc, downloadFilesAc, uploadFilesToDestAc,downloadBulkOperationAC,deleteBulkRecordAc, getValidatedFilesAc, addFileToCategoryAc,addFileToCategoryBulkAc, addFileToMiniPackBulkAc, changeActionDockStatusAc,changeActionDockBulkStatusAc, createNewFolderAc, getRecordDetailsAc, renameRecordAc, getAiSupportedFoldersAc,getUsersAc,shareUserEmailsAc,getUsersSelectedAc, getMiniPackFoldersAc } from '../actionCreators';
import { DataList, FolderInfo, FileInfo, AiSupportFolder } from '../components';
import { RecordsMiniMenu } from '../../Elements';
import { ShortHead, ContractBarLoader } from '../../../contracts/Elements';
import { RecordsSideBar, FileNavigation, DownloadPopup } from '../../Elements';
import { sGlobalConstantAc } from '../../../../shared/actionCreators';
import { NoRecordFound, ListLoader, LicenceModal  } from '../../../../shared';
import { addToIndexedDb, removeItemObject, getItemObjects } from '../../../../indexedDB';
import { getFileSize, toastFlashMessage } from '../../../../utils';
import { displayDateTimeFormatShort, imgStoragePath, displayDateTimeFormat, assetsType, fileIcons, imageExtensions, macDriveTool, window32DriveTool, window64DriveTool } from '../../../../constants';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { localTimeFn, getLocalStorageInfo } from '../../../../utils';
import { globalGetService } from '../../../../globalServices';
import { BUY_LICENCE } from '../../../../shared/actions'
import moment from 'moment'
import { getUsersUsageAc } from '../../../../User/actionCreators'
import Chips, { Chip } from 'react-chips'
import '../../assets/styles/records_a.scss';
import { trackActivity } from '../../../../utils/mixpanel';
let click = 0;

class DataRoom extends Component{
  constructor(props) {
    super(props);
    this.state = {
      ModalFlag: false,
      delModalFlag: false,
      delModalBulkFlag:false,
      isChecked: true,
      recordPreview: false,
      previewCard: {},
      deleteCard: {},
      renameCard: {},
      searchTerm: '',
      userstateEmails:[],
      chips: [],
      shareUrl: false,
      shareDuration: 24,
      shareType: 'hour',
      customDuration: false,
      shareValidity: '',
      destination: '',
      dragOver: false,
      fileListBox: false,
      clickCount: 0,
      uploadStack: [],
      renameModal: false,
      changeShareDuration: false,
      folderUUid:'',
      shareDurationError: false,
      createFolderFlag: false,
      toggleDropDown:false,
      canEdit:true,
      newFolderName: '',
      popoverOpen: false,
      treeData: [],
      checkedKeys: [],
      checkedIds:[],
      navigationTree: false,
      emails:[],
      activeSort: {
        value: 'asc',
        label: '',
        sortKey: ''
      },
      page: 1,
      readPopoverOpen: false,
      infoFlag: {open: false, name: '', item:{}},
      selectedEmail: [],
      newEmails: [],
      reasonForCreation: '',
      reasonModal: false,
      newEmail: false,
      miniPackFolders: false
    }
    this.toggle = this.toggle.bind(this);
    this.shareRecord = this.shareRecord.bind(this);
    this.previewRecord = this.previewRecord.bind(this);
    this.toggleFolderInfo = this.toggleFolderInfo.bind(this);
    this.copyFileUrl = this.copyFileUrl.bind(this);
    this.deleteRecord = this.deleteRecord.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.onFocusOut = this.onFocusOut.bind(this);
    this.fileListBox = this.fileListBox.bind(this);
    this.addToCategory = this.addToCategory.bind(this);
    this.updateCheckedIds = this.updateCheckedIds.bind(this);
    this.activeRecord = this.activeRecord.bind(this);
  }

  moveNextPage = () =>{
    this.setState({
      page: this.state.page + 1
    })
    if(this.state.searchTerm.trim() != ''){
      this.props.fetchDirTreeExpand(this.props.params, this.state.activeSort.value != '' ? {page: this.state.page, sort: 'name', sort_by: this.state.activeSort.value, search: this.state.searchTerm } : {page: this.state.page, search: this.state.searchTerm})
    }else{
      this.props.fetchDirTreeExpand(this.props.params, this.state.activeSort.value != '' ? {page: this.state.page, sort: 'name', sort_by: this.state.activeSort.value } : {page: this.state.page})
    }
  }

  getNewTreeData(treeData, curKey, child, level) {
    const loop = (data) => {
      // if (level < 1 || curKey.length - 3 > level * 2) return;
      data.forEach((item) => {
          if (item.children) {
            loop(item.children);
          } else {
            if(curKey.indexOf(item.uuid) === 0){
              item.children = child;
            }
          }
      });
    };
    loop(treeData);
    // setLeaf(treeData, curKey, level);
  }

  closeReasonModal = () => {
    this.setState({
      newEmail: false,
      reasonModal: false,
      reasonForCreation: '',
      chips: this.state.chips.pop()
    })
  }

  shareReason = () => {
    var emails= Object.assign([], this.state.userstateEmails);
    emails=[...emails,...this.props.userSelectedEmailLIst];
    if(this.state.newEmail){
      this.setState({
        newEmails: [...this.state.newEmails, {email: this.state.chips[this.state.chips.length - 1], reason: this.state.reasonForCreation}],
        newEmail: false,
        reasonModal: false,
        reasonForCreation: ''
      },() => this.props.updateSelectedEmails(this.state.chips))
    }else{
      let id = emails.filter(item => item.email == this.state.chips[this.state.chips.length - 1])[0].id
      this.setState({
        selectedEmail: [...this.state.selectedEmail, {id: id, reason: this.state.reasonForCreation}],
        newEmail: false,
        reasonModal: false,
        reasonForCreation: ''
      },() => this.props.updateSelectedEmails(this.state.chips))
    }
  }

  onChange = chips => {
    var emails= Object.assign([], this.state.userstateEmails);
    emails=[...emails,...this.props.userSelectedEmailLIst];
    let selectedEmail = this.state.selectedEmail
    this.setState({chips: chips, selectedEmail: this.props.userSelectedEmails.map(user =>{ return {id: user.id, reason: selectedEmail.filter(email => email.id == user.id).length ? selectedEmail.filter(email => email.id == user.id) : null}})}, () => {
      if(chips.length == 0){
        this.setState({
          chips: [],
          newEmails: [],
          selectedEmail: [],
        })
        this.props.updateSelectedEmails([])
      }else if(chips.length < this.state.chips.length){
        this.setState({
          chips,
          newEmails: this.state.newEmails.filter(email => !chips.includes(email.email)),
          selectedEmail: this.state.selectedEmail.filter(email => emails.filter(item => item.id == email.id).length),
        })
        this.props.updateSelectedEmails(chips)
      }else{
        if(chips.length > 0 && chips[chips.length-1].startsWith('+ ')){
         let email = chips[chips.length-1].substring(2);
         chips.pop();
         chips=[...chips,email]
         this.setState({ chips }, () => this.setState({reasonModal: true, newEmail: true}));
        }else{
          this.setState({chips}, () => {
            if(emails.filter(item => item.email == this.state.chips[this.state.chips.length - 1]).length){
              this.setState({selectedEmail: [...this.state.selectedEmail, {id: emails.filter(item => item.email == this.state.chips[this.state.chips.length - 1])[0].id, reason: null}]}, () => this.props.updateSelectedEmails(chips))
            }else{
              this.setState({reasonModal: true, newEmail: false});
            }
          }
          )
        }
      }
    })
  }

  moveRecord=()=>{
    this.setState({
      popoverOpen: true,
      destination: '',
    });
    this.props.getFoldersToMove({type: this.state.checkedIds.filter(item => item.type != "file").length>0?'folder':'file',uuid:this.state.checkedIds[0].uuid},{source: this.getSourceUuids()});
  }

  moveDest(destination){
    this.setState({destination});
  }

  togglePop() {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  }

  getSourceUuids(){
    let checkedIdsString='';
      if(this.state.checkedIds.filter(item => item.type != "file").length>0){
      this.state.checkedIds.map(item => {
          if(item.type != 'file'){
            if(checkedIdsString==''){
              checkedIdsString=item.uuid;
            }else{
              checkedIdsString=checkedIdsString+','+item.uuid;
            }
        }
      })
      }else{
        checkedIdsString=this.state.checkedIds[0].uuid
      }
      return checkedIdsString
  }

  aiSupport = () =>{
    if(this.props.aiSupportedFolderFlag){
      this.props.toggleAiSupportFolders(false)
    }else{
      this.props.getAiSupportedFolders()
    }
  }

  newFolderName = (value) =>{
    this.setState({
      newFolderName: value
    })
  }
  createFolder = () =>{
    this.setState({
      createFolderFlag: !this.state.createFolderFlag
    })
  }
  toggleDrop=()=>{
    this.setState({
      toggleDropDown: !this.state.toggleDropDown
    })
  }
  updateEdit=(value)=>{
    this.setState({
      canEdit: value
    })
    this.props.updateSelectedPermission(value);
  }
  setShareDuration = (type,duration,flag) =>{
    this.setState({
      shareDuration: duration,
      shareType: type,
      customDuration: flag,
      isChecked: true,
      shareDurationError: false
    });
    var shareDuration = type == "days" ? parseInt(duration * 24) : parseInt(duration);
    if(flag != true){
      this.props.shareRecords(this.state.renameCard,false,shareDuration)
    }
  }
  setCustomDuration = (type,duration,flag) =>{
    if(duration != null && duration != undefined && duration != '' && duration != 0){
      this.setState({
        shareDuration: duration,
        shareType: type,
        customDuration: flag,
        isChecked: true,
      });
      var shareDuration = type == "days" ? parseInt(duration * 24) : parseInt(duration);
      this.props.shareRecords(this.state.renameCard,false,shareDuration)
    }else{
      this.setState({
        shareDurationError: true
      })
    }
  }
  addToUpload = (file) =>{
    this.setState(prevState =>({
      ...prevState,
      uploadStack: prevState.uploadStack.length ? prevState.uploadStack.indexOf(file) != -1 ? prevState.uploadStack.filter(item => item != file) : [...prevState.uploadStack, file] : [...prevState.uploadStack, file]
    }))
  }
  addToUploadAll = () => {
    if(this.state.uploadStack.length == (this.props.validatedFiles.overwrite_files.length + this.props.validatedFiles.improper_files.length)){
      this.setState(prevState =>({
        ...prevState,
        uploadStack: []
      }))
    }else {
      this.setState(prevState =>({
        ...prevState,
        uploadStack: [...prevState.uploadStack, ...this.props.validatedFiles.improper_files]
      }))
      this.setState(prevState =>({
        ...prevState,
        uploadStack: [...prevState.uploadStack, ...this.props.validatedFiles.overwrite_files]
      }))
    }

  }
  updateCheckedIds=(data,e)=>{
    if(e.target.checked){
    this.setState(prevState =>({
      ...prevState,
      checkedIds: [...prevState.checkedIds, data]
    }))
  }else{
    this.setState(prevState =>({
      ...prevState,
      checkedIds:prevState.checkedIds.filter(item => item.id != data.id)
    }))
  }
  }

  checkFolderFiles=()=>{
    return this.state.checkedIds.filter(item => item.type == "file").length>0 && this.state.checkedIds.filter(item => item.type != "file").length>0 ? false : (this.state.checkedIds.filter(item => item.type != "file").length>0 ? false : true)
  }


  updateAllCheckIds=(e)=>{
    if(e.target.checked){
    this.setState(prevState =>({
      ...prevState,
      checkedIds:this.props.recordDirTree.list
    }))
  }else{
    this.setState(prevState =>({
      ...prevState,
      checkedIds:[]
    }))
  }
  }

  addToBucket(item){
    this.props.captureActivityLog([item.uuid])
    addToIndexedDb({uuid: item.uuid, name: item.name, size: getFileSize(item.size), slug: this.props.params.aircraft_slug, type: item.type, extension: item.extension });
    getItemObjects(this.props.params.aircraft_slug);
  }
  changeActionDockStatus = (fileObject) =>{
    this.props.changeActionDockStatus(fileObject);
    this.props.fetchDirTree(this.props, {page: 1});
    this.setState({
      page: 1
    })
  }

  deleteBulk=()=>{
    this.props.deleteFileFolder(this.state.checkedIds);
  }

  changeStatus=()=>{
    let checkedIDs=[];
    this.state.checkedIds.map(item => {
      checkedIDs.push(item.uuid);
   })
    let data={
     file: true,
     new_status: 3,
     objects:checkedIDs
    }
    this.props.changeActionDockBulkStatus(data);
  }

  fileListBox(){
    this.setState({
      fileListBox: false,
    });
    this.props.emptyUploadList();
  }
  shareRecord = (item) => {
    this.props.updateSelectedEmails([]);
    if(item.type == 'folder'){
      this.props.fetchUsers(item.uuid);
    }
    //this.props.fetchSelectedUsers(item.uuid);
    this.setState(prevState => ({
      ...prevState,
      ModalFlag: true,
      renameCard: item,
      shareUrl: false,
      isChecked: item.share_enabled && !item.user_shared_folder,
      folderUUid:item.uuid,
      userstateEmails:[],
      shareValidity: item.valid_till,
      shareDuration: item.share_duration ? ![24,72,168,240].includes(item.share_duration) && item.share_duration % 24 == 0 ? item.share_duration / 24 : item.share_duration : 24,
      shareType: item.share_duration % 24 == 0 ? 'days' : 'hours',
      customDuration: ![24,72,168,240].includes(item.share_duration),
      shareDurationError: false
    }));
    if(item.share_enabled != null && item.share_enabled != undefined && item.share_enabled == true){
      this.setState({
        changeShareDuration: true
      })
      this.props.getShareKey(item.share_key, item.valid_till);
    }else {
      this.setState({
        changeShareDuration: false
      })
      //this.props.shareRecords(item,false, 24);
    }
  }
  onDrop(evt){
    evt.stopPropagation();
    evt.preventDefault();
    var uploadArray = [];
    var notAllowedArray = []

    if(getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas){
      let filesSize = 0
      for(let file of evt.dataTransfer.files){
        filesSize += file.size
      }
      if(this.props.storageUsage.used + filesSize >= this.props.storageUsage.available){
        this.props.buyLicence({
          flag: true,
          action: '/licenses/storage',
          content: 'The uploaded file(s) size is not available in storage.'
        })
        this.props.fileVerifyLoader(false)
        return;
      }
    }
    if(getLocalStorageInfo().user.permission['records']['data_room'].indexOf('UP') == -1 )
    {
      toastFlashMessage("You don't have upload rights, please contact Admin", "error");
      return;
    }else{
      for(let file of evt.dataTransfer.files){
        // let fileName = file.name.substr(file.name.lastIndexOf('.') + 1, file.name.length -1)
        // if(this.props.techConstants.filter(item => item == fileName).length){
        //
        // }else{
        //   notAllowedArray.push(file.name)
        // }
        uploadArray.push(file.name);
      }
      this.setState({
        fileListBox: true,
        uploadStack: uploadArray,
        renameModal: true
      });
      this.props.getValidatedFiles(evt.dataTransfer.files, this.props.params.id,this.props.ctrShortInfo, this.props.recordDirTree.navigation);
    }
  }
  addToCategory(category, uuid, itemUuid){
    if(category === 2){
      this.props.addFileToMiniPackBulk(category,[{type: 'file', uuid: uuid}], this.props.miniPackFolders.filter(item => item.category === 7)[0].uuid);
    }else if(category === 1){
      this.props.addFileToMiniPackBulk(category,[{type: 'file', uuid: uuid}], this.props.miniPackFolders.filter(item => item.category === 8)[0].uuid);
    }
  }

  addToCategoryBulk = (category, uuid, itemUuid) =>{
    if(category === 3){
      if(itemUuid){
        this.props.addFileToMiniPackBulk(category,[{type: 'file', uuid: itemUuid}], uuid);
      }else{
        this.props.addFileToMiniPackBulk(category,this.state.checkedIds, uuid);
      }
      this.setState({miniPackFolders: false})
    }else if(category === 2){
      if(itemUuid){
        this.props.addFileToMiniPackBulk(category,[{type: 'file', uuid: itemUuid}], this.props.miniPackFolders.filter(item => item.category === 7)[0].uuid);
      }else{
        this.props.addFileToMiniPackBulk(category,this.state.checkedIds, this.props.miniPackFolders.filter(item => item.category === 7)[0].uuid);
      }
    }else if(category === 1){
      if(itemUuid){
        this.props.addFileToMiniPackBulk(category,[{type: 'file', uuid: itemUuid}], this.props.miniPackFolders.filter(item => item.category === 8)[0].uuid);
      }else{
        this.props.addFileToMiniPackBulk(category,this.state.checkedIds, this.props.miniPackFolders.filter(item => item.category === 8)[0].uuid);
      }
    }
  }
  onDragOver(evt){
      evt.stopPropagation();
      evt.preventDefault();
      this.toggleFolderInfo();
      if(getLocalStorageInfo().user.permission['records']['data_room'].indexOf('UP') == -1 )
      {
        return;
      }
        this.setState({
          dragOver:true,
        });
      this.props.emptyUploadList();
      document.querySelector('.list-wrapper').disabled = true;
  }
  deleteRecord = (item) => {

    this.setState(prevState => ({
      ...prevState,
      delModalFlag: !prevState.delModalFlag,
      deleteCard: item,
    }));
    removeItemObject(this.props.params.aircraft_slug);
  }

  deleteBulkRecord = () => {

    this.setState(prevState => ({
      ...prevState,
      delModalBulkFlag: !prevState.delModalBulkFlag,
    }));
    removeItemObject(this.props.params.aircraft_slug);
  }

  toggleFolderInfo(){
    this.setState({
      recordPreview: false,
      previewCard:{}
    });
  }
  previewRecord = (item, evt) =>{
    click++;
    if(!this.props.expansionLoader){
      if(click > 0){
        setTimeout(() => {
          if(click == 1){
            this.setState(prevState => ({
              ...prevState,
              previewCard: {},
            }));
            let url = ''
            if(item.type == "file"){
              if(imageExtensions.indexOf(item.extension) != -1)
              {
                this.setState(prevState => ({
                  ...prevState,
                  previewCard: item,
                  recordPreview: true,
                  navigationTree: false
                }));
                this.props.getRecordDetails({type: item.type, uuid: item.uuid});
              }else if(item.extension == 'pdf'){
                this.setState(prevState => ({
                  ...prevState,
                  previewCard: item,
                  recordPreview: true,
                  navigationTree: false
                }));
                this.props.getRecordDetails({type: item.type, uuid: item.uuid});
              }else if(item.extension == 'csv' || item.extension == 'xls' || item.extension == 'xlsx'){
                this.setState(prevState => ({
                  ...prevState,
                  previewCard: item,
                  recordPreview: true,
                  navigationTree: false
                }));
                this.props.getRecordDetails({type: item.type, uuid: item.uuid});
              }else {
                url = decodeURIComponent(`${item.path}`);
                window.open(url, '_blank').focus();
              }
            }else {
              this.setState(prevState => ({
                ...prevState,
                previewCard: item,
                recordPreview: true,
                navigationTree: false
              }));
              this.props.getRecordDetails({type: item.type, uuid: item.uuid});
            }
          }
          click = 0;
        },500);
      }
    }
  }
  toggle(){
    this.setState(prevState => ({
      ...prevState,
      ModalFlag: !prevState.ModalFlag,
      shareUrl: !prevState.shareUrl,
      shareKey: '',
      shareDuration: 24,
      shareType: 'hour',
      userstateEmails:[],
      customDuration: false,
      shareDurationError: false,
      canEdit: true,
      newEmails: [],
      selectedEmail: []
    }));

  }
  componentWillReceiveProps = (nextProps, state) => {
    if( nextProps.params.id != this.props.params.id){
      this.props.fetchDirTree(nextProps, {page: 1});
      this.setState({
        page: 1,
        checkedIds:[],
        searchTerm: ''
      })
    }
  }
  onFocusOut(){
    this.setState({
      dragOver: false,
    })
  }
  activeRecord(evt){
    // var target = document.querySelector('.record-list .row');
    // target.classList.remove('active');
    // evt.target.parentElement.className = "active row";
  }
  componentDidMount(){
    if(getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas){
			this.props.usageDetail()
		}
    this.props.fetchAssetInfo();
    this.props.fetchDirTree(this.props, {page: 1, sort: 'name', sort_by: this.state.activeSort.value});
    getItemObjects(this.props.params.aircraft_slug);
    this.onLoadDataGetTree(this.props.params.id);
    this.props.fetchTechConstant({constant_types: ['file_extension']})
    this.setState({
      checkedIds:[]
    })
    this.props.getMiniPackFolders()
    if(getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.is_saas){
      this.props.getUsersUsage()
    }
  }

  searchRecord = () => {
    this.props.fetchDirTree(this.props, {page: 1, sort: 'name', sort_by: this.state.activeSort.value, search: this.state.searchTerm});
  }

  onSelect = (info, node) => {
    if(node.node.props.type == 'file'){
      this.previewRecord(node.node.props.fileItem)
    }
    if(node.node.props.type == 'folder' && info.length){
      browserHistory.push('/'+this.props.params.type + '/' + this.props.params.aircraft_slug+'/records/data-room/' + this.props.params.application + '/' +info[0]);
    }
    this.setState({
      activeSort: {
        value: 'asc',
        label: 'A to Z',
        sortKey: 'name'
      }
    })
  }


  getNavigationTree = () =>{
    this.setState({
      recordPreview: false,
      previewCard: {},
      navigationTree: !this.state.navigationTree
    })
  }

  onLoadDataGetTree = (uuid) => {
    globalGetService('records/'+this.props.params.type+'/'+ this.props.params.aircraft_slug+'/data-room/'+uuid+'/',{root_structure: true})
    .then(response => {
      this.setState({
        treeData: response.data.data.list
      })
    })
  }

  onCheck = (checkedKeys) => {
    this.setState({
      checkedKeys,
    });
  }
  onLoadData = (treeNode) => {
    return new Promise((resolve) => {
      var treeData = [...this.state.treeData];
      globalGetService('records/'+this.props.params.type+'/'+ this.props.params.aircraft_slug+'/data-room/'+treeNode.props.uuid+'/',{tree_structure: true})
      .then(response => {
        this.getNewTreeData(treeData, treeNode.props.eventKey, response.data.data.list);
        this.setState({treeData});
        resolve();
      })
    });
  }
  updateDirTree = (item) => {
    if(item.type != 'file' && !this.props.expansionLoader){
      let newParams = this.props.params;
      newParams = {
        ...newParams,
        id:item.uuid
      }
      this.setState({
        activeSort: {
          value: 'asc',
          label: '',
          sortKey: ''
        },
        checkedIds:[]
      })
      if(this.props.location.query.type){
        browserHistory.push('/'+this.props.params.type + '/' + this.props.params.aircraft_slug+'/records/data-room/' + this.props.params.application + '/' +item.uuid + '?type=' + this.props.location.query.type);
      }else{
        browserHistory.push('/'+this.props.params.type + '/' + this.props.params.aircraft_slug+'/records/data-room/' + this.props.params.application + '/' +item.uuid);
      }
    }
  }
  shareUsers=(selectedEmails)=>{
    var ids=[];
    var emails= Object.assign([], this.state.userstateEmails);
    emails=[...emails,...this.props.userSelectedEmailLIst];
    selectedEmails.map(email => {
      if(emails.filter(item => item.email == email).length && emails.filter(item => item.email == email)[0].id && !ids.filter(user => emails.filter(item => item.email == email).length && user.id == emails.filter(item => item.email == email)[0].id).length){
        ids.push({id: emails.filter(item => item.email == email)[0].id, reason: null})
      }
    })
    this.setState({selectedEmail: ids})
    var newEmails=this.state.newEmails;
    let company_domain = getLocalStorageInfo().defaultLessor.company_domain
    company_domain = company_domain ? company_domain.substr(company_domain.lastIndexOf('/') + 1, company_domain.length - 1) : ''
    if(getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.is_saas){
      if(this.props.usersUsage.available < this.props.usersUsage.used + newEmails.filter(email => company_domain && company_domain != '' && email.email.includes(company_domain)).length){
        this.props.buyLicence({
          flag: true,
          action: '/licenses/user',
          content: 'License for adding new users is expired. Please upgrade your license to add more users.'
        })
        return;
      }
    }
    this.props.shareUserEmails(ids,this.state.folderUUid,newEmails,this.state.canEdit);
    this.toggle();
  }

  fetchSuggestions=(value)=>{
  if(value.length>0){
   return  new Promise((resolve) => {
    globalGetService('/records/user-folder-share/'+this.state.folderUUid + '/',{email: value,add_users: true})
    .then(response => {
      if(response.data.statusCode == 200){
      let emails=[]
      response.data.data.map(item => {
      emails.push(item.email);
      })
      this.setState(prevState=>({
        ...prevState,
        userstateEmails:[...prevState.userstateEmails, ...response.data.data]
           }))
     if(emails.length>0){
      resolve(emails);
        }else{
      emails.push('+ '+value);
      resolve(emails);
          }
    }else{
      toastFlashMessage("Something went wrong", "error");
    }
    })
    });
  }
  }
  changeMsn =(evt) =>{
    let url = "";
    if(evt.records){
      if(this.props.ctrShortInfo.records.technical_records.uuid != undefined && this.props.ctrShortInfo.records.technical_records.uuid != null && this.props.ctrShortInfo.records.technical_records.uuid != ""){
        url = "/"+assetsType[evt.asset_type]+'/'+evt.slug+'/records/dataRoom/';
      }
    }else{
      url = '/asset-view/'+assetsType[evt.asset_type]+'/'+evt.slug;
    }
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
    browserHistory.push(url);
  }
  downloadFiles = (item) => {
    let asset_serial_number = this.props.ctrShortInfo.asset_type === 1 ? `MSN ${this.props.ctrShortInfo.msn}` : [3,4,5].indexOf(this.props.ctrShortInfo.asset_type) !== -1 ? `${assetsType[this.props.ctrShortInfo.asset_type].toUpperCase()} ${this.props.ctrShortInfo.serial_number}` : `${this.props.ctrShortInfo.asset_type === 2 ? 'ESN ' : ''}${this.props.ctrShortInfo.esn}`
    trackActivity('Files Downloaded', {
      application_module: 'Records',
      asset: asset_serial_number,
      files: item.name,
      type: item.type,
      source : 'Web'
    })
    this.props.downloadFiles(item, this.props.ctrShortInfo.asset_type==2?this.props.ctrShortInfo.esn:this.props.ctrShortInfo.msn);
  }
  changeDirectory = (uuid) => {
    let url = ''
    if(this.props.location.query.type){
      url = '/'+this.props.params.type+'/'+this.props.params.aircraft_slug+'/records/data-room/' + this.props.params.application + '/'+uuid + '?type=' + this.props.location.query.type
    }else{
      url = '/'+this.props.params.type+'/'+this.props.params.aircraft_slug+'/records/data-room/' + this.props.params.application + '/'+uuid;
    }
    browserHistory.push(url);
    this.props.params.id = uuid;
    // this.setState({
    //   activeSort: {
    //     value: 'asc',
    //     label: 'A to Z',
    //     sortKey: 'name'
    //   }
    // })
    this.componentDidMount();
  }
  uploadFilesToDest = (uploadFiles, id, improper_files, fileType) => {
    this.setState(prevState=>({
      ...prevState,
      renameModal: false,
      uploadStack: fileType == 'all' ? [...this.state.uploadStack, ...this.props.validatedFiles.proper_files] : this.props.validatedFiles.proper_files
    }))
    this.props.uploadFilesToDest(uploadFiles, id, improper_files, fileType, this.state.uploadStack, this.props.validatedFiles.proper_files, this.props.ctrShortInfo, this.props.recordDirTree.navigation);
  }
  changeShareStatus(evt){
    this.setState({
      isChecked: !this.state.isChecked,
      shareDuration: 24,
      shareUrl: false,
      customDuration: false,
      shareDurationError: false,
    });
    this.props.shareRecords(this.state.renameCard,this.state.isChecked, this.state.shareDuration);
  }
  copyFileUrl(){
    let file_url = document.querySelector("#file_url");
    file_url.select();
    document.execCommand("copy");
    this.setState({
      shareUrl: true
    });
  }

  sortDataRoom = (sort) =>{
    this.setState({
      activeSort: sort
    })
    this.props.fetchDirTree(this.props, {sort_by: sort.value, sort: sort.sortKey, page: 1})
    this.setState({
      page: 1
    })
  }

  uploadFilesPermissionCheck = (evt) =>{
    let userInfo = getLocalStorageInfo()
    if(userInfo && userInfo.user && userInfo.user.permission && userInfo.user.permission.records && userInfo.user.permission.records.data_room && userInfo.user.permission.records.data_room.indexOf('UP') !== -1){
      this.setState({dragOver: false});
      this.props.fileVerifyLoader(true);
      this.onDrop(evt);
    }
  }
  renameRecord =(record) => {
    this.setState({activeSort:{value: 'asc'}}, () => this.props.renameRecord(record, this.state.activeSort.value))
  }
  render(){
    const location = Object.assign({}, browserHistory.getCurrentLocation());
    const { miniPackFolders, notificationOpen, recordDetail, popoverLoader, techConstants, ctrShortInfo, recordDirTree, directoryLoader, downloadLoader, folderDirTree, progressPercentage, dataBucket,userEmails,userSelectedEmails,userSelectedPermission, bulkDownloadPopup} = this.props;
    var fileIcons = {}
    techConstants.map(item => {
      if(item.type == "file_extension"){
        fileIcons = {...fileIcons, [item.label]: imgStoragePath + item.remarks}
      }
    })
    let emails=[]
    userEmails.map(item => {
    emails.push(item.email);
    })

    let { shareKey, uploadFiles, validatedFiles } = this.props;
    const uploadIcon = imgStoragePath+"uploadFile.png";
    const loop = (data) => {
      return data.map((item) => {
        if (item.children) {
          return <TreeNode icon={(props) => props.expanded ? <i className="fa fa-folder-open"></i> : <i className="fa fa-folder"></i>} title={item.name} type={item.type} uuid={item.uuid} fileItem={item} key={item.uuid}>{loop(item.children)}</TreeNode>;
        }
        return (
          <TreeNode icon={item.type == "file" ? <img src={item.extension ? fileIcons[item.extension] : imgStoragePath+"default_file_icon.png"} width="14" /> : <i className="fa fa-folder"></i>} title={item.name} fileItem={item} type={item.type} uuid={item.uuid} key={item.uuid} isLeaf={item.type == "folder" ? false : true} disabled={item.leaf && item.type != 'folder' ? 'disabled' : ''}/>
        );
      });
    };
    const userInfo = getLocalStorageInfo()
    var treeNodes = loop(this.state.treeData);
    let isChecked = this.state.isChecked
    const sortOptions = [{value: 'asc', label: 'A to Z', sortKey: 'name'}, {value: 'desc', label: 'Z to A', sortKey: 'name'}]
    // if(userSelectedEmails.length){
    //   isChecked = false
    // }else if(this.state.shareValidity){
    //   isChecked = true
    // }
    return(
      <div style={{marginLeft: '60px'}}>
        <RecordsSideBar />
        { Object.keys(ctrShortInfo).length ?
          <ShortHead
            headingText="Records"
            subHeaderTopMargin={notificationOpen == true ? 74 : 40}
            changeMsn={this.changeMsn}
            contrAssetBasicInfo={ctrShortInfo} />: <ContractBarLoader/>
        }
        <FileNavigation
          canCreateFolder={recordDirTree.create_folder}
          createFolder={() => this.createFolder()} getNavigationTree={this.getNavigationTree}
          dataBucket={dataBucket} goBackDirectory={this.goBackDirectory}
          changeDirectory={this.changeDirectory}
          navigation={recordDirTree.navigation}
          navigationTree={this.state.navigationTree}
          style={notificationOpen ? {marginLeft: '30px', marginTop: '30px'} : {marginLeft: '30px'}}
        />
        { directoryLoader ? <ListLoader/> : null }
        <div className={(this.state.dragOver == true && recordDirTree.navigation) ? "records-list-wrap upload-file": "records-list-wrap"}
          style={{minHeight: '400px', marginTop: '120px',marginLeft:'218px'}}
          onDragOver={(evt) => this.onDragOver(evt)} tabIndex={-1}
          onDrop={(evt) => this.uploadFilesPermissionCheck(evt)}
          >
          {(this.state.dragOver == true && recordDirTree.navigation) ?
            <div className="dragEvent flex-centered" onDragLeave={() => this.onFocusOut()} >
             <div>
                <img src={uploadIcon}/>
                <p>Drop Files To Upload</p>
              </div>
            </div>
          : null
          }
          <div className="navigation-side-bar" style={this.state.navigationTree ? {width: '300px', height: window.innerHeight - 146 + 'px'} : {width: '0px'}}>
            <Tree
              onSelect={this.onSelect}
              loadData={this.onLoadData}
              showLine={true}
              multiple={false}
              className="folder-tree-navigation"
            >
              {treeNodes}
            </Tree>
          </div>
          <div className="list-wrapper"  style={ this.state.navigationTree ? {marginLeft:'300px'} : this.state.recordPreview ? {marginRight:'550px'}:{margin:'20px 0px' }}>
          {this.state.checkedIds.length? null:
            <Row className="dataroom-sort-block" style={{marginBottom:'10px'}}>
              <Col md={this.state.recordPreview ? "6" : "7"}>
                {
                  this.props.params.type == 'aircraft' && getLocalStorageInfo().user.permission.records && !getLocalStorageInfo().user.permission.records.is_saas && getLocalStorageInfo().user.permission.records['data_room'] && getLocalStorageInfo().user.permission.records['data_room'].indexOf('UP') != -1 && getLocalStorageInfo().user.permission.auto_bulk_organizer ?
                  <button onClick={() => this.aiSupport()} style={this.state.recordPreview ? {padding: '6px 15px', marginLeft: '5px', border: 'none', marginTop: '20px', fontSize: '10px'} : {padding: '6px 15px', marginLeft: '5px', border: 'none', marginTop: '20px'}} className="primary-btn"><img width="20" src={imgStoragePath + 'ai_icon_white.png'} style={{marginRight: '5px'}} />AI Assistant Enabled, View supported folders &nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-caret-down"></i></button>
                  :null
                }
                <form style={{display: 'inline', marginLeft: '20px', position: 'absolute', width: '275px', top: '12px'}} onSubmit={(evt) => {evt.preventDefault(); evt.stopPropagation(); this.searchRecord()}}>
                  <input style={{width: '100%', borderRadius: '20px', padding: '5px'}} type="text" value={this.state.searchTerm} placeholder="search record" onChange={(evt) => this.setState({searchTerm: evt.target.value})}/>
                  <img onClick={() => this.searchRecord()} src="https://sparta-aims.ams3.digitaloceanspaces.com/others/assets/img/search_icn.png" style={{position: 'absolute', right: '10px', width: '20px', top: '10px', cursor: 'pointer', zIndex: 99}}/>
                </form>
                {
                  this.props.params.type == 'aircraft' && getLocalStorageInfo().user.permission.records['data_room'] && getLocalStorageInfo().user.permission.records['data_room'].indexOf('UP') != -1 && getLocalStorageInfo().user.permission.auto_bulk_organizer?
                  <div className="ai-support-info" style={this.props.aiSupportedFolderFlag ? {} : {height: '0px', overflow: 'hidden', boxShadow: 'none'}}>
                    <span className="ai-support-heading">Folders compatible for Auto Renaming and <br/>Organization - AI Model</span>
                      <p className="notice-info" style={{marginLeft:'5px',marginRight:'5px', marginTop: '10px'}}>
                        <img className="caution-img" src={imgStoragePath+"caution_sign.png"} alt="img"/>
                        CAUTION: The Auto Renaming and Organization for the below mentioned folders is expected to... <span onClick={()=>this.setState({readPopoverOpen: true})} id="read_more_ai" style={{cursor: 'pointer', textDecoration: 'underline'}}>Read more</span>
                      </p>
                      <Popover placement ="right" isOpen={this.state.readPopoverOpen} target="read_more_ai" toggle={() => this.setState({readPopoverOpen: false})}>
                          <PopoverBody>
                            <p style={{fontSize: '12px'}}>The Auto Renaming and Organization for the below mentioned folders is expected to work accurately only when certain pre-conditions are met and on certain sub-folders. The user is expected to ensure that these pre-conditions are considered when using the BOT and in analyzing the results received post using the BOT. Please refer the FAQ section for more details.</p>
                          </PopoverBody>
                      </Popover>
                    <div className="ai-support-body">
                      {
                        this.props.aiSupportedFolder && this.props.aiSupportedFolder.length ?
                          this.props.aiSupportedFolder.map((item, index) => <AiSupportFolder folder={item} key={`aifolder_${index}`}/>)
                        :<div className="ai-support-folder">
                           No Folder supports AI Model
                        </div>
                      }
                    </div>
                    <div className="ai-support-footer row">
                      <div className="col-md-6"><a download={window.navigator.appVersion.indexOf('Mac') != -1 ? "SPARTA.aero_Drive.dmg" : (navigator.userAgent.toLowerCase().includes('x64') || navigator.userAgent.toLowerCase().includes('win64')) ? "SPARTA.aero_Drive.msi" : "SPARTA.aero_Drive_32.msi"} href={window.navigator.appVersion.indexOf('Mac') != -1 ? `${macDriveTool}` : (navigator.userAgent.toLowerCase().includes('x64') || navigator.userAgent.toLowerCase().includes('win64')) ? `${window64DriveTool}` : `${window32DriveTool}` }>Download Drive Tool to Upload Records</a></div>
                      <div className="col-md-6"><Link to={`/${this.props.params.type}/${this.props.params.aircraft_slug}/records/auto-organiser-and-renaming`}>Review Auto Renamed & Organized Records</Link></div>
                    </div>
                  </div>
                  :null
                }
              </Col>
              <Col md="2">
                {
                  getLocalStorageInfo().user.permission['records']['data_room'].indexOf('CAT') != -1 && this.props.location.query.type == 'marketing' ?
                    <button disabled={!this.state.checkedIds.length} style={{fontSize:'11px',marginRight:'5px',background:'#2F8CFE', marginTop: '20px', float: 'right', cursor: 'not-allowed'}} className="btn btn-secondary" onClick={() => this.addToCategoryBulk(1)}>Add To Marketing Kit</button>
                  : null
                }
                {
                  getLocalStorageInfo().user.permission['records']['data_room'].indexOf('CAT') != -1 && this.props.location.query.type == 'engineering' ?
                    <button disabled={!this.state.checkedIds.length} style={{fontSize:'11px',marginRight:'5px',background:'#2F8CFE', marginTop: '20px', float: 'right', cursor: 'not-allowed'}} className="btn btn-secondary" onClick={() => this.addToCategoryBulk(2)}>Add To Engineering Kit</button>
                  : null
                }
                {
                  getLocalStorageInfo().user.permission['records']['data_room'].indexOf('CAT') != -1 && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('MPR') != -1 && this.props.location.query.type == 'minipack' ?
                    <button disabled={!this.state.checkedIds.length} style={{fontSize:'11px',marginRight:'5px',background:'#2F8CFE', marginTop: '20px', float: 'right', cursor: 'not-allowed'}} className="btn btn-secondary" onClick={() => this.addToCategoryBulk(3)}>Add To Major Assembly Minipack <span  className="fa fa-caret-down" ></span></button>
                  : null
                }
              </Col>
              <Col md={this.state.recordPreview ? "2" : "1"} className="inner-columns" >
                <span style={{marginTop: '20px', display: 'block'}}>Sort By :
                </span>
              </Col>
              <Col md="2" className="inner-columns-select">
              <Select
                value={this.state.activeSort.value}
                options={sortOptions}
                className="custom-select-block"
                onChange={(option) => this.sortDataRoom(option)}
                labelKey= 'label'
                valueKey= 'value'
                clearable={false}
                style={{marginTop: '15px'}}
                disabled={this.props.expansionLoader}
                />
              </Col>
            </Row>
            }
            {this.state.checkedIds.length?
            <Row style={{padding:'5px 20px',marginTop:'15px'}}>
            <Col md={this.state.recordPreview ? "4" : "6"} style={{fontSize:'12px'}} className="inner-columns-select">
              <input style={{marginRight:'10px'}} type="checkbox" checked = {recordDirTree.list?recordDirTree.list.length==this.state.checkedIds.length?true:false:false} onChange={(e) => this.updateAllCheckIds(e)}/>Select all ({this.state.checkedIds.length+' Selected'})
            </Col>
            {/*<Col md={this.state.recordPreview ? "2" : "2"} className="inner-columns-select"> //onClick={() => this.moveRecord()}
             <p style={{fontSize:'12px'}}>{this.state.checkedIds.length+' Selected'}</p>
            </Col>*/}
            <Col md={this.state.recordPreview ? "8" : "6"} className="inner-columns-select">
            {getLocalStorageInfo().user.permission['records'].action_dock?
            <div style={{float:'right'}}>
              {
                getLocalStorageInfo().user.permission['records']['data_room'].indexOf('CAT') != -1 && this.props.location.query.type == 'marketing' ?
                  <button  style={{fontSize:'11px',marginRight:'5px',background:'#2F8CFE'}} className="btn btn-secondary" onClick={() => this.addToCategoryBulk(1)}>Add To Marketing Kit</button>
                : null
              }
              {
                getLocalStorageInfo().user.permission['records']['data_room'].indexOf('CAT') != -1 && this.props.location.query.type == 'engineering' ?
                  <button  style={{fontSize:'11px',marginRight:'5px',background:'#2F8CFE'}} className="btn btn-secondary" onClick={() => this.addToCategoryBulk(2)}>Add To Engineering Kit</button>
                : null
              }
              {
                getLocalStorageInfo().user.permission['records']['data_room'].indexOf('CAT') != -1 && this.props.location.query.type == 'minipack' ?
                  <button  style={{fontSize:'11px',marginRight:'5px',background:'#2F8CFE'}} id={"info_minipack"} className="btn btn-secondary" onClick={() => this.setState({
                    miniPackFolders: true
                  })}>Add To Major Assembly Minipack <span  className="fa fa-caret-down" ></span></button>
                : null
              }
              {this.state.checkedIds.length && this.state.miniPackFolders ?
              <Popover placement="bottom" isOpen={this.state.miniPackFolders} target={"info_minipack"} toggle={() => this.setState({miniPackFolders: false})}>
                <PopoverHeader className="popover-header">
                  Minipack Folders
                  <span className="close-popover" onClick={() =>this.setState({miniPackFolders: false})}>X</span>
                </PopoverHeader>
                <PopoverBody>
                  <ul className="list-inline">
                    {
                      miniPackFolders && miniPackFolders.length ?
                        miniPackFolders.filter(item => item.category === 6).map((folder, index) => <li key={index} style={{padding: '6px 12px'}} onClick={() => this.addToCategoryBulk(3, folder.uuid)}>{folder.name}</li>)
                      :null
                    }
                  </ul>
                </PopoverBody>
              </Popover>:null}
              {getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1 ?
              <button style={this.checkFolderFiles()?{fontSize:'11px',marginRight:'5px',background:'#2F8CFE'}:{display:'none'}} className="btn btn-secondary" onClick={() => this.changeStatus()}>Publish</button>:null}
              {getLocalStorageInfo().user.permission['records']['data_room'].indexOf('DN') != -1 ?
              <button  style={{fontSize:'11px',marginRight:'5px',background:'#2F8CFE'}} className="btn btn-secondary" onClick={() => this.props.downloadMultipleFile(this.state.checkedIds, this.props.ctrShortInfo.asset_type==2?this.props.ctrShortInfo.esn:this.props.ctrShortInfo.msn)}>Download</button>:null}
               {
                 (getLocalStorageInfo().user.permission['records']['data_room'].indexOf('M') != -1 || getLocalStorageInfo().user.permission['records']['data_room'].indexOf('CAT') != -1) ?
                 <span style={{display:'inline-block'}} ><ContextMenuProvider id='bulk_more_actions' event="onClick">
                   <button  onContextMenu={(evt) => this.activeRecord(evt)}  style={{fontSize:'11px',marginRight:'5px',background:'#2F8CFE'}} className="btn btn-secondary" id={"info_bulk"} >More <span  className="fa fa-caret-down" ></span></button></ContextMenuProvider>
                 </span>
                :null
              }
              {getLocalStorageInfo().user.permission['records']['data_room'].indexOf('D') != -1 ?
              <button style={{fontSize:'11px'}}  className="btn btn-danger" onClick={() => {this.deleteBulkRecord()}}>Delete</button>:null}
            </div>:null
            }
            </Col>
            </Row>:null}
            <InfiniteScroll
              dataLength={recordDirTree.list ? recordDirTree.list.length : 0} //This is important field to render the next data
              next={() => this.moveNextPage()}
              hasMore={recordDirTree.list && recordDirTree.pagination && recordDirTree.list.length < recordDirTree.pagination.total}
              height={window.innerHeight - 220}
              >
                  {recordDirTree.list ? recordDirTree.list.length > 0 ?
                    recordDirTree.list.map((item, index) =>
                      <DataList
                        key={`item_${index}`}
                        miniPackFolders={miniPackFolders.filter(item => item.category === 6)}
                        addFileToMiniPackBulk={(uuid, itemUuid) => this.addToCategoryBulk(3, uuid, itemUuid)}
                        index={index}
                        addToBucket={() => this.addToBucket(item)}
                        shareRecord={this.shareRecord}
                        infoModal={() => this.setState({infoFlag: {open: true, name: item.name, item: item}})}
                        menuId={item.uuid}
                        checkBox={true}
                        item={item}
                        assetType={ctrShortInfo.asset_type}
                        updateCheckedIds={this.updateCheckedIds}
                        type={item.type}
                        checkedIds={this.state.checkedIds}
                        folderDirTree={folderDirTree}
                        downloadFiles={this.downloadFiles}
                        deleteRecord={this.deleteRecord}
                        previewRecord={(evt) =>  {this.previewRecord(item, evt)}}
                        getFoldersToMove={this.props.getFoldersToMove}
                        moveRecordToDest= {this.props.moveRecordToDest}
                        updateDirTree={(evt) => {this.updateDirTree(item)}}
                        popoverLoader={popoverLoader}
                        addToCategory={this.addToCategory}
                        level={recordDirTree.navigation && recordDirTree.navigation.length ? recordDirTree.navigation[recordDirTree.navigation.length - 1].level : 0}
                        changeActionDockStatus={this.changeActionDockStatus}
                        renameRecord={this.renameRecord}
                        parentFolder={recordDirTree.navigation && recordDirTree.navigation.length ? recordDirTree.navigation && recordDirTree.navigation[recordDirTree.navigation.length - 1] : null}
                        createFolder={recordDirTree.create_folder}
                        previewMode={Object.keys(this.state.previewCard).length ? true : false}
                        fileIcons={fileIcons}
                      />
                    ):<NoRecordFound description="Drag Files Here To Upload" />:<ListLoader/>
                  }
                </InfiniteScroll>
                {
                  this.props.expansionLoader ?
                    <div className="data-room-expansion-loader"><img src={imgStoragePath + "spinner.gif"}/></div>
                  :null
                }
          </div>
          {(Object.keys(this.state.previewCard).length && this.state.previewCard.type == 'file') ?
            <FileInfo recordDetail={recordDetail} previewInfo={this.state.previewCard} style={this.state.recordPreview ? {width: "550px"} : {width: "0px"}} toggleFolderInfo={this.toggleFolderInfo} /> :
            <FileInfo recordDetail={recordDetail} previewInfo={this.state.previewCard} style={{width: '0px'}} toggleFolderInfo={this.toggleFolderInfo} />
          }
          {(Object.keys(this.state.previewCard).length && this.state.previewCard.type == 'folder') ?
            <FolderInfo recordDetail={recordDetail} previewInfo={this.state.previewCard} style={this.state.recordPreview ? {width: "550px"} : {width: "0px"}} toggleFolderInfo={this.toggleFolderInfo} /> :
            <FolderInfo recordDetail={recordDetail} previewInfo={this.state.previewCard} style={{width: '0px'}} toggleFolderInfo={this.toggleFolderInfo} />
          }
        </div>
        <div className="file-upload-list"
          style={this.state.fileListBox && recordDirTree.navigation && uploadFiles.length && progressPercentage > 0 ? {display: 'block'} : {display: 'none'}}>
          <div className="file-upload-list-header">
            Uploading {this.state.uploadStack.length} Files ( {progressPercentage}% Complete )
            <span onClick={() => this.fileListBox()}><i className="fa fa-times"></i></span>
          </div>
          <div className="file-upload-list-body">
            <ul>
              {uploadFiles && uploadFiles.length && validatedFiles.proper_files ?
                uploadFiles.map((item) => validatedFiles.proper_files.indexOf(item.name) != -1 && this.state.uploadStack.indexOf(item.name) == -1 ? <li>{item.name}</li> : null)
                :null
              }
              {uploadFiles && uploadFiles.length?
                uploadFiles.map((item) => this.state.uploadStack.indexOf(item.name) != -1 ? <li>{item.name}</li> : null)
                :null
              }
            </ul>
          </div>
        </div>
        <Modal isOpen={this.state.infoFlag.open} size="sm" toggle={() => this.setState({infoFlag: {open: false, item: {}}})}>
          <ModalBody>
            <div className="delete-logs">
              <h4>{this.state.infoFlag.item && (this.state.infoFlag.item.stand_alone_folder_asset != null && this.state.infoFlag.item.stand_alone_folder_slug != null) ? `Click PROCEED to access ${this.state.infoFlag.name} records data room.` : ` To access ${this.state.infoFlag.name}, please create stand alone asset`}</h4>
              <ul className="list-inline">
                <li className="list-inline-item">
                {
                  this.state.infoFlag.item && this.state.infoFlag.item.stand_alone_folder_asset != null && this.state.infoFlag.item.stand_alone_folder_slug != null ?
                  <a onClick={() => this.setState({infoFlag: {open: false, name: ''}})} target="_blank" className="btn btn-danger" href={`/${this.state.infoFlag.item.stand_alone_folder_asset}/${this.state.infoFlag.item.stand_alone_folder_slug}/records/dataRoom/`}>Proceed</a>
                  :<button onClick={() => this.setState({infoFlag: {open: false, name: ''}})} className="btn btn-danger">Ok</button>
                }
                </li>
                <li className="list-inline-item">
                  <button className="btn btn-secondary" onClick={() => this.setState({infoFlag: {open: false, item: {}}})}>Cancel</button>
                </li>
              </ul>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.ModalFlag} size="lg" toggle={this.toggle} className="share-record-modal">
          <ModalHeader toggle={this.toggle}>
            Share <strong>"{this.state.renameCard.name}"</strong>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12">
                <input onChange={(evt) => this.changeShareStatus(evt) } ref="switch" checked={ isChecked } type="checkbox" />
                <span className="share-link-checkbox">Share Link</span>
              </Col>
            </Row>
            { isChecked == true ?
              <div>
                <Row>
                  <Col md="12">
                    <span>
                      Anyone with the url below will be able to view and download shared records.
                      Updating the sharing duration will generate a new URL below.
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Input id="file_url" readOnly type="text" disabled={ isChecked == true ? false : true} className="share-text" value={ isChecked == true ? this.props.shareKey ? window.location.protocol+'//'+window.location.hostname+'/records/view-shared/' + this.props.shareKey +'/':'':''} placeholder="https://"/>
                    <span className="share-button" onClick={() => this.copyFileUrl()}>Copy Link</span>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    {directoryLoader}
                    <h2> {this.props.shareValidity != null ? 'Share Link Validity' : null}   {this.props.shareValidity != null ? <span>(Valid Till: {this.props.shareValidity != null && this.props.shareValidity != undefined && this.props.shareValidity != '' ? moment.utc(this.props.shareValidity).format(displayDateTimeFormatShort) + ' UTC' : '--'})</span> : null} {this.state.changeShareDuration ? <Link onClick={() => this.setState({changeShareDuration: false, shareUrl: false})} className="change-share-duration">Update Share Duration</Link> : null}</h2>
                    {
                      this.state.changeShareDuration == false ?
                      <ul className="list-inline">
                        <li className={this.state.shareDuration == 24 && this.state.customDuration == false ? "list-inline-item active" : "list-inline-item"}>
                          <span onClick={() => this.setShareDuration("hour",24, false)}><input type="radio" checked={this.state.shareDuration == 24 ? 'checked' : ''} name="share_duration" /> 1 Day</span>
                        </li>
                        <li className={this.state.shareDuration == 72 && this.state.customDuration == false ? "list-inline-item active" : "list-inline-item"}>
                          <span onClick={() => this.setShareDuration("hour",72, false)}><input type="radio" checked={this.state.shareDuration == 72 ? 'checked' : ''} name="share_duration" /> 3 Days</span>
                        </li>
                        <li className={this.state.shareDuration == 168 && this.state.customDuration == false ? "list-inline-item active" : "list-inline-item"}>
                          <span><input type="radio" onClick={() => this.setShareDuration("hour",168, false)} checked={this.state.shareDuration == 168 ? 'checked' : ''} name="share_duration"/> 7 Days</span>
                        </li>
                        <li className={this.state.shareDuration == 240 && this.state.customDuration == false ? "list-inline-item active" : "list-inline-item"} >
                          <span onClick={() => this.setShareDuration("hour",240, false)}><input type="radio" checked={this.state.shareDuration == 240 ? 'checked' : ''} name="share_duration" /> 10 Days</span>
                        </li>
                        <li className={this.state.customDuration ? "list-inline-item active" : "list-inline-item"}>
                          <span onClick={() => this.setCustomDuration("hour",6, true)}><input type="radio" checked={this.state.customDuration ? 'checked' : ''} name="share_duration"/> Custom</span>
                          <input type="number" min='1' step='1' value={this.state.customDuration ? this.state.shareDuration : 6} onChange={(evt) => this.setShareDuration(this.state.shareType, evt.target.value, true)} onBlur={(evt) => this.setCustomDuration(this.state.shareType, evt.target.value, true)}/>
                          <select onChange={(evt) => this.setCustomDuration(evt.target.value, this.state.customDuration ? this.state.shareDuration : 6, true)}>
                            <option value="hour">Hours</option>
                            <option value="days">Days</option>
                          </select>
                        </li>
                      </ul>
                      :null
                    }
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    {this.state.shareUrl ?
                      <span className="copy-link-success">Link Copied Successfully</span>:null
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      {this.state.shareDurationError  ?
                        <span style={{marginLeft: '175px'}} className="copy-link-success">Custom share duration can not be 0 or empty.</span>:null
                      }
                    </Col>
                  </Row>
                </div>
                :null
              }
              <Row>
                <Col md="12">
                  { isChecked == false?
                    <span style={{marginLeft: '200px'}} className="copy-link-success">Sharing link is disabled for this record</span>:null
                  }
                </Col>
              </Row>
              {
             this.state.renameCard.type != 'file' && location && location.pathname.indexOf("/records/data-room/")!=-1 && getLocalStorageInfo().user.permission.records['data_room'] && getLocalStorageInfo().user.permission.records['data_room'].indexOf('SHU') != -1 ?
              <Row style={{borderTop:'1px solid #eeeeee',marginTop:'20px'}}>
              <Col md="11" style={{paddingRight:'5px'}}>
                   <span style={{fontSize:'15px',marginTop:'10px'}}>Share with Others</span>
                   <p style={{fontSize:'12px',marginTop:'2px'}}>Add Email to invite the user to collaborate the folder</p>
                   <div className="email-chips" style={{marginTop:'7px'}}>
                   <Chips
                    value={userSelectedEmails}
                    onChange={this.onChange}
                    fetchSuggestions={(value) => this.fetchSuggestions(value)}
                   />
                   </div>
                   <span className="done-button" style={{color:'#ffffff'}  } onClick={() => this.shareUsers(userSelectedEmails)}>Save</span>
              </Col>
              <Col md="1" style={{paddingLeft:'5px'}}>
              <div className="user-share-modal" style={userSelectedEmails.length ?{height:'40px'}:{height:'33px'}}>
                <Dropdown isOpen={this.state.toggleDropDown} toggle={this.toggleDrop}>
                  <DropdownToggle caret>
                  <div className="share-blk">
                      <div>
                        <h4>{userSelectedPermission == 1 ?<img width='15px' src={imgStoragePath+"phase-2/share_edit.png"}/>:<img width='15px' src={imgStoragePath+"phase-2/share_view.png"}/>}</h4>
                      </div>
                    </div>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={()=>this.updateEdit(1)}>Can edit, add &amp; delete</DropdownItem>
                    <DropdownItem onClick={()=>this.updateEdit(2)}>Can view only</DropdownItem>
                    <DropdownItem onClick={()=>this.updateEdit(0)}>Can view and download</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              </Col>
              </Row>:null}
            </ModalBody>
          </Modal>
          <Modal isOpen={this.state.delModalFlag} centered={true} toggle={this.deleteRecord} className={this.props.className}>
          <ModalHeader toggle={this.deleteRecord}>Delete {this.state.deleteCard.type}</ModalHeader>
          <ModalBody>
            <div className="delete-logs">
              <h4>Are you sure, you want to delete ?</h4>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button className="btn btn-danger" onClick={() => {this.props.deleteRecord(this.state.deleteCard); this.deleteRecord(this.state.deleteCard)}}>YES</button>
                </li>
                <li className="list-inline-item">
                  <button className="btn btn-secondary" onClick={() => this.deleteRecord(this.state.deleteCard)}>NO</button>
                </li>
              </ul>
            </div>
          </ModalBody>
        </Modal>
          <Modal isOpen={this.state.reasonModal} toggle={this.closeReasonModal} className={this.props.className}>
          <ModalHeader toggle={this.closeReasonModal}>Reason For Creation ({this.state.chips[this.state.chips.length - 1]})</ModalHeader>
        <ModalBody style={{height: '200px'}}>
              <textarea rows="20" cols="70" style={{padding: '10px', border: '1px solid rgb(204, 204, 204)', height: '175px'}} value={this.state.reasonForCreation} onChange={(evt) => this.setState({reasonForCreation: evt.target.value})} ></textarea>
          </ModalBody>
          <ModalFooter>
            <ul className="list-inline">
              <li className="list-inline-item">
                <button disabled={this.state.reasonForCreation.trim().length == 0} className="btn btn-primary add-new btn-sm" onClick={() => this.shareReason()}>Create</button>
              </li>
              <li className="list-inline-item">
                <button className="btn btn-secondary add-new btn-sm" onClick={() => this.closeReasonModal()}>Cancel</button>
              </li>
            </ul>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.delModalBulkFlag} centered={true} toggle={this.deleteBulkRecord} className={this.props.className}>
          <ModalHeader toggle={this.deleteBulkRecord}>Delete Selected Item(s)</ModalHeader>
          <ModalBody>
            <div className="delete-logs">
              <h4>Are you sure, you want to delete ?</h4>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button className="btn btn-danger" onClick={() => {this.deleteBulk(); this.deleteBulkRecord()}}>YES</button>
                </li>
                <li className="list-inline-item">
                  <button className="btn btn-secondary" onClick={() => this.deleteBulkRecord()}>NO</button>
                </li>
              </ul>
            </div>
          </ModalBody>
        </Modal>
        <Modal className="validatedFiles" isOpen={ ((validatedFiles.unsupported_files && validatedFiles.unsupported_files.length) || (validatedFiles.improper_files && validatedFiles.improper_files.length) || (validatedFiles.overwrite_files && validatedFiles.overwrite_files.length)) && this.state.renameModal ? true : false} centered={true}>
          <ModalHeader>
            <div className="improper-files-heading">
              <img src={imgStoragePath+"warning.png"}/>
            <h2>Oops looks like there are some files which has issues</h2>
            </div>
          </ModalHeader>
          <ModalBody>
              {
                (validatedFiles.overwrite_files && validatedFiles.overwrite_files.length) || (validatedFiles.improper_files && validatedFiles.improper_files.length) ?
                  <span className="select-all"><input type="checkbox"  checked={ this.state.uploadStack.length == (validatedFiles.overwrite_files.length + validatedFiles.improper_files.length) ? 'checked' : ''} onClick={() => this.addToUploadAll()}/>Select All</span>
                : null
              }
              {
                validatedFiles.overwrite_files && validatedFiles.overwrite_files.length ?
                <span className="validfiles-heading">Files that already exists</span>
                :null
              }
            {
              validatedFiles.overwrite_files && validatedFiles.overwrite_files.length ?
              <div className='records-list-wrap' style={{margin: '0px', maxHeight: '300px', overlay: 'scroll'}}>
                <div className="list-wrapper" style={{margin: '0px'}}>
                  { validatedFiles.overwrite_files && validatedFiles.overwrite_files.length ?
                    validatedFiles.overwrite_files.map((file) => <div className="record-list"><Row><Col md="12"><input type="checkbox" checked={ this.state.uploadStack.indexOf(file) != -1 ? 'checked' : ''} onClick={() => this.addToUpload(file)}/><img src={imgStoragePath+"pdf_file.png"} style={{width: '12px'}}/> <span>{file}</span><img src={imgStoragePath+"cross_red.png"} style={{width:'12px'}}/></Col></Row></div>) :null
                  }
                </div>
              </div>
              :null
            }
            {
              validatedFiles.improper_files && validatedFiles.improper_files.length ?
              <span className="validfiles-heading">Files that should be renamed</span>
              :null
            }
            {
              validatedFiles.improper_files && validatedFiles.improper_files.length ?
              <div className='records-list-wrap' style={{margin: '0px',  overlay: 'scroll'}}>
                <div className="list-wrapper" style={{margin: '0px'}}>
                  { validatedFiles.improper_files && validatedFiles.improper_files.length ?
                    validatedFiles.improper_files.map((file) => <div className="record-list"><Row><Col md="12"><input type="checkbox" checked={ this.state.uploadStack.indexOf(file) != -1 ? 'checked' : ''} onClick={() => this.addToUpload(file)}/><img src={imgStoragePath+"pdf_file.png"} style={{width: '12px'}}/> <span>{file}</span><img src={imgStoragePath+"cross_red.png"} style={{width:'12px'}}/></Col></Row></div>)
                    :null
                  }
                </div>
              </div>
              :null
            }
            {
              validatedFiles.file_naming_conventions && validatedFiles.file_naming_conventions.length  && validatedFiles.improper_files && validatedFiles.improper_files.length ?
              <span className="validfiles-heading">File name should match {validatedFiles.file_naming_conventions && validatedFiles.file_naming_conventions.length == 1 ? '' : 'any of the'} following:</span>
              :null
            }
            {
              validatedFiles.improper_files && validatedFiles.improper_files.length ?
              <div className='records-list-wrap' style={{margin: '0px'}}>
                <div className="list-wrapper" style={{margin: '0px'}}>
                  { validatedFiles.file_naming_conventions && validatedFiles.file_naming_conventions.length ?
                    validatedFiles.file_naming_conventions.map((file) => <div className="record-list convention-list">{file}</div>) :null
                  }
                </div>
              </div>
              :null
            }
            {
              validatedFiles.proper_files && validatedFiles.proper_files.length ?
              <span className="validfiles-heading">Proper Files</span>
              :null
            }
            {
              validatedFiles.proper_files && validatedFiles.proper_files.length ?
              <div className='records-list-wrap' style={{margin: '0px', maxHeight: '300px', overlay: 'scroll'}}>
                <div className="list-wrapper" style={{margin: '0px'}}>
                  { validatedFiles.proper_files && validatedFiles.proper_files.length ?
                    validatedFiles.proper_files.map((file) => <div className="record-list"><Row><Col md="12"><img src={imgStoragePath+"pdf_file.png"} style={{width: '12px'}}/> <span>{file}</span><img src={imgStoragePath+"green-tick.png"} style={{width:'12px'}}/></Col></Row></div>)
                    :null
                  }
                </div>
              </div>
              :null
            }
            {
              validatedFiles.unsupported_files && validatedFiles.unsupported_files.length ?
              <span className="validfiles-heading">Unsupported Files</span>
              :null
            }
            {
              validatedFiles.unsupported_files && validatedFiles.unsupported_files.length ?
              <div className='records-list-wrap' style={{margin: '0px', maxHeight: '300px', overlay: 'scroll'}}>
                <div className="list-wrapper" style={{margin: '0px'}}>
                  { validatedFiles.unsupported_files && validatedFiles.unsupported_files.length ?
                    validatedFiles.unsupported_files.map((file) => <div className="record-list"><Row><Col md="12"><img src={imgStoragePath+"pdf_file.png"} style={{width: '12px'}}/> <span>{file}</span></Col></Row></div>)
                    :null
                  }
                </div>
              </div>
              :null
            }
          </ModalBody>
          <ModalFooter>
              <ul className="list-inline">
                {
                  (validatedFiles.overwrite_files && validatedFiles.overwrite_files.length) || (validatedFiles.improper_files && validatedFiles.improper_files.length) ?
                  <li className="list-inline-item">
                    <button disabled={this.state.uploadStack.length ? '' : 'disabled'} className="btn btn-success btn-sm" style={{marginTop: '0px'}} onClick={() => {this.uploadFilesToDest(uploadFiles, this.props.params.id, validatedFiles.improper_files, 'all', this.state.uploadStack, validatedFiles.proper_files);this.setState({renameModal: false})}}>Upload <br/>Selected</button>
                  </li>
                  :null
                }
                {
                  validatedFiles.proper_files && validatedFiles.proper_files.length ?
                  <li className="list-inline-item">
                    <button className="btn btn-warning btn-sm" onClick={() => {this.uploadFilesToDest(uploadFiles, this.props.params.id, validatedFiles.improper_files, 'proper', this.state.uploadStack, validatedFiles.proper_files);this.setState({renameModal: false})}}>Upload only <br/> proper files</button>
                  </li> :null
                }
                <li className="list-inline-item">
                  <button className="btn btn-danger btn-sm" onClick={() => {this.props.discardUpload();this.props.emptyUploadList();this.setState({dragOver: false, fileListBox: false, uploadStack: [], renameModal: false})}}>Cancel Upload</button>
                </li>
              </ul>
          </ModalFooter>
        </Modal>
        <Modal className="validatedFiles" style={{height:'200px'}} isOpen={this.props.checkModal} centered={true}>
          <ModalHeader>
            <div className="improper-files-heading">
              <img src={imgStoragePath+"warning.png"}/>
            <h2>Oops looks like there are some files which has same name</h2>
            </div>
          </ModalHeader>
          <ModalBody>
              {
                this.props.checkFileDetails.same_folders &&  this.props.checkFileDetails.same_folders.length ?
                <span className="validfiles-heading">Folders</span>
                :null
              }
            {
             this.props.checkFileDetails.same_folders  && this.props.checkFileDetails.same_folders.length ?
              <div className='records-list-wrap' style={{margin: '0px', maxHeight: '300px', overlay: 'scroll'}}>
                <div className="list-wrapper" style={{margin: '0px'}}>
                  {  this.props.checkFileDetails.same_folders && this.props.checkFileDetails.same_folders.length ?
                    this.props.checkFileDetails.same_folders.map((file) => <div className="record-list"><Row><Col md="12"><img src={imgStoragePath+"folder_icn3.png"} style={{width: '12px'}}/>  <span>{file}</span></Col></Row></div>) :null
                  }
                </div>
              </div>
              :null
            }
            {
              this.props.checkFileDetails.same_files && this.props.checkFileDetails.same_files.length ?
              <span className="validfiles-heading">Files</span>
              :null
            }
            {
              this.props.checkFileDetails.same_files && this.props.checkFileDetails.same_files.length ?
              <div className='records-list-wrap' style={{margin: '0px', maxHeight: '300px', overlay: 'scroll'}}>
                <div className="list-wrapper" style={{margin: '0px'}}>
                  { this.props.checkFileDetails.same_files && this.props.checkFileDetails.same_files.length ?
                     this.props.checkFileDetails.same_files.map((file) => <div className="record-list"><Row><Col md="12"><img src={imgStoragePath+"pdf_file.png"} style={{width: '12px'}}/> <span>{file}</span></Col></Row></div>)
                    :null
                  }
                </div>
              </div>
              :null
            }
          </ModalBody>
          <ModalFooter>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button  className="btn btn-success btn-sm" style={{marginTop: '0px'}} onClick={() => {this.props.cancelCheckFileFolder();this.props.moveRecordBulk(this.state.checkedIds,this.state.destination)}}>Move &amp; Replace</button>
                </li>
                <li className="list-inline-item">
                  <button className="btn btn-danger btn-sm" style={{padding: '10px 25px'}} onClick={() => {this.props.cancelCheckFileFolder()}}>Cancel </button>
                </li>
              </ul>
          </ModalFooter>
        </Modal>
        <RecordsMiniMenu assetType={ctrShortInfo.asset_type} miniPackFolders={miniPackFolders.filter(item => item.category === 6)} moveRecord={this.moveRecord} addToCategoryBulk={this.addToCategoryBulk} />
        <DownloadPopup flag={bulkDownloadPopup} bulkPopupToggle={() => this.props.bulkPopupToggle(false)} />
        <Modal isOpen={this.state.createFolderFlag} centered={true} toggle={this.createFolder} className={this.props.className}>
          <ModalHeader toggle={this.createFolder}>New Folder</ModalHeader>
          <ModalBody>
            <input type="text" value={this.state.newFolderName} placeholder="Folder Name" onChange={(evt) => this.newFolderName(evt.target.value)}/>
          </ModalBody>
          <ModalFooter>
            <button disabled={String(this.state.newFolderName).trim() == '' ? 'disabled' : ''} className="btn btn-primary add-new btn-sm" onClick={() => {this.props.createNewFolder(this.state.newFolderName, this.state.activeSort.value);this.createFolder();this.setState({newFolderName: ''})}}>Create</button>
          </ModalFooter>
        </Modal>
        {this.state.checkedIds.length && (getLocalStorageInfo().user.permission['records']['data_room'].indexOf('M') != -1 || getLocalStorageInfo().user.permission['records']['data_room'].indexOf('CAT') != -1) ?
        <Popover placement="bottom" isOpen={this.state.popoverOpen} target={"info_bulk"} toggle={this.togglePop}>
          <PopoverHeader className="popover-header">
            Move To
            <span className="close-popover" onClick={() =>this.togglePop()}>X</span>
          </PopoverHeader>
          <PopoverBody>
            <ul className="list-inline">
              {
                this.props.popoverLoader ?
                <img src={imgStoragePath + "wait.gif"} style={{width:'40px', position: 'relative', left: '45%'}}/>:
                folderDirTree ?
                folderDirTree.length > 0 ?
                <li>
                  <img src={imgStoragePath+"popover_back.png"} onClick={() => folderDirTree[0].level?  this.props.getFoldersToMove(folderDirTree[0],{level: folderDirTree[0].level > 0 ? folderDirTree[0].level - 1 : 0, source: this.getSourceUuids()}):null}/> Parent Folder
                </li>
                :<li>
                  <img src={imgStoragePath+"popover_back.png"} onClick={() => this.props.getFoldersToMove({type:this.state.checkedIds.filter(item => item.type != "file").length>0?'folder':'file',uuid:this.state.checkedIds[0].uuid},{level:  (recordDirTree.navigation && recordDirTree.navigation.length ? recordDirTree.navigation[recordDirTree.navigation.length - 1].level : 0) > 0 ?  (recordDirTree.navigation && recordDirTree.navigation.length ? recordDirTree.navigation[recordDirTree.navigation.length - 1].level : 0) : 0 , source: this.getSourceUuids()})}/> Parent Folder
                </li>:null
              }
              {
                folderDirTree ?
                folderDirTree.length > 0 ?
                folderDirTree.map((folderItem, index) => <li className={this.state.destination == folderItem.uuid ? 'active' : ''} key={folderItem.uuid}>
                  <img src={imgStoragePath+"folder_icn3.png"}/><span onClick={() => this.moveDest(folderItem.uuid)}>{folderItem.name}</span>{folderItem.leaf == false ? <img src={imgStoragePath+"move_icon.png"} onClick={() => this.props.getFoldersToMove(folderItem,{select: folderItem.level, source:this.getSourceUuids() })} className='sub-folder'/>:null}
                </li>) :<li>--</li>:null
              }
            </ul>
          </PopoverBody>
          <Separator />
            <Button type="button" disabled={ this.state.destination != '' ? false : true} onClick={() => {this.props.moveRecordToDestBulk(this.state.checkedIds,this.state.destination);this.togglePop();}} color="primary" className="add-new">Move Here</Button>
        </Popover>:null}
        { downloadLoader ? <ListLoader/>:null }
        {
          this.props.buyLicenceModal.flag ?
          <LicenceModal
            toggleLicence={() => this.props.buyLicence({
              flag: false,
              action: '',
              content: ''
            })}
            planId="2"
            licenceModal={this.props.buyLicenceModal}
            />
          :null
        }
      </div>
    );
  }
}
const mapStateToProps = state => ({
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  recordDirTree: state.DataRoomReducer.recordDirTree,
  storageUsage: state.DataRoomReducer.storageUsage,
  shareKey: state.DataRoomReducer.shareKey,
  shareValidity: state.DataRoomReducer.shareValidity,
  directoryLoader: state.DataRoomReducer.directoryLoader,
  downloadLoader: state.DataRoomReducer.downloadLoader,
  folderDirTree: state.DataRoomReducer.folderDirTree,
  expansionLoader: state.DataRoomReducer.expansionLoader,
  progressPercentage: state.DataRoomReducer.progressPercentage,
  popoverLoader: state.DataRoomReducer.popoverLoader,
  uploadFiles: state.DataRoomReducer.uploadFiles,
  validatedFiles: state.DataRoomReducer.validatedFiles,
  recordDetail: state.DataRoomReducer.recordDetail,
  aiSupportedFolder: state.DataRoomReducer.aiSupportedFolder,
  aiSupportedFolderFlag: state.DataRoomReducer.aiSupportedFolderFlag,
  dataBucket: state.sharedReducers.dataBucket,
  techConstants: state.sharedReducers.techConstants,
  buyLicenceModal: state.sharedReducers.buyLicenceModal,
  checkModal: state.DataRoomReducer.checkModal,
  checkFileDetails: state.DataRoomReducer.checkFileDetails,
  userEmails:state.DataRoomReducer.userEmails,
  userSelectedEmails:state.DataRoomReducer.userSelectedEmails,
  userSelectedEmailLIst:state.DataRoomReducer.userSelectedEmailLIst,
  usersUsage:state.userAssetReducer.usersUsage,
  userSelectedPermission:state.DataRoomReducer.userSelectedPermission,
  bulkDownloadPopup: state.DataRoomReducer.bulkDownloadPopup,
  miniPackFolders: state.DataRoomReducer.miniPackFolders,
  notificationOpen:state.sharedReducers.notificationOpen,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deleteRecord: (item) => dispatch(deleteRecordAc(ownProps.params, item)),
    fetchUsers: (id) => dispatch(getUsersAc(id)),
    fetchSelectedUsers: (id) => dispatch(getUsersSelectedAc(id)),
    downloadFiles: (item, msn) => dispatch(downloadFilesAc(ownProps.params, item, msn)),
    uploadFilesToDest: (files, destination, improperFiles, type, uploadStack, proper_files, assetInfo, navigation) => dispatch(uploadFilesToDestAc(ownProps.params, files, destination, improperFiles, type, uploadStack, proper_files, assetInfo, navigation)),
    getFoldersToMove: (item, level) => dispatch(getFolderMoveRecordAc(ownProps.params, item, level)),
    moveRecordToDest: (source, destination) => dispatch(moveRecordToDestAc(ownProps.params, source, destination)),
    moveRecordToDestBulk: (checkedData, destination) => dispatch(moveRecordToDestBulkAc(ownProps.params, checkedData, destination)),
    moveRecordBulk: (checkedData, destination) => dispatch(moveRecordBulkAc(ownProps.params, checkedData, destination)),
    fetchAssetInfo: () => dispatch(loadAssetViewAc(ownProps.params, 'DataRoom')),
    shareRecords: (data, status, hours) => dispatch(shareRecordsAc(ownProps.params,data,status,hours)),
    fetchDirTree: (params, filters) => dispatch(dirTreeAc(params, filters)),
    fetchDirTreeExpand: (params, filters) => dispatch(dirTreeExpandAc(params, filters)),
    addFileToMiniPackBulk: (category, checkedData, uuid) => dispatch(addFileToMiniPackBulkAc(ownProps.params, category, checkedData, uuid)),
    addFileToCategoryBulk: (category, checkedData) => dispatch(addFileToCategoryBulkAc(ownProps.params, category, checkedData)),
    addFileToCategory: (category, uuid) => dispatch(addFileToCategoryAc(ownProps.params, category, uuid)),
    getValidatedFiles: (files, destination, assetInfo, navigation) => dispatch(getValidatedFilesAc(ownProps.params, files, destination, assetInfo, navigation)),
    fetchTechConstant : (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
    downloadMultipleFile:(checkedData,msn)=>dispatch(downloadBulkOperationAC(ownProps.params,checkedData,msn)),
    deleteFileFolder:(checkedData)=>dispatch(deleteBulkRecordAc(ownProps.params,checkedData)),
    shareUserEmails:(ids,folderUUid,emails,flag)=> dispatch(shareUserEmailsAc(ids,folderUUid,emails,flag)),
    cancelCheckFileFolder:()=>dispatch({
      type: actions.CANCEL_FILE_FOLDER_CHECK,
      payload:[],
    }),
    emptyUploadList: () => dispatch({
      type: actions.FILES_UPLOAD,
      payload: [],
    }),
    discardUpload: () => dispatch({
      type: actions.GET_VALIDATED_FILES,
      payload: [],
    }),
    getShareKey: (shareKey, shareValidity) => dispatch({
      type: actions.SHARE_KEY,
      payload: {shareKey,shareValidity},
    }),
    fileVerifyLoader: (flag) => dispatch({
      type: actions.DOWNLOAD_LOADER,
      payload: flag
    }),
    toggleAiSupportFolders: (flag) => dispatch({
      type: actions.AI_SUPPORTED_FOLDERS_FLAG,
      payload: flag
    }),
    updateSelectedEmails: (chips) => dispatch({
      type: actions.UPDATE_SELECTED_EMAILS,
      payload: chips
    }),
    updateSelectedPermission: (flag) => dispatch({
      type: actions.UPDATE_SELECTED_PERMISSION,
      payload: flag
    }),
    changeActionDockStatus : (fileObject) => dispatch(changeActionDockStatusAc(ownProps.params, fileObject)),
    changeActionDockBulkStatus : (fileObject) => dispatch(changeActionDockBulkStatusAc(ownProps.params, fileObject)),
    createNewFolder:(folderName, filters) => dispatch(createNewFolderAc(ownProps, folderName, filters)),
    getRecordDetails: (record) => dispatch(getRecordDetailsAc(ownProps.params, record)),
    renameRecord:(record, sortType) => dispatch(renameRecordAc(ownProps.params, record, sortType)),
    getAiSupportedFolders:() => dispatch(getAiSupportedFoldersAc(ownProps.params)),
    usageDetail:() => dispatch(usageDetailAc()),
    buyLicence: (modalObj) => dispatch({
      type: BUY_LICENCE,
      payload: modalObj
    }),
    bulkPopupToggle:(flag) => dispatch({
      type: actions.BULK_DOWNLOAD_POPUP,
      payload: flag
    }),
    getUsersUsage: () => dispatch(getUsersUsageAc()),
    getMiniPackFolders: () => dispatch(getMiniPackFoldersAc(ownProps.params)),
    captureActivityLog: (uuid) => dispatch(captureActivityLogAc(uuid)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(DataRoom,['records','data_room','R'])))

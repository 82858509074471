import React, {Component} from 'react';
import { connect } from 'react-redux';
import '../../assets/styles/MRRate.scss';
import { Header, ContractHeadertext, EditSidebar,CtrTableHd, ContractSideBar, FormModeType } from '../../Elements';
import { ListLoader, NoRecordFound } from '../../../../shared';
import { contrQfdMntEventListAc, addEditQfdMntEventAc, reseverAcntListAc, qfdMaintenanceEventCrudAc } from '../actionCreators';
import AddEditQfdEvent from '../components/AddEditQfdEvent';
import QfdMntEventRow from '../components/QfdMntEventRow';
import { QFT_MNT_COND_CRUD, TOGGLE_VIEW_EDIT } from '../actions';
import { sExportContractsAc, sAuditLogAc } from '../../../../shared/actionCreators';
import { S_AUDITLOG_ADD_DEL_VIEW } from '../../../../shared/actions';
import AddDeleteAudit from '../../../../shared/components/AddDeleteAudit';
import UpdateAuditLog from '../../../../shared/components/UpdateAuditLog';
import { withRouter, browserHistory, Link } from 'react-router';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { getLocalStorageInfo } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel';
import { assetsType } from '../../../../constants';
import backArrowIcon from '../../../../shared/assets/img/back_arrow.svg';
class QualifiedMaintenance extends Component {
  constructor(props){
    super(props);
    this.state = {}
  }
  componentDidMount(){
    this.props.fetchQfdMntEventList();
    this.props.fetchReserveAcntList();
  }

  changeMsn =(evt) =>{
    if(evt.contracts == false){
      let url = '/asset-view/'+assetsType[evt.asset_type]+'/'+evt.slug
      browserHistory.push(url)
    }else if(evt.contract_slug == null || evt.contract_slug == undefined || evt.contract_slug == ''){
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
      browserHistory.push(url);
    }else{
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contract/'+evt.contract_slug+'/qualified-maintenance';
      browserHistory.push(url);
      this.props.params.type = assetsType[evt.asset_type];
      this.props.params.aircraft_slug = evt.slug;
      this.props.params.contract_slug = evt.contract_slug;
      this.componentDidMount();
    }
  }

  render() {
    const { qfdMntEventList, reserveAcntList, qfdMntEventLoader, qfdMntEventCrud, auditLogView, assetViewId, params } = this.props;
    let assetTypeNames = {
      3: 'APU',
      4: 'LG',
      5: 'PROPELLER'
    }
    return (
      <div className="contract-container">
        <Header type="brief" headingText="Lease Management" params= {this.props.params} pageTitle="Qualified Event Events" changeMsn={this.changeMsn} exportContract={this.props.exportContract}/>
        <ContractSideBar application={`Qualified Maintenance Events`} type="brief" params= {this.props.params} />
        <div className="contract-content-blk">
          <Link to={"/" +params.type+ "/" +params.aircraft_slug+ "/contracts"} style={{color:'#bd10e0', fontSize:'16px', fontWeight:'700'}}>
            <img width="16" src={backArrowIcon} alt="Back Icon" style={{marginRight:'3px'}}/> Back to Lease Details
          </Link>
          <ContractHeadertext
            title="Qualified Maintenance Events"
            desc="Section holds qualified maintenance events under the Lease"
            addNew = {getLocalStorageInfo().user.permission['contracts']['qualified_maintenance_event'].indexOf('C') != -1 ? '+ Add Qualified Maintenance Event':''}
            toggleAddEvent={() => this.props.qfdMaintenanceEventCrud('add', {})}
            addAudit= {auditLogView ?  "Revision History" : null}
            toggleAuditlog = {() => {this.props.toggleAuditlogFn('contracts', 'ctqualifiedmaintenanceevents', 0, null, 'add', this.props.params.contract_slug, 'contract'); trackActivity('Revision History', { page_title: 'Qualified Maintenance Event', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.params.contract_slug}`, item_type: 'Qualified Maintenance Event'})}}

        />
          <div className="list-container">
             { qfdMntEventLoader ? <ListLoader />:null }
             <div className="table-container">
               <table>
                 <CtrTableHd theads={['Maintenance Reserve Account', 'Event', 'Qualified items', 'Validity']}/>
                 <tbody>
                   {qfdMntEventList.map((qfdMntEvent, index) =>
                     <QfdMntEventRow key={index} qfdMntEvent={qfdMntEvent} viewQfdMntEvent={() => this.props.qfdMaintenanceEventCrud('view', qfdMntEvent.id)} />)
                   }
                 </tbody>
               </table>
               {!qfdMntEventLoader && !qfdMntEventList.length ?
                 <NoRecordFound description="Get Started by clicking '+ Add Qualified Maintenance Event' button above" />:null
                }
             </div>
          </div>
        </div>
        <EditSidebar
         width="560px" right="-560px"
          title={
            qfdMntEventCrud.type != '' ?
            (qfdMntEventCrud.type == 'add') ? 'Add Qualified Maintenance Event':
            (qfdMntEventCrud.type == 'edit') ? 'Edit Qualified Maintenance Event': 'View Qualified Maintenance Event' : ''
            }
          editSideBar={qfdMntEventCrud.flag}
          toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}
          >
          {
            Object.keys(qfdMntEventCrud.data).length && qfdMntEventCrud.flag ?
            <div>
              <FormModeType
                auditLog={true}
                auditLogMode={()=>{this.props.toggleAuditlogFn('contracts', 'ctqualifiedmaintenanceevents', 1, qfdMntEventCrud.data.id, 'update'); trackActivity('Revision History', { page_title: 'Qualified Maintenance Events', application_module: 'Contracts', asset: assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${this.props.params.contract_slug}`, item_type: 'Qualified Maintenance Events', item_id:qfdMntEventCrud.data.id})}}
                type={qfdMntEventCrud.type}
                changeViewMode={()=>this.props.changeViewMode(qfdMntEventCrud.type)}
                editAble = {getLocalStorageInfo().user.permission['contracts']['qualified_maintenance_event'].indexOf('U') != -1 ? true:false}
              />
              <AddEditQfdEvent
                type={qfdMntEventCrud.type}
                reserveAcntList={reserveAcntList}
                qfdMntEventObj={qfdMntEventCrud.data}
                addEditQfdMntEvent={(data, type)=>this.props.addEditQfdMntEvent(data, type, assetViewId)}
                toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false})}
                qfdMntEventLoader={qfdMntEventLoader}
              />
            </div>:null
          }
        </EditSidebar>


           <EditSidebar
           width="560px" right="-560px"
          title="Revision History"
          editSideBar={auditLogView.flag}
          toggleEditSideBar={() => this.props.closeAuditSidebar({type: 'add', data: {}, flag: false})}
          >
           {
            Object.keys(auditLogView.data).length && auditLogView.flag ?
            <div>
                <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'20px',  padding: '5px 22px 5px 25px' }}>
               {
                 auditLogView.type == "add" ?
                  auditLogView.data.map((item, index) =>
                   <AddDeleteAudit auditLogView={item} key={index}/>
               ) :
               auditLogView.data.map((item, index) =>
                   <UpdateAuditLog auditLogView={item} key={index}/>
                )
               }
            </div>
            </div>
            : <NoRecordFound title="No Revision History Found"/>
           }
        </EditSidebar>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  qfdMntEventList: state.ContrQfdMntReducer.qfdMntEventList,
  qfdMntEventLoader: state.ContrQfdMntReducer.qfdMntEventLoader,
  reserveAcntList: state.ContrQfdMntReducer.reserveAcntList,
  qfdMntEventCrud: state.ContrQfdMntReducer.qfdMntEventCrud,
  auditLogView: state.sharedReducers.auditLogView,
  assetViewId: state.userAssetReducer.assetViewId
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchQfdMntEventList : () => dispatch(contrQfdMntEventListAc(ownProps.params)),
    addEditQfdMntEvent: (data, type, assetViewId) => dispatch(addEditQfdMntEventAc(ownProps.params, data, type, assetViewId)),
    fetchReserveAcntList: () => dispatch(reseverAcntListAc(ownProps.params)),
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
    toggleAuditlogFn: (application, content_type, action, objectId, type, paramValue, paramKey, workingGroup) => dispatch(sAuditLogAc(application, content_type, action, objectId, type, paramValue, paramKey, workingGroup)),
    toggleEditBar: (data) => dispatch({
      type: QFT_MNT_COND_CRUD,
      payload: data
    }),
    changeViewMode: (type) => dispatch({
      type: TOGGLE_VIEW_EDIT,
      payload: type
    }),
    closeAuditSidebar: (data) => dispatch({
      type: S_AUDITLOG_ADD_DEL_VIEW,
      payload: data
    }),
    qfdMaintenanceEventCrud: (type, id) => dispatch(qfdMaintenanceEventCrudAc(ownProps.params, type, id)),
  }
}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(RoleBasedAccess(QualifiedMaintenance, ['contracts', 'qualified_maintenance_event' ,'R'])));

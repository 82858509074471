export const roleHd = [
    {label:'Role Type', sortKey: ''},
    {label:'User', sortKey: ''},
    {label:'Action', sortKey: '' },
  ];
  export const permissionHd = [
    {label:'View', sortKey: ''},
    {label:'Publish', sortKey: ''},
    {label:'Reject', sortKey: '' },
    {label:'Restore', sortKey: '' },
    {label:'Rename', sortKey: '' },
    {label:'Trash', sortKey: '' },
    {label:'Download', sortKey: '' },
    {label:'Upload', sortKey: '' },
    {label:'Share', sortKey: '' },
    {label:'Export', sortKey: '' },
    {label:'More', sortKey: '' },
  ];
  export const userHd = [
    {label:'Name', sortKey: ''},
    {label:'Email', sortKey: ''},
    {label:'Designation', sortKey: '' },
  ];
import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import NumericLabel from 'react-pretty-numbers';
import moment from 'moment';
import InvCol from './InvCol';
import ChangeInvoiceStatus from './ChangeInvoiceStatus';
import { dateTransform, showCurrencyFormat, getLocalStorageInfo }from '../../../../utils';
import { DeleteModal } from  '../../../technicalInspection/Elements';
import { invoiceStatusList, invoiceDescList, imgStoragePath, displayDateFormatShort } from '../../../../constants';
export default class InvoiceList extends Component{
  constructor(props){
    super(props);
    this.state = {
      modal: false,
      delModal:false,
      data:{}
    };
  }
  changeStatus = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.toggle();

  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal,

    });
  }
  toggleDelModal(data) {
    this.setState({
      delModal: !this.state.delModal,
      data:data,
    });
  }
  updateInvStatus = (data) => {
    this.toggle();
    this.toggle();
    if(data.status==3||data.status==4){
      this.toggleDelModal(data);
    }else{
      this.props.updateListInvStatus(data, this.props.contract_slug, this.props.invoice.id, 'list');
    }
  }
  render(){
    const { invoice,params,contract_slug, ctrShortInfo } = this.props;
    const invoiceType = {
      1: 'Maintenance',
      2: 'Rental',
      3: 'Penalty',
      4: 'Debit Note',
      5: 'Credit Note'
    }
    return(
      <Link className="asset-list" to={'/' +params.type + '/' + params.aircraft_slug + '/contract/' +contract_slug+ '/invoice/view/' +this.props.invoice.id}>
        <Row>
          <InvCol size="2" sixeXs = "2" value={invoice.invoice_number ? invoice.invoice_number : '--'} />
          <InvCol size="2" sixeXs = "2" value={invoiceDescList[invoice.invoice_type] ? invoiceDescList[invoice.invoice_type]: '--'} />
          <InvCol size="2" sixeXs = "2" value={(invoice.start_date ? moment(invoice.start_date).format(displayDateFormatShort) : '--') + ' to ' + (invoice.end_date ? moment(invoice.end_date).format(displayDateFormatShort) : '--')}/>
          <InvCol size="1" sixeXs = "1" value={invoice.date ? moment(invoice.date).format(displayDateFormatShort) : '--' } />
          <InvCol size="1" sixeXs = "1 " value={invoice.due_date ? moment(invoice.due_date).format(displayDateFormatShort) : '--'} />
          <InvCol size="2" sixeXs = "2" customClass = { (invoice.in_due && invoice.status == 2) ?  'in-due' : null}
            value={invoice.total_amount ?
              invoice.invoice_type==2?
              invoice.children.length>0?
              <div>
              <div>
              <NumericLabel params={showCurrencyFormat(invoice.currency)}>{invoice.total_amount}</NumericLabel>
              </div>
              <NumericLabel params={showCurrencyFormat(invoice.children[0].currency)}>{invoice.children[0].sub_total}</NumericLabel>
              </div>
              :
              <NumericLabel params={showCurrencyFormat(invoice.currency)}>{invoice.total_amount}</NumericLabel>
              :
              <NumericLabel params={showCurrencyFormat(invoice.currency)}>{invoice.total_amount}</NumericLabel>
             : '--' }
          />
          <InvCol size="2" sixeXs = "2"
            value={
              <div class="flex-not-centered">
              <div>
              <p>
                  {invoice.status == '3' || invoice.status == '4'  ?
                    <span
                    className={invoice.status ?  invoiceStatusList[invoice.status] + ' invoice-status' : 'invoice-status'}>
                   {invoice.status ? invoiceStatusList[invoice.status] : '-- '}
                   { invoice.status == 1 || invoice.status == 2 || invoice.status == 5  ?
                     <i style={{float: 'right'}} className="fa fa-caret-down" onClick={(e)=> {
                         if(getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.contracts && getLocalStorageInfo().user.permission.contracts.invoices && (getLocalStorageInfo().user.permission.contracts.invoices.indexOf('U') !== -1 || getLocalStorageInfo().user.permission.contracts.invoices.indexOf('C') !== -1)){
                          this.changeStatus(e)
                         }
                       } }></i>:null
                   }
                 </span> :
                 getLocalStorageInfo().user.permission['contracts']['insurance'].indexOf('C') != -1 ?
                 <span
                 onClick={(e)=> {if(getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.contracts && getLocalStorageInfo().user.permission.contracts.invoices && (getLocalStorageInfo().user.permission.contracts.invoices.indexOf('U') !== -1 || getLocalStorageInfo().user.permission.contracts.invoices.indexOf('C') !== -1)){
                  this.changeStatus(e)
                 }} }
                 className={invoice.status ?  invoiceStatusList[invoice.status] + ' invoice-status' : 'invoice-status'}>
                {invoice.status ? invoiceStatusList[invoice.status] : '-- '}
                { invoice.status == 1 || invoice.status == 2 || invoice.status == 5  ?
                  <i style={{float: 'right'}} className="fa fa-caret-down" onClick={(e)=> {if(getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.contracts && getLocalStorageInfo().user.permission.contracts.invoices && (getLocalStorageInfo().user.permission.contracts.invoices.indexOf('U') !== -1 || getLocalStorageInfo().user.permission.contracts.invoices.indexOf('C') !== -1)){
                   this.changeStatus(e)
                  }} }></i>:null
                }
              </span>:<span
              className={invoice.status ?  invoiceStatusList[invoice.status] + ' invoice-status' : 'invoice-status'}>
             {invoice.status ? invoiceStatusList[invoice.status] : '-- '}
             { invoice.status == 1 || invoice.status == 2 || invoice.status == 5  ?
               <i style={{float: 'right'}} className="fa fa-caret-down" onClick={(e)=> {if(getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.contracts && getLocalStorageInfo().user.permission.contracts.invoices && (getLocalStorageInfo().user.permission.contracts.invoices.indexOf('U') !== -1 || getLocalStorageInfo().user.permission.contracts.invoices.indexOf('C') !== -1)){
                this.changeStatus(e)
               }} }></i>:null
             }
           </span>
                  }
                  <br/>
                  <span className="overdue-text" style={{ display: 'block', textAlign:'left', marginTop:'4px'}}>
                    { ((invoice.status == 2) && invoice.in_due) ? 'Overdue By ' + dateTransform(invoice.due_date)  : ''}
                  </span>
                  {
                    invoice.status == 5 && invoice.partial_paid_date ?
                    <span style={{display:'block', marginTop:'4px', textAlign:'left', textTransform:'none'}}>
                      Payment Date: {moment(invoice.partial_paid_date).format('MMM DD,YYYY')}
                      {moment(invoice.partial_paid_date).diff(moment(invoice.due_date), 'days') > 0 ? <span style={{display:'block', textAlign:'left', marginTop:'4px', color:'#ff0000', fontSize:'9px', textTransform:'none'}} >{"Payment late by " + dateTransform(invoice.due_date)}</span>:''}
                    </span> : null
                  }
                  {
                    invoice.status == 3 && invoice.paid_date ?
                    <span style={{ display: 'block', marginTop:'4px', textAlign:'left', textTransform: 'none'}}>
                      Payment Date: {moment(invoice.paid_date).format(displayDateFormatShort)}
                      {moment(invoice.paid_date).diff(moment(invoice.due_date), 'days') > 0 ? <span style={{ display: 'block', textAlign:'left', marginTop:'4px', color: '#ff0000', fontSize: '9px', textTransform: 'none'}}>{"Payment late by " + dateTransform(invoice.due_date, invoice.paid_date)}</span> :''}
                    </span>:
                    null
                  }
              </p>
              </div>
              </div>
              }
          />

        </Row>
        <DeleteModal isOpen={this.state.delModal} toggle={() => this.toggleDelModal('')} title={"Change Status"} className="modal-tform-delete-pictures modal-dialog-centered">
          <p style={{ textAlign:'center', marginBottom:'15px'}}>Are you sure, you want to mark this invoice as {this.state.data&&this.state.data.status==3?"'paid' ?":"'cancelled' ?"}</p>
          <ul className="list-inline">
            <li className="list-inline-item">
              <button type="button" className="btn btn-danger" onClick={() => {this.toggleDelModal(''); this.props.updateListInvStatus(this.state.data, this.props.contract_slug, this.props.invoice.id, 'list');}}>Yes</button>
            </li>
            <li className="list-inline-item">
              <button type="button" className="btn btn-primary" onClick={() => this.toggleDelModal('')}>No</button>
            </li>
          </ul>
        </DeleteModal>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className="invoice-status-modal">
          <ModalHeader toggle={this.toggle}>Change Invoice Status</ModalHeader>
          <ModalBody>
            { this.state.modal ?
              <ChangeInvoiceStatus
                status={invoice.status}
                updateInvStatus={this.updateInvStatus}
                invoiceType={invoice.invoice_type}
                invoiceStartDate={ctrShortInfo.contract.contractual_start_date}
                invoiceEndDate={ctrShortInfo.contract.contractual_end_date}
                totalAmount={invoice.sub_total}
              />:null
            }
          </ModalBody>
        </Modal>

      </Link>
    )
  }
}

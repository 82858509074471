import React from 'react';
import checkCircleIcon from '../../../shared/assets/img/check_circle_icon.svg';
import cancelIcon from '../../../shared/assets/img/cancel_icon.svg';
import blockIcon from '../../../shared/assets/img/block_icon.svg';
const Permissions = ({name, permissions, permission_headers}) => {
  return (
    <tr style={{cursor: 'pointer'}}>
      <td><h4 style={{fontSize:'16px'}}>{name}</h4></td>
      {
        permission_headers.map(per =>
          permissions.true.indexOf(per) !== -1 ?
          <td>
            <img width="18" src={checkCircleIcon} alt="Icon"/>
          </td>
          :permissions.false.indexOf(per) !== -1 ?
            <td><img width="18" src={cancelIcon} alt="Icon"/></td>
          :<td><img width="18" src={blockIcon} alt="Icon"/></td>
        )
      }
      <td>More</td>
    </tr>
  )
}
export default Permissions;

import React, {Component} from 'react';
import { Row, Col, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import ReactDOM from "react-dom";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import { prettyNumberParamsL, displayDateFormat, monthsObj } from '../../../../constants';
import { removeEmptyKey, getCookie } from '../../../../utils';
import { FIXED_POSITIONS_VALUE } from '../../../../shared/actions';
import { Link } from 'react-router';
import '../../../../shared/assets/styles/CommonHeaderBlock.scss';
import { imgStoragePath } from '../../../../constants';

 class CashFlowModal extends Component{
  constructor(props){
    super(props);
    this.state = {
      cashFlow: [],
      perPage: 40,
      currentPage: 1,
      totalCount: 0,
      fixedBar: true,
      headerfixPostion: 0,
      resultCopy: [],
      years: [],
      dist: 0,
      headerheight: 0,
      nameFilter:[],
      contrctheaderheight: 0,
      filter: {
        month: '',
        name: ''
      }
    }
  }
  componentDidMount(){
    let data = JSON.parse(JSON.stringify(this.props.cashFlow));
    let years = [];
    this.unique(data,['name']);
    data = data.sort((a,b) => parseInt(a.month) - parseInt(b.month)).slice(0,this.state.perPage);
    let startYear = parseInt(this.props.cashFlow[0].month.slice(0,4));
    let endYear = parseInt(this.props.cashFlow[this.props.cashFlow.length -1].month.slice(0,4));

    let cookieVal = getCookie();
    let maintenanceHeight = document.body.contains(document.getElementById('maintenance')) ? document.getElementById('maintenance').clientHeight : 0 ;
    let contractHeaderHeight = document.body.contains(document.getElementById('contract-header')) ? document.getElementById('contract-header').clientHeight : 0;
    let headerHeight = document.body.contains(document.getElementById('header')) ? document.getElementById('header').clientHeight : 0 ;
    let cashflowHeadHt = document.body.contains(document.getElementById('maintainence-gen-blk')) ? document.getElementById('maintainence-gen-blk').clientHeight : 0;

    while(startYear <= endYear){
      years.push({label: startYear, value: startYear});
      startYear += 1;
    }
    this.setState(prevState => ({
      ...prevState,
      cashFlow: data,
      resultCopy: this.props.cashFlow,
      currentPage: 1,
      totalCount: this.props.cashFlow.length,
      years: years,
      dist:   maintenanceHeight + contractHeaderHeight + cashflowHeadHt + 10,
      headerheight: headerHeight,
      contrctheaderheight: contractHeaderHeight
    }));
    ReactDOM.findDOMNode(this.refs.category_scroll).addEventListener('scroll', this.handleScroll);



    if(cookieVal){
			this.props.fixedPositionFn('closeMaintenance',false);
      this.props.fixedPositionFn('maintenanceBarHeight',0);
      this.props.fixedPositionFn('subHeaderTop', headerHeight);
      this.props.fixedPositionFn('recordNavTop', contractHeaderHeight);

    }else{
			this.props.fixedPositionFn('closeMaintenance',true);
      this.props.fixedPositionFn('maintenanceBarHeight',maintenanceHeight);
      this.props.fixedPositionFn('subHeaderTop',maintenanceHeight + headerHeight);
      this.props.fixedPositionFn('recordNavTop', contractHeaderHeight);

    }
  }
  componentWillUnmount(){
    //window.removeEventListener('scroll', this.handleScroll);
    ReactDOM.findDOMNode(this.refs.category_scroll).removeEventListener('scroll', this.handleScroll);
  }

  unique(arr, keyProps) {
    const kvArray = arr.map(entry => {
     const key = keyProps.map(k => entry[k]).join('|');
     return [key, entry];
    });
    const map = new Map(kvArray);
    let filter =[];
    Array.from(map.values()).map(data=>{
      filter.push({label: data.name, value: data.name});
    }
    )
    this.setState({
      nameFilter:filter ,
    });
   }

  filterFn = (filterParams, data) => {
    if(Object.keys(filterParams).length === 0)
    {
      let data = JSON.parse(JSON.stringify(this.props.cashFlow));
      data = data.sort((a,b) => parseInt(a.month) - parseInt(b.month));
      return data;
    }
    return data.filter(cashflow => {
      for (var key in filterParams) {
        let value = cashflow[key];
        if(key == 'month'){
          value = value.slice(0,4);
        }
        if (value === undefined || value != filterParams[key]){
          return false;
        }
      }
      return true;
    });
  }
  handleScroll = () => {
    let scrollData = [];
    const d = document.getElementById('monthly-cashflow');
    const f = document.getElementById('monthly-cashflow-list');
    const offset = d.scrollTop + 500;
    const height = f.offsetHeight;
    if (height - 100 < offset) {
      if((this.state.currentPage*this.state.perPage) < this.state.resultCopy.length){
        scrollData = JSON.parse(JSON.stringify(this.state.resultCopy));
        scrollData = scrollData.sort((a,b) => parseInt(a.month) - parseInt(b.month)).slice((this.state.currentPage*this.state.perPage), (this.state.currentPage*this.state.perPage)+this.state.perPage);
        this.setState(prevState => ({
          ...prevState,
          cashFlow: [...prevState.cashFlow, ...scrollData ],
          currentPage: (prevState.currentPage + 1)
        }));
      }
    }

  }

  filterChange = (value, type) => {
    let filter = removeEmptyKey({
      month: type == 'month' ? value : this.state.filter.month,
      name: type == 'name' ? value:this.state.filter.name,
    });
    let cf = this.filterFn(filter, JSON.parse(JSON.stringify(this.props.cashFlow))).
    sort((a,b) => parseInt(a.month) - parseInt(b.month)).slice(0,40);
    this.setState(prevState => ({
      ...prevState,
      cashFlow: cf,
      resultCopy:this.filterFn(filter, JSON.parse(JSON.stringify(this.props.cashFlow))).sort((a,b) => parseInt(a.month) - parseInt(b.month)),
      currentPage: 1,
      filter: {
        ...prevState.filter,
        [type] : value
      }
    }));
  }
  getmonthsTotal = (month) => {
    let total =  this.state.cashFlow.filter(item => item.month == month).reduce((sum, list) => {
      return (sum + list.credit - list.debit)
    } , 0);
    return (
      <p>
        <p style={{fontSize: '11px', color: '#8c9ba5'}}>Monthly Cash Outflow Total</p>
        <NumericLabel params={prettyNumberParamsL}>
          {total}
        </NumericLabel>
      </p>
    )
  }
  downloadGraph=()=>{
    let filter = removeEmptyKey(this.state.filter);
    this.props.downloadGraphPostMonthly( Object.keys(filter).length ?filter:{})
  }

  render(){
    const { cashFlow, perPage, currentPage,nameFilter, totalCount, years, headerfixPostion } = this.state;
    return(
    <div className="reviewFinanceRpt-blk">
        <ModalHeader className="generate-finance-heading" id="maintainence-gen-blk" style={{marginLeft:'-15px',padding:'0px',marginTop:'-16px',boxShadow:'none'}}>
          <h2 className="beta gengraph-text" style={{marginLeft:'10px',marginBottom:'0px',marginTop:'14px'}}>
            <Link >Monthly Cash Flow Report</Link>
            <span onClick={() => this.props.downloadGraphPostMonthly(this.state.filter)}><img width="12" src={imgStoragePath+"export_icon.png"}  alt="export"/>Export</span>
          </h2>
          <span  style={{marginRight:'10px',float:'right',marginTop:'14px',cursor:'pointer', position:"absolute", right:"1px"}} onClick={() => this.props.monthlytoggleModal(false)}><img width="12" src={imgStoragePath+"black_close.png"}  alt="close"/></span>
        </ModalHeader>
      <div className="mobile-asset-list" id="monthly-cashflow" style={{maxHeight:'500px',overflow:'scroll',marginTop:0}} onScroll={this.handleScroll.bind(this)} ref="category_scroll" >
        <div className="asset-listing-wrap cashflow" style={{margin:'0 0 0 10px'}} id="monthly-cashflow-list" >
        <div className={ this.state.fixedBar ? 'asset-list-filter-sort asset-list-fixed' : 'asset-list-filter-sort'} style={ this.state.fixedBar ? {   zIndex: 999,width:'100%' }: null}>
          <Row className=" add-filter-type">
            <Col md="12">
              <ul className="list-inline cash-list-filter flex-centered">
                <li className="list-inline-item year-blk">
                    <Select
                      name="form-field-name"
                      value={this.state.filter.month}
                      placeholder="Select Year"
                      onChange={(target) => this.filterChange(target?target.value: '', 'month')}
                      options={years}
                      className="custom-select-block"
                      style={{position: "relative", top:"-4px"}}
                    />
                </li>
                <li className="list-inline-item reserve-blk">
                  <Select
                    name="form-field-name"
                    value={this.state.filter.name}
                    placeholder="Maintenance Reserve Account"
                    onChange={(target) => this.filterChange(target?target.value: '', 'name')}
                    options={nameFilter}
                    className="custom-select-block"
                    style={{position: "relative", top:"-4px"}}
                  />
                </li>
              </ul>
            </Col>
          </Row>
          <Row className="filter-block" style={{width:'510'}}>
            <Col><h6>Month</h6></Col>
            <Col><h6>Maintenance Reserve Account</h6></Col>
            <Col><h6>Credit</h6></Col>
            <Col><h6>Debit</h6></Col>
            <Col><h6>Balance</h6></Col>
          </Row>
        </div>
        {
          cashFlow.map((monthlyEvent,index) =>
          <div className="asset-list"  key={index}>
            <Row className="flex-not-centered cashflow-row">
              <Col className={ index != 0 ? cashFlow[index].month != cashFlow[index-1].month ? 'para-ui month-block ' :' ' : 'para-ui month-block ' }  >
                {index != 0 ? cashFlow[index].month != cashFlow[index-1].month ? monthsObj[monthlyEvent.month.slice(4,6)] + ' ' + monthlyEvent.month.slice(0,4):'' : monthsObj[monthlyEvent.month.slice(4,6)] + ' ' + monthlyEvent.month.slice(0,4)}
                {index != 0 ? cashFlow[index].month != cashFlow[index-1].month ? this.getmonthsTotal(monthlyEvent.month):'' : this.getmonthsTotal(monthlyEvent.month)}

              </Col>
              <Col className="cash-blk cash-info border-col para-ui flex-centered">
                {monthlyEvent.name}
                {
                  monthlyEvent.date ?
                  <p className="event-date">{'Event On: '+ moment(monthlyEvent.date).format(displayDateFormat)}</p>:null
                }
              </Col>
              <Col className="cash-blk cash-info para-ui flex-centered">
                <NumericLabel params={prettyNumberParamsL}>
                  {monthlyEvent.credit}
                </NumericLabel>
              </Col>
              <Col className="cash-blk cash-info para-ui flex-centered">
                <NumericLabel params={prettyNumberParamsL}>
                  {monthlyEvent.debit}
                </NumericLabel>
              </Col>
              <Col className="cash-blk cash-info para-ui flex-centered">
                <NumericLabel params={prettyNumberParamsL}>
                  {monthlyEvent.balance}
                </NumericLabel>
              </Col>
            </Row>
          </div>
          )
        }
      </div>
      </div>
      </div>

    )
  }
}

const mapStateToProps = state => ({

  closeMaintenance: state.sharedReducers.closeMaintenance,
  maintenanceBarHeight: state.sharedReducers.maintenanceBarHeight,
  subHeaderTop: state.sharedReducers.subHeaderTop,
  recordNavTop: state.sharedReducers.recordNavTop,
  contractHeaderHeight : state.sharedReducers.contractHeaderHeight
});
const mapDispatchToProps = (dispatch, ownProps ) => {
	return {

		fixedPositionFn: (type, position) => dispatch({
      type: FIXED_POSITIONS_VALUE,
      payload: {type: type, position: position}
    })
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(CashFlowModal);

import React , { Component } from 'react';
import { Col } from 'reactstrap';
import VertCardLabel from './VertCardLabel';
import { getLocalStorageInfo } from '../../../../utils';
import { imgStoragePath } from '../../../../constants';
import editIcon from '../../../../shared/assets/img/edit_icon.svg';
export default class LesseeCard extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { lesseeInfo } = this.props;
    return(
      <Col xs="12" md="6" >
        <div className="vertical-card card-block">
          <h5 className="para"> Lessee
            {getLocalStorageInfo().user.permission['contracts']['headlines'].indexOf('U') != -1 ?
              <img onClick = {this.props.toggleEditSideBar} width="16" className="editIcon" src={editIcon} alt="img"/>:null
            }

          </h5>
          <ul className="list-unstyled">
            <VertCardLabel label="Name" value={lesseeInfo.lessee && lesseeInfo.lessee.name ? lesseeInfo.lessee.name : '-'} />
            <VertCardLabel label="Jurisdiction" value={lesseeInfo.lessee_jurisdiction ? lesseeInfo.lessee_jurisdiction : '-'} />
            <VertCardLabel label="Habitual Base" value={lesseeInfo.habitual_base ? lesseeInfo.habitual_base: '-' } />
            <VertCardLabel label="Contact Address" value={lesseeInfo.lessee_contact_address ? lesseeInfo.lessee_contact_address : '-'} />
            <VertCardLabel label="Contact (Email and Phone)" value={lesseeInfo.lessee_contact_details ? lesseeInfo.lessee_contact_details : '-'} />
            <VertCardLabel label="Contractual Notice Address" value={lesseeInfo.lessee_contractual_notice_address ? lesseeInfo.lessee_contractual_notice_address : '-'} />
             {
               lesseeInfo.same_operator_lessee ?
               <li className="flex-centered operator-same">
                 <img width="16" src={imgStoragePath+"icon_tick.png"} alt="img"/>
                 <span>Operator is same as Lessee</span>
               </li>:null
             }
           </ul>
         </div>
      </Col>
     )
  }
}

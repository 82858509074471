import React, { Component } from 'react';
import { Link } from 'react-router';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate } from '../../Elements';
import { checkString, checkValidDate, checkIntNumber,checkDateDiffText, checkNumber } from '../../../../formValidator';
import { ToolTipHover, InfoIcon } from '../../../../shared';
import { mrRangeCondition, imgStoragePath } from '../../../../constants';
import { Row, Col } from 'reactstrap';
import moment from 'moment'
const ratioRange = { min_ratio: 0, ratio_range: '', max_ratio: 0, mr_rate: 0, remarks: '' };
const adjustment = { ratio: 0,adjustment_factor: 0};
export default class EditMRRateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mrRate: {},
      inflationList:[],
      validationInflationArray:[],
      validationIntervalArray:[],
      mrRationRange: [],
      error: {}
    }
  }
  componentDidMount() {
    let data = Object.assign({}, this.props.mrRatesView);
    let conditions = this.props.techConstants.filter(item => item.type=="mr_rate_range_condition")
    data = {
      ...data,
      interval_condition: this.props.mrRatesView.interval_condition.length ? this.props.mrRatesView.interval_condition.map(item => {
        let innerItem = item
        innerItem = {
          ...innerItem,
          remarks: conditions.filter(condition => condition.value === item.ratio_range).length ? conditions.filter(condition => condition.value === item.ratio_range)[0].remarks : ''
        }
        return innerItem
      }) : [{ min_ratio: 0, ratio_range: '', max_ratio: 0, mr_rate: 0 }],
      adjustment_factor: this.props.mrRatesView.adjustment_factor ? this.props.mrRatesView.adjustment_factor.length ? this.props.mrRatesView.adjustment_factor:[{ ratio: 0, adjustment_factor: 0}]:[{ ratio: 0, adjustment_factor: 0}],
      interval: this.props.mrRatesView.interval && this.props.mrRatesView.interval.value ? this.props.mrRatesView.interval.value : '',
      interval_name: this.props.mrRatesView.interval && this.props.mrRatesView.interval.label ? this.props.mrRatesView.interval.label : '',
      rate_escalation_interval: this.props.mrRatesView.rate_escalation_interval && this.props.mrRatesView.rate_escalation_interval.value ? this.props.mrRatesView.rate_escalation_interval.value : '',
      rate_escalation_interval_name: this.props.mrRatesView.rate_escalation_interval && this.props.mrRatesView.rate_escalation_interval.label ? this.props.mrRatesView.rate_escalation_interval.label : '',
    }
    this.setState({
      mrRate: data
    });
  }
  updateForm = (type, value) => {

    if(type=='rate_escalation_start_date'&& value=='Invalid date'){
      value= null;
    }

    if(type=='cost_escalation_percentage'&& value==null){
      value= 0;
    }
    if(type=='rate_escalation_percentage'&& value==null){
      value= 0;
    }
    if(type === 'interval' || type === 'interval_condition_status'){
      if(value !== this.state.mrRate.interval){
        this.setState(prevState => ({
          ...prevState,
          mrRate: {
            ...prevState.mrRate,
            interval_condition: []
          }
        }))
      }
    }
    if (type == 'major_assembly') {
      let asset_id = '';
      let asset_type = '';
      if (value) {
        let asset = this.props.utilsMajorAssembly.filter(assembly => assembly.id == value);
        asset_id = asset[0].asset_id;
        asset_type = asset[0].asset_type;
      }
      this.setState(prevState => ({
        ...prevState,
        mrRate: {
          ...prevState.mrRate,
          asset_id: asset_id,
          asset_type: asset_type,
          major_assembly: value
        },
        error: {
          ...prevState.error,
          [type]: ''
        }
      }))
    } else {
      this.setState(prevState => ({
        ...prevState,
        mrRate: {
          ...prevState.mrRate,
          [type]: value
        },
        error: {
          ...prevState.error,
          [type]: ''
        }
      }))
    }
  }

  addRatio = (type) => {
    this.setState(prevState => ({
      ...prevState,
      mrRate: {
        ...prevState.mrRate,
        interval_condition: [...prevState.mrRate.interval_condition, {...ratioRange, type}]
      }
    }));
  }
  removeRatio = (ratioIndex) => {
    this.setState(prevState => ({
      ...prevState,
      mrRate: {
        ...prevState.mrRate,
        interval_condition: prevState.mrRate.interval_condition.filter((ratio, index) => ratioIndex != index)
      }
    }));
  }
  addAdjustment= () => {
    this.setState(prevState => ({
      ...prevState,
      mrRate: {
        ...prevState.mrRate,
        adjustment_factor: [...prevState.mrRate.adjustment_factor, adjustment]
      }
    }));
  }
  removeAdjustment = (adjustmentIndex) => {
    this.setState(prevState => ({
      ...prevState,
      mrRate: {
        ...prevState.mrRate,
        adjustment_factor: prevState.mrRate.adjustment_factor.filter((factor, index) => adjustmentIndex != index)
      }
    }));
  }
  updateFieldRatio = (ratioIndex, value, type) => {
    if(type === 'ratio_range'){
      let conditions = this.props.techConstants.filter(item => item.type=="mr_rate_range_condition")
      this.setState(prevState => ({
        ...prevState,
        mrRate: {
          ...prevState.mrRate,
          interval_condition: prevState.mrRate.interval_condition.map((ratio, index) => ratioIndex == index ? { ...ratio, min_ratio: '', max_ratio: '', [type]: value, remarks: conditions.filter(condition => condition.value === value).length ? conditions.filter(condition => condition.value === value)[0].remarks : '' } : ratio)
        }
      }))
    }else{
      this.setState(prevState => ({
        ...prevState,
        mrRate: {
          ...prevState.mrRate,
          interval_condition: prevState.mrRate.interval_condition.map((ratio, index) => ratioIndex == index ? { ...ratio, [type]: value } : ratio)
        }
      }))
    }
  }
  updateFieldAdjust = (adjustIndex, value, type) => {
    this.setState(prevState => ({
      ...prevState,
      mrRate: {
        ...prevState.mrRate,
        adjustment_factor: prevState.mrRate.adjustment_factor.map((factor, index) => adjustIndex == index ? { ...factor, [type]: value } : factor)
      }
    }))
  }



  checkChildIntervalMap = () => {
    let vaildArray=[];
    this.state.mrRate.interval_condition.map((interval, index) => {
    let obj={};
    if(interval.remarks && interval.remarks.includes('min')){
      if((checkNumber({value: interval.min_ratio, required: true, minLength: '', maxLength: '', message: 'Please Enter Min ratio'}))==''){
        obj.min_ratio = true;
      }else{
       obj.min_ratio = false;
      }
    }else{
      obj.min_ratio = true
    }
    if((checkString({value: interval.ratio_range, required: true, minLength: '', maxLength: '', message: 'Please Enter Ratio Range'}))==''){
     obj.ratio_range = true;
    }else{
      obj.ratio_range = false;
    }
    if(interval.remarks && interval.remarks.includes('max')){
      if((checkNumber({value: interval.max_ratio, required: true, minLength: '', maxLength: '', message: 'Please Enter Max Ratio'}))==''){
        obj.max_ratio = true;
      }else{
        obj.max_ratio = false;
      }
    }else{
      obj.max_ratio = true;
    }
    if((checkNumber({value: interval.mr_rate, required: true, minLength: '', maxLength: '', message: 'Please Enter MR Rate'}))==''){
      obj.mr_rate = true;
    }else{
      obj.mr_rate = false;
    }
    if((checkNumber({value: interval.min_ratio, required: true, minLength: '', maxLength: '', message: 'Please Enter Min ratio'}))=='' &&
       (checkNumber({value: interval.max_ratio, required: true, minLength: '', maxLength: '', message: 'Please Enter Max Ratio'}))=='' &&
       (checkString({value: interval.ratio_range, required: true, minLength: '', maxLength: '', message: 'Please Enter Ratio Range'}))==''){
      if(this.checkValidationRatio(interval.min_ratio,interval.max_ratio,interval.ratio_range)){
        obj.ratio_range_validation = true;
      }else{
        obj.ratio_range_validation = false;
      }
    }else{
      obj.ratio_range_validation =true;
    }
      vaildArray.push(obj)
    })
      return vaildArray;
    }


    checkValidationRatio=(min,max,type)=>{
      switch (type) {


          case 4:
           if(min<max){
            return true;
           }else{
           return false;
          }

          case 5:
          if(min<max){
            return true;
           }else{
           return false;
          }

          default:
          return true;

      }

    }

    checkChildInflationMap = () => {
      let vaildArray=[];
      this.state.mrRate.adjustment_factor.map((adjustment, index) => {
      let obj={};
      if((checkNumber({value: adjustment.adjustment_factor, required: true, minLength: '', maxLength: '', message: 'Please Enter Adjustment Factor'}))==''){
        obj.adjustment_factor = true;
      }else{
       obj.adjustment_factor = false;
      }
      if((checkNumber({value: adjustment.ratio, required: true, minLength: '', maxLength: '', message: 'Please Enter FC/FH Ratio'}))==''){
       obj.ratio = true;
      }else{
        obj.ratio = false;
      }
        vaildArray.push(obj)
      })
        return vaildArray;
      }



  updateMrRate = (e) => {
    let validInflationArray=[];
    let validIntervalArray=[];
    e.preventDefault();
    const validateNewInput = {
      name: checkString({ value: this.state.mrRate.name, required: true, minLength: '', maxLength: '', message: 'Please Enter Reserve Account Name' }),
      major_assembly: checkString({ value: this.state.mrRate.reserve_rate_per_interval, required: true, minLength: '', maxLength: '', message: 'Please Select Asset' }),
      interval: checkString({ value: this.state.mrRate.interval, required: true, minLength: '', maxLength: '', message: 'Please Enter The Interval' }),
      reserve_rate_per_interval: checkString({ value: this.state.mrRate.reserve_rate_per_interval, required: true, minLength: '', maxLength: '', message: 'Please Enter Reserve Rate Per Interval' }),
      rate_start_date: checkValidDate({ value: this.state.mrRate.rate_start_date, required: true, minLength: '', maxLength: '', message: 'Please Enter Rate Start Date' }),
      rate_end_date: checkValidDate({ value: this.state.mrRate.rate_end_date, required: true, minLength: '', maxLength: '', message: 'Please Enter Rate End Date' }),
      rate_escalation_interval: checkString({ value: this.state.mrRate.rate_escalation_interval, required: this.state.mrRate.is_escalation_mucip ? false:false, minLength: '', maxLength: '', message: 'Please Enter Rate Escalation Interval' }),
      rate_escalation_percentage: checkNumber({ value: this.state.mrRate.rate_escalation_percentage, required: this.state.mrRate.is_escalation_mucip ? false:false, minLength: '', maxLength: '', message: 'Please Enter Rate Escalation Percentage' }),
      starting_fund_date: checkValidDate({ value: this.state.mrRate.starting_fund_date, required: true, minLength: '', maxLength: '', message: 'Please Enter Starting Paid Date' }),
      starting_fund_amount: checkNumber({ value: this.state.mrRate.starting_fund_amount, required: true, minLength: '', maxLength: '', message: 'Please Enter Valid Paid Amount' }),
      rate_end_date: checkDateDiffText({value:this.state.mrRate.rate_start_date,required:true},{value:this.state.mrRate.rate_end_date,required:true},'Rate Start Date','Rate End Date'),
      contractual_factor: checkNumber({ value: this.state.mrRate.contractual_factor, required: this.state.mrRate.is_adjustment_factor ? true:false, minLength: '', maxLength: '', message: 'Please Enter Lease FH/FC Ratio' }),
    };

    if(this.state.mrRate.interval_condition_status){
      validIntervalArray=this.checkChildIntervalMap();

    }
    if(this.state.mrRate.is_adjustment_factor){
      validInflationArray=this.checkChildInflationMap();

    }

    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
    if(this.state.mrRate.interval_condition_status){
    if((validIntervalArray.filter(item => !(item.min_ratio && item.max_ratio && item.ratio_range && item.mr_rate && item.ratio_range_validation))).length==0){
      if (this.props.type == 'add') {
        this.props.toggleEditSideBar();
      }
      this.props.updateMrRate(this.state.mrRate, this.props.type);
     }else{
      this.setState({
        validationIntervalArray: validIntervalArray
      });
    }
    }else if(this.state.mrRate.is_adjustment_factor){
      if((validInflationArray.filter(item => !(item.adjustment_factor && item.ratio ))).length==0){
        if (this.props.type == 'add') {
          this.props.toggleEditSideBar();
        }
        this.props.updateMrRate(this.state.mrRate, this.props.type);
       }else{
        this.setState({
          validationInflationArray: validInflationArray
        });
      }

    }else{
      if (this.props.type == 'add') {
        this.props.toggleEditSideBar();
      }
      this.props.updateMrRate(this.state.mrRate, this.props.type);
    }
    } else {
      this.setState({
        error: validateNewInput
      });
    }
  }

  updateErrorCode = (type) => {
    this.setState(prevState => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        [type]: ''
      }
    }));
  }

  render() {
    const { type, techConstants, utilsMajorAssembly } = this.props;
    const { mrRate, mrRationRange, error } = this.state;
    return (
      <form onSubmit={(e) => this.updateMrRate(e)}>
        <div style={{ height: (window.innerHeight - 157) + 'px', overflow: 'auto', marginBottom: '60px' }}>
          <Row>
            {
              type != 'add' ?
                <Col md="12">
                  <div className="form-group">
                    <label className="label">Reserve Account</label>
                    <h6>{mrRate.name}</h6>
                  </div>
                </Col> : null
            }
            {
              type == 'add' ?
                <FieldCol md="6">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Reserve Account Name
                    {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                    </FieldLabel>
                    <FieldInput
                      value={mrRate.name}
                      type={type}
                      keyParam="name"
                      updateField={this.updateForm}
                    />
                    <h6 className="error-msg">{error.name}</h6>
                  </FieldGroup>
                </FieldCol> : null
            }
            <FieldCol md="6">
            {
              type == 'view' ?
              <div className="form-group">
                <label className="label">Is Notional MR</label>
                <h6>{mrRate.is_notional_mr ? 'YES': 'NO'}</h6>
              </div>
              :
              <div className="form-group">
                <label className="label checkbox-block">
                  <input
                    type="checkbox"
                    value={mrRate.is_notional_mr}
                    checked={mrRate.is_notional_mr}
                    onChange={(e) => this.updateForm('is_notional_mr', e.target.checked)}
                    style= {{ marginRight: '4px'}}
                  />
                  <span>Is Notional MR</span>
                </label>
              </div>
            }
             </FieldCol>



            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  MR Rate Interval
                {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Interval'
                  keyParam="interval"
                  value={mrRate.interval}
                  type={type}
                  options={techConstants.filter(item => item.type=="interval" && item.value !== 5)}
                  updateField={this.updateForm}
                  labelKey='label'
                  valueKey='value'
                  name={mrRate.interval_name}
                />
                <h6 className="error-msg">{error.interval}</h6>

              </FieldGroup>
            </FieldCol>


             {
              type == 'add' ?
                <FieldCol md="6">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                      Asset
                  {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                    </FieldLabel>
                    <FieldSelect
                      placeholder='Select Asset'
                      keyParam="major_assembly"
                      value={mrRate.major_assembly}
                      type={type}
                      options={utilsMajorAssembly}
                      updateField={this.updateForm}
                      labelKey='name'
                      valueKey='id'
                    />
                    <h6 className="error-msg">{error.major_assembly}</h6>

                  </FieldGroup>
                </FieldCol> : null
            }


            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                <p>Basic MR Rate ({mrRate.currency ? mrRate.currency: 'USD'})
                {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                <InfoIcon id="mr-rate-info" /></p>
                  <ToolTipHover placement="top" tagetId="mr-rate-info">
                  Note: Base MR rate value should not include MUICP inflation rates and Adjustment factors
                </ToolTipHover>
                </FieldLabel>
                <FieldInput
                  value={mrRate.reserve_rate_per_interval}
                  type={type}
                  keyParam="reserve_rate_per_interval"
                  updateField={this.updateForm}
                  currency = {true}
                  currencyType={mrRate.currency}
                />
                <h6 className="error-msg">{error.reserve_rate_per_interval}</h6>

              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  MR Rate Start Date
                {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                </FieldLabel>
                <FieldDate
                  value={mrRate.rate_start_date}
                  type={type}
                  keyParam="rate_start_date"
                  updateField={this.updateForm}
                />
                <h6 className="error-msg">{error.rate_start_date}</h6>

              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  MR Rate End Date
                {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                </FieldLabel>
                <FieldDate
                  value={mrRate.rate_end_date}
                  type={type}
                  keyParam="rate_end_date"
                  minDate={moment(mrRate.rate_start_date)}
                  updateField={this.updateForm}
                />
                <h6 className="error-msg">{error.rate_end_date}</h6>

              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                  Starting Paid Date
                {type != 'view' ? <sup className="reqStar"> * </sup> : null}

                </FieldLabel>
                <FieldDate
                  value={mrRate.starting_fund_date}
                  type={type}
                  keyParam="starting_fund_date"
                  updateField={this.updateForm}
                />
                <h6 className="error-msg">{error.starting_fund_date}</h6>
              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">Starting Paid Amount (USD)
                 {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                </FieldLabel>
                <FieldInput
                  value={mrRate.starting_fund_amount}
                  type={type}
                  keyParam="starting_fund_amount"
                  updateField={this.updateForm}
                  currency = {true}

                />
                <h6 className="error-msg">{error.starting_fund_amount}</h6>
              </FieldGroup>
            </FieldCol>
            <Col md="12" >
              {
                type != 'view' && mrRate.interval !== 4 ?

                  <div className="form-group border-form">
                    <label>
                      <input type="checkbox" checked={mrRate.interval_condition_status} onChange={(e) => {this.updateForm('interval_condition_status', e.target.checked);this.updateForm('is_adjustment_factor', false)}}/>
                      <span style={{ paddingLeft: '10px', fontSize: '13px', color: '#a0a0a0' }}>MR Rate Based on Utilization</span>
                    </label>
                  </div>
                  :  mrRate.interval_condition_status ? <h5 className="para-ui escaltion-seperator margin-bottom-15 border-form">MR Rate Based on Utilization</h5> :null
              }
            </Col>
            {
              mrRate.interval_condition_status &&( mrRate.interval === 1 || mrRate.interval === 4)?
                <Col md="12" >
                  <h5 className="para-ui escaltion-seperator border-form" style={{ marginLeft: '25px', marginBottom:'20px' }}>Monthly</h5>
                </Col>
              :null
            }
            {
              mrRate.interval_condition_status && (mrRate.interval === 1 || mrRate.interval === 4) ?
                <Col md="12"  >
                  <div className={type == 'edit' ? 'table-block' : 'table-block margin-bottom-15'}>
                    <table style={{ width: '100%' }}>
                      <thead>
                        <tr>
                          <th className="label">Min Ratio</th>
                          <th className="label">Range Condition</th>
                          <th className="label">Max Ratio</th>
                          <th className="label">MR Rate</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          mrRate.interval_condition.map((ratioRange, index) =>
                            ratioRange.type === 1 ?
                            <tr key={index}>
                              <td>
                                <div className="form-group">
                                  {
                                    type != 'view' ?
                                      <input disabled={ratioRange.remarks && ratioRange.remarks.indexOf('min') != -1 ? false : true} type="text" value={ratioRange.min_ratio} onChange={(e) => this.updateFieldRatio(index, e.target.value, 'min_ratio')} />
                                      :
                                      <h6 style={{ fontSize: '14px' }}>{ratioRange.min_ratio ? ratioRange.min_ratio : '--'}</h6>
                                  }
                                  {this.state.validationIntervalArray.length== mrRate.interval_condition.length?
                                    this.state.validationIntervalArray[index].min_ratio == false ?
                                   <h6 className="error-msg">Please Enter Min Ratio</h6>:null:null}
                                </div>
                              </td>
                              <td>
                                <div className="form-group">
                                  {
                                    type != 'view' ?
                                      <Select
                                        name="form-field-name"
                                        placeholder="Select Range"
                                        className="custom-select-block"
                                        value={ratioRange.ratio_range}
                                        options={techConstants.filter(item => item.type=="mr_rate_range_condition")}
                                        labelKey="label"
                                        valueKey="value"
                                        type={type}
                                        onChange={(target) => this.updateFieldRatio(index, target ? target.value : '', 'ratio_range')}
                                      /> :
                                      <h6>{ratioRange.ratio_range ? mrRangeCondition[ratioRange.ratio_range] : '--'}</h6>

                                  }
                                   {this.state.validationIntervalArray.length== mrRate.interval_condition.length?
                                    this.state.validationIntervalArray[index].ratio_range == false ?
                                   <h6 className="error-msg">Please Enter Ratio Range</h6>:null:null}
                                   {this.state.validationIntervalArray.length== mrRate.interval_condition.length?
                                    this.state.validationIntervalArray[index].ratio_range_validation == false ?
                                   <h6 className="error-msg">Invalid Ratio Range</h6>:null:null}
                                </div>
                              </td>
                              <td>
                                <div className="form-group">
                                  {
                                    type != 'view' ?
                                      <input type="text" disabled={ratioRange.remarks && ratioRange.remarks.indexOf('max') != -1 ? false : true} value={ratioRange.max_ratio} onChange={(e) => this.updateFieldRatio(index, e.target.value, 'max_ratio')} />
                                      :
                                      <h6>{ratioRange.max_ratio ? ratioRange.max_ratio : '--'}</h6>

                                  }
                                  {this.state.validationIntervalArray.length== mrRate.interval_condition.length?
                                    this.state.validationIntervalArray[index].max_ratio == false ?
                                   <h6 className="error-msg">Please Enter Max Ratio</h6>:null:null}
                                </div>
                              </td>
                              <td>
                                <div className="form-group">
                                  {
                                    type != 'view' ?
                                      <input type="text" value={ratioRange.mr_rate} onChange={(e) => this.updateFieldRatio(index, e.target.value, 'mr_rate')} />
                                      : <h6>{ratioRange.mr_rate ? ratioRange.mr_rate : '--'}</h6>
                                  }
                                    {this.state.validationIntervalArray.length== mrRate.interval_condition.length?
                                    this.state.validationIntervalArray[index].mr_rate == false ?
                                   <h6 className="error-msg">Please Enter MR Rate</h6>:null:null}
                                </div>
                              </td>
                              {
                                type != 'view' ? <td onClick={() => this.removeRatio(index)} className="delete-mr-ut"><img width="12" src={imgStoragePath+"del_icon.png"} /></td> : null
                              }
                            </tr>
                            :null
                          )
                        }
                      </tbody>
                    </table>
                    {
                      type != 'view' ?
                        mrRate.interval_condition.filter(item => item.type === 1).length < 6 ? <span className="add-mr-int" onClick={() => this.addRatio(1)}>+ Add MR Rate</span> : null
                        : null
                    }
                  </div>
                </Col> :
                null
            }
            {
              mrRate.interval_condition_status &&( mrRate.interval === 2 || mrRate.interval === 4)?
                <Col md="12" >
                  <h5 className="para-ui escaltion-seperator border-form" style={{ marginLeft: '25px', marginBottom:'20px' }}>Flight Hours</h5>
                </Col>
              :null
            }
            {
              mrRate.interval_condition_status && (mrRate.interval === 2 || mrRate.interval === 4) ?
                <Col md="12"  >
                  <div className={type == 'edit' ? 'table-block' : 'table-block margin-bottom-15'}>
                    <table style={{ width: '100%' }}>
                      <thead>
                        <tr>
                          <th className="label">Min Ratio</th>
                          <th className="label">Range Condition</th>
                          <th className="label">Max Ratio</th>
                          <th className="label">MR Rate</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          mrRate.interval_condition.map((ratioRange, index) =>
                          ratioRange.type === 2 ?
                          <tr key={index}>
                            <td>
                              <div className="form-group">
                                {
                                  type != 'view' ?
                                    <input disabled={ratioRange.remarks && ratioRange.remarks.indexOf('min') != -1 ? false : true} type="text" value={ratioRange.min_ratio} onChange={(e) => this.updateFieldRatio(index, e.target.value, 'min_ratio')} />
                                    :
                                    <h6 style={{ fontSize: '14px' }}>{ratioRange.min_ratio ? ratioRange.min_ratio : '--'}</h6>
                                }
                                {this.state.validationIntervalArray.length== mrRate.interval_condition.length?
                                  this.state.validationIntervalArray[index].min_ratio == false ?
                                 <h6 className="error-msg">Please Enter Min Ratio</h6>:null:null}
                              </div>
                            </td>
                            <td>
                              <div className="form-group">
                                {
                                  type != 'view' ?
                                    <Select
                                      name="form-field-name"
                                      placeholder="Select Range"
                                      className="custom-select-block"
                                      value={ratioRange.ratio_range}
                                      options={techConstants.filter(item => item.type=="mr_rate_range_condition")}
                                      labelKey="label"
                                      valueKey="value"
                                      type={type}
                                      onChange={(target) => this.updateFieldRatio(index, target ? target.value : '', 'ratio_range')}
                                    /> :
                                    <h6>{ratioRange.ratio_range ? mrRangeCondition[ratioRange.ratio_range] : '--'}</h6>

                                }
                                 {this.state.validationIntervalArray.length== mrRate.interval_condition.length?
                                  this.state.validationIntervalArray[index].ratio_range == false ?
                                 <h6 className="error-msg">Please Enter Ratio Range</h6>:null:null}
                                 {this.state.validationIntervalArray.length== mrRate.interval_condition.length?
                                  this.state.validationIntervalArray[index].ratio_range_validation == false ?
                                 <h6 className="error-msg">Invalid Ratio Range</h6>:null:null}
                              </div>
                            </td>
                            <td>
                              <div className="form-group">
                                {
                                  type != 'view' ?
                                    <input type="text" disabled={ratioRange.remarks && ratioRange.remarks.indexOf('max') != -1 ? false : true} value={ratioRange.max_ratio} onChange={(e) => this.updateFieldRatio(index, e.target.value, 'max_ratio')} />
                                    :
                                    <h6>{ratioRange.max_ratio ? ratioRange.max_ratio : '--'}</h6>

                                }
                                {this.state.validationIntervalArray.length== mrRate.interval_condition.length?
                                  this.state.validationIntervalArray[index].max_ratio == false ?
                                 <h6 className="error-msg">Please Enter Max Ratio</h6>:null:null}
                              </div>
                            </td>
                            <td>
                              <div className="form-group">
                                {
                                  type != 'view' ?
                                    <input type="text" value={ratioRange.mr_rate} onChange={(e) => this.updateFieldRatio(index, e.target.value, 'mr_rate')} />
                                    : <h6>{ratioRange.mr_rate ? ratioRange.mr_rate : '--'}</h6>
                                }
                                  {this.state.validationIntervalArray.length== mrRate.interval_condition.length?
                                  this.state.validationIntervalArray[index].mr_rate == false ?
                                 <h6 className="error-msg">Please Enter MR Rate</h6>:null:null}
                              </div>
                            </td>
                            {
                              type != 'view' ? <td onClick={() => this.removeRatio(index)} className="delete-mr-ut"><img width="12" src={imgStoragePath+"del_icon.png"} /></td> : null
                            }
                          </tr>
                          :null
                          )
                        }
                      </tbody>
                    </table>
                    {
                      type != 'view' ?
                        mrRate.interval_condition.filter(item => item.type === 2).length < 6 ? <span className="add-mr-int" onClick={() => this.addRatio(2)}>+ Add MR Rate</span> : null
                        : null
                    }
                  </div>
                </Col> :
                null
            }
            {
              mrRate.interval_condition_status &&( mrRate.interval === 3 || mrRate.interval === 4)?
                <Col md="12" >
                  <h5 className="para-ui escaltion-seperator border-form" style={{ marginLeft: '25px', marginBottom:'20px' }}>Flight Cycle</h5>
                </Col>
              :null
            }
            {
              mrRate.interval_condition_status && (mrRate.interval === 3 || mrRate.interval === 4) ?
                <Col md="12"  >
                  <div className={type == 'edit' ? 'table-block' : 'table-block margin-bottom-15'}>
                    <table style={{ width: '100%' }}>
                      <thead>
                        <tr>
                          <th className="label">Min Ratio</th>
                          <th className="label">Range Condition</th>
                          <th className="label">Max Ratio</th>
                          <th className="label">MR Rate</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          mrRate.interval_condition.map((ratioRange, index) =>
                          ratioRange.type === 3 ?
                          <tr key={index}>
                            <td>
                              <div className="form-group">
                                {
                                  type != 'view' ?
                                    <input disabled={ratioRange.remarks && ratioRange.remarks.indexOf('min') != -1 ? false : true} type="text" value={ratioRange.min_ratio} onChange={(e) => this.updateFieldRatio(index, e.target.value, 'min_ratio')} />
                                    :
                                    <h6 style={{ fontSize: '14px' }}>{ratioRange.min_ratio ? ratioRange.min_ratio : '--'}</h6>
                                }
                                {this.state.validationIntervalArray.length== mrRate.interval_condition.length?
                                  this.state.validationIntervalArray[index].min_ratio == false ?
                                 <h6 className="error-msg">Please Enter Min Ratio</h6>:null:null}
                              </div>
                            </td>
                            <td>
                              <div className="form-group">
                                {
                                  type != 'view' ?
                                    <Select
                                      name="form-field-name"
                                      placeholder="Select Range"
                                      className="custom-select-block"
                                      value={ratioRange.ratio_range}
                                      options={techConstants.filter(item => item.type=="mr_rate_range_condition")}
                                      labelKey="label"
                                      valueKey="value"
                                      type={type}
                                      onChange={(target) => this.updateFieldRatio(index, target ? target.value : '', 'ratio_range')}
                                    /> :
                                    <h6>{ratioRange.ratio_range ? mrRangeCondition[ratioRange.ratio_range] : '--'}</h6>

                                }
                                 {this.state.validationIntervalArray.length== mrRate.interval_condition.length?
                                  this.state.validationIntervalArray[index].ratio_range == false ?
                                 <h6 className="error-msg">Please Enter Ratio Range</h6>:null:null}
                                 {this.state.validationIntervalArray.length== mrRate.interval_condition.length?
                                  this.state.validationIntervalArray[index].ratio_range_validation == false ?
                                 <h6 className="error-msg">Invalid Ratio Range</h6>:null:null}
                              </div>
                            </td>
                            <td>
                              <div className="form-group">
                                {
                                  type != 'view' ?
                                    <input type="text" disabled={ratioRange.remarks && ratioRange.remarks.indexOf('max') != -1 ? false : true} value={ratioRange.max_ratio} onChange={(e) => this.updateFieldRatio(index, e.target.value, 'max_ratio')} />
                                    :
                                    <h6>{ratioRange.max_ratio ? ratioRange.max_ratio : '--'}</h6>

                                }
                                {this.state.validationIntervalArray.length== mrRate.interval_condition.length?
                                  this.state.validationIntervalArray[index].max_ratio == false ?
                                 <h6 className="error-msg">Please Enter Max Ratio</h6>:null:null}
                              </div>
                            </td>
                            <td>
                              <div className="form-group">
                                {
                                  type != 'view' ?
                                    <input type="text" value={ratioRange.mr_rate} onChange={(e) => this.updateFieldRatio(index, e.target.value, 'mr_rate')} />
                                    : <h6>{ratioRange.mr_rate ? ratioRange.mr_rate : '--'}</h6>
                                }
                                  {this.state.validationIntervalArray.length== mrRate.interval_condition.length?
                                  this.state.validationIntervalArray[index].mr_rate == false ?
                                 <h6 className="error-msg">Please Enter MR Rate</h6>:null:null}
                              </div>
                            </td>
                            {
                              type != 'view' ? <td onClick={() => this.removeRatio(index)} className="delete-mr-ut"><img width="12" src={imgStoragePath+"del_icon.png"} /></td> : null
                            }
                          </tr>
                          :null
                          )
                        }
                      </tbody>
                    </table>
                    {
                      type != 'view' ?
                        mrRate.interval_condition.filter(item => item.type === 3).length < 6 ? <span className="add-mr-int" onClick={() => this.addRatio(3)}>+ Add MR Rate</span> : null
                        : null
                    }
                  </div>
                </Col> :
                null
            }
             <Col md="12">
              {
                type != 'view' ?

                  <div className="form-group border-form">
                    <label>
                      <input type="checkbox" checked={mrRate.is_adjustment_factor} onChange={(e) => {this.updateForm('is_adjustment_factor', e.target.checked);this.updateForm('interval_condition_status', false)}} />
                      <span style={{ paddingLeft: '10px', fontSize: '13px', color: '#a0a0a0' }}>MR Rate has Adjustment based on FC/FH Ratio</span>
                    </label>
                  </div>

                  :  mrRate.is_adjustment_factor ? <h5 className="para-ui escaltion-seperator margin-bottom-15 border-form">MR Rate has Adjustment based on FC/FH Ratio</h5>:null
              }
            </Col>
            {
              mrRate.is_adjustment_factor ?
              <div style={{ width: '100%' }}>
              <FieldCol md="6">
              <FieldGroup className="form-group">
                <FieldLabel className="label">
                 Contractual FH/FC Ratio
                 {type != 'view' ? <sup className="reqStar"> * </sup> : null}
                </FieldLabel>
                <FieldInput
                      value={mrRate.contractual_factor}
                      type={type}
                      keyParam="contractual_factor"
                      updateField={this.updateForm}
                    />
                <h6 className="error-msg">{error.contractual_factor}</h6>

              </FieldGroup>
              </FieldCol>
                <Col md="12"  >
                  <div className={type == 'edit' ? 'table-block' : 'table-block margin-bottom-15'}>
                   { type != 'view' ?
                       <span className="add-mr-int" onClick={this.addAdjustment}>+ Add Adjustment Factor</span> :null
                    }
                    <table style={{ width: '100%' }}>
                      <thead>
                        <tr>
                          <th className="label">FC/FH Ratio</th>
                          <th className="label">Adjustment Factor (%)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                         mrRate.adjustment_factor.map((adujustmentfactor, index) =>
                            <tr key={index}>
                              <td>
                                <div className="form-group">
                                  {
                                    type != 'view' ?
                                      <input type="text" value={adujustmentfactor.ratio} onChange={(e) => this.updateFieldAdjust(index, e.target.value, 'ratio')} />
                                      :
                                      <h6 style={{ fontSize: '14px' }}>{adujustmentfactor.ratio ? adujustmentfactor.ratio : '--'}</h6>

                                  }
                                   {this.state.validationInflationArray.length == mrRate.adjustment_factor.length?
                                    this.state.validationInflationArray[index].ratio == false ?
                                   <h6 className="error-msg">Please Enter FC/FH Ratio</h6>:null:null}

                                </div>
                              </td>
                              <td>
                                <div className="form-group">
                                  {
                                    type != 'view' ?
                                      <input type="text" value={adujustmentfactor.adjustment_factor} onChange={(e) => this.updateFieldAdjust(index, e.target.value, 'adjustment_factor')} />
                                      :
                                      <h6 style={{ fontSize: '14px' }}>{adujustmentfactor.adjustment_factor ? adujustmentfactor.adjustment_factor : '--'}</h6>
                                  }
                                   {this.state.validationInflationArray.length== mrRate.adjustment_factor.length?
                                    this.state.validationInflationArray[index].adjustment_factor == false ?
                                   <h6 className="error-msg">Please Enter Adjustment Factor</h6>:null:null}
                                </div>
                              </td>
                              {
                                type != 'view' ? <td onClick={() => this.removeAdjustment(index)} className="delete-mr-ut"><img width="12" src={imgStoragePath+"del_icon.png"} /></td> : null
                              }
                            </tr>
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                </Col>
                </div>  : null
            }
            <Col md="12" >
              <h5 className="para-ui escaltion-seperator border-form" style={{ marginLeft: '25px', marginBottom:'20px' }}>Escalation details</h5>
            </Col>
            <Col md="12">
              { type== 'view' && !mrRate.is_escalation_mucip ? null :
                  <div className="form-group">
                    <label>
                      <input type="checkbox" checked={mrRate.is_escalation_mucip} onChange={(e) => type != 'view' ? this.updateForm('is_escalation_mucip', e.target.checked): ''} />
                      <span style={{ paddingLeft: '10px', fontSize: '13px', color: '#a0a0a0' }}>Escalation to be picked up from MUICP</span>
                    </label>
                  </div>
              }
            </Col>
            <FieldCol md="6">
            {type == 'view' && mrRate.is_escalation_mucip ? null :
              <FieldGroup className="form-group" style={{opacity:type != 'view' && mrRate.is_escalation_mucip ?'0.5':'1'}}>
                <FieldLabel className="label">
                  MR Escalation Percentage
                {/*type != 'view' ? mrRate.is_escalation_mucip ? null: <sup className="reqStar"> * </sup> : null*/}
                </FieldLabel>
                <FieldInput
                  value={mrRate.rate_escalation_percentage}
                  type={type}
                  keyParam="rate_escalation_percentage"
                  updateField={this.updateForm}
                  percentage={true}
                  disable={type != 'view' && mrRate.is_escalation_mucip ? true : false}

                />
                <h6 className="error-msg">{error.rate_escalation_percentage}</h6>

              </FieldGroup>
            }
            </FieldCol>
            <FieldCol md="6">
            {type == 'view' && mrRate.is_escalation_mucip ? null :
              <FieldGroup className="form-group" style={{opacity:type != 'view' && mrRate.is_escalation_mucip ?'0.5':'1'}}>
                <FieldLabel className="label">
                Shop Visit Cost Escalation Percentage
                </FieldLabel>
                <FieldInput
                  value={mrRate.cost_escalation_percentage}
                  type={type}
                  keyParam="cost_escalation_percentage"
                  updateField={this.updateForm}
                  percentage={true}
                  disable={type != 'view' && mrRate.is_escalation_mucip ? true : false}
                />
                <h6 className="error-msg">{error.cost_escalation_percentage}</h6>
              </FieldGroup>
            }
            </FieldCol>
            <FieldCol md="6">
              <FieldGroup className="form-group"  >
                <FieldLabel className="label">
                  Escalation Interval
                {/*type != 'view' ? mrRate.is_escalation_mucip ? null: <sup className="reqStar"> * </sup> : null*/}
                </FieldLabel>
                <FieldSelect
                  placeholder='Select Escalation Interval'
                  keyParam="rate_escalation_interval"
                  value={mrRate.rate_escalation_interval}
                  type={type}
                  options={techConstants.filter(item => item.type=="escalation_interval")}
                  updateField={this.updateForm}
                  labelKey='label'
                  valueKey='value'
                  name={mrRate.rate_escalation_interval_name}

                />
                <h6 className="error-msg">{error.rate_escalation_interval}</h6>

              </FieldGroup>
            </FieldCol>
            <FieldCol md="6">
            {type == 'view' && mrRate.is_escalation_mucip ? null :
              <FieldGroup className="form-group" style={{opacity:type != 'view' && mrRate.is_escalation_mucip ?'0.5':'1'}} >
                <FieldLabel className="label">
                  Escalation Start Date
                {/*type != 'view' ?mrRate.is_escalation_mucip ? null: <sup className="reqStar"> * </sup> : null*/}
                </FieldLabel>
                <FieldDate
                  value={mrRate.rate_escalation_start_date}
                  type={type}
                  keyParam="rate_escalation_start_date"
                  updateField={this.updateForm}
                  disable={type != 'view' && mrRate.is_escalation_mucip ? true : false}
                />
                <h6 className="error-msg">{error.rate_escalation_start_date}</h6>
              </FieldGroup>
            }
            </FieldCol>
            <Col md="12">
              <div className="form-group">
                <label className="label">
                  {
                    type != 'view' ?
                      <input type="checkbox" checked={mrRate.status == 1 ? true : false} onChange={(e) => this.updateForm('status', e.target.checked ? 1 : 0)} /> : null
                  }
                  <span >
                    Activate Reserve Account: {type == 'view' ? mrRate.status == 1 ? 'YES' : 'NO' : ''} </span>
                </label>
              </div>
            </Col>
          </Row>
        </div>
        {
          type != 'view' ?
            <div className="submit-block">
              <input type="submit" className="primary-btn" value="Save Changes" />
              <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
            </div> : null
        }
      </form>
    )
  }
}

import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { globalPostService, globalGetService } from '../../../globalServices';
import { toastFlashMessage } from '../../../utils';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import moment from 'moment';
import { backendDateFormat, fieldDateFormat } from '../../../constants';
import { FieldDate, FieldGroup, FieldLabel} from '../../contracts/Elements';
import { checkValidDate } from '../../../formValidator';
class UtilizationInvRpt extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checked:'xlsx',
      start_date:'',
      end_date:'',
      updateForm:false,
      asset_type_id:[],
      error:{},
      asset : [],
      selectAll:false,
      lessor_name_id:[]

    }
  }
  componentDidMount(){
    this.getAssetlist();
  }
  getAssetlist = (query) => {
    const {lessor_name_id} = this.state
    let lessorStr = lessor_name_id.map(item => (`(${item.id},${item.lessor_level})`)).toString()
    globalGetService(`/console/list/?dropdown=true&lessor_name_id=${lessorStr}`)
    .then(response => {
      if(response.data.statusCode == 200){
        this.setState(prevState => ({
          ...prevState,
          asset:response.data.data.asset
        }))
      }
    })
  }
  updateErrorField = (key, message) => {
    this.setState(prevState => ({
      ...prevState,
      error:{
        ...prevState.error,
        [key]:message
      }
    }))
  }
  updateAsset = (key, value) => {
    this.setState((prevState => ({
      ...prevState,
      [key]:value
    })))

  }
  onChangeFromDate = (key, value) => {
    this.setState(prevState => ({
      ...prevState,
      [key]:value,
      end_date:''
    }))
  }
  onChangeToDate = (key, value) => {
    this.setState(prevState => ({
      ...prevState,
      [key]:value,
    }))
  }
  updateOwner = (key, value) => {
    if(this.state.asset_type_id.length){
      this.setState((prevState => ({
        ...prevState,
        [key]:value,
        asset_type_id:[]
      })),() => this.getAssetlist())
    }else {
      this.setState((prevState => ({
        ...prevState,
        [key]:value,
      })),() => this.getAssetlist())
    }

  }
  UtilizationInvRpt = () => {
    const { asset_type_id, start_date, end_date, checked, lessor_name_id } = this.state
    let data = {
      file_type:checked,
      start_date:start_date,
      end_date:end_date,
      asset_type_id:asset_type_id,
      lessor_name_id:lessor_name_id.map(item => (`(${item.id},${item.lessor_level})`)).toString()
    }
    let validateNewInput = {
      start_date: checkValidDate({value: start_date, required:true, minLength:'', maxLength:'', message:'Please enter From Date'}),
      end_date: checkValidDate({value: end_date, required:true, minLength:'', maxLength:'', message:'Please enter To Date'}),
      asset_type_id: asset_type_id && asset_type_id.length ? '' : 'Please Select any Asset'
    }
    if(Object.keys(validateNewInput).every((k) => {return validateNewInput[k] == "" })) {
      globalPostService(`/console/export-utilisation-invoice-report/`, data)
      .then(response => {
        if(response.data.statusCode == 200){
          toastFlashMessage(response.data.message, 'success')
          this.setState({
            error:{}
          })
        }else {
          toastFlashMessage(response.data.message, 'error')
        }
      })
    }else {
      this.setState({
        error:validateNewInput
      })
    }
  }

  render() {
    const { checked, start_date, end_date, updateForm, error, asset_type_id, asset, selectAll, lessor_name_id } = this.state
    const { ownerList } = this.props
    return(
      <div className="export-module-card" style={{height: updateForm ? '600px':selectAll ? "1000px": updateForm && asset_type_id && asset_type_id.length >= 25 ? "1000px" :  '440px' , overflow:'auto' }} >
        <div className="rpt-card-header">
          <h2>Utilization Invoice Report
            <Button color="primary" size="sm" style={{float:'right', marginTop:'-4px', backgroundColor:'#3f51b5', borderColor:'#3f51b5'}} onClick={() =>  this.UtilizationInvRpt()}>Export</Button>
          </h2>
        </div>
        <div className="rpt-card-body">
          <div className="export-items">
            <div className="">
              <Row className="flex-centered">
                <Col xs="5">
                  <div className="export-avial">
                    <h6>Export Format</h6>
                      <ul className="list-inline">
                      <li className="list-inline-item">
                        <label >
                          <input
                            name="UtilizationInvRpt"
                            checked={checked == 'xlsx' ?  true : false}
                            type="radio"
                            onChange={() =>  this.setState({checked:'xlsx'})}

                          />
                          <span>XL</span>
                        </label>
                      </li>
                        <li className="list-inline-item">
                          <label >
                            <input
                              name="UtilizationInvRpt"
                              checked={checked == "csv" ?  true :false}
                              type="radio"
                              onChange={() =>  this.setState({checked:'csv'})}
                            />
                            <span>CSV</span>
                          </label>
                        </li>
                      </ul>
                  </div>
                </Col>
              </Row>
                <div className="filter-avail-custom">
                  <Row>
                    <ul className="list-inline flex-centered">
                      <Col md="6">
                      <li className="list-inline-item">
                     <FieldGroup className="form-group">
                       <FieldLabel className="label" >From Date</FieldLabel>
                       <FieldDate
                         value={start_date}
                         updateField={this.onChangeFromDate}
                         keyParam="start_date"
                         focus={() => this.setState({updateForm:true})}
                         blur={() =>  this.setState({updateForm:false})}
                         maxDate={moment()}
                         focus={() =>  this.updateErrorField('start_date', '')}
                      />
                    <h6 className="error-msg">{error.start_date}</h6>
                     </FieldGroup>
                      </li>
                    </Col>
                    <Col md="6">
                      <li className="list-inline-item">
                      <FieldGroup className="form-group">
                        <FieldLabel className="label">To Date</FieldLabel>
                        <FieldDate
                          value={end_date}
                          updateField={this.onChangeToDate}
                          keyParam="end_date"
                          focus={() => this.setState({updateForm:true, error:{...error, end_date:''}})}
                          blur={() =>  this.setState({updateForm:false})}
                          disable={!start_date ? true : false}
                          minDate={moment(start_date)}
                          maxDate={moment(start_date).diff(moment(), 'month') === 0 ? moment(new Date()) :moment(start_date).add(120, 'M')}
                        />
                      <h6 className="error-msg">{error.end_date}</h6>
                      </FieldGroup>
                      </li>
                    </Col>
                    </ul>
                      <Col md="12" >
                        <FieldGroup className="form-group">
                          <FieldLabel className="label">Lessor</FieldLabel>
                            <Select
                              name="form-field-name"
                              placeholder={<p style={{marginLeft:'10px'}}>Select Lessors</p>}
                              className="custom-select-block"
                              value={lessor_name_id}
                              labelKey="name"
                              valueKey="id"
                              options = {ownerList}
                              multi={true}
                              onChange={(target) => this.updateOwner('lessor_name_id', target)}
                              style={{width:'500px', background:'#fff'}}
                              onBlur={() =>  this.setState({updateForm:false })}
                              onFocus={() =>  this.setState({updateForm:true, selectAll:false,})}
                            />
                        </FieldGroup>
                    </Col>
                    <Col md="12" >
                      <FieldGroup className="form-group">
                        <FieldLabel className="label">Asset <span style={{color:"#2f8cfe", marginLeft:'5px'}} onClick={() =>  this.setState({asset_type_id : asset, selectAll:true})} >Select All </span> {asset_type_id.length ? <span style={{color:"#2f8cfe", marginLeft:'5px'}} onClick={() =>  this.setState({asset_type_id:[], selectAll:false})} >Remove All</span> : null} </FieldLabel>
                          <Select
                            name="form-field-name"
                            placeholder={<p style={{marginLeft:'10px'}}>Select Assets</p>}
                            className="custom-select-block"
                            value={asset_type_id}
                            labelKey="asset_name"
                            valueKey="id"
                            options = {asset}
                            multi={true}
                            onChange={(target) => this.updateAsset('asset_type_id', target)}
                            style={{width:'500px', background:'#fff'}}
                            onBlur={() =>  this.setState({updateForm:false })}
                            onFocus={() =>  this.setState({updateForm:true, selectAll:false, error:{...error,asset_type_id:''}})}
                          />
                        <h6 className="error-msg">{error.asset_type_id}</h6>
                      </FieldGroup>
                  </Col>
                  </Row>
                </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default UtilizationInvRpt;

import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { APUtab, OverViewUtilization,  MjrAssShopVisitHeader, MjrAssShopVisitDetail, GenTabField, RemarksList, AddEditRemarks, TableHeadingTechSummary, GenericAssemblyLLPs } from '../components';
import { getLgDetailsAc, addEditTechSummaryAc,techSummaryCrudAc, editLgAc, exportLgLLpsAc, exportPropellerLLpsAc, deleteLgLLPAc, deletePropellerLLPAc, importLgLLPsAc, importPropellerLLPsAc } from '../actionCreators';
import { getOwnerDropdownListAc } from '../../../../phase2/ConfigureSettings/actionCreators';
import { EditSidebar, CtrTableHd, FormModeType  } from '../../../contracts/Elements';
import { getApuTypesAc } from '../../../../User/actionCreators'
import { TECH_SMRY_CRUD, APU_DETAIL } from '../actions';
import { sManufacturerAc, sRegionListAc, sLesseeListAc,  } from '../../../../shared/actionCreators';
import { addApu } from '../createTechSummary';
import { getLocalStorageInfo } from '../../../../utils';
import { NoRecordFound, ListLoader } from '../../../../shared';
import { AddEditLg, AddEditPropeller } from '../../../../User/components'
import { getLessorListAc } from '../../../../User/actionCreators'
import { imgStoragePath, displayDateFormat } from '../../../../constants'
import moment from 'moment';
import RemarksCont from './RemarksCont'
import { fetchPortfolioListAC } from '../../../fleetAnalysis/actionCreators'
import { Row, Col } from 'reactstrap'
class LGDetails extends Component{
  constructor(props) {
    super(props);
    this.state = {
      error: ''
    }
  }

  updateDisclaimer = (key, value) =>{
		let dataObject = this.props.apuDetail
		dataObject = {...dataObject, [key]: value}
		this.props.overviewTechUpdate(dataObject)
	}

  componentDidMount() {
    this.props.getLgDetails()
    this.props.fetchPortfolios({status: 3});
    this.props.getLesseeList()
    this.props.fetchRegionList()
    this.props.getLessorList()
    this.props.fetchPortfolios({status: 3});
    this.props.getOwnerList()
  }

  saveRemarks = (evt) =>{
    evt.preventDefault()
    evt.stopPropagation()
    if(this.props.remarksCrud.data.remarks.trim() == ''){
      this.setState({error: 'Please enter Remark'})
    }else{
      this.props.saveRemarks(this.props.remarksCrud.data.remarks)
    }
  }

  render(){
    const { apuDetail, lgCrud, apucraftManufacturerList, overviewTechInfo, techSpecsLoader, techConstants, remarksCrud,remarksList, ownerList, type} = this.props;
    let assetType = type === 'lg' ? 'landing_gears' : type
    if(Object.keys(apuDetail).length){
      return(

          <div className="edit-view-tech-spec">
              <div style={{marginLeft: '60px'}}>
              <div className="horizontal-card card-block">
                {
                  getLocalStorageInfo() && getLocalStorageInfo().user && getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.technical_specs && getLocalStorageInfo().user.permission.technical_specs[assetType] && (getLocalStorageInfo().user.permission.technical_specs[assetType].includes('C') || getLocalStorageInfo().user.permission.technical_specs[assetType].includes('U')) ?
                    <img onClick = {() => this.props.toggleEditBar({type: 'edit',data: apuDetail, flag: true}, 'lgCrud')} src={imgStoragePath + "icon_editblock.png"} alt="img" className="blue-img" width="16" />
                  :null
                }
                <Row>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Manufacturer" text={apuDetail.manufacturer ? apuDetail.manufacturer : '--'}/>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Serial Number" text={apuDetail.serial_number ? apuDetail.serial_number : '--'}/>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Position" text={apuDetail.position ? techConstants.filter(item => item.type=="lg_position" && item.value === apuDetail.position).length ? techConstants.filter(item => item.type=="lg_position" && item.value === apuDetail.position)[0].label :'--' : '--'}/>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Part Number" text={apuDetail.part_number ? apuDetail.part_number : '--'}/>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Manufacturing Date" text={apuDetail.date_of_manufacture ? moment(apuDetail.date_of_manufacture).format(displayDateFormat):'--'}/>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Date of Purchase" text={apuDetail.purchase_date ? moment(apuDetail.purchase_date).format(displayDateFormat) : '--' }/>
                  {
                    apuDetail.status && parseInt(apuDetail.status.value) !== 4 ?
                    <GenTabField grid="3" classText = "horizontal-inner-block " label="Lessor Name" text={apuDetail.lessor_name && apuDetail.lessor_name.name ? apuDetail.lessor_name.name : getLocalStorageInfo().defaultLessor ?  getLocalStorageInfo().defaultLessor.name : '--'}/>
                    :null
                  }
                  {
                    apuDetail.status && parseInt(apuDetail.status.value) !== 4 ?
                    <GenTabField grid="3" classText = "horizontal-inner-block " label="Owner" text={apuDetail.owner && apuDetail.owner.name ? apuDetail.owner.name : getLocalStorageInfo().defaultLessor ?  getLocalStorageInfo().defaultLessor.name : '--'}/>
                    :null
                  }
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Lease Status" text={apuDetail.status && apuDetail.status.label ? apuDetail.status.label : "--"}/>
                  { apuDetail.status == 2 || (apuDetail.status && apuDetail.status.value == 2) ?
                    <GenTabField grid="3" classText = "horizontal-inner-block " label="Off Lease Status" text={apuDetail.off_lease_status && apuDetail.off_lease_status.label ? apuDetail.off_lease_status.label : "--"}/>
                    : null
                  }

                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Region of Operation" text={apuDetail.region && apuDetail.region.name ? apuDetail.region.name : "--"}/>
                  <GenTabField grid="3" classText = "horizontal-inner-block " label="Location" text={apuDetail.location ? apuDetail.location : "--"}/>




                </Row>

               </div>
               {
                 this.props.params.type == 'lg' ?
                 <OverViewUtilization index={1} utilization={apuDetail} importAllowed={false} assembly="lg" slug={this.props.params.aircraft_slug} refresh={() => this.componentDidMount()} />
                 :null
               }
               { Object.keys(apuDetail).length ?
                 <GenericAssemblyLLPs
                   llp={apuDetail.llps}
                   assemblyType={this.props.params.type == "lg" ? 'lg': "propeller"}
                   assemblySlug={apuDetail.slug}
                   heading={this.props.params.type == "lg" ? 'landing gear': 'propeller'}
                   assembly={apuDetail}

                 />:null
               }

                <div className="contr-table-blk" style={this.props.params.type != 'lg' ? {marginTop: '30px'} : {}}>
                  <h5 style={{ borderBottom: '0'}}>Shop Visits History</h5>
                  <div className="mobile-table-format">
                    <MjrAssShopVisitHeader theads = {[{ label: 'For Shop Visit / Shop Visit Date', size: 2 }, { label: 'Shop Visit Cost', size: 2}, { label: 'Shop Visit Facility/ Location', size: 2}, { label: 'TSN at Shop Visit', size: 2}, { label: 'CSN at Shop Visit', size: 2},  {label: 'Status', size: 2}]}/>
                      {
                        apuDetail.shopvisit && Object.keys(apuDetail.shopvisit).length ?
                        apuDetail.shopvisit.map((item,index) =>
                          <MjrAssShopVisitDetail shopvisit = {item} key={index}/>
                        ) : null
                      }
                   </div>
                    {!techSpecsLoader && ((apuDetail.shopvisit && !Object.keys(apuDetail.shopvisit).length) || !apuDetail.shopvisit) ?
                            <NoRecordFound description="" /> :  null
                      }
                </div>
                <RemarksCont/>
                <h3 style={{fontSize: '16px', marginBottom: '10px'}}>Disclaimer</h3>
                <div className="horizontal-card card-block">
                <Row>
                <Col md="12" className="horizontal-inner-block">
                  <div>
                    <h6 style={{marginBottom: '10px'}}>Technical</h6>
                    {
                      getLocalStorageInfo().user.permission['technical_specs'] ?
                      <textarea disabled={!getLocalStorageInfo().user.permission['technical_specs'] ? true : false} style={{border: '1px solid #d7d7d7', height: '115px', width: '100%', padding: '10px', resize: 'none', borderRadius: '4px'}} value={this.props.apuDetail.technical_disclaimer} onChange={(evt) => this.updateDisclaimer('technical_disclaimer', evt.target.value)}></textarea>
                      :<p style={{border: '1px solid #d7d7d7', borderRadius: '4px', width: '100%', padding: '10px'}}>{this.props.apuDetail.technical_disclaimer && this.props.apuDetail.technical_disclaimer.length ? this.props.apuDetail.technical_disclaimer.length : '--'}</p>
                    }
                  </div>
                </Col>
                {
                  getLocalStorageInfo().user.permission['technical_specs'] ?
                  <Col md="12">
                      <input type="button" style={{float: 'right', marginTop: '15px', marginBottom: '40px'}} className="primary-btn" onClick={() => this.props.editLg({technical_disclaimer: this.props.apuDetail.technical_disclaimer, slug: this.props.apuDetail.slug})} value="Save"/>
                  </Col>
                  :null
                }
                </Row>
                </div>
              </div>
            <EditSidebar title={lgCrud.type == 'add' ? 'Add Landing Gear' : lgCrud.type == 'edit' ? this.props.params.type == 'lg' ? 'Edit Landing Gear' : 'Edit Propeller' : 'View Landing Gear'}  width="800px" right= "-800px" editSideBar={lgCrud.flag} toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'lgCrud')}>
    					{Object.keys(lgCrud.data).length && lgCrud.flag ?
                this.props.params.type == 'lg'?
                <AddEditLg
                  type={lgCrud.type}
                  operators = {this.props.operators}
                  clients={ this.props.sLesseeList }
                  sRegionList = {this.props.sRegionList}
                  addEditApu={this.props.editLg}
                  apuSummeryObj={lgCrud.data}
                  manufacturerList={apucraftManufacturerList}
                  toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'lgCrud')}
                  techConstants={techConstants}
                  portfolioList={this.props.portfolioList}
                  loader={this.props.techSpecsLoader}
                  standAlone={true}
                  portfolioList={this.props.portfolioList}
                  ownerList={ownerList}
                  />:
                  <AddEditPropeller
                    type={lgCrud.type}
                    operators = {this.props.operators}
                    clients={ this.props.sLesseeList }
                    sRegionList = {this.props.sRegionList}
                    addEditApu={this.props.editLg}
                    apuSummeryObj={lgCrud.data}
                    standAlone={true}
                    portfolioList={this.props.portfolioList}
                    manufacturerList={apucraftManufacturerList}
                    toggleEditSideBar={() => this.props.toggleEditBar({type: '', data: {}, flag: false}, 'lgCrud')}
                    techConstants={techConstants}
                    loader={this.props.techSpecsLoader}
                    portfolioList={this.props.portfolioList}
                    ownerList={ownerList}
                    />:null
              }
    				</EditSidebar>
          </div>
        )
    }else {
      return <ListLoader />
    }
  }
}
const mapStateToProps = state => ({
  apucraftManufacturerList: state.sharedReducers.apucraftManufacturerList,
  apuDetail: state.TechSummaryReducer.apuDetail,
  lgCrud: state.TechSummaryReducer.lgCrud,
  techConstants: state.sharedReducers.techConstants,
  apuTypesList: state.userAssetReducer.apuTypesList,
  lessorList: state.userAssetReducer.lessorList,
  clients: state.userAssetReducer.clients,
  operators: state.userAssetReducer.operators,
  sRegionList: state.sharedReducers.sRegionList,
  sLesseeList: state.sharedReducers.sLesseeList,
  portfolioList: state.FleetAnalysisReducer.portfolioList,
  ownerList:state.confReducer.ownerDropdownList
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    overviewTechUpdate: (dataObject) => dispatch({
      type: APU_DETAIL,
      payload: dataObject
    }),
    fetchManufacturer: (group_type, reducerType) => dispatch(sManufacturerAc(group_type, reducerType)),
    getOwnerList: () => dispatch(getOwnerDropdownListAc()),
    addEditTechSummary : (data, operation, type, viewType) => dispatch(addEditTechSummaryAc(ownProps.params , data, operation, type, viewType)),
    techSummaryCrud: (type, id, actionType,dataObject,index) => dispatch(techSummaryCrudAc(ownProps.params, type, id, actionType,dataObject,index)),
    toggleEditBar: (data, actionType) => dispatch({
      type:TECH_SMRY_CRUD,
      payload:data,
      actionType
    }),
    getApuTypes: () => dispatch(getApuTypesAc()),
    getLgDetails: () => dispatch(getLgDetailsAc(ownProps.params)),
    fetchRegionList: () => dispatch(sRegionListAc()),
    getLessorList: () => dispatch(getLessorListAc()),
    getLesseeList: () => dispatch(sLesseeListAc()),
    editLg: (lg) => dispatch(editLgAc(ownProps.params,lg)),
    deleteLgLLP:(lg_slug, id)=>dispatch(deleteLgLLPAc(ownProps.params,lg_slug, id)),
    deletePropellerLLP:(propeller_slug, id) => dispatch(deletePropellerLLPAc(ownProps.params, propeller_slug, id)),
    exportLLPs:(lg_slug) => dispatch(exportLgLLpsAc(ownProps.params, lg_slug)),
    importLLPs:(file, lg_slug) => dispatch(importLgLLPsAc(ownProps.params, file, lg_slug)),
    importPropellerLLPs:(file, propeller_slug) => dispatch(importPropellerLLPsAc(ownProps.params, file, propeller_slug)),
    exportPropellerLLPs:(propeller_slug) => dispatch(exportPropellerLLpsAc(ownProps.params, propeller_slug)),
    fetchPortfolios: (filters) => dispatch(fetchPortfolioListAC(filters)),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LGDetails));

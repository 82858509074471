import React from 'react';
const RoleBaseUser = ({item, index}) => {
  return (
    <tr style={{cursor: 'pointer'}}>
      <td>{item.name}</td>
      <td>{item.email}</td>
      <td>{item.designation}</td>
    </tr>
  )
}
export default RoleBaseUser;

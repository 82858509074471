import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';
import { Router, Route, IndexRoute, browserHistory } from 'react-router';
import { Login, ChangePassword, UserProfile, AssetsListing, AssetView, ForgotPassword, ResetPassword, ResetSuccessfull, SelectLessor, PrivacyPolicy, TermsAndCondition, UsersList, EditUser, UserInviteList } from './User';
import { Listing, HeadLines, MRRate, FinanceDetail, ReturnCondition, LeaseRentalRates, QualifiedMaintenance, DeliveryCondition, InductionCondition, LeaseOption, Insurance, SubLease, Party, Obligations, WorkingGroup, SecurityDeposit, CapeTownConvention, Utilization, AddEditUtils, ContractCashFlow, ContractDashboard, ViewInvoice, EditInvoice, Invoice, MRAccural, MrAccrualCashFlow, HalfLifeAdjustment, DataLoglist, DataLogView } from './applications/contracts';
import {
	InvestmentManagement,
	ProjectManagement,
	AircraftManagement
} from './staticPages';
import {ContractsFleetDb } from './applications/dashboards';
import { AircraftTechSummary } from './applications/console';
import { ADReviewLogs, AirworthinessView, EasaDatabaseList, TechSpecsLogs, TechSpecsLogView } from './applications/MlModel';
import { AutoRenamingandOrg, RenameAndOrgView } from './applications/records/AutoRenaming';
import { PageNotFound, NoAccessApps } from './shared';
import Faqs from './shared/components/Faqs';
import FeaturesList from './shared/components/FeaturesList';
import App from './App';
import requireAuth from './utils/requireAuth';
import LoggedUserRed from './utils/LoggedUserRed';
import { Onboarding } from './phase2/auth';
import {Pricing} from './phase2/auth';
import { Users, Storage, Inspections, FleetCashflow, Contracts, Maintenance, DataTransfer, MRCalculator, Cart, PaymentMethods } from './phase2/licenses';
import { InvoiceIndex, InvoiceDetail } from './phase2/billing';
import { ConfIndex } from './phase2/ConfigureSettings';
import { StorageListing } from './phase2/storage';
import { Notification } from './phase2/notification';
import { ReportExport } from './applications/reports';
import NewsFeed from './applications/newsFeed/containers/index';
import BlogShow from './applications/newsFeed/containers/Blog';
import SecurityPermissions from './User/containers/SecurityPermissions';
import { Role, User } from './applications/settings/containers';
import Analytics from './applications/analytics/containers/Analytics';

const store = configureStore();
ReactDOM.render(
	<div>
	<Provider store={store}>
		<Router history={browserHistory}>
			<Route path="/" component={App}>
				<IndexRoute component={requireAuth(ContractsFleetDb)}/>
				<Route path="/reset-successfully" component={LoggedUserRed(ResetSuccessfull)}/>
				<Route path="/create-successfully" component={LoggedUserRed(ResetSuccessfull)}/>
				<Route path="/password/reset/:token" component={LoggedUserRed(ResetPassword)}/>
				<Route path="/password/create/:token" component={LoggedUserRed(ResetPassword)}/>
				<Route path="/signup" component={LoggedUserRed(Onboarding)} />
				<Route path="/otp-verification/:token" component={LoggedUserRed(Onboarding)} />
				<Route path="/login" component={LoggedUserRed(Onboarding)} />
				<Route path="/termscondition" component={LoggedUserRed(Pricing)} />
				<Route path="/data-policy" component={LoggedUserRed(Pricing)} />
				<Route path="/select-lessor" component={requireAuth(Onboarding)} />
				<Route path="/forgot-password" component={LoggedUserRed(Onboarding)} />
				<Route path="/user/activate/:token" component={LoggedUserRed(Onboarding)} />
				<Route path="/pricing" component={Pricing} />
				<Route path="/change-password" component={requireAuth(ChangePassword)} />
				<Route path="/user-profile" component={requireAuth(UserProfile)} />
				<Route path="/privacy-policy" component={PrivacyPolicy}/>
				<Route path="/terms" component={TermsAndCondition}/>
				<Route path="/assets-listing" component={requireAuth(AssetsListing)} />
				<Route path="/asset-view/:type/:aircraft_slug(/:action)" component={requireAuth(AssetView)} />
				<Route path='/:type/:aircraft_slug/contracts' component={requireAuth(Listing)}/>
				<Route path="/fleet/contracts/dashboard" component={requireAuth(ContractsFleetDb)} />
				<Route path='/:type/:aircraft_slug/contract/list/:contract_slug' component={requireAuth(HeadLines)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/working-group' component={requireAuth(WorkingGroup)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/parties' component={requireAuth(Party)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/obligations' component={requireAuth(Obligations)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/sub-lease' component={requireAuth(SubLease)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/insurance' component={requireAuth(Insurance)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/security-deposit' component={requireAuth(SecurityDeposit)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/cape-town-convention' component={requireAuth(CapeTownConvention)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/lease-option' component={requireAuth(LeaseOption)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/induction-condition' component={requireAuth(InductionCondition)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/delivery-condition' component={requireAuth(DeliveryCondition)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/return-condition' component={requireAuth(ReturnCondition)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/mr-rate' component={requireAuth(MRRate)} />
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/finance-detail' component={requireAuth(FinanceDetail)} />
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/lease-rental-condition' component={requireAuth(LeaseRentalRates)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/qualified-maintenance' component={requireAuth(QualifiedMaintenance)}/>
				<Route path='/:type/:aircraft_slug/contract/half-life-maintenance-adjustments' component={requireAuth(HalfLifeAdjustment)} />
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/extract-file-list' component={requireAuth(DataLoglist)} />
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/extract-file/:id' component={requireAuth(DataLogView)} />
				<Route path='/:type/:aircraft_slug/contract/utilization' component={requireAuth(Utilization)} />
				<Route path='/:type/:aircraft_slug/contract/utilization/add' component={requireAuth(AddEditUtils)} />
				<Route path='/:type/:aircraft_slug/contract/cash-flow' component={requireAuth(ContractCashFlow)}/>
				<Route path='/:type/:aircraft_slug/contract/mr-accrual' component={requireAuth(MRAccural)}/>
				<Route path='/:type/:aircraft_slug/contract/mr-accrual-cash-flow' component={MrAccrualCashFlow} />
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/invoice/edit/:id' component={requireAuth(EditInvoice)}/>
				<Route path='/:type/:aircraft_slug/contract/:contract_slug/invoice/view/:id' component={requireAuth(ViewInvoice)}/>
		 		<Route path='/:type/:aircraft_slug/contract/dashboard' component={requireAuth(ContractDashboard)}/>
				<Route path='/:type/:aircraft_slug/contract/invoice' component={requireAuth(Invoice)}/>
				<Route path="/restricted-access/:type" component={requireAuth(NoAccessApps)} />
				<Route path="/users/manage/active" component={requireAuth(UsersList)} />
				<Route path="/users/manage/inactive" component={requireAuth(UsersList)} />
				<Route path="/users/manage/active/:id" component={requireAuth(EditUser)} />
				<Route path="/users/permissions" component={requireAuth(SecurityPermissions)} />
				<Route path="/:type/:aircraft_slug/airworthiness-list" component={requireAuth(ADReviewLogs)} />
				<Route path="/:type/:aircraft_slug/techspecs-logs" component={requireAuth(TechSpecsLogs)} />
				<Route path="/:type/:aircraft_slug/easa-list" component={requireAuth(EasaDatabaseList)} />
				<Route path="/:type/:aircraft_slug/airworthiness-detail/:id" component={requireAuth(AirworthinessView)} />
				<Route path="/:type/:aircraft_slug/techspecs-logs/:id" component={requireAuth(TechSpecsLogView)} />
				<Route path="/billing/invoices" component={requireAuth(InvoiceIndex)} />
				<Route path="/configure" component={requireAuth(ConfIndex)} />
				<Route path="/billing/payment-methods" component={requireAuth(PaymentMethods)} />
				<Route path="/billing/invoice/detail/:id" component={requireAuth(InvoiceDetail)} />
				<Route path="/storage-listing" component={requireAuth(StorageListing)} />
				<Route path="/notifications" component={requireAuth(Notification)} />
				<Route path="/export/reports" component={requireAuth(ReportExport)} />
				<Route path="/newsfeed" component={requireAuth(NewsFeed)} />
				<Route path="/newsfeed/:slug" component={requireAuth(BlogShow)} />
				<Route path="/faqs" component={requireAuth(Faqs)} />
				<Route path="/whats-new" component={requireAuth(FeaturesList)} />
				<Route path="/analytics" component={requireAuth(Analytics)} />
			</Route>
			<Route path="/banking" component={InvestmentManagement} />
			<Route path="/engineering" component={ProjectManagement} />
			{/* <Route path="/error" component={ErrorPage} /> */}
			<Route path="/aviation" component={AircraftManagement} />
			<Route path='*' exact={true} component={PageNotFound} />
		</Router>
	</Provider></div>,
	document.querySelector('.root')
);

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { withRouter, browserHistory, Link } from 'react-router';
import { EditSidebar, ContractSideBar, FormModeType } from '../../Elements'
import Header from '../../Elements/Header';
import { HorizontalInfoCard,LessorCard,LesseeCard,EditLesseeType,EditLeaseType,EditLessorType } from "../components";
import { contrHdlineInfoAc,contrEditHdlineInfoAc, portfolioAc } from '../actionCreators';
import { sLesseeListAc, sOperatorListAc, sGlobalConstantAc, sExportContractsAc, sAuditLogAc} from '../../../../shared/actionCreators';
import { ListLoader } from '../../../../shared';
import '../../assets/styles/Headline.scss';
import { CHANGE_HD_INFO_OVERLAY, CLEAR_CONTRACT_VIEW } from '../actions';
import { S_AUDITLOG_ADD_DEL_VIEW } from '../../../../shared/actions';
import AddDeleteAudit from '../../../../shared/components/AddDeleteAudit';
import UpdateAuditLog from '../../../../shared/components/UpdateAuditLog';
import NoRecordFound from '../../../../shared/components/NoRecordFound';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { getLocalStorageInfo } from '../../../../utils/';
import { trackActivity } from '../../../../utils/mixpanel';
import { imgStoragePath, assetsType } from '../../../../constants';
import {getOwnerDropdownListAc} from '../../../../phase2/ConfigureSettings/actionCreators';
import revisionHistoryIcon from '../../../../shared/assets/img/revision_history_icon.svg';
import backArrowIcon from '../../../../shared/assets/img/back_arrow.svg';
class HeadLines extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editLeaseSideBar: false,
      editLessorSideBar: false,
      editLesseeSideBar: false,
      filesList: [],
      loader: false
    }
  }

  uploadFiles = (files) => {
    this.setState({loader: true})
    let filesList = []
    let index = 0
    for(let file of files){
      filesList = [...filesList, {name: file.name, id: index}]
      index++
    }
    setTimeout(() => {
      this.setState({filesList: filesList, loader: false})
    }, 5000)
  }

  componentDidMount(){
    this.props.fetchHdline();
    this.props.fetchTechConstant({constant_types:['lease_type_accounting', 'lease_type']});
    if(!this.props.lessees.length){
      this.props.fetchLesseeList();
    }
    if(!this.props.operators.length){
      this.props.fetchOperatorList();
    }
    this.props.getOwnerList();
  }

  changeMsn =(evt) =>{
    if(evt.contracts == false){
      let url = '/asset-view/'+assetsType[evt.asset_type]+'/'+evt.slug
      browserHistory.push(url)
    }else if(evt.contract_slug == null || evt.contract_slug == undefined || evt.contract_slug == ''){
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts';
      browserHistory.push(url);
    }else{
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contract/list/'+evt.contract_slug;
      browserHistory.push(url);
      this.props.params.type = assetsType[evt.asset_type];
      this.props.params.aircraft_slug = evt.slug;
      this.props.params.contract_slug = evt.contract_slug;
      this.componentDidMount();
    }

  }

  componentWillUnmount(){
    this.props.clearContractInfo();
  }
  toggleEditSideBar = (type) => {
    this.setState(prevState => ({
      ...prevState,
      [type]: !prevState[type],
      hdInfoOverlay: !prevState[type]
    }));
  }

  render() {
    const { contrHdlineInfo, assetViewId, lessees, operators, portfolioList,  hdLineLoader, techConstants, auditLogView, params,contractSummaryLoader, ownerList } = this.props;
    const userInfo = getLocalStorageInfo();
    let assetTypeNames = {
      3: 'APU',
      4: 'LG',
      5: 'PROPELLER'
    }
    return (
      <div className="contract-container">
        <Header type="brief" headingText="Lease Management" pageTitle="Headlines" params = {this.props.params} changeMsn={this.changeMsn}  exportContract={this.props.exportContract}   />
        <ContractSideBar  application="Headlines" type="brief" params = {this.props.params} />
        {hdLineLoader || this.state.loader ? <ListLoader />:null}
        { contractSummaryLoader?<ListLoader />:null}
        <div className="contract-content-blk">
          <Link to={"/" +params.type+ "/" +params.aircraft_slug+ "/contracts"} style={{color:'#bd10e0', fontSize:'16px', fontWeight:'700'}}>
            <img width="16" src={backArrowIcon} alt="Back Icon" style={{marginRight:'3px'}}/> Back to Lease Details
          </Link>
          {Object.keys(contrHdlineInfo.hdlineInfo).length ?
            <div className="contract-headline-block">
              <h4 className="gama">Headlines
                {/* {userInfo.user.permission.contracts && !userInfo.user.permission.contracts.is_saas ?
                <Link style={params.type == 'aircraft' ? {} : {display: 'none'}} to={"/"+params.type+"/"+params.aircraft_slug + "/contract/"+params.contract_slug+"/extract-file-list"} className="ml-export-link hidden-xs"><img height="40" src={imgStoragePath+"upload_ml_img.png"} alt="img"/></Link>
                :null} */}
                <Link className="auditlog-btn position"  style={{ float: 'right', 'marginRight': '2px', 'display': 'inline-block'}} onClick= {()=>{this.props.toggleAuditlogFn('contracts', 'ctcontracts',  '0,1', contrHdlineInfo.hdlineInfo.id, 'update', 'headlines', 'params'); trackActivity('Revision History', { page_title: 'Headlines', application_module: 'Contracts', asset:assetViewId.asset_type === 1 ? `MSN ${assetViewId.msn}` : [3,4,5].includes(assetViewId.asset_type) ? `${assetTypeNames[assetViewId.asset_type]} ${assetViewId.serial_number}` : `ESN ${assetViewId.esn}`,contract_slug:`${contrHdlineInfo.slug}`, item_type: 'Headlines'})}}> <img className="audit-img" width="15" src={revisionHistoryIcon} alt="img"/> Revision History</Link>
              </h4>
                <Fragment>
                  <Row className="flex-not-centered">
                    <Col xs="12" md="12" >
                      <div className="vertical-card card-block" style={{marginBottom: '10px'}}>
                        <h5 className="para avergare-monthly-heading" >Attachments <a className="btn btn-primary add-new" style={{fontSize: '12px', marginLeft: '10px'}} onClick={() => document.querySelector("#attachments").click()}>Add Files</a>
                        <input type="file" style={{display: 'none'}} multiple onChange={(event) => this.uploadFiles(event.target.files)} id="attachments"/>
                        <span className="as-of-date">
                          {
                            this.state.filesList.length ?
                            <ul style={{marginTop: '10px', marginLeft: '10px', borderTop: '1px solid #f6f6f6'}}>
                              {
                                this.state.filesList.map((file) => <li style={{fontSize: '12px', color: 'blue'}}><a href={`https://sparta-aims.ams3.digitaloceanspaces.com/others/demo-assets/MSN0505-03-21-MR1_Invoice.pdf`} target="_blank">{file.name}</a> <span onClick={() => this.setState({filesList: this.state.filesList.filter(f => f.id !== file.id)})} style={{color: 'red', cursor: 'pointer'}}><img src={imgStoragePath+'icon_trash.png'} alt="" width="15" /></span></li>)
                              }
                            </ul>
                            :<div style={{marginTop: '10px', marginLeft: '10px', borderTop: '1px solid #f6f6f6'}}>No Attachments Found</div>
                          }
                        </span>
                        </h5>
                      </div>
                    </Col>
                    <LessorCard toggleEditSideBar={() => this.toggleEditSideBar('editLessorSideBar')} lessorInfo={contrHdlineInfo.hdlineInfo}/>
                    <LesseeCard toggleEditSideBar={() => this.toggleEditSideBar('editLesseeSideBar')} lesseeInfo={contrHdlineInfo.hdlineInfo}/>
                  </Row>
                  <HorizontalInfoCard toggleEditSideBar={() => this.toggleEditSideBar('editLeaseSideBar')} leaseInfo={contrHdlineInfo.hdlineInfo}/>
                </Fragment>
            </div>
            :null
          }
        </div>
        { Object.keys(contrHdlineInfo.hdlineInfo).length ?
          <EditSidebar
            title="Edit Lessor Information"
            editSideBar={this.state.editLessorSideBar}
            toggleEditSideBar={() => this.toggleEditSideBar('editLessorSideBar')}
          >
            { this.state.editLessorSideBar ?
              <EditLessorType
                type="edit"
                portfolioList={portfolioList}
                contract={contrHdlineInfo.hdlineInfo}
                updateHdlineInfo={(data)=>this.props.updateHdlineInfo(data, 'Lessor', assetViewId)}
                toggleEditSideBar={() => this.toggleEditSideBar('editLessorSideBar')}
                ownerList={ownerList}
                />:null
            }
          </EditSidebar>:null
        }
        {Object.keys(contrHdlineInfo.hdlineInfo).length ?
          <EditSidebar
            title="Edit Lessee Information"
            editSideBar={this.state.editLesseeSideBar}
            toggleEditSideBar={() => this.toggleEditSideBar('editLesseeSideBar')}
          >
            { this.state.editLesseeSideBar ?
              <EditLesseeType
                type="edit"
                lessees={lessees}
                operators={operators}
                contract={contrHdlineInfo.hdlineInfo}
                updateHdlineInfo={(data)=>this.props.updateHdlineInfo(data, 'Lessee', assetViewId)}
                toggleEditSideBar={() => this.toggleEditSideBar('editLesseeSideBar')}
                hdLineLoader={this.props.hdLineLoader}
              />:null
            }

          </EditSidebar>:null
        }
        {Object.keys(contrHdlineInfo.hdlineInfo).length ?
          <EditSidebar
            title="Edit Lease Information"
            editSideBar={this.state.editLeaseSideBar}
            toggleEditSideBar={() => this.toggleEditSideBar('editLeaseSideBar')}>
            { this.state.editLeaseSideBar?
              <div>
                <EditLeaseType
                  type="edit"
                  contract={contrHdlineInfo.hdlineInfo}
                  techConstants={techConstants}
                  updateHdlineInfo={(data)=>this.props.updateHdlineInfo(data, 'Lease', assetViewId)}
                  toggleEditSideBar={() => this.toggleEditSideBar('editLeaseSideBar')}
                  hdLineLoader={this.props.hdLineLoader}
                  />
                </div>
              :null
            }
          </EditSidebar>:null
        }

        <EditSidebar
          title="Revision History"
          editSideBar={auditLogView.flag}
          toggleEditSideBar={() => this.props.closeAuditSidebar({type: 'add', data: {}, flag: false})}
        >
         {Object.keys(auditLogView.data).length && auditLogView.flag ?
           <div>
             <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'20px',  padding: '5px 22px 5px 25px' }}>
               {auditLogView.type == "add" ?
                  auditLogView.data.map((item, index) =>
                    <AddDeleteAudit auditLogView={item} key={index}/>
                  ) :
                  auditLogView.data.map((item, index) =>
                    <UpdateAuditLog auditLogView={item} key={index}/>
                  )
                }
            </div>
          </div>
          : <NoRecordFound title="No Revision History Found"/>
         }
        </EditSidebar>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  contrHdlineInfo: state.ContrHdlineReducer.contrHdlineInfo,
  hdLineLoader: state.ContrHdlineReducer.hdLineLoader,
  portfolioList: state.ContrHdlineReducer.portfolioList,
  operators: state.sharedReducers.sOperatorList,
  lessees: state.sharedReducers.sLesseeList,
  techConstants: state.sharedReducers.techConstants,
  auditLogView: state.sharedReducers.auditLogView,
  contractSummaryLoader:state.sharedReducers.contractSummaryLoader,
  ownerList: state.confReducer.ownerDropdownList,
  assetViewId: state.userAssetReducer.assetViewId,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchHdline : () => dispatch(contrHdlineInfoAc(ownProps.params)),
    fetchLesseeList: () => dispatch(sLesseeListAc()),
    fetchOperatorList: () => dispatch(sOperatorListAc()),
    updateHdlineInfo: (data, type, assetViewId) => dispatch(contrEditHdlineInfoAc(ownProps.params, data, type, assetViewId)),
    fetchTechConstant : (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
    toggleAuditlogFn: (application, content_type, action, objectId, type, cntrctType, paramKey) => dispatch(sAuditLogAc(application, content_type, action, objectId, type, cntrctType, paramKey)),
    clearContractInfo: () => dispatch({
      type: CLEAR_CONTRACT_VIEW,
      payload: {}
    }),
    closeAuditSidebar: (data) => dispatch({
      type: S_AUDITLOG_ADD_DEL_VIEW,
      payload: data
    }),
    exportContract : (contract_slug) => dispatch(sExportContractsAc(contract_slug)),
    getOwnerList: ()=> dispatch(getOwnerDropdownListAc())
  }
}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(RoleBasedAccess(HeadLines, ['contracts', 'headlines' ,'R'])));

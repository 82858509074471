import React, {Component, Fragment} from 'react';
import {Row, Col} from 'reactstrap';
import {Link} from 'react-router';
import {Users} from '../components';
import {SecondarySideNav, ListLoader} from '../../../shared';
import { globalGetService, globalDeleteService, globalPutService } from '../../../globalServices'
import { toastFlashMessage } from '../../../utils'
class User extends Component {
  constructor(props){
    super(props)
    this.state = {
      laoder: false,
      users: {
        active_users_count: 0,
        inactive_users_count: 0,
        list: [],
        pagination: {
          current_page: 1,
          number_of_pages: 1,
          total: 0
        }
      },
      roles: []
    }
  }

  componentDidMount(){
    this.getUsersList()
  }

  getRolesList = () =>{
    globalGetService(`/v2/get-sparta-groups/?application=contracts`)
    .then(response => {
      this.setState({loader: false})
      if(response.data.statusCode === 200){
        this.setState({roles: response.data.data})
      }
    })
  }

  getUsersList = () =>{
    this.setState({loader: true})
    globalGetService(`/v2/get-user-list/?application=contracts`)
    .then(response => {
      if(response.data.statusCode === 200){
        this.setState({users: response.data.data})
        this.getRolesList()
      }
    })
  }

  revokeUser = (user) =>{
    globalDeleteService(`/v2/revoke-user-access/${user.slug}/?application=contracts`)
    .then(response => {
      if(response.data.statusCode === 200){
        this.getUsersList()
        toastFlashMessage(`${user.first_name} ${user.last_name} Removed from Projects Application`, 'success')
      }
    })
  }

  updateUserPermission = (user, roleId) => {
    this.setState({loader: true})
    globalPutService(`v2/update-user-sparta-group/${user.slug}/?application=contracts`, {sparta_group_list: [roleId]})
    .then(response => {
      this.setState({loader: false})
      if(response.data.statusCode === 200){
        this.getUsersList()
        toastFlashMessage(`${user.first_name} ${user.last_name} updated`, 'success')
      }
    })
  }

  render() {

    return(
    <section className="users-section">
      {
        this.state.loader ? <ListLoader/> : null
      }
      <SecondarySideNav/>
        <div className="user-header">
          <Row>
            <Col md="4">
              <h3>User(s)</h3>
            </Col>
            <Col md="8">
              <ul className="list-inline user-cta">
                <li className="list-inline-item"></li>
                <li className="list-inline-item">
                  <button className="btn-primary">ADD USER</button>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
        <div className="user-card-blk">
          <Users revokeUser={(user) => this.revokeUser(user)} users={this.state.users} roles={this.state.roles} updateUserPermission={(user, roleId) => this.updateUserPermission(user, roleId)}/>
        </div>
    </section>
    )
  }
}
export default User;

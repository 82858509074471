import React, { Component } from 'react';
import { Link, browserHistory } from 'react-router';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { UserManageSideBar, GroupList, AssetList, ContractList, RatingInfoModal} from '../components';
import {toastFlashMessage, getAccessToken} from '../../utils'
import { NoRecordFound } from '../../shared';
import { getLocalStorageInfo } from '../../utils';
import { connect } from 'react-redux';
import { sGlobalConstantAc } from '../../shared/actionCreators';
import { globalGetService } from '../../globalServices';
import { FieldCol, FieldGroup, FieldLabel, FieldTextarea, FieldSelect, FieldInput, FieldDate, EditSidebar } from '../../applications/contracts/Elements';
import { DeleteModal,GetInputField } from '../../applications/technicalInspection/Elements';
import classnames from 'classnames';
import { addEditUserContractAc, addEditExperienceAc,deleteUserContractAc, getUsetContractsAc, userInfoAc, editUserAc, resendInviteAc,  getGroupsListAc, deleteUserGroupAc, deleteUserAssetAc, addUserGroupAc, getAssetsListAc, addUserAssetsAc, removeUserAc,deleteExpAc, exportCVAc, userProjectRatingAc, updateUserProjectsAc, updateUserSkillRatingsAc, userStatusUpdateAc, deleteUserStatusAc } from '../actionCreators';
import { ListLoader } from '../../shared';
import { USER_CONTRACT_CRUD, USER_AVAILABILITY_CRUD, TYPE_UPDATE,GROUP_LOADER_UPDATE ,ASSET_LOADER_UPDATE, FILTER_ASSET_LIST, FILTER_SECURITY_GRP_LIST,ADD_UPDATE_EXPEREIENCE, UPDATE_USER_FIELD } from '../actions';
import { imgStoragePath, toolsBaseUrl } from '../../constants';
import PlacesAutocomplete from 'react-places-autocomplete';
import Select from 'react-select'
import { checkFloat } from '../../formValidator';
import { checkMobileNumber } from '../../formValidator';
import DropToUpload from 'react-drop-to-upload';
import PhoneInput from 'react-phone-input-2';
import { UtilizationGraph } from '../../applications/technicalInspection/Tracker/components'
import {fetchManPowerTrackerUtilizationAc} from '../../applications/technicalInspection/Tracker/actionCreators';
import editIcon from '../../shared/assets/img/edit_icon.svg';
import viewIcon from '../../shared/assets/img/view_icon.svg';
import exportIcon from '../../shared/assets/img/export_icon.svg';
// import 'react-phone-input-2/dist/style.css'
import countryList from 'react-select-country-list'
import moment from 'moment'
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
const regex = /^[0-9]*$/;
class UserProfile extends Component {
  constructor(props){
    super(props);
    this.options = countryList().getData()
    this.state = {
      updateImageFlag:false,
      profile_pic:'',
      userName: '',
      contact_no: '',
      alternative_contact_no:'',
      activeTab: '2',
      base_location:'',
      city:'',
      base_location_country_code:'',
      country:'',
      department:'',
      category:'',
      error:{},
      availibility_error: '',
      imageFile:null,
      groupModal: false,
      assetModal: false,
      deleteModal: false,
      deleteBulkModal: false,
      modalGraph:false,
      inviteUserModal: false,
      deactivateModal: false,
      deleteTitle: '',
      deleteType: '',
      deleteObject: [],
      userAssets: [],
      userGroups: [],
      addAssetToUser: [],
      addGroupToUser: [],
      special_trainings: '',
      licenses: '',
      addEditContract: false,
      contract_error: {},
      experience_error:{},
      userSkills: [],
      expData:{
        type: 'add',
        flag: false,
        data:{
          aircraft_type: '',
          year_of_experience: '',
          remarks: ''
        }
      },
     docData:{
        type: 'add',
        flag: false,
        data:{
          start_date: '',
          end_date: '',
          contract_file: '',
          fee_rate: ''
        }
      },
      deleteContract: {
        flag: false,
        id: ''
      },
      showAditionalInfo: false,
      showRating: false,
      handleDrag: false,
      project_types: [],
      competency_rating: [],
      competancyFlag: false,
      expModal: false,
      expId:'',
      deleteStatus:{
        flag: false,
        status: {}
      }
    }
    this.toggleGraph = this.toggleGraph.bind(this);
  }

  handleDrop = (files) => {
    this.updateContractImage(files[0])
    this.setState({
      handleDrag: false
    })
  }
  onDragOver = () => {
    this.setState({
      handleDrag: true

    })
  }
  onDragLeave = () => {
    this.setState({
      handleDrag: false

    })
  }

  componentDidMount(){
    this.props.fetchUserInfo();
    this.props.ratingHistory();
    this.props.fetchProjectDepartment({constant_types:['project_departments','user_category','project_name','user_competency']});
    this.props.getUsetContracts();
    if(!(getLocalStorageInfo().user.permission.console &&
    getLocalStorageInfo().user.permission.console.manage_user &&
    getLocalStorageInfo().user.permission.console.manage_user.indexOf('U') != -1) ){
      this.setState(prevState => ({
        ...prevState,
          activeTab:'2',
      }));
    }
  }

  getUserSkillsList = (value, callBack) =>{
    var sendData = function(error, data){
      callBack(error, data)
    }
      globalGetService(`console/user-skills/`,{filter: value})
      .then(response => {
        if(response.data.statusCode == 200){
          this.setState({userSkills: response.data.data})
        }
        sendData(null, {
          options: response.data.data,
        })
      })
  }


  checkForInt=(value)=>{
   return regex.test(value)
  }

  componentWillUnmount(){
    this.props.typeUpdate('view');
  }
  toggleGroupModal = (value) =>{
    this.setState({
      groupModal: value,
      addGroupToUser: [],
      deleteObjectId:[],
      deleteObject:[],
    })
    if(value){
      this.props.getGroupsList();
    }else{
      this.props.updateGroupLoader();
    }
  }

  toggleGraph() {
    this.setState(prevState => ({
      modalGraph: !prevState.modalGraph,
    }));
  }


  toggleAssetModal = (value) =>{
    this.setState({
      assetModal: value,
      addAssetToUser: [],
      deleteObjectId: [],
      deleteObject: [],
    })
    if(value){
      this.props.getAssetsList();
    }else{
      this.props.updateAssetLoader();
    }
  }
  toggleDeleteModal = (title, deleteType, deleteObject) =>{
    this.setState({
      deleteModal: !this.state.deleteModal,
      deleteTitle: title,
      deleteType: deleteType,
      deleteObjectId: [deleteObject],
      deleteObject: []
    })
  }
  toggleDeleteBulkModal = (title, deleteType, deleteObj) =>{
    this.setState({
      deleteBulkModal: !this.state.deleteBulkModal,
      deleteTitle: title,
      deleteType: deleteType,
      deleteObject: deleteObj
    })
  }
  toggleInviteModal = () =>{
    this.setState({
      inviteUserModal: !this.state.inviteUserModal,
    })
  }
  toggleDeactivateModal = () =>{
    this.setState({
      deactivateModal: !this.state.deactivateModal,
    })
  }
  toggleExpModal = () =>{
    this.setState({
      expModal: !this.state.expModal,
    })
  }
  setExpId=(id)=>{
    this.setState({
      expId: id,
    })
  }

  showAdditonalFields = () =>{
    this.setState(prevState => ({
      ...prevState,
      showAditionalInfo : !this.state.showAditionalInfo
    }));
  }
  handleChange = address => {
    this.setState(prevState => ({
      ...prevState,
        base_location: address,

      error: {
        ...prevState.error,
        base_location: ''
      }
    }));
  };
  handleSelect = address => {
    let location =  geocodeByAddress(address);
    location.then(results =>
      getLatLng(results[0]))
      .then(latLng =>
        this.setState(prevState => ({
          ...prevState,
          base_location: address
      })))
      .catch(error => console.error('Error', error));
      location.then(results =>
        results[0]
        ).then(country => {
          let place = country['address_components'].filter((data, index) =>   data['types'][0] == 'country'  );
          this.setState(prevState => ({
            ...prevState,
                country: place.length ? place[0]['long_name'] : address
            }))
           })
      .catch(error => console.error('Error', error));

      location.then(results =>
        results[0]
        ).then(country => {
          let place = country['address_components'].filter((data, index) =>  data['types'][0] == 'country');
          this.setState(prevState => ({
            ...prevState,
            base_location_country_code: place.length ? place[0]['short_name']:address
            }))
        }

        )
        .catch(error => console.error('Error', error));
  };
  addUserGroup = (group) =>{
    if(this.state.userGroups.filter(item => group.id == item.id).length){
      this.setState({
        userGroups: this.state.userGroups.filter((item, index) => item.id != group.id),
        deleteObject: this.state.deleteObject.filter((item, index) => item != group.id),
      })
    }else{
      this.setState(prevState => ({
        ...prevState,
        userGroups: [ ...prevState.userGroups, group],
        deleteObject: [ ...prevState.deleteObject, group.id]
      }))
    }
  }
  addAssetToUserFn = (type, asset={}) => {
    if(type == 'single'){
      if(this.state.addAssetToUser.find(obj => obj.asset_id == asset.asset_id) && this.state.addAssetToUser.find(obj => obj.asset_type == asset.asset_type)){
        this.setState({
          addAssetToUser: this.state.addAssetToUser.filter(item => item.asset_id != asset.asset_id)
        })
      }else{
        this.setState({
          addAssetToUser: [...this.state.addAssetToUser, asset]
        })
      }
    }else if (type == 'all') {
      this.setState({
        addAssetToUser:this.state.addAssetToUser.length == this.props.assetList.length ? []:this.props.assetList,
      })
    }else{
      this.setState({
        addAssetToUser:[],
        assetModal: false
      })
    }
  }
  deleteAssetFromUserFn = () => {}
  addGroupsToUserFn = (type, group={}) => {
    if(type == 'single'){
      if(this.state.addGroupToUser.includes(group.id)){
        this.setState({
          addGroupToUser: this.state.addGroupToUser.filter(id => id != group.id)
        })
      }else{
        this.setState({
          addGroupToUser: [...this.state.addGroupToUser, group.id]
        })
      }
    }else if (type == 'all') {
      let groupIds = this.props.groupList.map(group => { return group.id});
      this.setState({
        addGroupToUser:this.state.addGroupToUser.length == groupIds.length ? []:groupIds,
      })
    }else{
      this.setState({
        groupModal: false
      })
    }
  }
  deleteGroupsFromUserFn = () => {}
  addUserAsset = (assets) =>{
    if(this.state.userAssets.filter(item => assets.id == item.id).length){
      this.setState({
        userAssets: this.state.userAssets.filter((item, index) => item.id != assets.id),
        deleteObject: this.state.deleteObject.filter((item, index) => item != assets.id),
      })
    }else{
      this.setState({
        userAssets: [ ...this.state.userAssets, assets],
        deleteObject: [ ...this.state.deleteObject, assets.id]
      })
    }
  }
  selectAllGroups = () => {
    this.setState({
      deleteObject: this.state.deleteObject.length == this.props.userProfileInfo.sparta_group.length ? [] : this.props.userProfileInfo.sparta_group.map(item => item.id)
    })
  }
  selectAllAssets = () => {
    this.setState({
      deleteObject: this.state.deleteObject.length == this.props.userProfileInfo.asset.length ? [] : this.props.userProfileInfo.asset.map(item => item.id)
    })
  }
  toggle(tab) {
    if(tab == 5){
      this.setState({
        project_types: this.props.userProfileInfo.project_types
      })
    }
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        deleteObject: []
      });
    }
  }

  handleOnChange =(e)=>{
  this.updateForm('alternative_contact_no',e.target.value)
  }

  handleOnChangeContact =(e)=>{
    this.updateForm('contact_no',e.target.value)
  }

  fetchManPowerUtilization=(queryParam)=>{
    let filterPram={...queryParam,
      user_slug:this.props.userProfileInfo.slug
    }
   this.props.fetchManPowerUtilization(filterPram);
  }


  updateForm = (type, value) => {
    let data = this.props.userProfileInfo
    this.props.updateUserField({...data, [type]: value})
    if(type=='alternative_contact_no'){
      if(value=='+'){
        value=''
      }
    }
    if(type=='contact_no'){
      if(value=='+'){
        value=''
      }
    }
    if(type == 'acumen_resume'){
      this.setState({
        acumenResume: value.name
      })
    }
    if(type == 'original_resume'){
      this.setState({
        originalResume: value.name
      })
    }
    if(value==null && value.length == 0){
      value=''
    }
    if(type == 'language_proficiency' && value.length > 0){
      this.setState(prevState => ({
        ...prevState,
        [type]: value.charAt(0).toUpperCase() + value.slice(1),
        error: {
          ...prevState.error,
          [type]: ''
        }
      }));
    }
    if(type=='category'&& value==10){
        this.setState(prevState => ({
          ...prevState,
          [type]: value,
          type:{value: 3},
          error: {
            ...prevState.error,
            [type]: '',
          }
        }));
    }
    if(type == "skill_sets" ) {
      this.setState(prevState => ({
        ...prevState,
        [type]:value,
        error: {
          ...prevState.error,
          [type]: ''
        }
      }));
    }
       this.setState(prevState => ({
        ...prevState,
        [type]:  value,
        error: {
          ...prevState.error,
          [type]: ''
        }
      }));
  }
  editUserAction = (userProfileInfo) => {
    this.setState(prevState => ({
      ...prevState,
      acumenResume: '',
      originalResume: '',
      userName:userProfileInfo.first_name+' '+(userProfileInfo.last_name?userProfileInfo.last_name:''),
      acumen_resume: userProfileInfo.acumen_resume && userProfileInfo.acumen_resume.name != '' ? null : userProfileInfo.acumen_resume,
      original_resume: userProfileInfo.original_resume && userProfileInfo.original_resume.name != '' ? null : userProfileInfo.original_resume,
      profile_pic:userProfileInfo.profile_pic,
      contact_no:userProfileInfo.contact_no?userProfileInfo.contact_no:'',
      alternative_contact_no:userProfileInfo.alternative_contact_no?userProfileInfo.alternative_contact_no:'',
      base_location:userProfileInfo.base_location,
      department:userProfileInfo.department&&userProfileInfo.department.value?userProfileInfo.department.value:'',
      category:userProfileInfo.category.value?userProfileInfo.category.value:'',
      designation:userProfileInfo.designation?userProfileInfo.designation:'',
      organisation:userProfileInfo.organisation?userProfileInfo.organisation:'',
      experience:userProfileInfo.experience?userProfileInfo.experience:'',
      skill_sets:userProfileInfo.skill_sets?userProfileInfo.skill_sets:'',
      licenses:userProfileInfo.licenses?userProfileInfo.licenses:'',
      passport_issued_by:userProfileInfo.passport_issued_by?userProfileInfo.passport_issued_by:'',
      license_endorsements:userProfileInfo.license_endorsements?userProfileInfo.license_endorsements:'',
      license_issued_by:userProfileInfo.license_issued_by?userProfileInfo.license_issued_by:'',
      special_trainings:userProfileInfo.special_trainings?userProfileInfo.special_trainings:'',
      special_approvals:userProfileInfo.special_approvals?userProfileInfo.special_approvals:'',
      fee_rate:userProfileInfo.fee_rate?userProfileInfo.fee_rate:'',
      project_types:userProfileInfo.project_types?userProfileInfo.project_types:'',
      competency_rating:userProfileInfo.competency_rating?userProfileInfo.competency_rating:'',
      additional_remarks:userProfileInfo.additional_remarks?userProfileInfo.additional_remarks:'',
      language_proficiency:userProfileInfo.additional_remarks?userProfileInfo.language_proficiency:'',
      city:userProfileInfo.city?userProfileInfo.city:'',
      type: {value: userProfileInfo.type && userProfileInfo.type.value ? userProfileInfo.type.value : 1},
      updateImageFlag:false,
      error: {}
    }));
  }
  inviteResetAction = (userProfileInfo) => {
    this.setState(prevState => ({
      ...prevState,
      userName:userProfileInfo.first_name+' '+(userProfileInfo.last_name ? userProfileInfo.last_name:''),
      profile_pic:userProfileInfo.profile_pic,
      contact_no:userProfileInfo.contact_no,
      base_location:userProfileInfo.base_location,
      department:userProfileInfo.department.value?userProfileInfo.department.value:'',
      category:userProfileInfo.category.value?userProfileInfo.category.value:'',
      alternative_contact_no:userProfileInfo.alternative_contact_no,
      updateImageFlag:false,
      error: { }
    }));
  }
  updateImage=(image)=>{
    if(image.type=='image/jpeg'||image.type=='image/png'){
      this.setState(prevState => ({
        ...prevState,
        profile_pic: URL.createObjectURL(image),
        updateImageFlag:true,
        imageFile:image
      }));
   }else{
    toastFlashMessage('Please add proper image', 'error');
   }
  }
  updateContractImage=(image)=>{
      this.setState(prevState => ({
        ...prevState,
        contract_doc: URL.createObjectURL(image),
        contractImageFlag:true,
        contractFile:image,
        contractFileName: image.name
      }));
  }
  deactivateUser(){
    var formData = new FormData();
    formData.append("is_active", false);
    this.props.userInfoUpdate(formData);
  }



  addEditContractFn = () =>{
    var formData = new FormData()
    var validateNewInput = {}
    validateNewInput = {
      ...validateNewInput,
      contract_file: !this.state.contractImageFlag && this.props.userContract.type == 'add' && (this.state.contractFile == undefined || this.state.contractFile == null) ? 'Please add document' : '',
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      if(this.state.contractImageFlag){
        formData.append("contract_file", this.state.contractFile);
      }
      if(this.props.userContract.data.start_date != null && this.props.userContract.data.start_date.trim() != ''){
        formData.append("start_date", this.props.userContract.data.start_date);
      }
      if(this.props.userContract.data.end_date != null && this.props.userContract.data.end_date.trim() != ''){
        formData.append("end_date",this.props.userContract.data.end_date);
      }
      if(this.props.userContract.data.name != null && this.props.userContract.data.name.trim() != ''){
        formData.append("name", this.props.userContract.data.name);
      }
      this.props.addEditUserContract({...this.props.userContract, data: formData}, this.props.userContract.data.id);
      this.setState({
        contract_error: {},
        contractFileName: ''
      });
    }else{
      this.setState({
        contract_error: validateNewInput
      });
    }
  }




  addEditContractFlag = (flag) =>{
    this.props.addEditContract(this.state.docData)
    this.setState(prevState => ({
      ...prevState,
      contract_error: {...prevState.contract_error,
        fee_rate: null,
        start_date: null,
        end_date: null,
        contract_file: null,
        addEditContract: flag,
        contractFileName: ''
      }
    }))
  }


  addEditExperienceFn = () =>{
    var formData = new FormData()
    var validateNewInput = {}
    validateNewInput = {
      ...validateNewInput,
      aircraft_type: this.props.experienceData.data.aircraft_type ? '':'Please enter Aircraft Type',
      year_of_experience:checkFloat({value: this.props.experienceData.data.year_of_experience, required: true, minLength: '', maxLength: '', message: 'Please Enter Valid Data'}),
    }
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {

      if(this.props.experienceData.data.aircraft_type != null && this.props.experienceData.data.aircraft_type.trim() != ''){
        formData.append("aircraft_type", this.props.experienceData.data.aircraft_type);
      }
      if(this.props.experienceData.data.year_of_experience != null && this.props.experienceData.data.year_of_experience!= ''){
        formData.append("year_of_experience", new Number(parseFloat(this.props.experienceData.data.year_of_experience)).toFixed(1));
      }
      if(this.props.experienceData.data.remarks != null){
        formData.append("remarks",this.props.experienceData.data.remarks);
      }
      this.props.addEditExperience({...this.props.experienceData, data: formData}, this.props.experienceData.data.id);
      this.setState({
        experience_error: {}
      });
    }else{
      this.setState({
        experience_error: validateNewInput
      });
    }
  }

  addEditAvailabilityFn = () =>{
    let data = this.props.userAvailability.data
    this.setState((prevState) => ({
      ...prevState,
      availibility_error: {...prevState.availibility_error,
        from_date: data.from_date.trim() == '' ? 'Please select from date' : '',
        to_date: data.to_date.trim() == '' ? 'Please select to date' : '',
        remarks: data.remarks.trim() == '' ? 'Please enter remarks' : '',
      }
    }), () => {
      if(!Object.keys(this.state.availibility_error).filter(key => this.state.availibility_error[key] != '').length){
        this.props.userStatusUpdate(this.props.userAvailability)
      }
    })
  }

  addEditExperienceFlag = (flag) =>{
    this.props.toggleExpereience(this.state.expData)
    this.setState(prevState => ({
      ...prevState,
      experience_error: {...prevState.experience_error,
        aircraft_type: null,
        year_of_experience: null
      }
    }))
  }

  getRating=(userProfileInfo)=>{
    var total= userProfileInfo.competency_rating && userProfileInfo.competency_rating.length ?
     parseFloat((userProfileInfo.competency_rating.reduce((total, item) => parseFloat(total) + parseFloat(item.skill_rate), 0)/userProfileInfo.competency_rating.length).toFixed(2)) : '--'

     return total;
  }

  editUserInfo = (e) => {
    if(e){
      e.preventDefault();
    }
    let objError = {
      userName: this.state.userName.trim() == '' ? 'This Field is Required':'',
    }
    if (Object.keys(objError).every((k) => { return objError[k] === '' })) {
    var formData = new FormData();
    if(this.state.updateImageFlag){
      formData.append("profile_pic", this.state.imageFile);
    }
    if(this.state.userName!= ''){
      formData.append("first_name", this.state.userName.substring(0,this.state.userName.indexOf(' ')));
      formData.append("last_name", this.state.userName.substring(this.state.userName.indexOf(' ')));
    }
    formData.append("contact_no", this.state.contact_no== null ? '':this.state.contact_no);
    formData.append("acumen_resume", this.state.acumen_resume== null ? '':this.state.acumen_resume);
    formData.append("original_resume", this.state.original_resume== null ? '':this.state.original_resume);
    formData.append("alternative_contact_no", this.state.alternative_contact_no== null ? '' :this.state.alternative_contact_no);
    formData.append("designation", this.state.designation== null ? '':this.state.designation);
    formData.append("department", this.state.department==null?'':this.state.department);
    formData.append("organisation", this.state.organisation==null?'':this.state.organisation);
    formData.append("base_location", this.state.base_location==null?'':this.state.base_location);
    formData.append("city", this.state.city==null?'':this.state.city);
    formData.append("base_location_country_code", this.state.base_location_country_code==null?'':this.state.base_location_country_code);
    formData.append("country", this.state.country==null?'':this.state.country);
    formData.append("category", this.state.category==null?'':this.state.category);
    formData.append("type", this.state.type.value);
    if(this.props.userProfileInfo.type.value == 3 || this.props.userProfileInfo.type.value == 1){
      formData.append("experience", this.state.experience== null ? '':this.state.experience);
      formData.append("skill_sets", this.state.skill_sets== null ? '' :this.state.skill_sets.map(skill => skill.id));
      formData.append("licenses", this.state.licenses== null ? '':this.state.licenses);
      formData.append("passport_issued_by", this.state.passport_issued_by== null ? '':this.state.passport_issued_by);
      formData.append("license_endorsements", this.state.license_endorsements== null ? '':this.state.license_endorsements);
      formData.append("license_issued_by", this.state.license_issued_by== null ? '':this.state.license_issued_by);
      formData.append("special_trainings", this.state.special_trainings==null?'':this.state.special_trainings);
      formData.append("special_approvals", this.state.special_approvals==null?'':this.state.special_approvals);
      formData.append("additional_remarks", this.state.additional_remarks==null?'':this.state.additional_remarks);
      formData.append("language_proficiency", this.state.language_proficiency);
      formData.append("project_types", JSON.stringify(this.state.project_types));
      if(this.state.fee_rate){
        formData.append("fee_rate",this.state.fee_rate);
      }
    }
    this.props.userInfoUpdate(formData);
    this.setState({
      acumenResume: '',
      originalResume: ''
    })
  }else{
    this.setState({
      error: objError,
    });
   }
  }
  showRating = () => {
    this.setState(prevState => ({
      ...prevState,
      showRating : !this.state.showRating
    }))
  }

  updateProjectTypes = (project) =>{
    this.setState({
      project_types: this.state.project_types.filter(item => item.project_type_value == project.project_type_value).length ? this.state.project_types.map(item => item.project_type_value == project.project_type_value ? {...item, no_of_projects: project.no_of_projects} : item) : [...this.state.project_types, project]
    })
  }

  updateRatingBlur = (id) =>{
    this.setState({
      competency_rating: this.state.competency_rating.filter(item => (item.skill_id == id&&item.skill_rate=='')).length ? this.state.competency_rating.map(item => (item.skill_id == id && item.skill_rate=='') ? {...item, skill_rate: 0} : item) : this.state.competency_rating
    })
  }

  updateSkillRatings = (skill) =>{
    this.setState({
      competency_rating: this.state.competency_rating.filter(item => item.skill_id == skill.skill_id).length ? this.state.competency_rating.map(item => item.skill_id == skill.skill_id ? {...item, skill_rate: skill.skill_rate} : item) : [...this.state.competency_rating, skill]
    })
  }

  updateProjects = (id) =>{
    var project_list=[];
    project_list = this.state.project_types.filter(item => (item.project_type_value == id && item.no_of_projects=='')).length ? this.state.project_types.map(item => (item.project_type_value == id && item.no_of_projects=='')? {...item, no_of_projects: 0} : item) : this.state.project_types
    this.setState({
      project_types:project_list,
    })
    var formData = new FormData()
    formData.append("project_types", JSON.stringify(project_list));
    this.props.updateUserProjects(formData)
  }

  updateRating=(id,value)=>{
    if(isNaN(value) || value < 0 || value > 5 ){
      this.updateSkillRatings({skill_id: id, skill_rate: ''})
    }else{
      this.updateSkillRatings({skill_id:id, skill_rate:value})
    }
  }

  updateProjectCounts=(id,value)=>{
    if(isNaN(value) || value < 0 || !regex.test(value) ){
      this.updateProjectTypes({project_type_value: id, no_of_projects:''})
    }else{
      this.updateProjectTypes({project_type_value: id, no_of_projects:value})
    }
  }



  updateUserSkillRatings = () =>{
    this.props.updateUserSkillRatings(this.state.competency_rating)
    this.setState({
      competancyFlag: false
    })
  }

  render(){
    var countries = this.options.map(item => {return {...item, value: item.label}})
    const userInfo = getLocalStorageInfo();
    const { error, contract_error, userSkills,experience_error, availibility_error } = this.state;
    const { userAvailabilityList, userAvailability, userContract, userContracts, type, userProfileInfo, userProfileLoader, processing, groupList, assetList, groupLoader,assetLoader ,techConstants,deleteExpLoader,utilizationData,infiniteLoader} = this.props;
    var userType = this.state.type && this.state.type.value ? this.state.type.value : userProfileInfo.type && userProfileInfo.type.value ? userProfileInfo.type.value : 1
    var showContractor = window.location.href.includes(toolsBaseUrl) ? false : true
    showContractor = true
    showContractor = getLocalStorageInfo().defaultLessor.lessor_type == 1 ? true : false
    return(
      <div className="contract-container user-profile-blk">
        <UserManageSideBar />
        {deleteExpLoader? <ListLoader/>:null}
        {infiniteLoader?<ListLoader/>:null}
        { userProfileLoader ? <ListLoader/>:
        <div>
          <div className="user-profile">
            <h4>
              <Link className="back-link-profile" onClick={() => browserHistory.goBack()}>
                <img width="15" src={imgStoragePath+"back_arw.png"} alt="img"/>
                <span style={{color: '#9439E4', marginLeft:'5px'}}> Profile </span>
              </Link>
              {
              <span onClick={() => this.props.exportCV(userProfileInfo.slug, userProfileInfo.first_name ) } style={!showContractor ? {display: 'none'} : {paddingLeft: '16px', cursor: 'pointer', color: '#3f51b5', fontSize: '12px', float: 'right', fontFamily : 'Conv_IBMPlexSans-Regular'}}><img width="15" src={exportIcon} alt="export" style={{ marginRight: '5px' }} />Export CV</span>
              }
              {
                userProfileInfo.show_utilization ?
                  <span onClick={()=>{this.toggleGraph(); this.props.fetchManPowerUtilization({user_slug:userProfileInfo.slug})}} style={{paddingLeft: '16px', cursor: 'pointer', color: '#3f51b5', fontSize: '12px', float: 'right', fontFamily : 'Conv_IBMPlexSans-Regular',marginRight:'10px'}}><img src={`${imgStoragePath}phase-2/mini-graph.png`} width="13" style={{marginRight: '3px'}}/>Show Utilization</span>
                :null
              }
            </h4>
            <form onSubmit={(e) => this.editUserInfo(e)}>
              <Row className="user-detail">
                <FieldCol md="2">
                  <div className="user-profile-pic">
                    <div className="update-img-block">
                      { this.state.profile_pic== null || this.state.profile_pic == '' ?
                        userProfileInfo.profile_pic ?
                        <img className="profile-pic"  src={userProfileInfo.profile_pic} alt="img"/>
                        :
                        <img  src={imgStoragePath+"default_lessor.png"} alt="img"/>:<img className="profile-pic" src={this.state.profile_pic} alt="img"/>
                      }
                      { type == 'edit' ?
                       <h4 style={{color: '#3f51b5'}}>Edit<input type="file" onChange={(e)=>this.updateImage(e.target.files[0])}/></h4> : null }
                    </div>
                  </div>
                  <FieldCol md="12" style={{width: '200px'}} className="update-img-block">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label">
                        <span>Resume {type=='edit' ? <h4 style={{display: 'inline'}}>{userProfileInfo.original_resume ? "Change" : "Upload"}</h4> : ''}</span><br/> { type == 'edit' ?
                         <h4 style={{textAlign: 'left'}}><span>{this.state.originalResume}</span><input accept="application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document" type="file" onChange={(e)=>this.updateForm('original_resume',e.target.files[0])}/></h4> : userProfileInfo.original_resume != null  && typeof userProfileInfo.original_resume != 'object' ? <a target="_blank" href={userProfileInfo.original_resume} style={!showContractor ? {display: 'none'} : { color: '#2f8cff', fontFamily: 'Conv_IBMPlexSans-Regular', fontSize: '12px', right: '0', bottom: '0' }}>Download</a> : 'Not Available' }
                        </FieldLabel>
                       <h6 className="error-msg">{error.userName}</h6>
                     </FieldGroup>
                  </FieldCol>

                  { (getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.manage_user && getLocalStorageInfo().user.permission.console.manage_user.indexOf('U') != -1) || getLocalStorageInfo().user.security_groups.filter(group => group.id === 30).length ? <FieldCol md="12" style={{width: '200px'}} className="update-img-block">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label">
                      <span>Acumen Resume { type == "edit" && (getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.manage_user && getLocalStorageInfo().user.permission.console.manage_user.indexOf('U') != -1) ? <h4 style={{display: 'inline'}}>{userProfileInfo.acumen_resume ? "Change" : "Upload"}</h4> : ''}</span><br/>
                         <h4 style={{textAlign: 'left'}}>
                         <span>{this.state.acumenResume}</span>
                         { type == "edit" && (getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.manage_user && getLocalStorageInfo().user.permission.console.manage_user.indexOf('U') != -1) ? <input accept="application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document" type="file" onChange={(e)=>this.updateForm('acumen_resume',e.target.files[0])}/>:null}</h4> { userProfileInfo.acumen_resume != null && typeof userProfileInfo.acumen_resume != 'object' && (getLocalStorageInfo().user.security_groups.filter(group => group.id === 30).length || (getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.manage_user && getLocalStorageInfo().user.permission.console.manage_user.indexOf('U') != -1)) ? <a target="_blank" style={!showContractor ? {display: 'none'} : { color: '#2f8cff', fontFamily: 'Conv_IBMPlexSans-Regular', fontSize: '12px', right: '0', bottom: '0' }} href={userProfileInfo.acumen_resume}>Download</a> : type != 'edit' ? 'Not Available' : '' }
                        </FieldLabel>
                       <h6 className="error-msg">{error.userName}</h6>
                     </FieldGroup>
                  </FieldCol> : null}
                </FieldCol>
                <Col md="5">
                  <FieldCol md="8">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label">
                        Name{ type == 'edit' ? <sup className="reqStar"> * </sup>:null}
                        </FieldLabel>
                         <FieldInput
                          value={this.state.userName == '' ? (userProfileInfo.first_name+' '+(userProfileInfo.last_name ? userProfileInfo.last_name:'')) :this.state.userName}
                          type={type}
                          keyParam="userName"
                          updateField={this.updateForm}
                        />
                       <h6 className="error-msg">{error.userName}</h6>
                     </FieldGroup>
                  </FieldCol>
                  <FieldCol md="8">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label">Designation</FieldLabel>
                      <FieldInput
                       value={userProfileInfo.designation ? userProfileInfo.designation :''}
                       type={type}
                       keyParam="designation"
                       updateField={this.updateForm}

                     />
                    </FieldGroup>
                  </FieldCol>
                  <FieldCol md="8">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label">Email</FieldLabel>
                      <h6>{userProfileInfo.email != null ? userProfileInfo.email : '--'}</h6>
                    </FieldGroup>
                  </FieldCol>
                 {getLocalStorageInfo().defaultLessor.lessor_type==1?
                  <FieldCol md="8">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                    Category
                     </FieldLabel>
                     {type!='view'?
                     <FieldSelect
                      placeholder='Category'
                      keyParam="category"
                      value={this.state.category ==''? userProfileInfo.category && userProfileInfo.category.value ? userProfileInfo.category.value :'':this.state.category}
                      type={type}
                      options={techConstants?techConstants.filter(item => item.type == 'user_category'):[]}
                      updateField={this.updateForm}
                      labelKey='label'
                      valueKey='value'
                      name=""
                    />:
                    <h6>{userProfileInfo.category&&userProfileInfo.category.label? userProfileInfo.category.label : '--'}</h6>
                  }
                   <h6 className="error-msg">{error.category}</h6>
                  </FieldGroup>
                </FieldCol>:null}

                {(this.state.showAditionalInfo && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor) || (userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor && type == 'edit')  ? <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">Overall Work Experience</FieldLabel>
                    <FieldInput
                      value={userProfileInfo.experience ? userProfileInfo.experience  ? userProfileInfo.experience :'--':this.state.experience}
                      type={type}
                      keyParam="experience"
                      updateField={(keyParam, value) => this.updateForm('experience', value)}
                    />
                  </FieldGroup>
                </FieldCol> : null}
                {(this.state.showAditionalInfo && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor) || (userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor && type == 'edit')  ? <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">License Issued By</FieldLabel>
                    <FieldTextarea
                      value={userProfileInfo.license_issued_by ? userProfileInfo.license_issued_by  ? userProfileInfo.license_issued_by :'--':this.state.license_issued_by}
                      type={type}
                      keyParam="license_issued_by"
                      updateField={(keyParam, value) => this.updateForm('license_issued_by', value)}
                    />
                  </FieldGroup>
                </FieldCol> : null}
                {(this.state.showAditionalInfo && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor) || (userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor && type == 'edit')  ? <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">License Type Endorsements</FieldLabel>
                    <FieldTextarea
                      value={userProfileInfo.license_endorsements ? userProfileInfo.license_endorsements  ? userProfileInfo.license_endorsements :'--':this.state.license_endorsements}
                      type={type}
                      keyParam="license_endorsements"
                      updateField={(keyParam, value) => this.updateForm('license_endorsements', value)}
                    />
                  </FieldGroup>
                </FieldCol> : null}

                { getLocalStorageInfo().defaultLessor.lessor_type==1 && !getLocalStorageInfo().user.permission.console.is_saas ?
                  (type == 'edit') && showContractor ?
                  <FieldCol md="12">
                      <FieldLabel className="label">Profile Type</FieldLabel>
                    <ul className="list-inline" style={{marginBottom: '20px'}}>
                      <li className="list-inline-item">
                      <label style={{cursor:'pointer'}}>
                        <input
                          type="radio"
                          disabled={type != 'edit'? true: this.state.category==10?true:false}
                          checked={userType == 1 ? 'checked' : ''}
                          onChange={(e) => this.updateForm('type', {value: 1})}
                          style={{marginRight: '10px'}}
                        />Acumen</label>
                      </li>
                      <li className="list-inline-item">
                      <label style={{cursor:'pointer'}}>
                        <input
                          type="radio"
                          disabled={type != 'edit'? true: this.state.category==10?true:false}
                          checked={userType == 2 ? 'checked' : ''}
                          onChange={(e) => this.updateForm('type', {value: 2})}
                          style={{marginRight: '10px'}}
                        />Client</label>
                      </li>
                      <li className="list-inline-item">
                      <label style={{cursor:'pointer'}}>
                        <input
                          type="radio"
                          disabled={type != 'edit'? true: this.state.category==10?true:false}
                          checked={userType == 3 ? 'checked' : ''}
                          onChange={(e) => this.updateForm('type', {value: 3})}
                          style={{marginRight: '10px'}}
                        />Contractor</label>
                      </li>
                    </ul>
                  </FieldCol>
                  : <FieldCol md="8" >
                    <FieldGroup className="form-group">
                      <FieldLabel className="label">Profile Type</FieldLabel>
                      <FieldInput
                        value={userProfileInfo.type && userProfileInfo.type.label ? userProfileInfo.type.label :'--'}
                        type='view'
                        keyParam="contact_no"
                        updateField={this.updateForm}
                      />
                    </FieldGroup>
                  </FieldCol>:null
                }

                {(this.state.showAditionalInfo && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor) || (showContractor && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && type == 'edit' && showContractor) ? <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">Skill Sets</FieldLabel>
                    {
                      type == 'edit' ?
                      <Select.AsyncCreatable
                        multi={true}
                        options={userSkills && userSkills.length ? userSkills : []}
                        onChange={(value) => this.updateForm('skill_sets', value)}
                        value={this.state.skill_sets ? this.state.skill_sets : userProfileInfo.skill_sets ? userProfileInfo.skill_sets : []}
                        disabled={type != 'edit'}
                        valueKey="id"
                        labelKey="name"
                        autoload={true}
                        loadOptions={this.getUserSkillsList}
                      />
                      :userProfileInfo.skill_sets.length ?
                      <p style={{ display: 'block', overflow: 'hidden' }}>
                       {userProfileInfo.skill_sets.map((skill, index) => <span key={index} className="user-skill-list-item">{skill.name}</span>)}
                      </p>
                      :'--'
                    }
                  </FieldGroup>
                </FieldCol> : null}
                {userProfileInfo.rating && showContractor ?
                  <FieldCol md="12">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label">Rating  <Link style={{ color: '#3f51b5', fontFamily: 'Conv_IBMPlexSans-Regular', fontSize: '10px', position: 'relative', marginLeft: '4px'}} onClick={()=> this.showRating()}>View</Link></FieldLabel>
                      <h6>
                        {parseFloat(userProfileInfo.rating.toFixed(2))} <i class="fa fa-star" style={{ fontSize: '10px', color: '#cccccc', position: 'relative', bottom: '3px' }}></i>
                      </h6>
                    </FieldGroup>
                  </FieldCol> : null}
                  {(this.state.showAditionalInfo && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor) || (userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor && type == 'edit') ? <FieldCol md="8">
                    <FieldGroup className="form-group" style={{textTransform: "capitalize !important"}}>
                      <FieldLabel className="label">Language Proficiency</FieldLabel>
                       <FieldInput
                        value={type == 'edit' ? this.state.language_proficiency : userProfileInfo.language_proficiency}
                        type={type}
                        keyParam="language_proficiency"
                        updateField={(keyParam, value) => this.updateForm('language_proficiency', value.length > 0 ? `${value.charAt(0).toUpperCase()}${value.slice(1)}`:value)}
                      />
                    </FieldGroup>
                  </FieldCol> : null}
                </Col>
                <Col md="5">
                  <FieldCol md="8">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label" style={{marginBottom: '2px'}}>Phone Number</FieldLabel>
                      <PhoneInput
                     defaultCountry={'in'}
                     value={type=='edit'? this.state.contact_no : userProfileInfo.contact_no? userProfileInfo.contact_no :'--'}
                     type={'string'}
                     disabled={type=='edit'?false:true}
                     onBlur={this.handleOnChangeContact}/>
                      <h6 className="error-msg">{error.contact_no}</h6>
                    </FieldGroup>
                  </FieldCol>
                  <FieldCol md="4"></FieldCol>
                  <FieldCol md="8">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label"  style={{marginBottom: '2px'}} >Alternate Phone Number</FieldLabel>
                    <PhoneInput
                     defaultCountry={'in'}
                     value={type=='edit'?this.state.alternative_contact_no:userProfileInfo.alternative_contact_no?userProfileInfo.alternative_contact_no :'--'}
                     type={'string'}
                     inputExtraProps={{
                      name: 'phone',
                      required: false
                    }}
                     disabled={type=='edit'?false:true}
                     onBlur={this.handleOnChange}/>
                     <h6 className="error-msg">{error.alternative_contact_no}</h6>
                    </FieldGroup>
                  </FieldCol>
                  <FieldCol md="4"></FieldCol>
                  <FieldCol md="6">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                    Country
                     </FieldLabel>
                   {type!='view'?
                   <FieldSelect
                    placeholder='Country'
                    keyParam="base_location"
                    value={this.state.base_location ? userProfileInfo.base_location :''}
                    type={type}
                    options={countries}
                    updateField={(key, value) => this.updateForm(key, value)}
                    labelKey='label'
                    valueKey='value'
                    name=""
                  />
               :
                <h6>{userProfileInfo.base_location? userProfileInfo.base_location : '--'}</h6>
                    }
                   <h6 className="error-msg">{error.base_location}</h6>
                  </FieldGroup>
                </FieldCol>
                  <FieldCol md="6">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                    City
                     </FieldLabel>
                   {type!='view'?
                   <FieldInput
                     value={userProfileInfo.city  ? userProfileInfo.city :''}
                     type={type}
                     keyParam="city"
                     updateField={(key, value)=> this.updateForm(key, value)}
                   />
               :
                <h6>{userProfileInfo.city? userProfileInfo.city : '--'}</h6>
                    }
                   <h6 className="error-msg">{error.base_location}</h6>
                  </FieldGroup>
                </FieldCol>
                <FieldCol md="12">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                    Department
                     </FieldLabel>
                     {type!='view'?
                     <FieldSelect
                      placeholder='Department'
                      keyParam="department"
                      value={type == 'edit' ? this.state.department : userProfileInfo.department && userProfileInfo.department.value ? userProfileInfo.department.value :''}
                      type={type}
                      options={techConstants?techConstants.filter(item => item.type == 'project_departments'):[]}
                      updateField={this.updateForm}
                      labelKey='label'
                      valueKey='value'
                      name=""
                    />:
                    <h6>{userProfileInfo.department&&userProfileInfo.department.label? userProfileInfo.department.label : '--'}</h6>
                  }
                   <h6 className="error-msg">{error.department}</h6>
                  </FieldGroup>
                </FieldCol>
                <FieldCol md="12" style={{ display: 'none'}}>
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">
                    Organization
                     </FieldLabel>
                     <FieldInput
                       value={userProfileInfo.organisation  ? userProfileInfo.organisation :'--'}
                       type={type}
                       keyParam="organisation"
                       updateField={this.updateForm}
                     />
                   <h6 className="error-msg">{error.department}</h6>
                  </FieldGroup>
                </FieldCol>
                {(this.state.showAditionalInfo && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor) || (userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && type == 'edit' && showContractor) ? <FieldCol md="8">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">Special Approvals</FieldLabel>
                    <FieldTextarea
                      value={ type == 'edit' ? this.state.special_approvals :  userProfileInfo.special_approvals }
                      type={type}
                      keyParam="special_approvals"
                      updateField={(keyParam, value) => this.updateForm('special_approvals', value)}
                    />
                  </FieldGroup>
                </FieldCol> : null}
                {(this.state.showAditionalInfo && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor) || (userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor && type == 'edit') ? <FieldCol md="8">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">License</FieldLabel>
                    <FieldTextarea
                      value={type == 'edit' ? this.state.licenses : userProfileInfo.licenses}
                      type={type}
                      keyParam="licenses"
                      updateField={(keyParam, value) => this.updateForm('licenses', value)}
                    />
                  </FieldGroup>
                </FieldCol> : null}
                {(this.state.showAditionalInfo && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor) || (userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor && type == 'edit')  ? <FieldCol md="8">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">Passport Issued By</FieldLabel>
                    <FieldInput
                      value={userProfileInfo.passport_issued_by ? userProfileInfo.passport_issued_by  ? userProfileInfo.passport_issued_by :'--':this.state.passport_issued_by}
                      type={type}
                      keyParam="passport_issued_by"
                      updateField={(keyParam, value) => this.updateForm('passport_issued_by', value)}
                    />
                  </FieldGroup>
                </FieldCol> : null}
                {(this.state.showAditionalInfo && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor) || (userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && type == 'edit' && showContractor) ? <FieldCol md="8">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">Special Trainings</FieldLabel>
                    <FieldTextarea
                      value={ type == 'edit' ?  this.state.special_trainings : userProfileInfo.special_trainings }
                      type={type}
                      keyParam="special_trainings"
                      updateField={(keyParam, value) => this.updateForm('special_trainings', value)}
                    />
                  </FieldGroup>
                </FieldCol> : null}
                {showContractor ?
                  <FieldCol md="12">
                    <FieldGroup className="form-group">
                      <FieldLabel className="label">Competency / Skill Experience Rating
                      <Link style={{ color: '#3f51b5', fontFamily: 'Conv_IBMPlexSans-Regular', fontSize: '10px', position: 'relative', marginLeft: '4px'}} onClick={()=> this.setState({competancyFlag: true, competency_rating: userProfileInfo.competency_rating})}> View</Link>
                      </FieldLabel>
                      <h6>
                        {this.getRating(userProfileInfo)} <i class="fa fa-star" style={{ fontSize: '10px', color: '#cccccc', position: 'relative', bottom: '3px' }}></i>
                      </h6>
                    </FieldGroup>
                  </FieldCol> : null}
                {(this.state.showAditionalInfo && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor) || (userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor && type == 'edit') ? <FieldCol md="8">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">Daily Fee Rate</FieldLabel>
                    <FieldTextarea
                      value={type == 'edit' ? this.state.fee_rate : userProfileInfo.fee_rate}
                      type={type}
                      keyParam="fee_rate"
                      updateField={(keyParam, value) => this.updateForm('fee_rate', value)}
                    />
                  </FieldGroup>
                </FieldCol> : null}
                {(this.state.showAditionalInfo && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor) || (userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor && type == 'edit') ? <FieldCol md="8">
                  <FieldGroup className="form-group">
                    <FieldLabel className="label">Additional Remarks</FieldLabel>
                    <FieldTextarea
                      value={type == 'edit' ? this.state.additional_remarks : userProfileInfo.additional_remarks}
                      type={type}
                      keyParam="additional_remarks"
                      updateField={(keyParam, value) => this.updateForm('additional_remarks', value)}
                    />
                  </FieldGroup>
                </FieldCol> : null}
                {type =='view' && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) && showContractor ?
                  !this.state.showAditionalInfo ?
                    <Link style={!showContractor ? {display: 'none'} : { color: '#3f51b5', float: 'right', fontFamily: 'Conv_IBMPlexSans-Regular', fontSize: '12px', position: 'absolute', right: '0', bottom: '0' }} onClick={() => {this.showAdditonalFields()}}><i className="fa fa-plus-circle"></i>&nbsp;Show Additional Info</Link>
                  :<Link style={!showContractor ? {display: 'none'} : { color: '#3f51b5', float: 'right', fontFamily: 'Conv_IBMPlexSans-Regular', fontSize: '12px', position: 'absolute', right: '0', bottom: '0' }} onClick={() => {this.showAdditonalFields()}}><i className="fa fa-minus-circle"></i>&nbsp;Hide Additional Info</Link>
                :null}
                </Col>
              </Row>
              <div className={getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console &&
                  getLocalStorageInfo().user.permission.console.manage_user && getLocalStorageInfo().user.permission.console.manage_user.indexOf('U') != -1 ?  "submit-block" : type=='edit' ? "submit-block" :" " } style={{overflow: 'hidden'}}
              >
                { type == 'edit' ?
                  <div style={{display: 'inline'}}>
                    <input type="submit" id="update_user" className="primary-btn" disabled={processing ? 'disabled' : ''}  value={processing ? 'Processing...' :"Save"}/>
                    <Link style={{color:'#3f51b5'}} onClick={() => { this.editUserAction(userProfileInfo);this.props.typeUpdate('view')}}>CANCEL</Link>
                  </div>:null
                }
              </div>
            </form>
            <div>
              <div className="edit-view-tech-spec profile-tab">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '2' })}
                      onClick={() => { this.toggle('2'); }}
                      >
                      Roles
                    </NavLink>
                  </NavItem>
                  { getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console &&
                    getLocalStorageInfo().user.permission.console.manage_user && getLocalStorageInfo().user.permission.console.manage_user.indexOf('U') != -1  ?
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '3' })}
                        onClick={() => { this.toggle('3'); }}
                        >
                        Allowed Assets
                      </NavLink>
                    </NavItem>:null
                  }
                  { getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console &&
                    getLocalStorageInfo().user.permission.console.manage_user && getLocalStorageInfo().user.permission.console.manage_user.indexOf('U') != -1 && userProfileInfo.type && (userProfileInfo.type.value == 3 ) ?
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '4' })}
                        onClick={() => { this.toggle('4'); }}
                        >
                        Documents
                      </NavLink>
                    </NavItem>:null
                  }
                  { getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console &&
                    getLocalStorageInfo().user.permission.console.manage_user && getLocalStorageInfo().user.permission.console.manage_user.indexOf('U') != -1 && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) ?
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '5' })}
                        onClick={() => { this.toggle('5'); }}
                        >
                        Previous Inspections
                      </NavLink>
                    </NavItem>:null
                  }
                  { getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console &&
                    getLocalStorageInfo().user.permission.console.manage_user && getLocalStorageInfo().user.permission.console.manage_user.indexOf('U') != -1 && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) ?
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '6' })}
                        onClick={() => { this.toggle('6'); }}
                        >
                        Experience on Aircraft Type
                      </NavLink>
                    </NavItem>:null
                  }
                  { getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console &&
                    getLocalStorageInfo().user.permission.console.manage_user && getLocalStorageInfo().user.permission.console.manage_user.indexOf('U') != -1 && userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) ?
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '7' })}
                        onClick={() => { this.toggle('7'); }}
                        >
                        Availability Tracker
                      </NavLink>
                    </NavItem>:null
                  }
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  { getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console &&
                    getLocalStorageInfo().user.permission.console.manage_user && getLocalStorageInfo().user.permission.console.manage_user.indexOf('U') != -1 ?
                    <Row>
                       { this.state.activeTab=='3' ?
                        <Col sm="12">
                          <p className="notice-info" style={{marginLeft:'5px'}}>User can only access the assets listed below. If no assets are added in allowed asset list, he will have access to all the assets under the current lessor.</p>
                        </Col>
                         :null}
                      <Col sm="6">
                        { this.state.activeTab=='2' ? userProfileInfo && userProfileInfo.sparta_group && userProfileInfo.sparta_group.length ?
                          <span className="select-all" onClick={() => this.selectAllGroups()}>

                          </span>:null
                          : this.state.activeTab=='3' && userProfileInfo && userProfileInfo.asset && userProfileInfo.asset.length ?
                          <span className="select-all" onClick={() => this.selectAllAssets()}>
                            <input type="checkbox" checked={!this.state.groupModal  && !this.state.assetModal && userProfileInfo.asset && userProfileInfo.asset.length >0 && this.state.deleteObject.length == userProfileInfo.asset.length}/> Select all assets
                          </span>:null
                        }
                      </Col>

                      <Col sm="6" className="text-right">
                        <span className="nav-item" style={{ float: 'right'}}>
                          { this.state.activeTab == '2' ?
                            <Link style={{fontSize: '12px',color:'#dc3545', marginRight: '5px',textAlign:'center'}} onClick={() => this.toggleDeleteBulkModal('Delete selected groups', 'group', this.state.deleteObject)}>
                              { this.state.deleteObject.length  ? `Delete Roles (${this.state.deleteObject.length})` : null}
                            </Link>:
                            this.state.activeTab == '3' ?
                            <Link style={{fontSize: '12px',color:'#dc3545', marginRight: '5px', textAlign:'center'}} onClick={() => this.toggleDeleteBulkModal('Delete selected assets', 'assets', this.state.deleteObject)}>
                              { this.state.deleteObject.length  ? `Delete Assets (${this.state.deleteObject.length})` : null}
                            </Link>:null
                          }
                        </span>
                      </Col>
                    </Row>:null
                  }
                  <TabPane tabId="2">
                    <ul className="list-unstyled lessor-list">
                      { userProfileInfo && userProfileInfo.sparta_group && userProfileInfo.sparta_group.length ?
                        userProfileInfo.sparta_group.map((userGroup, index) =>
                          <GroupList
                            key={index}
                            deleteObject={this.state.deleteObject}
                            addUserGroup={this.addUserGroup}
                            userGroup={userGroup}
                            deleleGroup={this.toggleDeleteModal}
                          />
                        ):<NoRecordFound/>
                      }
                    </ul>
                  </TabPane>
                  <TabPane tabId="3">
                    <ul className="list-unstyled lessor-list">
                      { userProfileInfo && userProfileInfo.asset && userProfileInfo.asset.length ?
                        userProfileInfo.asset.map((asset, index) =>
                        <AssetList
                          deleteObject={this.state.deleteObject}
                          addUserAsset={this.addUserAsset}
                          key={index}
                          asset={asset}
                          deleleAsset={this.toggleDeleteModal}
                        />
                        ):<NoRecordFound/>
                      }
                    </ul>
                  </TabPane>
                  {
                    userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) ?
                    <TabPane tabId="4">
                      <div className="users-contract-list-con">
                          <Row className="users-contract-list-con-header">
                            <Col md="1">#</Col>
                            <Col md="3">Title</Col>
                            <Col md="3">Start Date</Col>
                            <Col md="3">End Date</Col>
                            <Col md="2"></Col>
                          </Row>
                            { userContracts && userContracts.length ?
                              userContracts.map((contract, index) => <ContractList key={index} deleteContract={() => this.setState({deleteContract: {flag: true, id: contract.id}})} editable={true} addEditContract={() => this.props.addEditContract({...this.props.userContract, data: contract, flag: true, type: 'edit'})} contract={contract} index={index}/>):<NoRecordFound/>
                            }
                        </div>
                    </TabPane>
                    :null
                  }
                  {
                    userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) ?
                    <TabPane tabId="5">
                      <div className="users-contract-list-con">
                          <Row className="users-contract-list-con-header">
                            <Col md="7">Inspection Type</Col>
                            <Col md="3"></Col>
                            <Col md="2"></Col>
                          </Row>
                            { techConstants && techConstants.length ?
                              techConstants.map((project, index) =>
                              project.type == 'project_name' ?
                                <Row className="users-contract-list">
                                  <Col md="7">{project.label}</Col>

                                </Row>
                              :null
                            ):<NoRecordFound/>
                            }
                        </div>
                    </TabPane>
                    :null
                  }
                  {
                    userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) ?
                    <TabPane tabId="6">
                      <div className="users-contract-list-con">
                      <div style={{textAlign:'right',marginBottom:'10px'}} >
                      </div>
                          <Row className="users-contract-list-con-header">
                            <Col md="4">Aircraft Type</Col>
                            <Col md="3">No. of Years</Col>
                            <Col md="3">Notes</Col>
                            <Col md="2"></Col>
                          </Row>
                          { userProfileInfo && userProfileInfo.experience_of_aircraft_type && userProfileInfo.experience_of_aircraft_type.length ?
                              userProfileInfo.experience_of_aircraft_type.map((experience, index) =>
                                <Row className="users-contract-list">
                                  <Col md="4">{experience.aircraft_type}</Col>
                                  <Col md="3">{new Number(parseFloat(experience.year_of_experience)).toFixed(1)}</Col>
                                  <Col md="3">{experience.remarks}</Col>
                                  <Col md="2" style={{textAlign: 'right'}}>
                                     <a onClick={() => this.props.toggleExpereience({type:'update',flag:true,data:experience})}><i style={{color: '#4382f9'}} className="fa fa-edit"></i></a>
                                     <a onClick={() => {this.toggleExpModal();this.setExpId(experience.id)}}><i style={{color: 'red'}} className="fa fa-trash"></i></a>
                                  </Col>
                                </Row>
                            ):<NoRecordFound/>
                            }
                        </div>
                    </TabPane>
                    :null
                  }
                  {
                    userProfileInfo.type && (userProfileInfo.type.value == 3 || userProfileInfo.type.value == 1) ?
                    <TabPane tabId="7">
                      <div className="users-contract-list-con">
                      <div style={{textAlign:'right',marginBottom:'10px'}} >
                      </div>
                          <Row className="users-contract-list-con-header">
                            <Col md="4">Availability Type</Col>
                            <Col md="2">From</Col>
                            <Col md="2">To</Col>
                            <Col md="3">Remarks</Col>
                            <Col md="1"></Col>
                          </Row>
                          { userAvailabilityList.list.length ?
                              userAvailabilityList.list.map((list, index) =>
                                <Row className="users-contract-list">
                                  <Col md="4">{list.status.label ? list.status.label : '--'}</Col>
                                  <Col md="2">{list.from_date}</Col>
                                  <Col md="2">{list.to_date}</Col>
                                  <Col md="3">{list.remarks}</Col>
                                  <Col md="1" style={{textAlign: 'right'}}>
                                      <a onClick={() => {this.props.addEditAvailability({type:'update',flag:true,data:{...list, status: list.status.value}});this.setState({availibility_error: {}})}}><i style={{color: '#4382f9'}} className="fa fa-edit"></i></a>
                                      <a onClick={() => this.setState({deleteStatus: {flag: true, status: list}})}><i style={{color: 'red'}} className="fa fa-trash"></i></a>
                                  </Col>
                                </Row>
                            ):<NoRecordFound/>
                            }
                        </div>
                    </TabPane>
                    :null
                  }
                </TabContent>
                <Modal isOpen={this.state.groupModal} toggle={() => this.addGroupsToUserFn('clear')} className="modal-md profile-lessor-list modal-dialog-centered">
                  <ModalHeader toggle={() => this.addGroupsToUserFn('clear')}>
                    Roles <span style={{fontSize:'14px'}}><Link to="/users/permissions/" target="_blank">View Security Permissions</Link></span> <br/>
                    { groupList.length ?
                      <label className="for-label" onClick={() => this.addGroupsToUserFn('all')}>

                      </label>:null
                    }
                    <input type="text" placeholder="Search for Roles" style={{borderBottom: '1px solid #d7d7d7', marginBottom: '5px', width: '100%'}} onChange={(e) => this.props.updateSecurityGroupsSearch(e.target.value)} />
                  </ModalHeader>
                  <ModalBody style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto', position: 'relative',paddingTop:'0px' }}>
                  { groupLoader ?
                    <Row>
                      { groupList.length ?
                        groupList.map((group, index) =>
                          <Col md="12" className="modal-list-user" key={index} onClick={() => this.addGroupsToUserFn('single',group)}>
                            <span >
                              {group.name}
                            </span>
                          </Col>
                        ): <Col md="12"><NoRecordFound/></Col>
                      }
                    </Row>:
                    <span style={{position:'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>Loading...</span>
                  }
                </ModalBody>
                <ModalFooter>
                  { groupList.length ? this.state.addGroupToUser.length ?
                    <button className="btn btn-primary btn-sm add-new"  onClick= {() =>  {this.props.addUserGroup(this.state.addGroupToUser); this.addGroupsToUserFn('clear');}}>Add</button>:
                    <button className="btn btn-primary btn-sm add-new" style={{opacity:'0.3',cursor:'disabled'}} >Add</button>:null
                  }
                  <button className="btn btn-danger btn-sm add-new" onClick={() => this.addGroupsToUserFn('clear')}>Cancel</button>
                </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.assetModal} toggle={() => this.addAssetToUserFn('clear')} className="modal-md profile-lessor-list modal-dialog-centered">
                  <ModalHeader toggle={() => this.addAssetToUserFn('clear')}>
                    Assets <br/>
                    { assetList.length ?
                      <label className="for-label" onClick={() => this.addAssetToUserFn('all')}>
                        <input type="checkbox"  checked={this.state.addAssetToUser.length == assetList.length ? true:false} /> Select All ( {assetList.length} )
                      </label>:null
                    }
                    <input type="text" placeholder="Search for Assets" style={{borderBottom: '1px solid #d7d7d7', marginBottom: '5px', width: '100%'}} onChange={(e) => this.props.updateAssetSearch(e.target.value)} />
                  </ModalHeader>
                  <ModalBody style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto', position: 'relative',paddingTop:'0px' }}>
                    { assetLoader?
                      <Row>
                        { assetList.length ?
                          assetList.map((asset, index) =>
                            <Col md="12" className="modal-list-user" key={index} onClick={() => this.addAssetToUserFn('single',asset)}>
                              <span>
                                <input style={{marginRight:'20px'}} type="checkbox"
                                  checked={this.state.addAssetToUser.find(item => item.asset_id == asset.asset_id) ? true :false}
                                />
                                {asset.asset_name}
                              </span>
                            </Col>
                          ):<Col md="12"><NoRecordFound/></Col>
                        }
                      </Row>:
                      <span style={{position:'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>Loading...</span>
                    }
                  </ModalBody>
                  <ModalFooter>
                    { assetList.length ? this.state.addAssetToUser.length?
                      <button className="btn btn-primary btn-sm add-new" onClick={() => {this.props.addUserAsstes(this.state.addAssetToUser); this.addAssetToUserFn('clear');}}>Add</button>:
                      <button className="btn btn-primary btn-sm add-new" style={{opacity:'0.3',cursor:'disabled'}} >Add</button>:null
                    }
                    <button className="btn btn-danger btn-sm add-new" onClick={() => this.addAssetToUserFn('clear')}>Cancel</button>
                  </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.competancyFlag} toggle={() => this.setState({competancyFlag: false})} className="modal-md profile-skills-list modal-dialog-centered">
                  <ModalHeader toggle={() => this.setState({competancyFlag: false})}>
                    Competency / Skill Experience Rating
                  </ModalHeader>
                  <ModalBody style={{ height: (window.innerHeight - 257) + 'px', overflow:'auto', position: 'relative',paddingTop:'0px' }}>
                  <div className="users-contract-list-con">
                    <Row className="users-contract-list-con-header">
                      <Col md="3">SL No</Col>
                      <Col md="9">Skill</Col>
                    </Row>
                    { techConstants && techConstants.length ?
                      techConstants.filter((projects) =>
                      projects.type == 'user_competency').map((project,index) =>
                        <Row className="users-contract-list">
                          <Col md="3">{index+1}</Col>
                          <Col md="9">{project.label}</Col>
                        </Row>

                  ):<NoRecordFound/>
                  }
                  </div>
                  </ModalBody>

                  <ModalFooter>
                    <button className="btn btn-primary btn-sm add-new" onClick={() => this.updateUserSkillRatings()}>Save</button>
                    <button className="btn btn-danger btn-sm add-new" onClick={() => this.setState({competancyFlag: false})}>Cancel</button>
                  </ModalFooter>
                </Modal>
                <DeleteModal title={this.state.deleteTitle} isOpen={this.state.deleteModal} className="modal-dialog-centered" toggle={() => this.toggleDeleteModal('', '')}>
                  <p style={{ textAlign:'center', marginBottom:'15px'}}>Are you sure, you want to delete ?</p>
                  <ul className="list-inline" style={{textAlign: 'center'}}>
                    <li className="list-inline-item">
                      { this.state.deleteType == 'group' ?
                        <button className="btn btn-primary btn-sm add-new" onClick={() => {this.props.deleteUserGroup(this.state.deleteObjectId); this.toggleDeleteModal('', '');}}>Yes</button>:
                        <button className="btn btn-primary btn-sm add-new" onClick={() => {this.props.deleteUserAsset(this.state.deleteObjectId); this.toggleDeleteModal('', '');}} >Yes</button>
                      }
                    </li>
                    <li className="list-inline-item">
                      <button className="btn btn-danger btn-sm add-new" onClick={() => this.toggleDeleteModal('', '')}>No</button>
                    </li>
                  </ul>
                </DeleteModal>
                <DeleteModal title="Delete Status" isOpen={this.state.deleteStatus.flag} className="modal-dialog-centered" toggle={() => this.setState({deleteStatus: {flag: false, status: {}}})}>
                  <p style={{ textAlign:'center', marginBottom:'15px'}}>Are you sure, you want to delete ?</p>
                  <ul className="list-inline" style={{textAlign: 'center'}}>
                    <li className="list-inline-item">
                        <button className="btn btn-primary btn-sm add-new" onClick={() => {this.props.deleteUserStatus(this.state.deleteStatus.status.id);this.setState({deleteStatus: {flag: false, status: {}}});}}>Yes</button>
                    </li>
                    <li className="list-inline-item">
                      <button className="btn btn-danger btn-sm add-new" onClick={() => this.setState({deleteStatus: {flag: false, status: {}}})}>No</button>
                    </li>
                  </ul>
                </DeleteModal>
                <DeleteModal title={this.state.deleteTitle} isOpen={this.state.deleteBulkModal} className="modal-dialog-centered" toggle={() => this.toggleDeleteBulkModal('','', [])}>
                  <p style={{ textAlign:'center', marginBottom:'15px'}}>Are you sure, you want to delete ?</p>
                  <ul className="list-inline" style={{textAlign: 'center'}}>
                    <li className="list-inline-item">
                      { this.state.deleteType == 'group' ?
                        <button className="btn btn-primary btn-sm add-new" onClick={() => {this.props.deleteUserGroup(this.state.deleteObject); this.toggleDeleteBulkModal('', '', []);}}>Yes</button>:
                        <button className="btn btn-primary btn-sm add-new" onClick={() => {this.props.deleteUserAsset(this.state.deleteObject); this.toggleDeleteBulkModal('', '', []);}} >Yes</button>
                      }
                    </li>
                    <li className="list-inline-item">
                      <button className="btn btn-danger btn-sm add-new" onClick={() => this.toggleDeleteBulkModal('', '', [])}>No</button>
                    </li>
                  </ul>
                </DeleteModal>
                <DeleteModal title={"Remove User"} isOpen={this.state.deactivateModal} className="modal-dialog-centered" toggle={() => this.toggleDeactivateModal()}>
                  <p style={{ textAlign:'center', marginBottom:'15px'}}>Are you sure, you want to remove the user ?</p>
                  <ul className="list-inline" style={{textAlign: 'center'}}>
                    <li className="list-inline-item">
                      <button className="btn btn-primary btn-sm add-new" onClick={() => {this.props.removeUser();this.toggleDeactivateModal()}}>Yes</button>
                    </li>
                    <li className="list-inline-item">
                      <button className="btn btn-danger btn-sm add-new" onClick={() => this.toggleDeactivateModal()}>No</button>
                    </li>
                  </ul>
                </DeleteModal>
                <DeleteModal title={"Delete Experience"} isOpen={this.state.expModal} className="modal-dialog-centered" toggle={() => this.toggleExpModal()}>
                  <p style={{ textAlign:'center', marginBottom:'15px'}}>Are you sure, you want to delete the experience ?</p>
                  <ul className="list-inline" style={{textAlign: 'center'}}>
                    <li className="list-inline-item">
                      <button className="btn btn-primary btn-sm add-new" onClick={() => {this.props.removeExp(this.state.expId);this.toggleExpModal()}}>Yes</button>
                    </li>
                    <li className="list-inline-item">
                      <button className="btn btn-danger btn-sm add-new" onClick={() => this.toggleExpModal()}>No</button>
                    </li>
                  </ul>
                </DeleteModal>
                <DeleteModal title={"Resend Invitation"} isOpen={this.state.inviteUserModal} className="modal-dialog-centered" toggle={() => this.toggleInviteModal()}>
                  <p style={{ textAlign:'center', marginBottom:'15px'}}>Are you sure, you want to resend invitation ?</p>
                  <ul className="list-inline" style={{textAlign: 'center'}}>
                    <li className="list-inline-item">
                      <button className="btn btn-primary btn-sm add-new" onClick={() => {this.props.resendInvite(); this.toggleInviteModal()}} >Yes</button>
                    </li>
                    <li className="list-inline-item">
                      <button className="btn btn-danger btn-sm add-new" onClick={() => this.toggleInviteModal()}>No</button>
                    </li>
                  </ul>
                </DeleteModal>
                <DeleteModal isOpen={this.state.deleteContract.flag} toggle={() => this.setState({deleteContract: {flag: false, id: ''}})} title={"Delete Document"} className="modal-tform-delete-pictures modal-dialog-centered">
                <p style={{ textAlign:'center', marginBottom:'15px'}}>Are you sure, you want to delete ?</p>
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <button type="button" className="btn btn-danger" onClick={() => {this.props.deleteUserContract(this.state.deleteContract.id);this.setState({deleteContract: {flag: false, id: ''}});}}>Yes</button>
                  </li>
                  <li className="list-inline-item">
                    <button type="button" className="btn btn-primary" onClick={() => this.setState({deleteContract: {flag: false, id: ''}})}>No</button>
                  </li>
                </ul>
              </DeleteModal>
                {userContract.flag ?
                  <EditSidebar title={userContract.type == 'add' ? 'Add Document' : 'Edit Document'}  width="400px" right= "-400px" editSideBar={userContract.flag} toggleEditSideBar={() => this.addEditContractFlag(false)}>
                    <Row className="add-edit-user-contract">
                      <Col md="12">
                          <FieldGroup className="form-group">
                            <FieldLabel className="label" style={{display: 'block'}}>Title</FieldLabel>
                            <FieldInput
                              value={this.props.userContract.data.name != '' ? this.props.userContract.data.name : ''}
                              type="edit"
                              keyParam="name"
                              updateField={(keyParam, value) => this.props.addEditContract({...this.props.userContract, data: {...this.props.userContract.data, name: value}})}
                            />
                          </FieldGroup>
                      </Col>
                      <Col md="12">
                        <FieldGroup className="form-group">
                          <FieldLabel className="label">
                            Start Date
                            </FieldLabel>
                             <FieldDate
                              value={this.props.userContract.data.start_date != '' ? this.props.userContract.data.start_date : '--'}
                              type='edit'
                              keyParam="start_date"
                              updateField={(keyParam, value) => this.props.addEditContract({...this.props.userContract, data: {...this.props.userContract.data, start_date: value}})}
                            />
                          <h6 className="error-msg">{contract_error.start_date}</h6>
                         </FieldGroup>
                      </Col>
                      <Col md="12">
                        <FieldGroup className="form-group">
                          <FieldLabel className="label">
                            End Date
                            </FieldLabel>
                             <FieldDate
                              value={this.props.userContract.data.end_date != '' ? this.props.userContract.data.end_date : '--'}
                              type='edit'
                              keyParam="end_date"
                              updateField={(keyParam, value) => this.props.addEditContract({...this.props.userContract, data: {...this.props.userContract.data, end_date: value}})}
                            />
                          <h6 className="error-msg">{contract_error.end_date}</h6>
                         </FieldGroup>
                      </Col>
                      <Col md="12">
                        <FieldGroup className="form-group">
                          <FieldLabel className="label">
                            Attachment {this.props.userContract.type == 'add' ? <sup className="reqStar"> * </sup> : ''}
                            {
                              this.props.userContract.data.url && this.props.userContract.type != 'add' ?
                              <a style={{marginLeft: '5px'}} download={`${this.props.userContract.data.start_date}`} href={this.props.userContract.data.url}>View</a>
                              :null
                            }
                            <span style={{fontSize: '12px', color: '#2f8cfe', display: 'block', wordBreak: 'break-all', marginRight: '30px'}}>{this.state.contractFileName}</span>
                            </FieldLabel>
                            <DropToUpload
                              onDrop={ this.handleDrop }
                              onOver= { this.onDragOver}
                              onLeave = {this.onDragLeave}
                            >
                              <div className="ml-modal-upload">
                                <input
                                  type="file"
                                  style={{position: 'absolute', top: '40px', left: '0', width: '100%', height: '100%', opacity: 0}}
                                  onChange={(e) => this.updateContractImage(e.target.files[0])}
                                />
                                <h5>
                                  <img src={imgStoragePath + 'content_attachment.png'} height="30"/>
                                  Drag and Drop Document here or click here to choose the Document.
                                </h5>
                              </div>
                            </DropToUpload>
                            <h6 className="error-msg">{contract_error.contract_file}</h6>
                         </FieldGroup>
                      </Col>
                    </Row>
                    <div className="edit-sidebar-footer" style={userContract.flag ? {width: '400px', position: 'fixed', bottom: '0px', right: '0px',left: (window.innerWidth - 400) + 'px'} : {width: '0px', left: window.innerWidth + 'px'}}>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <button disabled={this.props.processing} onClick={(e) => this.addEditContractFn()} type="button" className="btn btn-primary">{this.props.processing ? 'Processing...':'Save Changes'}</button>
                          </li>
                          <li className="list-inline-item">
                            <Link onClick={() => this.addEditContractFlag(false)}> Cancel</Link>
                          </li>
                        </ul>
                      </div>
                  </EditSidebar>
                  :null
                }
                {this.props.experienceData.flag ?
                  <EditSidebar title={this.props.experienceData.type == 'add' ? 'Add Aircraft Type Experience' : 'Edit Aircraft Type Experience'}  width="400px" right= "-400px" editSideBar={this.props.experienceData.flag} toggleEditSideBar={() => this.addEditExperienceFlag(false)}>
                    <Row className="add-edit-user-contract text-edit-area">
                      <Col md="12">
                          <FieldGroup className="form-group">
                            <FieldLabel className="label" style={{display: 'block'}}>Aircraft Type<sup className="reqStar"> * </sup></FieldLabel>
                            <FieldInput
                              value={this.props.experienceData.data.aircraft_type != '' ? this.props.experienceData.data.aircraft_type : ''}
                              type="edit"
                              keyParam="name"
                              updateField={(keyParam, value) => this.props.toggleExpereience({...this.props.experienceData, data: {...this.props.experienceData.data, aircraft_type: value}})}
                            />
                          <h6 className="error-msg">{experience_error.aircraft_type}</h6>
                          </FieldGroup>
                      </Col>
                      <Col md="12">
                        <FieldGroup className="form-group">
                          <FieldLabel className="label" style={{display: 'block'}} >
                            No. Of Years<sup className="reqStar"> * </sup></FieldLabel>
                            <FieldInput
                              value={this.props.experienceData.data.year_of_experience != '' ? this.props.experienceData.data.year_of_experience : ''}
                              type="edit"
                              keyParam="name"
                              updateField={(keyParam, value) => this.props.toggleExpereience({...this.props.experienceData, data: {...this.props.experienceData.data, year_of_experience: value}})}
                            />
                          <h6 className="error-msg">{experience_error.year_of_experience}</h6>
                         </FieldGroup>
                      </Col>
                      <Col md="12">
                        <FieldGroup className="form-group">
                          <FieldLabel className="label" style={{display: 'block'}}>
                            Notes
                            </FieldLabel>
                            <FieldTextarea
                              value={this.props.experienceData.data.remarks != '' ? this.props.experienceData.data.remarks : ''}
                              type="edit"
                              keyParam="name"
                              updateField={(keyParam, value) => this.props.toggleExpereience({...this.props.experienceData, data: {...this.props.experienceData.data, remarks: value}})}
                            />
                         </FieldGroup>
                      </Col>
                    </Row>
                    <div className="edit-sidebar-footer" style={this.props.experienceData.flag ? {width: '400px', position: 'fixed', bottom: '0px', right: '0px',left: (window.innerWidth - 400) + 'px'} : {width: '0px', left: window.innerWidth + 'px'}}>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <button disabled={this.props.processing} onClick={(e) => this.addEditExperienceFn()} type="button" className="btn btn-primary">{this.props.processing ? 'Processing...':'Save Changes'}</button>
                          </li>
                          <li className="list-inline-item">
                            <Link onClick={() => this.addEditExperienceFlag(false)}> Cancel</Link>
                          </li>
                        </ul>
                      </div>
                  </EditSidebar>
                  :null
                }
                {this.props.userAvailability.flag ?
                  <EditSidebar title={this.props.userAvailability.type == 'add' ? 'Add Availability Status' : 'Edit Availability Status'}  width="400px" right= "-400px" editSideBar={this.props.userAvailability.flag} toggleEditSideBar={() => {this.props.addEditAvailability({flag: false, data: {from_date: moment().format('YYYY-MM-DD'),to_date: '',remarks: '',status: 1}, type: 'add'});this.setState({availibility_error: {}})}}>
                    <Row className="add-edit-user-contract text-edit-area">
                      <Col md="12">
                        <FieldGroup className="form-group">
                          <FieldLabel className="label">
                            Status
                            </FieldLabel>
                            <FieldSelect
                             placeholder='Status'
                             keyParam="status"
                             value={this.props.userAvailability.data.status}
                             type={this.props.userAvailability.type}
                             options={[{label: 'Not Available', value: 2}, {label: 'On Leave', value: 1}]}
                             updateField={(keyParam, value) => {this.props.addEditAvailability({...this.props.userAvailability, data: {...this.props.userAvailability.data, status: value}});this.setState({availibility_error: {...availibility_error, status: ''}})}}
                             labelKey='label'
                             valueKey='value'
                             name=""
                           />
                          <h6 className="error-msg">{availibility_error.from_date}</h6>
                         </FieldGroup>
                      </Col>
                      <Col md="12">
                        <FieldGroup className="form-group">
                          <FieldLabel className="label">
                            Start Date <sup className="reqStar"> * </sup>
                            </FieldLabel>
                             <FieldDate
                              value={this.props.userAvailability.data.from_date != '' ? moment(this.props.userAvailability.data.from_date) : '--'}
                              type='edit'
                              minDate={moment()}
                              keyParam="from_date"
                              updateField={(keyParam, value) => {this.props.addEditAvailability({...this.props.userAvailability, data: {...this.props.userAvailability.data, from_date: moment(value).format('YYYY-MM-DD')}});this.setState({availibility_error: {...availibility_error, from_date: ''}})}}
                              readOnly={true}
                            />
                          <h6 className="error-msg">{availibility_error.from_date}</h6>
                         </FieldGroup>
                      </Col>
                      <Col md="12">
                        <FieldGroup className="form-group">
                            <FieldLabel className="label">
                              End Date <sup className="reqStar"> * </sup>
                              </FieldLabel>
                               <FieldDate
                                value={this.props.userAvailability.data.to_date != '' ? this.props.userAvailability.data.to_date : '--'}
                                type='edit'
                                minDate={moment(this.props.userAvailability.data.from_date)}
                                keyParam="to_date"
                                updateField={(keyParam, value) => {this.props.addEditAvailability({...this.props.userAvailability, data: {...this.props.userAvailability.data, to_date: moment(value).format('YYYY-MM-DD')}});this.setState({availibility_error: {...availibility_error, to_date: ''}})}}
                              />
                            <h6 className="error-msg">{availibility_error.to_date}</h6>
                           </FieldGroup>
                      </Col>
                      <Col md="12">
                        <FieldGroup className="form-group">
                          <FieldLabel className="label" style={{display: 'block'}}>
                            Remarks <sup className="reqStar"> * </sup>
                            </FieldLabel>
                            <FieldTextarea
                              value={this.props.userAvailability.data.remarks != '' ? this.props.userAvailability.data.remarks : ''}
                              type="edit"
                              keyParam="name"
                              updateField={(keyParam, value) => {this.props.addEditAvailability({...this.props.userAvailability, data: {...this.props.userAvailability.data, remarks: value}});this.setState({availibility_error: {...availibility_error, remarks: ''}})}}
                            />
                          <h6 className="error-msg">{availibility_error.remarks}</h6>
                         </FieldGroup>
                      </Col>
                    </Row>
                    <div className="edit-sidebar-footer" style={this.props.userAvailability.flag ? {width: '400px', position: 'fixed', bottom: '0px', right: '0px',left: (window.innerWidth - 400) + 'px'} : {width: '0px', left: window.innerWidth + 'px'}}>
                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <button disabled={this.props.processing} onClick={(e) => this.addEditAvailabilityFn()} type="button" className="btn btn-primary">{this.props.processing ? 'Processing...':'Save Changes'}</button>
                          </li>
                          <li className="list-inline-item">
                            <Link onClick={() => this.props.addEditAvailability({flag: false, type: 'add', data: {from_date: moment().format('YYYY-MM-DD'),to_date: '',remarks: '',status: 1}})}> Cancel</Link>
                          </li>
                        </ul>
                      </div>
                  </EditSidebar>
                  :null
                }
            </div>
          </div>
        </div>
      </div>
      }
      {
        this.state.showRating ?
        <RatingInfoModal
          showRating={this.state.showRating}
          ratings={this.props.userProjectRating}
          closeRatingModal={this.showRating}
        />
        :null
      }
       <Modal centered={true} isOpen={this.state.modalGraph} toggle={this.toggleGraph} className={this.props.className} size="lg">
          <ModalHeader toggle={this.toggleGraph}>Utilization</ModalHeader>
          <ModalBody style={{minWidth: '800px'}}>
            <div>
            <UtilizationGraph
                       symbol="Hours Remaining :"
                       yType="percentage"
                       toolTipText="Percentage (%)"
                       xLabel="Duration (Months)"
                       yLabel="Manpower Usage (%)"
                       legend="Hours"
                       type="low"
                       title="Life On Wings Analysis"
                       color='#FEF7D1'
                       lineColor="#F8D41C"
                       fetchManPowerUtilization={this.fetchManPowerUtilization}
                       startDate= {utilizationData? utilizationData.start_date:''}
                       events={[]}
                       plots={utilizationData && utilizationData.utilizations ?utilizationData.utilizations:[]} />
            </div>
          </ModalBody>
        </Modal>
    </div>
    )
  }
}
const mapStateToProps = state => ({
  userProfileInfo: state.userAssetReducer.userProfileInfo,
  userAvailability: state.userAssetReducer.userAvailability,
  userAvailabilityList: state.userAssetReducer.userAvailabilityList,
  userProfileLoader:state.userAssetReducer.userProfileLoader,
  processing:state.userAssetReducer.processing,
  type:state.userAssetReducer.type,
  groupLoader:state.userAssetReducer.groupLoader,
  assetLoader:state.userAssetReducer.assetLoader,
  userContracts:state.userAssetReducer.userContracts,
  userContract:state.userAssetReducer.userContract,
  groupList:state.userAssetReducer.groupList,
  assetList:state.userAssetReducer.assetList,
  experienceData:state.userAssetReducer.experienceData,
  userProjectRating: state.userAssetReducer.userProjectRating,
  techConstants:state.sharedReducers.techConstants,
  deleteExpLoader:state.userAssetReducer.deleteExpLoader,
  utilizationData:state.TrackerReducer.utilizationData,
  infiniteLoader:state.TrackerReducer.infiniteLoader,
})
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchUserInfo: () => dispatch(userInfoAc(ownProps.params.id)),
    userInfoUpdate: (formdata) => dispatch(editUserAc(formdata, ownProps.params.id)),
    updateUserProjects: (formdata) => dispatch(updateUserProjectsAc(formdata, ownProps.params.id)),
    updateUserSkillRatings: (formdata) => dispatch(updateUserSkillRatingsAc(formdata, ownProps.params.id)),
    typeUpdate: (value) => dispatch({
       type:TYPE_UPDATE,
       payload:value
    }),
    updateGroupLoader:() => dispatch({
      type:GROUP_LOADER_UPDATE,
    }),
    updateAssetLoader:() => dispatch({
      type:ASSET_LOADER_UPDATE,
    }),
    removeUser: () => dispatch(removeUserAc(ownProps.params.id)),
    removeExp:(expId)=>dispatch(deleteExpAc(ownProps.params.id,expId)),
    resendInvite:()=> dispatch(resendInviteAc(ownProps.params.id)),
    fetchProjectDepartment: (type) => dispatch(sGlobalConstantAc(type)),
    getGroupsList: () => dispatch(getGroupsListAc(ownProps.params.id)),
    getAssetsList: () => dispatch(getAssetsListAc(ownProps.params.id)),
    deleteUserGroup: (userGroups) => dispatch(deleteUserGroupAc(userGroups, ownProps.params.id)),
    deleteUserAsset: (userAsset) => dispatch(deleteUserAssetAc(userAsset, ownProps.params.id)),
    addUserGroup: (userGroups) => dispatch(addUserGroupAc(userGroups, ownProps.params.id)),
    addUserAsstes: (userAssets) => dispatch(addUserAssetsAc(userAssets, ownProps.params.id)),
    getUsetContracts: () => dispatch(getUsetContractsAc(ownProps.params)),
    deleteUserContract: (contractId) => dispatch(deleteUserContractAc(ownProps.params, contractId)),
    fetchManPowerUtilization:(filter) => dispatch(fetchManPowerTrackerUtilizationAc(filter)),
    toggleExpereience:(data) => dispatch({
      type: ADD_UPDATE_EXPEREIENCE,
      payload: data
    }),
    addEditExperience: (data, expId) => dispatch(addEditExperienceAc(ownProps.params, data, expId)),
    addEditUserContract: (contractCrud, contractId) => dispatch(addEditUserContractAc(ownProps.params, contractCrud, contractId)),
    updateAssetSearch: (value) => dispatch({
      type: FILTER_ASSET_LIST,
      payload: value
    }),
    updateSecurityGroupsSearch: (value) => dispatch({
      type: FILTER_SECURITY_GRP_LIST,
      payload: value
    }),
    addEditContract: (data) => dispatch({
      type: USER_CONTRACT_CRUD,
      payload: data
    }),
    addEditAvailability: (data) => dispatch({
      type: USER_AVAILABILITY_CRUD,
      payload: data
    }),
    exportCV: (user_slug, user_name) => dispatch(exportCVAc(user_slug, user_name)),
    ratingHistory:() => dispatch(userProjectRatingAc(ownProps.params.id)),
    userStatusUpdate:(data) => dispatch(userStatusUpdateAc(ownProps.params, data)),
    deleteUserStatus:(id) => dispatch(deleteUserStatusAc(ownProps.params, id)),
    updateUserField: (object) => dispatch({
      type: UPDATE_USER_FIELD,
      payload: object
    })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);

import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, Col } from 'reactstrap';
import { imgStoragePath } from '../../../constants';
export default class Footer extends Component {
  constructor(props){
    super(props);
  }
  render(){
    return(
      <footer>
        <div className="container">
            <Row>
                <Col md="2" xs="6">
                    <h3>Media Center</h3>
                    <ul className="list-unstyled">
                        <li><a href="https://www.acumen.aero/resources/blog/" target="_blank">Blog</a></li>
                        <li><a href="https://www.acumen.aero/resources/events/" target="_blank">Events</a></li>
                        <li><a href="https://www.acumen.aero/resources/webinars/" target="_blank">Press Releases</a></li>
                        <li><a href="https://www.acumen.aero/resources/case-studies/" target="_blank">Case Studies</a></li>
                        <li><a href="https://www.acumen.aero/resources/webinars/" target="_blank">Videos & Podcast</a></li>
                    </ul>
                </Col>
                <Col md="2" xs="6">
                    <h3>About Us</h3>
                    <ul className="list-unstyled">
                        <li><a href="https://www.acumen.aero/company/leadership/" target="_blank">Our Team</a></li>
                        <li><a href="https://www.acumen.aero/digital/" target="_blank">Clients</a></li>
                        <li><a href="https://www.linkedin.com/company/sparta-aero/jobs/" target="_blank">Careers</a></li>
                    </ul>
                </Col>
                <Col md="2" xs="6">
                    <h3>Features</h3>
                    <ul className="list-unstyled">
                        <li><a href="/signup#contractsFeatures">Lease Management</a></li>
                        <li><a href="/signup#cashflowAnalysis">MR Calculator</a></li>
                        <li><a href="/signup#recordFeatures">Records Dataroom</a></li>
                        <li><a href="/signup#techFeatures">Projects Management</a></li>
                    </ul>
                </Col>
                <Col md="2" xs="6">
                    <h3>Others</h3>
                    <ul className="list-unstyled">
                        <li><a href="/termscondition">Terms & Conditions</a></li>
                        <li><a href="/data-policy">Data Policy</a></li>
                        <li><a href="https://www.acumen.aero/privacy-policy/" target="_blank">Privacy Policy</a></li>
                        <li><a href="https://www.acumen.aero/privacy-policy/?security=true" target="_blank">Security</a></li>
                    </ul>
                </Col>
                <Col md="4">
                    <h3>Connect with Us</h3>
                    <ul className="list-unstyled images-list">
                        <li><a href="https://www.facebook.com/SPARTAAero-354530545100281/" target="_blank"><img src={imgStoragePath+'phase-2/facebook.svg'}/></a></li>
                        <li><a href="https://www.instagram.com/sparta.aero/" target="_blank"><img src={imgStoragePath+'phase-2/instagram.svg'}/></a></li>
                        <li><a href="https://www.linkedin.com/company/sparta-aero" target="_blank"><img src={imgStoragePath+'phase-2/icon_LinkedIn.svg'}/></a></li>
                        <li><a href="https://twitter.com/aero_sparta" target="_blank"><img src={imgStoragePath+'phase-2/twitter.svg'}/></a></li>
                    </ul>
                    <h3 style={{ marginBottom: '6px'}}>Contact Us</h3>
                    <ul className="list-unstyled">
                        <li><a href="mailto:support@sparta.aero" target="_blank">support@sparta.aero</a></li>
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col md="12" className="text-center copy-right">
                    <a href="https://acumen.aero/" target="_blank">
                        <img src={imgStoragePath+'phase-2/acumen.png'} />
                    </a>
                    <p>© 2021 Acumen Aviation Europe Limited. All rights reserved. <a href="https://acumen.aero/" target="_blank">www.acumen.aero</a></p>
                </Col>
            </Row>
        </div>
      </footer>
    )
  }
}

import React, { Component } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { displayDateFormat, imgStoragePath } from '../../../../constants';
import { numberWithCommas, removeDuplicates, getLocalStorageInfo } from '../../../../utils';
require('highcharts/modules/exporting')(Highcharts);
export default class SummeryGraphCard extends Component {
  constructor(props){
    super(props);
  }
  render() {
    const { summeryCardData, newsummeryCardData, title, color, newcolor, simulationType, lineColor, newlineColor, ctrShortInfo, report } = this.props;
    let categories = [], newcategories=[], data = [], newdata = [], xAxisData = [], newxAxisData = [], eventFlags = [];
    function onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }
    function genNum(num) {
      return num.toFixed(2)
    }
    function getSummeryGrahp(obj, category, flag){
      let graphData = [];
      Object.keys(obj).map(summeryType => {
        if(summeryType == 'airframe'){
          Object.keys(obj[summeryType]).map(airframe => {
            if(flag){
              Object.keys(obj[summeryType][airframe].events).map(event => {
                eventFlags.push({type:'airframe', eventDate: moment(obj[summeryType][airframe].events[event].eventDate).format('YYYYMM')})
              });
            }
            Object.keys(obj[summeryType][airframe].plots).map(year => {
              if(flag){
                graphData.push(year);
              }else{
                const arrayIndex = category.indexOf(year);
                graphData[arrayIndex] = (graphData[arrayIndex] ? graphData[arrayIndex] :0 ) + obj[summeryType][airframe].plots[year];
              }
            });
          });
        }
        if(summeryType == 'engines'){
          let engineData = obj[summeryType].engine_data ? obj[summeryType].engine_data:[];
          Object.keys(engineData).map(engine => {
            if(flag){
              Object.keys(engineData[engine].shop_visits).map(sv => {
                eventFlags.push({type:'engines', eventDate: moment(engineData[engine].shop_visits[sv][0].eventDate).format('YYYYMM')})
              });
            }
            Object.keys(engineData[engine].plots).map(year => {
              if(flag){
                graphData.push(year);
              }else{
                const arrayIndex = category.indexOf(year);
                graphData[arrayIndex] = (graphData[arrayIndex] ? graphData[arrayIndex]:0) + engineData[engine].plots[year];
              }
            });
          });
        }
        if(summeryType == 'landing_gear'){
          if(flag){
            Object.keys(obj[summeryType].events).map(event => {
              eventFlags.push({type:'landing_gear', eventDate: moment(obj[summeryType].events[event].eventDate).format('YYYYMM')})
            });
          }
          Object.keys(obj[summeryType].plots).map(year => {
            if(flag){
              graphData.push(year);
            }else{
              const arrayIndex = category.indexOf(year);
              graphData[arrayIndex] = (graphData[arrayIndex] ? graphData[arrayIndex]:0) + obj[summeryType].plots[year];
            }
          });
        }
        if(summeryType == 'landing_gears'){
          Object.keys(obj[summeryType]).map(landing_gear => {
            if(flag){
              Object.keys(obj[summeryType][landing_gear].events).map(event => {
                eventFlags.push({type:'landing_gear', eventDate: moment(obj[summeryType][landing_gear].events[event].eventDate).format('YYYYMM')})
              });
            }
            Object.keys(obj[summeryType][landing_gear].plots).map(year => {
              if(flag){
                graphData.push(year);
              }else{
                const arrayIndex = category.indexOf(year);
                graphData[arrayIndex] = (graphData[arrayIndex] ? graphData[arrayIndex]:0) + obj[summeryType][landing_gear].plots[year];
              }
            });
          });
        }
        if(summeryType == 'apu'){
          if(flag){
            Object.keys(obj[summeryType].events).map(event => {
              eventFlags.push({type:'apu', eventDate: moment(obj[summeryType].events[event].eventDate).format('YYYYMM')})
            });
          }
          Object.keys(obj[summeryType].plots).map(year => {
            if(flag){
              graphData.push(year);
            }else{
              const arrayIndex = category.indexOf(year);
              graphData[arrayIndex] = (graphData[arrayIndex] ? graphData[arrayIndex]:0) + obj[summeryType].plots[year];
            }
          });
        }
      });
      return graphData;
    }
    categories = getSummeryGrahp(summeryCardData, categories, true).filter( onlyUnique ).sort();
    data = getSummeryGrahp(summeryCardData, categories, false);
    eventFlags = removeDuplicates(eventFlags,'eventDate');
    let eventdateArr = [...eventFlags];
    eventdateArr = eventdateArr.map(eventFlag => eventFlag.eventDate);
    categories.map((date,index) => {
      let d = {}, url =  '';
      let airframeMarker
      if(eventdateArr.indexOf(date) != -1){
        let type = eventFlags[eventdateArr.indexOf(date)].type;
        switch (type) {
          case 'airframe':
            url =  'url(' + imgStoragePath + 'airframe_marker.png' + ')';
            break;
          case 'engines':
            url =  'url(' + imgStoragePath + 'engines_marker.png' + ')';
            break;
          case 'apu':
            url =  'url(' + imgStoragePath + 'apu_marker.png' + ')';
            break;
          case 'landing_gear':
            url =  'url(' + imgStoragePath + 'lg_marker.png' + ')';
            break;
          default:
            url =  'url(' + airframeMarker + ')';
        }
        d = {x: new Date(date.slice(4,6) + '/01/' + date.slice(0,4)).getTime(), y: data[index], marker:{symbol: url, width: 30, height: 30, enabled: true}}
      }else{
        d = {x: new Date(date.slice(4,6) + '/01/' + date.slice(0,4)).getTime(), y: data[index]}
      }
      xAxisData.push(d);
    });
    if( simulationType == 2 ){
      newcategories = getSummeryGrahp(newsummeryCardData, newcategories, true).filter( onlyUnique ).sort();
      newdata = getSummeryGrahp(newsummeryCardData, newcategories, false);
      newcategories.map((date,index) => {
        let newd = [];
        newd.push(new Date(date.slice(4,6) + '/01/' + date.slice(0,4)).getTime());
        newd.push(newdata[index]);
        newxAxisData.push(newd);
      });
    }
    function customToolTip(points){
      if(points.length == 1){
        return '<b>Date</b>: ' + moment(points[0].x).format('MMM YYYY') + '<br/><b>Fund</b>: $' + numberWithCommas(genNum(points[0].y));
      }else{
        let tlTip = '';
        points.map(point => {
          tlTip += '<b>' + point.series.name + '</b>:<br/>';
          tlTip += '<b>Date</b>: ' + moment(point.x).format('MMM YYYY') + '<br/>';
          tlTip += '<br/><b>Fund</b>: $' +  numberWithCommas(genNum(point.y)) + '<br/>'
        });
        return tlTip
      }
    }
    var options = {}
    var staticXAxisData = []
    staticXAxisData = xAxisData.map(xItem => {
      let item = xItem
      if(xItem.y > 0){
        item = {...item, y: item.y - (item.y * 2)}
      }
      return item
    })
    if(ctrShortInfo && report && !window.location.href.includes('financial-plots')){
      options = {
        chart: {
          type: 'area',
          zoomType: 'x',
          marginTop: 60,
          events: {
            load: function() {
              if(ctrShortInfo && ctrShortInfo.lessor_name){
                var label = this.renderer.label( report + (ctrShortInfo.asset_type == 1 ? ' MSN ' + ctrShortInfo.msn : ' ESN ' + ctrShortInfo.esn ) + "<br/>Lessor: " + ctrShortInfo.lessor_name)
              }else{
                var label = this.renderer.label( report + (ctrShortInfo.asset_type == 1 ? ' MSN ' + ctrShortInfo.msn : ' ESN ' + ctrShortInfo.esn ) + "<br/>Lessor: " + getLocalStorageInfo().defaultLessor.name)
              }

                  label.css({
                    width: '400px',
                    fontSize: '9px'
                  })
                  .attr({
                    'stroke': 'silver',
                    'stroke-width': 1,
                    'r': 2,
                    'padding': 5
                  })
                  .add();

                label.align(Highcharts.extend(label.getBBox(), {
                  align: 'left',
                  x: 0, // offset
                  verticalAlign: 'bottom',
                  y: -340 // offset
                }), null, 'spacingBox');
            }
          }
        },
        title: {
          text: null
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Asset Life ( Year )'
          }
        },
        yAxis:{
          title: {
            text: 'Maintenance Reserve Paid `000 (USD)'
          },
          labels: {
            formatter: function () {
                return  '$' + this.value/1000;
            }
          },
          gridLineDashStyle: 'longdash'
        },
        credits: {
          enabled: false
        },
        tooltip: {
         formatter: function(){
           return customToolTip(this.points)
         },
         shared: true
        },
        series:  [
          {
            boostThreshold: 0,
            turboThreshold:0,
            showInLegend: true,
            name: 'Calculated Paid Accruals',
            data: staticXAxisData,
            color: color,
            lineColor: lineColor,
            style: {opacity: 0.3},
            marker: {
              fillColor: lineColor
            }
          },
          {
            boostThreshold: 1,
            turboThreshold:1000,
            showInLegend: newdata.length ? true:false,
            name: 'Simulated Paid Accruals',
            data: newxAxisData,
            color: newcolor,
            lineColor: newlineColor,
            style: {opacity: 0.5},
            marker: {
              fillColor: lineColor
            }
          }
        ],
        exporting: {
          buttons: {
            contextButton: {
              text: 'Export',
              menuItems: [
                {
                  text: 'Export as PNG',
                  onclick: function () {
                    this.exportChart();
                  }
                },
                {
                  text: 'Export as SVG',
                  onclick: function () {
                    this.exportChart({ type: 'image/svg+xml' });
                  }
                },
                {
                  text: 'Export as PDF',
                  onclick: function () {
                    this.exportChart({ type: 'application/pdf' });
                  }
                }
              ]
            }
          }
        }
      }
    }else{
       options = {
        chart: {
          type: 'area',
          zoomType: 'x',
        },
        title: {
          text: null
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: 'Asset Life ( Year )'
          }
        },
        yAxis:{
          title: {
            text: 'Maintenance Reserve Paid `000 (USD)'
          },
          labels: {
            formatter: function () {
                return  '$' + this.value/1000;
            }
          },
          gridLineDashStyle: 'longdash'
        },
        credits: {
          enabled: false
        },
        tooltip: {
         formatter: function(){
           return customToolTip(this.points)
         },
         shared: true
        },
        series:  [
          {
            boostThreshold: 0,
            turboThreshold:0,
            showInLegend: true,
            name: 'Calculated Paid Accruals',
            data: staticXAxisData,
            color: color,
            lineColor: lineColor,
            style: {opacity: 0.3},
            marker: {
              fillColor: lineColor
            }
          },
          {
            boostThreshold: 1,
            turboThreshold:1000,
            showInLegend: newdata.length ? true:false,
            name: 'Simulated Paid Accruals',
            data: newxAxisData,
            color: newcolor,
            lineColor: newlineColor,
            style: {opacity: 0.5},
            marker: {
              fillColor: lineColor
            }
          }
        ],
        exporting: {
          buttons: {
            contextButton: {
              text: 'Export',
              menuItems: [
                {
                  text: 'Export as PNG',
                  onclick: function () {
                    this.exportChart();
                  }
                },
                {
                  text: 'Export as SVG',
                  onclick: function () {
                    this.exportChart({ type: 'image/svg+xml' });
                  }
                },
                {
                  text: 'Export as PDF',
                  onclick: function () {
                    this.exportChart({ type: 'application/pdf' });
                  }
                }
              ]
            }
          }
        }
      }
    }
    return(
      <div className="finance-graph-blk">
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      </div>
    )
  }
}

import * as actions from '../actions';
import { globalGetService, globalExportService, globalStaticGetService } from '../../../../globalServices/';
import { downloadFileType } from '../../../../utils';
import staticAxiosInst from '../../../../utils/splhttpInterceptor';
import { CTR_SHORT_INFO } from '../../../contracts/Listing/actions';
import { DIRECTORY_LOADER } from '../../DataRoom/actions';
import { loadResourcesRecordsAc } from '../../Resources/actionCreators';
import { trackActivity } from '../../../../utils/mixpanel';
import { assetsType } from '../../../../constants';
let assetTypeNames = {
  3: 'APU',
  4: 'LG',
  5: 'PROPELLER'
}
export const loadAssetViewAc = (params, from ) => {
  return (dispatch) => {
    dispatch({
      type: DIRECTORY_LOADER,
      payload: true
    });
    globalGetService('console/'+ params.type+'/' + params.aircraft_slug + '/', {})
    .then(response => {
      dispatch({
        type: DIRECTORY_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: CTR_SHORT_INFO,
          payload: response.data.data
        });
        let ctrShortInfo = response.data.data
        if(Object.keys(ctrShortInfo).length){
          let asset_serial_number = ctrShortInfo.asset_type === 1 ? `MSN ${ctrShortInfo.msn}` : [3,4,5].indexOf(ctrShortInfo.asset_type) !== -1 ? `${assetsType[ctrShortInfo.asset_type].toUpperCase()} ${ctrShortInfo.serial_number}` : `${ctrShortInfo.asset_type === 2 ? 'ESN ' : ''}${ctrShortInfo.esn}`
          trackActivity('Page Visited',{
            application_module: 'Records',
            page_title: from,
            asset: asset_serial_number
          })
        }
        if(from =='Resources'){
          dispatch(loadResourcesRecordsAc(params, response.data.data.records.technical_records.uuid));
        }

      }
    })
  }
}
export const fetchDbStatsAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.FILE_DASHBOARD_LOADER,
      payload: true
    });
    globalStaticGetService('records/'+params.type+'/' + params.aircraft_slug + '/dashboard/', {})
    .then(response => {
      dispatch({
        type: actions.FILE_DASHBOARD_LOADER,
        payload: false
      })
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.RECORDS_DB_STATS,
          payload: response.data.data
        })
      }
    })
  }
}
export const fetchDbActivityAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.RECORDS_DASHBOARD_LOADER,
      payload: true
    });
    globalGetService('records/'+params.type+'/' + params.aircraft_slug + '/dashboard/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.RECORDS_DB_ACTIVITY,
          payload: response.data.data
        })
      }
      dispatch({
        type: actions.RECORDS_DASHBOARD_LOADER,
        payload: false
      });
    })
  }
}

export const fetchLastActivityAc = (params) => {
  return (dispatch) => {
    dispatch({
      type: actions.RECORDS_DASHBOARD_LOADER,
      payload: true
    });
    globalGetService('records/'+params.type+'/' + params.aircraft_slug + '/dashboard-activities/', {})
    .then(response => {
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.LAST_ACTIVITY_DATA,
          payload: response.data
        })
      }
      dispatch({
        type: actions.RECORDS_DASHBOARD_LOADER,
        payload: false
      });
    })
  }
}

export const exportActivityReportAc = (params, ctrShortInfo) =>{
  return(dispatch) => {
    dispatch({
      type: actions.RECORDS_DASHBOARD_LOADER,
      payload: true
    });
    staticAxiosInst({
      method: 'GET',
      url: 'records/'+params.type+'/'+params.aircraft_slug+'/activities/',
      responseType: 'blob',
      params: {limit: 100, download: 'xls'}
    })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      if(ctrShortInfo.asset_type === 1 ){
        link.setAttribute('download', 'MSN_' + ctrShortInfo.msn + '_Activity Report.xls');
      }else if(ctrShortInfo.asset_type === 2){
        link.setAttribute('download', 'ESN_' + ctrShortInfo.esn + '_Activity Report.xls');
      }else if(ctrShortInfo.asset_type === 3){
        link.setAttribute('download', 'APU_' + ctrShortInfo.serial_number + '_Activity Report.xls');
      }else if(ctrShortInfo.asset_type === 4){
        link.setAttribute('download', 'LG_' + ctrShortInfo.serial_number + '_Activity Report.xls');
      }else if(ctrShortInfo.asset_type === 5){
        link.setAttribute('download', 'PROPELLER_' + ctrShortInfo.serial_number + '_Activity Report.xls');
      }else if([6,7,8,9].includes(ctrShortInfo.asset_type)){
        link.setAttribute('download', ctrShortInfo.esn + '_Activity Report.xls');
      }
      document.body.appendChild(link);
      link.click();
      let assetInfo = ctrShortInfo;
      let asset_serial_number = assetInfo.asset_type === 1 ? `MSN ${assetInfo.msn}` : [3,4,5].indexOf(assetInfo.asset_type) !== -1 ? `${assetTypeNames[assetInfo.asset_type].toUpperCase()} ${assetInfo.serial_number}` : `${assetInfo.asset_type === 2 ? 'ESN ' : 'ESN '}${assetInfo.esn}`
      trackActivity('Report Exported',{
        page_title: 'Dashboard',
        application_module: 'Records',
        asset: asset_serial_number,
        file_name: 'Activity Report',
        file_type: 'XLS',
      })
      dispatch({
        type: actions.RECORDS_DASHBOARD_LOADER,
        payload: false
      });
    })
  }
}

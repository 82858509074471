import React from 'react';
import viewIcon from '../../../shared/assets/img/view_icon.svg';
const Roles = ({item, index, onRoleDetail}) => {
  return (
    <tr  style={{cursor: 'pointer'}}>
      <td>{item.name}</td>
      <td>{item.users_count}</td>
      <td onClick={() => onRoleDetail()}><img width="22" src={viewIcon} alt="Icon"/></td>
    </tr>
  )
}
export default Roles;

import React, { Component } from 'react';
import { Link } from 'react-router';
import { ToolTipHover } from '../../../../shared';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import '../../assets/styles/records_v.scss';
import { displayDateTimeFormat, displayDateFormat } from '../../../../constants';
import moment from 'moment';
import { getFileSize, getAccessToken, getLocalStorageInfo } from '../../../../utils';
import { imgStoragePath } from '../../../../constants';
export default class BinList extends Component{
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false
    };
  }


  render(){
    const { listItem, index } = this.props;
    return(
      <div className="asset-list" >
        <Row className="flex-centered">
          <Col md={this.props.recycleBin ? "6" : "8"}>
              <div className="flex-centered msn-block record-preview">
              <div>
                  {this.props.recycleBin && !this.props.actions ? <input style={{ marginRight: '10px'}} onClick={this.props.addToRecycle} checked={this.props.recycleList.filter(item => item.uuid == listItem.uuid).length ? true : false } type="checkbox"/>:null}
                </div>
                <div><img src={listItem.type == "file" ? this.props.fileIcons[listItem.extension] : imgStoragePath+"folder_icn3.png"} alt="img" width="20"/></div>
              <Link to={(listItem.type == "file" && listItem.extension == 'pdf' && this.props.recycleBin) ? `https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/pdf.js/web/viewer.html?file=${listItem.path}&fileName=${listItem.name}`: (listItem.extension == 'csv' || listItem.extension == 'xls' || listItem.extension == 'xlsx') && this.props.recycleBin ? `https://sparta-aims.ams3.digitaloceanspaces.com/others/tools/xls_viewer/index.html?file=${listItem.path}` : ''} target="_blank"><span style={{fontSize:'14px', position: 'relative', top: '0px', right: '0px'}}>{listItem.name}</span></Link>
              </div>
              <h6 className="para-ui" style={{fontSize: '12px'}}>{listItem.location}</h6>
          </Col>
          <Col md="1">

            <h6 className="para-ui">
              {
                listItem.size != 0 && listItem.size != null && listItem.size != undefined?
                `${getFileSize(listItem.size)}`:'--'
              }
            </h6>
         </Col>
         {
           this.props.recycleBin ?
            <Col md="2">
             <h6 className="para-ui">{listItem.modified_by && listItem.modified_by != null && listItem.modified_by != undefined ? listItem.modified_by.name : '--'}</h6>
            </Col>
            :null
         }
         {
           this.props.recycleBin ?
              <Col md="2">
                <h6 className="para-ui" >{moment(listItem.modified).format(displayDateFormat)}</h6>
            </Col>:null
          }
         {
           this.props.recycleBin && !this.props.actions && getLocalStorageInfo().user.permission['records']['recycle_bin'] ?
           <Col md="1" className="text-right status-block bucket-action">
            {
               getLocalStorageInfo().user.permission['records']['recycle_bin'].indexOf('RES') != -1 ?
             <span style={{color: '#007bff'}} onClick={this.props.restoreRecord} >
               <i id={"restore-"+index} className="fa fa-sync"></i>
               <ToolTipHover placement="top" tagetId={"restore-"+index}>Restore</ToolTipHover>
          </span>:null}
           {
             getLocalStorageInfo().user.permission['records']['recycle_bin'].indexOf('REJ') != -1 ?
             <span style={{color: '#ff5e5e'}} onClick={this.props.deleteRecord}>
             <i id={"trash-"+index} className="fa fa-trash"></i>
             <ToolTipHover placement="top" tagetId={"trash-"+index}>Trash</ToolTipHover>
         </span>:null}
           </Col>: this.props.dataKit ?
           <Col md="1" className="text-right status-block bucket-action">
             {getLocalStorageInfo().user.permission['records']['recycle_bin']&&
               getLocalStorageInfo().user.permission['records']['recycle_bin'].indexOf('REJ') != -1 ?
            <span style={{color: '#ff5e5e'}} onClick={this.props.removeRecord}>
             Remove
           </span>:null}
           </Col> : null
         }
        </Row>
      </div>
    )
  }
}

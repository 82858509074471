import * as actions from '../actions';
import { globalGetService, globalPostService, globalPutService } from '../../../../globalServices/';
import { toastFlashMessage } from '../../../../utils';
import { trackActivity } from '../../../../utils/mixpanel'
export const getRecycleBinAc = (params, pageNumber) =>{
  return(dispatch) => {
    dispatch({
      type: actions.RECYCLE_BIN_LOADER,
      payload: true
    });
    globalGetService('records/'+params.type+'/'+params.aircraft_slug+'/recycle-bin/list/', { page: pageNumber})
    .then(response => {
      dispatch({
        type: actions.RECYCLE_BIN_LOADER,
        payload: false
      });
      if(response.data.statusCode == 200){
        dispatch({
          type: actions.GET_RECYCLE_BIN,
          payload: response.data.data,
          pagination: response.data.pagination
        })
      }
    })
  }
}

export const actionRecycleBinAc = (params,actionType, recycleList) => {
  let binObject = {
    action: actionType,
    items:recycleList,
  }

  return(dispatch) => {
    dispatch({
      type: actions.RECYCLE_BIN_LOADER,
      payload: true
    });
    globalPostService('records/'+params.type+'/'+params.aircraft_slug+'/recycle-bin/action/', binObject)
    .then( response => {
      if(response.data.statusCode == 200){
        toastFlashMessage(response.data.message, 'success');
        dispatch({
          type: actions.RECYCLE_BIN_ACTION,
          payload: binObject.items,
        });
      }
      if(parseInt(binObject.action) === 1){
        if(binObject.item.length === 1){
          trackActivity('File Deleted', {
            item_id: binObject.items[0].uuid,
            application_module: 'Records',
            page_title: 'DataRoom',
            item_type: binObject.items[0].type
          })
        }else{
          trackActivity('File Deleted', {
            application_module: 'Records',
            page_title: 'Recycle Bin',
            item_type: "multiple"
          })
        }
      }else{
        if(binObject.item.length === 1){
          trackActivity('File Restored', {
            item_id: binObject.items[0].uuid,
            application_module: 'Records',
            page_title: 'DataRoom',
            item_type: binObject.items[0].type
          })
        }else{
          trackActivity('File Restored', {
            application_module: 'Records',
            page_title: 'Recycle Bin',
            item_type: "multiple"
          })
        }
      }
      dispatch({
        type: actions.RECYCLE_BIN_LOADER,
        payload: false
      });
    })
  }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { SIDEBAR_TOGGLE } from '../actions';
import { Link, IndexLink } from 'react-router';
import { getLocalStorageInfo, eraseGlobalCookie } from '../../utils';
import { trackLogOut } from '../../utils/mixpanel'
import { imgStoragePath } from '../../constants';
import { licenseListAc } from '../../phase2/licenses/actionCreators'
import ToolTipHover from './ToolTipHover';
import dashboardIcon from '../../shared/assets/img/dashbord_icon.svg';
import homeIcon from '../../shared/assets/img/home_icon.png';
class PrySidebarExp extends Component{
  constructor(props){
    super(props);
  }
  logout = () => {
    trackLogOut(getLocalStorageInfo())
    localStorage.clear();
    eraseGlobalCookie('lessorAccess')
    eraseGlobalCookie('redirectURILease')
    eraseGlobalCookie('domain')
    this.props.sidebarToggleFn(false)
    browserHistory.push('/login');
  }

  componentDidMount(){
    if(getLocalStorageInfo() && getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.license){
      this.props.fetchLicenses()
    }
  }

  checkTracker(){
    if(getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('R')){
      return  '/technical/projects/tracker';
    }else if(getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('GR')){
      return  '/technical/projects/tracker';
    }else if(getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('MR')){
      return  '/technical/man-power/tracker';
    }else if(getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.project_tracker && getLocalStorageInfo().user.permission.technical.project_tracker.includes('CR')){
      return  '/technical/cor/tracker';
    }
  }

  primaryNavLink = (imgIcon, imgWidth, url, activeClassName, flag, targetId, placement, title, linkAccess, isBeta) => {

    if(linkAccess){
      return (
        <li>
          <Link to={url} onClick={() => this.props.sidebarToggleFn(false)} style={(title == 'Co') ? {cursor: 'not-allowed'} : {}} className={(targetId == 'userManagement' ? ' ': ' ') + (targetId == 'usersManager' ? 'utility-block ': ' ')  + (activeClassName ? 'flex-centered active' :'flex-centered')}>
            <img id={targetId} src={imgStoragePath+imgIcon} alt="nav-icon" width={imgWidth} />
            <br/>
            <span className="sideBarSubheading">{title}</span>
          {
            isBeta ? <sup style={{color: '#ff0000', fontSize: '10px', fontFamily: 'Conv_IBMPlexSans-Bold', padding: '0 2px'}}>BETA</sup> : null
          }
            {
              //this.showToolTip(flag, targetId, placement, title)
            }
          </Link>
        </li>
      )
    }else{
      return null
     }
  }
  showToolTip = (flag, targetId, placement, title) => {
    if(!flag){
      return(
        <ToolTipHover tagetId={targetId} placement={placement}> {title} </ToolTipHover>
      )
    }else{ return null }
  }
  render(){
    var { sideBarStatus, licenses, notificationOpen, dashSw } = this.props;
    sideBarStatus = false;
    const pathname = this.props.pathName;
    const userDetail = JSON.parse(localStorage.getItem('userInfo'));
    let is_saas = false
    Object.keys(userDetail.user.permission).map(permission => {
      if(userDetail.user.permission[permission].is_saas == true){
        is_saas = true
      }
      return permission
    })
    return(
      <div className="prySidebarExp" style={ notificationOpen || this.props.topNotice ? {marginTop: '30px'} : null }>
        <div className="dashboard-block">
          {
            <ul className="list-unstyled" style={{ marginTop: userDetail.defaultLessor.on_trail ? '30px' : '0px'  }}>
              <li>
                <Link className="flex-centered" to="https://sparta.aero/" target="_blank">
                  <img id="home" src={homeIcon} alt="img" width='25'/>
                  <br/>
                  <span className="sideBarSubheading ">
                    Home
                  </span>
                </Link>
              </li>
              <li>
                <IndexLink  onClick={() => this.props.sidebarToggleFn(false)}  activeClassName="active" className={pathname === '' || pathname === '/' ? "flex-centered active" : "flex-centered"} to="/fleet/contracts/dashboard">
                  <img id="dashboard" src={dashboardIcon} alt="img" width='20'/>
                  <br/>
                  <span className="sideBarSubheading ">
                    Dashboard
                  </span>
                </IndexLink>
              </li>
              { this.primaryNavLink('phase-2/asset_list_icon.png', 20, '/assets-listing', ((pathname.includes('assets-listing') || pathname.includes('contract')) && !pathname.includes('fleet/contracts/dashboard')), sideBarStatus, 'assetList', 'right', 'Assets', true) }
              { this.primaryNavLink('report_icon.png', 20, '/export/reports/', (pathname.includes('export/reports')), true, 'exportReport', 'right', 'Reports', getLocalStorageInfo().defaultLessor && getLocalStorageInfo().defaultLessor.lessor_type != 3) }
              {this.primaryNavLink('settings.png', 20, '/configure', pathname.includes('/configure'), true, 'userManagement', 'right', 'Configure', userDetail.user.permission.console && userDetail.user.permission.console.lessor_configuration)}
              { this.primaryNavLink('setting.svg', 20, '/users/manage/active', (pathname.includes('/users')), sideBarStatus, 'Settings', 'right', 'Settings', getLocalStorageInfo().user.permission && getLocalStorageInfo().user.permission.console && getLocalStorageInfo().user.permission.console.manage_user) }
              { window.innerWidth <= 1199 ?
                <li>
                  <Link to="/user-profile">
                    <img src={imgStoragePath+"phase-2/users.png"} alt="profile" />
                    <br/>
                    <span className="sideBarSubheading">Profile</span>
                  </Link>
                </li> : null
              }
              { window.innerWidth <= 1199 ?
                <li>
                  <Link onClick = {this.logout}>
                    <img className="logout-icon" src={imgStoragePath+"logout_icon.png"} alt="logout"  width="20"/>
                    <br/>
                    <span className="sideBarSubheading">Logout</span>
                  </Link>
                </li> : null
              }
            </ul>
          }
        </div>
        {
          // <Link className="click-indicator" onClick={this.props.sideBarToggle}>
          //   <img src={imgStoragePath + (true ? 'tabpush.png': 'tabpull.png')} alt="slider" />
          // </Link>
        }
     </div>
    )
  }
}

const mapStateToProps = state => ({
  licenses: state.LicenseReducer.licensList,
  notificationOpen:state.sharedReducers.notificationOpen,
  dashSw:state.sharedReducers.dashSw,
})

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sidebarToggleFn: (statusFlag) => dispatch({
      type: SIDEBAR_TOGGLE,
      payload: false
    }),
    fetchLicenses : () => dispatch(licenseListAc())
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(PrySidebarExp);

import { getStore } from '../store/globalStore';
import { LOAD_DATA_BUCKET } from '../shared/actions';
import { toastFlashMessage } from '../utils';
const userDetail = JSON.parse(localStorage.getItem('userInfo'));
let db;
let request = window.indexedDB.open('spartaBucket',1);
request.onsuccess = function(event){
  db = request.result;
}
request.onupgradeneeded = function(event) {
  var db = event.target.result;
  var objectStore = db.createObjectStore("itemObject", {keyPath: "uuid"});
}
export const addToIndexedDb = (itemObject) => {
  itemObject['lessor_id'] = userDetail.defaultLessor.id;
  let transaction = db.transaction(["itemObject"], "readwrite").objectStore("itemObject").add(itemObject);
  toastFlashMessage('File added to bucket successfully','success')
}

export const getItemObjects = (slug) => {
  var db = window.indexedDB.open("spartaBucket", 1);
  db.onsuccess = function(event){
    db = db.result;
    let transaction = db.transaction(["itemObject"]);
    let objectStore = transaction.objectStore("itemObject");
    var request = objectStore.getAll();
    let store = getStore();
    request.onsuccess = function (event){
      store.dispatch({
        type: LOAD_DATA_BUCKET,
        payload: {
          result: request.result,
          slug: slug,
        }
      });
    }
  }
}

export const removeItemObject = (slug) => {
  var db = window.indexedDB.open("spartaBucket", 1);
  db.onsuccess = function(event){
    db = db.result;
    let transaction = db.transaction(["itemObject"], "readwrite");
    let objectStore = transaction.objectStore("itemObject");
    var request = objectStore.getAll();
    request.onsuccess = function (event){
      request.result.map((item) => {
        if(item.lessor_id == userDetail.defaultLessor.id && item.slug == slug){
            objectStore.delete(item.uuid)
        }
      })
    }
  }
}

export const removeItem = (item, msn) => {
  var db = window.indexedDB.open("spartaBucket", 1);
  db.onsuccess = function(event){
    db = db.result;
    let transaction = db.transaction(["itemObject"], "readwrite");
    let objectStore = transaction.objectStore("itemObject");
    objectStore.delete(item.uuid)
    toastFlashMessage('File removed from bucket successfully','danger')
    getItemObjects(msn);
  }
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { Row, Col, Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
import { RecordsSideBar } from '../../Elements';
import { loadAssetViewAc } from '../../Dashboard/actionCreators';
import { downLoadResourcesTempAC, downloadDriveToolAc } from '../actionCreators';
import { shareRecordsAc } from '../../DataRoom/actionCreators'
import { withRouter, browserHistory } from 'react-router';
import { ShortHead, ContractBarLoader } from '../../../contracts/Elements';
import { getFileSize, getLocalStorageInfo } from '../../../../utils';
import { imgStoragePath, assetsType, displayDateTimeFormatShort } from '../../../../constants';
import moment from 'moment'
import  ResourceFolder  from '../components';
import { ListLoader  } from '../../../../shared';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import '../../assets/styles/records_d.scss';
import DownloadPopup from '../../Elements/DownloadPopup';
import { BULK_DATA_DWN_FLAG } from '../actions';
import { SHARE_KEY } from '../../DataRoom/actions'
class RecordResources extends Component{
  constructor(props){
    super(props);
    this.state = {
      shareKey: '',
      shareDurationError: false,
      shareValidity: '',
      shareDuration: 24,
      shareUrl: false,
      ModalFlag: false,
      isChecked: false,
      renameCard:{}
    };
  }
  componentDidMount(){
    this.props.fetchAssetInfo();
  }

  copyFileUrl = () => {
    let file_url = document.querySelector("#file_url");
    file_url.select();
    document.execCommand("copy");
    this.setState({
      shareUrl: true
    });
  }

  changeShareStatus(evt){
    this.setState({
      isChecked: !this.state.isChecked,
      shareDuration: 24,
      shareUrl: false,
      customDuration: false,
      shareDurationError: false,
    });
    this.props.shareRecords(this.state.renameCard,this.state.isChecked, this.state.shareDuration);
  }

  shareRecord = (item) => {
    this.setState(prevState => ({
      ...prevState,
      ModalFlag: true,
      renameCard: item,
      shareUrl: false,
      isChecked: item.share_enabled,
      folderUUid:item.uuid,
      shareValidity: item.valid_till,
      shareDuration: item.share_duration ? ![24,72,168,240].includes(item.share_duration) && item.share_duration % 24 == 0 ? item.share_duration / 24 : item.share_duration : 24,
      shareType: item.share_duration % 24 == 0 ? 'days' : 'hours',
      customDuration: ![24,72,168,240].includes(item.share_duration),
      shareDurationError: false
    }));
    if(item.share_enabled != null && item.share_enabled != undefined && item.share_enabled == true){
      this.setState({
        changeShareDuration: true
      })
      this.props.getShareKey(item.share_key, item.valid_till);
    }else {
      this.setState({
        changeShareDuration: false
      })
      //this.props.shareRecords(item,false, 24);
    }
  }

  setShareDuration = (type,duration,flag) =>{
    this.setState({
      shareDuration: duration,
      shareType: type,
      customDuration: flag,
      isChecked: true,
      shareDurationError: false
    });
    var shareDuration = type == "days" ? parseInt(duration * 24) : parseInt(duration);
    if(flag != true){
      this.props.shareRecords(this.state.renameCard,false,shareDuration)
    }
  }
  setCustomDuration = (type,duration,flag) =>{
    if(duration != null && duration != undefined && duration != '' && duration != 0){
      this.setState({
        shareDuration: duration,
        shareType: type,
        customDuration: flag,
        isChecked: true,
      });
      var shareDuration = type == "days" ? parseInt(duration * 24) : parseInt(duration);
      this.props.shareRecords(this.state.renameCard,false,shareDuration)
    }else{
      this.setState({
        shareDurationError: true
      })
    }
  }

  changeMsn =(evt) =>{
    let url = "";
    if(evt.records){
      url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/records/resources/';
    }else{
      url = '/asset-view/'+assetsType[evt.asset_type]+'/'+evt.slug;
    }
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
    this.props.fetchAssetInfo();
  }

  render(){
    const { ctrShortInfo, resourcesRecords, resourceLoader, notificationOpen, dataDownloadFlag } = this.props;
    let downloadName = '';
    if(Object.keys(ctrShortInfo).length ){
      downloadName = ctrShortInfo.asset_type == '1' ? 'MSN ' + ctrShortInfo.msn: ctrShortInfo.asset_type == '2' ? 'ESN ' +ctrShortInfo.esn : ctrShortInfo.asset_type == '3' ? 'APU ' +ctrShortInfo.serial_number : ctrShortInfo.asset_type == '4' ? 'LG ' :ctrShortInfo.asset_type == '5' ? 'PROPELLER' +ctrShortInfo.serial_number : ctrShortInfo.esn
    }
    const { isChecked } = this.state
    return(
      <div className="records-resource-container">
         <DownloadPopup bulkPopupToggle={this.props.bulkPopupToggle} flag={dataDownloadFlag}/>
        <RecordsSideBar />
        { Object.keys(ctrShortInfo).length ?
          <ShortHead
            headingText="Records"
            subHeaderTopMargin={notificationOpen == true ? 74 : 40}
            changeMsn={this.changeMsn}

            contrAssetBasicInfo={ctrShortInfo} />: <ContractBarLoader />
        }
        { Object.keys(resourcesRecords).length ? null : <ListLoader/>}
        { Object.keys(resourcesRecords).length ?
          <div>
            <div className="folder-row">
              <Row>
                {resourcesRecords.marketing_kit && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('DN') != -1  ?
                  <Col md="4">
                    <Link to={`/${this.props.params.type}/${this.props.params.aircraft_slug}/records/resources/data-kit/1?type=marketing`}>
                      <div className="folder-card">
                        <img src={imgStoragePath+"folder_icn2.png"} alt="" />
                        <div className="folder-info">
                          <h4>
                            {resourcesRecords.marketing_kit.name}
                            {
                              resourcesRecords.marketing_kit.share_enabled ?
                                <img src={imgStoragePath + "share_icon.png"} style={{float: 'none', width: '14px', height: '14p'}}/>
                              :null
                            }
                          </h4>
                        <p>No of files: {
                          resourcesRecords.marketing_kit.count?
                          resourcesRecords.marketing_kit.count :0
                        }</p>
                        </div>

                        </div>
                    </Link>
                    {
                      resourcesRecords.marketing_kit && getLocalStorageInfo().user.permission['records'].data_room && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('SH') != -1 ?
                        <span onClick={() => this.shareRecord(resourcesRecords.marketing_kit)} style={{position: 'absolute', cursor: 'pointer', right: '20px', fontSize: '12px', bottom: '20px', color: 'rgb(20, 121, 241)'}}>Share</span>
                      :null
                    }
                    { resourcesRecords.marketing_kit.count > 0 && resourcesRecords.marketing_kit.premission == "DN" ?
                      <img style={{position: 'absolute', width: '14px', bottom: '25px', right: '60px'}} className="rec-download-icon" src={imgStoragePath+"downloadresources.png"} alt="" onClick={() => this.props.downloadresources('marketing_kit',ctrShortInfo.records.technical_records.uuid,resourcesRecords.marketing_kit.download_type, 'MER')}/>
                      :null
                    }
                  </Col>:
                  null
                }
                { resourcesRecords.engineering_kit && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('DN') != -1 ?
                  <Col md="4" >
                    <Link to={`/${this.props.params.type}/${this.props.params.aircraft_slug}/records/resources/data-kit/2?type=engineering`}>
                      <div className="folder-card">
                        <img src={imgStoragePath+"folder_icn2.png"} alt="" />
                        <div className="folder-info">
                          <h4>
                            {resourcesRecords.engineering_kit.name}
                            {
                              resourcesRecords.engineering_kit.share_enabled ?
                                <img src={imgStoragePath + "share_icon.png"} style={{float: 'none', width: '14px', height: '14p'}}/>
                              :null
                            }
                          </h4>
                          <p>
                            No of files:
                            {
                              resourcesRecords.engineering_kit.count? resourcesRecords.engineering_kit.count
                              :0
                            }
                          </p>
                        </div>
                      </div>
                    </Link>
                    {
                      resourcesRecords.engineering_kit  && getLocalStorageInfo().user.permission['records'].data_room && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('SH') != -1 ?
                        <span  onClick={() => this.shareRecord(resourcesRecords.engineering_kit)} style={{position: 'absolute', cursor: 'pointer', right: '20px', fontSize: '12px', bottom: '20px', color: 'rgb(20, 121, 241)'}}>Share</span>
                      :null
                    }
                    { resourcesRecords.engineering_kit.count>0 && resourcesRecords.engineering_kit.premission == "DN" ?
                      <img style={{position: 'absolute', width: '14px', bottom: '25px', right: '60px'}} className="rec-download-icon" src={imgStoragePath+"downloadresources.png"} alt="" onClick={() => this.props.downloadresources('engineering_kit',ctrShortInfo.records.technical_records.uuid,resourcesRecords.engineering_kit.download_type,downloadName+' Engineering_kit')} />
                      :null
                    }
                  </Col>:null
                }
                { resourcesRecords.engine_apu_mini_pack && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('MPR') != -1 && !['fan', 'hpc', 'hpt', 'lpt'].includes(this.props.params.type)  ?
                  <Col md="4" >
                    <Link to={`/${this.props.params.type}/${this.props.params.aircraft_slug}/records/resources/mini-pack/?type=minipack`}>
                      <div className="folder-card">
                        <img src={imgStoragePath+"folder_icn2.png"} alt="" />
                        <div className="folder-info">
                          <h4>
                            {resourcesRecords.engine_apu_mini_pack.name}
                            {
                              resourcesRecords.engine_apu_mini_pack.share_enabled ?
                                <img src={imgStoragePath + "share_icon.png"} style={{float: 'none', width: '14px', height: '14p'}}/>
                              :null
                            }
                          </h4>
                          <p>
                            No of files:
                            {
                              resourcesRecords.engine_apu_mini_pack.count?
                              resourcesRecords.engine_apu_mini_pack.count
                              :0
                            }
                          </p>
                        </div>
                      </div>
                    </Link>
                    {
                      resourcesRecords.engine_apu_mini_pack  && getLocalStorageInfo().user.permission['records'].data_room && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('SH') != -1 ?
                        <span  onClick={() => this.shareRecord(resourcesRecords.engine_apu_mini_pack)} style={{position: 'absolute', cursor: 'pointer', right: '20px', fontSize: '12px', bottom: '20px', color: 'rgb(20, 121, 241)'}}>Share</span>
                      :null
                    }
                    { resourcesRecords.engine_apu_mini_pack.count>0 && resourcesRecords.engine_apu_mini_pack.premission == "DN" ?
                      <img style={{position: 'absolute', width: '14px', bottom: '25px', right: '60px'}} className="rec-download-icon" src={imgStoragePath+"downloadresources.png"} alt="" onClick={() => this.props.downloadresources('engine_apu_mini_pack',ctrShortInfo.records.technical_records.uuid,resourcesRecords.engine_apu_mini_pack.download_type,downloadName+' engine_apu_mini_pack')} />
                      :null
                    }
                  </Col>:null
                }
              </Row>
            </div>
            { resourcesRecords.folder_structures ?
          <div className="folder-row">
            <h2 className="folder-title">Folder Structures</h2>
            <Row>
            { resourcesRecords.folder_structures.msn_folder_empty_str ?
                <ResourceFolder downloadFn={() => this.props.downloadresources('msn_folder_empty_str', ctrShortInfo.records.technical_records.uuid, resourcesRecords.folder_structures.msn_folder_empty_str.download_type,downloadName+' Empty_folder_structure')}
                  name = {resourcesRecords.folder_structures.msn_folder_empty_str.title}
                  permission = {resourcesRecords.folder_structures.msn_folder_empty_str.permission}
                  size = {'0 bytes'}
                  donwloadEnabled={true}
                /> : null
            }
            { resourcesRecords.folder_structures.esn_folder_empty_str ?
              <ResourceFolder downloadFn={() => this.props.downloadresources('esn_folder_empty_str', ctrShortInfo.records.technical_records.uuid, resourcesRecords.folder_structures.esn_folder_empty_str.download_type,downloadName+' Empty_folder_structure')}
              name = {resourcesRecords.folder_structures.esn_folder_empty_str.title}
              permission = {resourcesRecords.folder_structures.esn_folder_empty_str.permission}
              size = {'0 bytes'}
              donwloadEnabled={true}
              // hide = {true}
              /> : null
            }
            { resourcesRecords.folder_structures.apu_folder_empty_str ?
              <ResourceFolder downloadFn={() => this.props.downloadresources('apu_folder_empty_str', ctrShortInfo.records.technical_records.uuid, resourcesRecords.folder_structures.apu_folder_empty_str.download_type,downloadName+' Empty_folder_structure')}
              name = {resourcesRecords.folder_structures.apu_folder_empty_str.title}
              permission = {resourcesRecords.folder_structures.apu_folder_empty_str.permission}
              size = {'0 bytes'}
              donwloadEnabled={true}
              // hide = {true}
              /> : null
            }
            { resourcesRecords.folder_structures.lg_folder_empty_str ?
              <ResourceFolder downloadFn={() => this.props.downloadresources('lg_folder_empty_str', ctrShortInfo.records.technical_records.uuid, resourcesRecords.folder_structures.lg_folder_empty_str.download_type,downloadName+' Empty_folder_structure')}
              name = {resourcesRecords.folder_structures.lg_folder_empty_str.title}
              permission = {resourcesRecords.folder_structures.lg_folder_empty_str.permission}
              size = {'0 bytes'}
              donwloadEnabled={true}
              // hide = {true}
              /> : null
            }
            { resourcesRecords.folder_structures.fan_folder_empty_str ?
              <ResourceFolder downloadFn={() => this.props.downloadresources('fan_folder_empty_str', ctrShortInfo.records.technical_records.uuid, resourcesRecords.folder_structures.fan_folder_empty_str.download_type,downloadName+' Empty_folder_structure')}
              name = {resourcesRecords.folder_structures.fan_folder_empty_str.title}
              permission = {resourcesRecords.folder_structures.fan_folder_empty_str.permission}
              size = {'0 bytes'}
              donwloadEnabled={true}
              // hide = {true}
              /> : null
            }
            { resourcesRecords.folder_structures.hpc_folder_empty_str ?
              <ResourceFolder downloadFn={() => this.props.downloadresources('hpc_folder_empty_str', ctrShortInfo.records.technical_records.uuid, resourcesRecords.folder_structures.hpc_folder_empty_str.download_type,downloadName+' Empty_folder_structure')}
              name = {resourcesRecords.folder_structures.hpc_folder_empty_str.title}
              permission = {resourcesRecords.folder_structures.hpc_folder_empty_str.permission}
              size = {'0 bytes'}
              donwloadEnabled={true}
              // hide = {true}
              /> : null
            }
            { resourcesRecords.folder_structures.hpt_folder_empty_str ?
              <ResourceFolder downloadFn={() => this.props.downloadresources('hpt_folder_empty_str', ctrShortInfo.records.technical_records.uuid, resourcesRecords.folder_structures.hpt_folder_empty_str.download_type,downloadName+' Empty_folder_structure')}
              name = {resourcesRecords.folder_structures.hpt_folder_empty_str.title}
              permission = {resourcesRecords.folder_structures.hpt_folder_empty_str.permission}
              size = {'0 bytes'}
              donwloadEnabled={true}
              // hide = {true}
              /> : null
            }
            { resourcesRecords.folder_structures.lpt_folder_empty_str ?
              <ResourceFolder downloadFn={() => this.props.downloadresources('lpt_folder_empty_str', ctrShortInfo.records.technical_records.uuid, resourcesRecords.folder_structures.lpt_folder_empty_str.download_type,downloadName+' Empty_folder_structure')}
              name = {resourcesRecords.folder_structures.lpt_folder_empty_str.title}
              permission = {resourcesRecords.folder_structures.lpt_folder_empty_str.permission}
              size = {'0 bytes'}
              donwloadEnabled={true}
              // hide = {true}
              /> : null
            }
            { resourcesRecords.folder_structures.propeller_folder_empty_str ?
              <ResourceFolder downloadFn={() => this.props.downloadresources('propeller_folder_empty_str', ctrShortInfo.records.technical_records.uuid, resourcesRecords.folder_structures.propeller_folder_empty_str.download_type,downloadName+' Empty_folder_structure')}
              name = {resourcesRecords.folder_structures.propeller_folder_empty_str.title}
              permission = {resourcesRecords.folder_structures.propeller_folder_empty_str.permission}
              size = {'0 bytes'}
              donwloadEnabled={true}
              // hide = {true}
              /> : null
            }
          </Row>
        </div> : null
        }
        { resourcesRecords.templates ?
        <div className="folder-row" >
          <h2 className="folder-title">Templates</h2>
          <Row>
            { resourcesRecords.templates.statement_tpl ?
             <ResourceFolder  downloadFn={() => this.props.downloadresources('statement_tpl', ctrShortInfo.records.technical_records.uuid, resourcesRecords.templates.statement_tpl.download_type,'statement_tpl')}
             name = {resourcesRecords.templates.statement_tpl.title}
             permission = {resourcesRecords.templates.statement_tpl.permission}
             size = {getFileSize(resourcesRecords.templates.statement_tpl.size)}
             hide = {true}
             donwloadEnabled={true}
             /> : null
            }
            { resourcesRecords.templates.data_room_tpl ?
            <ResourceFolder downloadFn={() => this.props.downloadresources('data_room_tpl', ctrShortInfo.records.technical_records.uuid, resourcesRecords.templates.data_room_tpl.download_type,downloadName+' Data_room_tpl')}
            name = {resourcesRecords.templates.data_room_tpl.title}
            permission = {resourcesRecords.templates.data_room_tpl.permission}
            size = {'0 bytes'}
            donwloadEnabled={true}
            /> : null
            }
            { resourcesRecords.templates.suite_picture_ctr ?
            <ResourceFolder downloadFn={() => this.props.downloadresources('suite_picture_ctr', ctrShortInfo.records.technical_records.uuid, resourcesRecords.templates.suite_picture_ctr.download_type,'suite_picture_ctr')}
            name = {resourcesRecords.templates.suite_picture_ctr.title}
            permission = {resourcesRecords.templates.suite_picture_ctr.permission}
            size = {getFileSize(resourcesRecords.templates.suite_picture_ctr.size)}
            hide = {true}
            donwloadEnabled={true}
            /> : null
            }
          </Row>
        </div>:null
        }
        {resourcesRecords.tool && getLocalStorageInfo().user.permission['records'].data_room && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('DN') != -1  ?
        <div className="folder-row">
          <h2 className="folder-title">Tools</h2>
          <Row>
          <ResourceFolder downloadFn={() => this.props.downloadresources('sync_tool_widows', '', '','')}
             name = {'Drive Tool'}
             size = {'Windows OS 32 bit'}
             permission='DN'
             donwloadEnabled={true}
             downloadFn={() => this.props.downloadDriveTool(1)}
             />
          <ResourceFolder downloadFn={() => this.props.downloadresources('sync_tool_widows', '', '','')}
             name = {'Drive Tool'}
             size = {'Windows OS 64 bit'}
             permission='DN'
             donwloadEnabled={true}
             downloadFn={() => this.props.downloadDriveTool(2)}
             />
             <ResourceFolder downloadFn={() => this.props.downloadresources('sync_tool_mac', '', '','')}
             name = {'Drive Tool'}
             size = {'Mac OS'}
             permission='DN'
             donwloadEnabled={true}
             downloadFn={() => this.props.downloadDriveTool(3)}
             />
          {resourcesRecords.tool.sync_tool ?
             <ResourceFolder downloadFn={() => this.props.downloadresources('sync_tool', ctrShortInfo.records.technical_records.uuid, resourcesRecords.tool.sync_tool.download_type,'sync_tool')}
             name = {resourcesRecords.tool.sync_tool.title}
             permission = {resourcesRecords.tool.sync_tool.permission}
             size = {getFileSize(resourcesRecords.tool.sync_tool.size)}
             hide = {true}
             donwloadEnabled={true}
             /> : null
           }

           {resourcesRecords.tool.transition_doc_checklist ?
            <ResourceFolder downloadFn={() => this.props.downloadresources('transition_doc_checklist', ctrShortInfo.records.technical_records.uuid, resourcesRecords.tool.transition_doc_checklist.download_type,'transition_doc_checklist')}
            name = {resourcesRecords.tool.transition_doc_checklist.title}
            permission = {resourcesRecords.tool.transition_doc_checklist.permission}
            size = {getFileSize(resourcesRecords.tool.transition_doc_checklist.size)}
            hide = {true}
            donwloadEnabled={true}
            /> : null
            }
          </Row>
        </div>:null
        }
        {
          resourceLoader ? <ListLoader/> : null
        }
        {resourcesRecords.data && getLocalStorageInfo().user.permission['records'].data_room && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('DN') != -1 ?
        <div className="folder-row">
          <h2 className="folder-title">Data</h2>
          <Row>
           {resourcesRecords.data.msn_folder_data ?
            <ResourceFolder
              downloadFn={() => this.props.downloadresources('msn_folder_data', ctrShortInfo.records.technical_records.uuid, resourcesRecords.data.msn_folder_data.download_type,downloadName+' Folder_data')}
              name = {resourcesRecords.data.msn_folder_data.title}
              permission = {resourcesRecords.data.msn_folder_data.permission}
              size = {getLocalStorageInfo().user.permission['records'].action_dock && (getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1 || getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('NPWC') != -1) ? getFileSize(resourcesRecords.data.msn_folder_data.size) : getFileSize(resourcesRecords.data.msn_folder_data.published_size)}
              type="data_room"
              donwloadEnabled={true}
            /> : null
           }
            {resourcesRecords.data.esn_folder_data ?
            <ResourceFolder downloadFn={() => this.props.downloadresources('esn_folder_data', ctrShortInfo.records.technical_records.uuid, resourcesRecords.data.esn_folder_data.download_type,downloadName +' Folder_data')}
              name = {resourcesRecords.data.esn_folder_data.title}
              permission = {resourcesRecords.data.esn_folder_data.permission}
              size = { getLocalStorageInfo().user.permission['records'].action_dock && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1 ? getFileSize(resourcesRecords.data.esn_folder_data.size) : getFileSize(resourcesRecords.data.esn_folder_data.published_size)}
              donwloadEnabled={parseFloat(resourcesRecords.data.esn_folder_data.size) <= 500000000 ? true :false }
              // hide = {true}
            /> : null
            }
            {resourcesRecords.data.apu_folder_data ?
            <ResourceFolder downloadFn={() => this.props.downloadresources('apu_folder_data', ctrShortInfo.records.technical_records.uuid, resourcesRecords.data.apu_folder_data.download_type,downloadName +' Folder_data')}
              name = {resourcesRecords.data.apu_folder_data.title}
              permission = {resourcesRecords.data.apu_folder_data.permission}
              size = { getLocalStorageInfo().user.permission['records'].action_dock && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1 ? getFileSize(resourcesRecords.data.apu_folder_data.size) : getFileSize(resourcesRecords.data.apu_folder_data.published_size)}
              donwloadEnabled={parseFloat(resourcesRecords.data.apu_folder_data.size) <= 500000000 ? true :false }
              // hide = {true}
            /> : null
            }
            {resourcesRecords.data.lg_folder_data ?
            <ResourceFolder downloadFn={() => this.props.downloadresources('lg_folder_data', ctrShortInfo.records.technical_records.uuid, resourcesRecords.data.lg_folder_data.download_type,downloadName +' Folder_data')}
              name = {resourcesRecords.data.lg_folder_data.title}
              permission = {resourcesRecords.data.lg_folder_data.permission}
              size = { getLocalStorageInfo().user.permission['records'].action_dock && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1 ? getFileSize(resourcesRecords.data.lg_folder_data.size) : getFileSize(resourcesRecords.data.lg_folder_data.published_size)}
              donwloadEnabled={parseFloat(resourcesRecords.data.lg_folder_data.size) <= 500000000 ? true :false }
              // hide = {true}
            /> : null
            }
            {resourcesRecords.data.propeller_folder_data ?
            <ResourceFolder downloadFn={() => this.props.downloadresources('propeller_folder_data', ctrShortInfo.records.technical_records.uuid, resourcesRecords.data.propeller_folder_data.download_type,downloadName +' Folder_data')}
              name = {resourcesRecords.data.propeller_folder_data.title}
              permission = {resourcesRecords.data.propeller_folder_data.permission}
              size = { getLocalStorageInfo().user.permission['records'].action_dock && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1 ? getFileSize(resourcesRecords.data.propeller_folder_data.size) : getFileSize(resourcesRecords.data.propeller_folder_data.published_size)}
              donwloadEnabled={parseFloat(resourcesRecords.data.propeller_folder_data.size) <= 500000000 ? true :false }
              // hide = {true}
            /> : null
            }
            {resourcesRecords.data.re_marketing_data ?
            <ResourceFolder downloadFn={() => this.props.downloadresources('re_marketing_data', ctrShortInfo.records.technical_records.uuid, resourcesRecords.data.re_marketing_data.download_type,'re_marketing_data')}
              name = {resourcesRecords.data.re_marketing_data.title}
              permission = {resourcesRecords.data.re_marketing_data.permission}
              size = {getFileSize(resourcesRecords.data.re_marketing_data.size)}
              hide = {true}
              donwloadEnabled={true}
            /> : null
            }
            { resourcesRecords.data.redelivery_data ?
            <ResourceFolder downloadFn={() => this.props.downloadresources('redelivery_data', ctrShortInfo.records.technical_records.uuid, resourcesRecords.data.redelivery_data.download_type,'redelivery_data')}
              name = {resourcesRecords.data.redelivery_data.title}
              permission = {resourcesRecords.data.redelivery_data.permission}
              size = {getFileSize(resourcesRecords.data.redelivery_data.size)}
              hide = {true}
              donwloadEnabled={true}
            /> : null
            }
            {resourcesRecords.data.fan_folder_data ?
            <ResourceFolder downloadFn={() => this.props.downloadresources('fan_folder_data', ctrShortInfo.records.technical_records.uuid, resourcesRecords.data.fan_folder_data.download_type,downloadName +' Folder_data')}
              name = {resourcesRecords.data.fan_folder_data.title}
              permission = {resourcesRecords.data.fan_folder_data.permission}
              size = { getLocalStorageInfo().user.permission['records'].action_dock && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1 ? getFileSize(resourcesRecords.data.fan_folder_data.size) : getFileSize(resourcesRecords.data.fan_folder_data.published_size)}
              donwloadEnabled={resourcesRecords.data && resourcesRecords.data.fan_folder_data && resourcesRecords.data.fan_folder_data.size && parseFloat(resourcesRecords.data.fan_folder_data.size) <= 500000000 ? true :false  }
              // hide = {true}
            /> : null
            }
            {resourcesRecords.data.hpc_folder_data ?
            <ResourceFolder downloadFn={() => this.props.downloadresources('hpc_folder_data', ctrShortInfo.records.technical_records.uuid, resourcesRecords.data.hpc_folder_data.download_type,downloadName +' Folder_data')}
              name = {resourcesRecords.data.hpc_folder_data.title}
              permission = {resourcesRecords.data.hpc_folder_data.permission}
              size = { getLocalStorageInfo().user.permission['records'].action_dock && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1 ? getFileSize(resourcesRecords.data.hpc_folder_data.size) : getFileSize(resourcesRecords.data.hpc_folder_data.published_size)}
              donwloadEnabled={resourcesRecords.data && resourcesRecords.data.hpc_folder_data && resourcesRecords.data.hpc_folder_data.size && parseFloat(resourcesRecords.data.hpc_folder_data.size) <= 500000000 ? true :false  }
              // hide = {true}
            /> : null
            }
            {resourcesRecords.data.hpt_folder_data ?
            <ResourceFolder downloadFn={() => this.props.downloadresources('hpt_folder_data', ctrShortInfo.records.technical_records.uuid, resourcesRecords.data.hpt_folder_data.download_type,downloadName +' Folder_data')}
              name = {resourcesRecords.data.hpt_folder_data.title}
              permission = {resourcesRecords.data.hpt_folder_data.permission}
              size = { getLocalStorageInfo().user.permission['records'].action_dock && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1 ? getFileSize(resourcesRecords.data.hpt_folder_data.size) : getFileSize(resourcesRecords.data.hpt_folder_data.published_size)}
              donwloadEnabled={resourcesRecords.data && resourcesRecords.data.hpt_folder_data && resourcesRecords.data.hpt_folder_data.size && parseFloat(resourcesRecords.data.hpt_folder_data.size) <= 500000000 ? true :false  }
              // hide = {true}
            /> : null
            }
            {resourcesRecords.data.lpt_folder_data ?
            <ResourceFolder downloadFn={() => this.props.downloadresources('lpt_folder_data', ctrShortInfo.records.technical_records.uuid, resourcesRecords.data.lpt_folder_data.download_type,downloadName +' Folder_data')}
              name = {resourcesRecords.data.lpt_folder_data.title}
              permission = {resourcesRecords.data.lpt_folder_data.permission}
              size = { getLocalStorageInfo().user.permission['records'].action_dock && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1 ? getFileSize(resourcesRecords.data.lpt_folder_data.size) : getFileSize(resourcesRecords.data.lpt_folder_data.published_size)}
              donwloadEnabled={resourcesRecords.data && resourcesRecords.data.lpt_folder_data && resourcesRecords.data.lpt_folder_data.size && parseFloat(resourcesRecords.data.lpt_folder_data.size) <= 500000000 ? true :false  }
              // hide = {true}
            /> : null
            }
          </Row>
        </div>: null
        }
        </div>: null
        }
        <Modal isOpen={this.state.ModalFlag} size="lg" toggle={() => this.setState({ModalFlag: false})} className="share-record-modal">
          <ModalHeader toggle={() => this.setState({ModalFlag: false})}>
            Share <strong>"{this.state.renameCard.name}"</strong>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md="12">
                <input onChange={(evt) => this.changeShareStatus(evt) } ref="switch" checked={ isChecked } type="checkbox" />
                <span className="share-link-checkbox">Share Link</span>
              </Col>
            </Row>
            { isChecked == true ?
              <div>
                <Row>
                  <Col md="12">
                    <span>
                      Anyone with the url below will be able to view and download shared records.
                      Updating the sharing duration will generate a new URL below.
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Input id="file_url" readOnly type="text" disabled={ isChecked == true ? false : true} className="share-text" value={ isChecked == true ? this.props.shareKey ? window.location.protocol+'//'+window.location.hostname+'/records/view-shared/' + this.props.shareKey +'/':'':''} placeholder="https://"/>
                    <span className="share-button" onClick={() => this.copyFileUrl()}>Copy Link</span>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    {resourceLoader}
                    <h2> {this.props.shareValidity != null ? 'Share Link Validity' : null}   {this.props.shareValidity != null ? <span>(Valid Till: {this.props.shareValidity != null && this.props.shareValidity != undefined && this.props.shareValidity != '' ? moment.utc(this.props.shareValidity).format(displayDateTimeFormatShort) + ' UTC' : '--'})</span> : null} {this.state.changeShareDuration ? <Link onClick={() => this.setState({changeShareDuration: false, shareUrl: false})} className="change-share-duration">Update Share Duration</Link> : null}</h2>
                    {
                      this.state.changeShareDuration == false ?
                      <ul className="list-inline">
                        <li className={this.state.shareDuration == 24 && this.state.customDuration == false ? "list-inline-item active" : "list-inline-item"}>
                          <span onClick={() => this.setShareDuration("hour",24, false)}><input type="radio" checked={this.state.shareDuration == 24 ? 'checked' : ''} name="share_duration" /> 1 Day</span>
                        </li>
                        <li className={this.state.shareDuration == 72 && this.state.customDuration == false ? "list-inline-item active" : "list-inline-item"}>
                          <span onClick={() => this.setShareDuration("hour",72, false)}><input type="radio" checked={this.state.shareDuration == 72 ? 'checked' : ''} name="share_duration" /> 3 Days</span>
                        </li>
                        <li className={this.state.shareDuration == 168 && this.state.customDuration == false ? "list-inline-item active" : "list-inline-item"}>
                          <span><input type="radio" onClick={() => this.setShareDuration("hour",168, false)} checked={this.state.shareDuration == 168 ? 'checked' : ''} name="share_duration"/> 7 Days</span>
                        </li>
                        <li className={this.state.shareDuration == 240 && this.state.customDuration == false ? "list-inline-item active" : "list-inline-item"} >
                          <span onClick={() => this.setShareDuration("hour",240, false)}><input type="radio" checked={this.state.shareDuration == 240 ? 'checked' : ''} name="share_duration" /> 10 Days</span>
                        </li>
                        <li className={this.state.customDuration ? "list-inline-item active" : "list-inline-item"}>
                          <span onClick={() => this.setCustomDuration("hour",6, true)}><input type="radio" checked={this.state.customDuration ? 'checked' : ''} name="share_duration"/> Custom</span>
                          <input type="number" min='1' step='1' value={this.state.customDuration ? this.state.shareDuration : 6} onChange={(evt) => this.setShareDuration(this.state.shareType, evt.target.value, true)} onBlur={(evt) => this.setCustomDuration(this.state.shareType, evt.target.value, true)}/>
                          <select onChange={(evt) => this.setCustomDuration(evt.target.value, this.state.customDuration ? this.state.shareDuration : 6, true)}>
                            <option value="hour">Hours</option>
                            <option value="days">Days</option>
                          </select>
                        </li>
                      </ul>
                      :null
                    }
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    {this.state.shareUrl ?
                      <span className="copy-link-success">Link Copied Successfully</span>:null
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      {this.state.shareDurationError  ?
                        <span style={{marginLeft: '175px'}} className="copy-link-success">Custom share duration can not be 0 or empty.</span>:null
                      }
                    </Col>
                  </Row>
                </div>
                :null
              }
              <Row>
                <Col md="12">
                  { isChecked == false?
                    <span style={{marginLeft: '200px'}} className="copy-link-success">Sharing link is disabled for this record</span>:null
                  }
                </Col>
              </Row>
            </ModalBody>
          </Modal>
      </div>

    )
  }
}

const mapStateToProps = (state) => ({
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  resourcesRecords: state.ResourcesReducer.resourcesRecords,
  resourceLoader: state.ResourcesReducer.resourceLoader,
  notificationOpen:state.sharedReducers.notificationOpen,
  dataDownloadFlag:state.ResourcesReducer.dataDownloadFlag,
  shareKey: state.DataRoomReducer.shareKey,
  shareValidity: state.DataRoomReducer.shareValidity,
});
const mapDispatchToProps = (dispatch,ownProps) =>{
  return{
    fetchAssetInfo: () => dispatch(loadAssetViewAc(ownProps.params,'Resources')),
    downloadDriveTool: (type) => dispatch(downloadDriveToolAc(ownProps.params, type)),
    downloadresources: (template, uuid, type, name) => dispatch (downLoadResourcesTempAC(ownProps.params, template, uuid, type, name)),
    shareRecords: (data, status, hours) => dispatch(shareRecordsAc(ownProps.params,data,status,hours, true, true)),
    bulkPopupToggle: () => dispatch({
      type: BULK_DATA_DWN_FLAG,
      payload: false
    }),
    getShareKey: (shareKey, shareValidity) => dispatch({
      type: SHARE_KEY,
      payload: {shareKey,shareValidity},
    }),
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(RecordResources,['records','resources','R'])))

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row } from 'reactstrap'
import { ListingRow, ListingHeading, AddContractForm } from '../components';
import { Link, withRouter, browserHistory } from 'react-router';
import { Header, EditSidebar, ContractSideBar, FieldInput, FieldDate, FieldCol, FieldGroup, FieldLabel } from '../../Elements';
import { contrAddAc, contrListAc, contractUsageAc, terminateContractAc } from '../actionCreators';
import { sLesseeListAc, sOperatorListAc, sGlobalConstantAc} from '../../../../shared/actionCreators';
import { aircraftMaintenanceAc } from '../../../console/TechSummary/actionCreators';
import { ListLoader, NoRecordFound, LicenceModal } from '../../../../shared';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { getLocalStorageInfo } from '../../../../utils';
import { assetsType } from '../../../../constants';
import moment from 'moment'
import { BUY_LICENCE } from '../../../../shared/actions'

class ContractListing extends Component {
  constructor(props){
    super(props);
    this.state = {
      addForm: false,
      closeContractModal: false,
      closure_reason: '',
      closure_date: '',
      contractSlug: '',
      closureDateError:'',
      closureReasonError:''
    }
  }
  componentDidMount(){
    this.props.fetchContrList();
    this.props.contractUsageInfo();
    this.props.fetchTechConstant({constant_types:['lease_type']});
    this.props.fetchMaintenanceGrp();
    if(!this.props.operators.length){
      this.props.fetchOperatorList();
    }
    if(!this.props.lessees.length){
      this.props.fetchLesseeList();
    }
  }

  changeMsn =(evt) =>{
    if(evt.contracts == false){
      let url = '/asset-view/'+assetsType[evt.asset_type]+'/'+evt.slug
      browserHistory.push(url)
    }else{
      let url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/contracts/';
      browserHistory.push(url);
      this.props.params.type = assetsType[evt.asset_type];
      this.props.params.aircraft_slug = evt.slug;
      this.componentDidMount();
    }
  }
  terminateContractFn = (data, slug) => {
    if(moment(data.closure_date).isValid() && data.closure_reason){
      this.props.terminateContract(data, slug)
      this.setState({closeContractModal:false})
    }else {
      if(!moment(data.closure_date).isValid()){
        this.setState({closureDateError:'Please enter Valid Closure date'})
      }
      if(!data.closure_reason){
        this.setState({closureReasonError:'Please enter Closure Reason'})
      }
    }
  }

  toggleEditSideBar = (type) => {
    if(getLocalStorageInfo().user.permission.contracts && getLocalStorageInfo().user.permission.contracts.is_saas){
      if(this.props.contractUsage.available == this.props.contractUsage.used){
        this.props.buyLicence({
          flag: true,
          action: '/licenses/contracts',
          content: "License for contracts is expired. Please upgrade your license to add more contracts."
        })
        return;
      }
    }
    this.setState(prevState => ({
      ...prevState,
      [type]: !prevState[type]
    }))
  }

  render() {
    const { lists, operators, lessees, contractListLoader, sLeaseStatus, techConstants, maintenanceProgGrp } = this.props;
    return (
      <div className="contract-container">
        <div className="log-listing-block">
          <Header headingText="Lease Management" type="short" changeMsn={this.changeMsn} params= {this.props.params}/>
          <div className=" add-contract text-right">
            {getLocalStorageInfo().user.permission['contracts']['contract_history'].indexOf('C') != -1 ?
              <Link onClick = {() => this.toggleEditSideBar('addForm')} className="primary-btn hidden-xs">+ Add Lease</Link>:null
            }
          </div>
          <ContractSideBar application={`Lease History`} type="shorter" params={this.props.params} />
          {contractListLoader ? <ListLoader />:null}
          <div className="mobile-asset-list contract-list-mbl-spac" style={{marginLeft: '60px'}}>
            <ListingHeading />
            {lists.map((list, index) => <ListingRow closeContract={() => this.setState({closeContractModal: true, closure_date: '', closure_reason: '', contractSlug: list.slug})} key={index} list={list} params={this.props.params} /> )}
            {!contractListLoader && !lists.length ?
              <NoRecordFound description="Get Started by clicking '+ Add Lease' button above" />:null
            }
          </div>
          <EditSidebar title="Add Lease" editSideBar={this.state.addForm}
          toggleEditSideBar={() => this.toggleEditSideBar('addForm')}>
            {
              this.state.addForm ?
              <AddContractForm type="add" toggleEditSideBar={() => this.toggleEditSideBar('addForm')}
                createContract={this.props.createContract}
                slug={this.props.params.slug}
                operators={operators}
                lessees={lessees}
                sLeaseStatus={sLeaseStatus}
                techConstants={techConstants}
                maintenanceProgGrp = {maintenanceProgGrp}
                params = {this.props.params}
              />:null
            }

          </EditSidebar>
        </div>
        <Modal isOpen={this.state.closeContractModal} centered={true} toggle={() => this.setState({closeContractModal: false, closure_reason: '', closure_date: '', contractSlug: ''})} size="lg" style={{maxHeight: '1000px', height: '100%'}}>
          <ModalHeader toggle={() => this.setState({closeContractModal: false, closure_reason: '', closure_date: '', contractSlug: ''})}>Terminate Lease</ModalHeader>
          <ModalBody style={{height:'500px'}}>
            <div className="delete-logs">
              <h4>Are you sure, you want to foreclose this Lease?</h4>
            </div>
            <Row>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Termination Date
                    <sup className="reqStar"> * </sup>
                  </FieldLabel>
                  <br/>
                  <FieldDate
                    value={this.state.closure_date}
                    type={'edit'}
                    keyParam="closure_date"
                    updateField={(type, value) => this.setState({closure_date: moment(value).format('YYYY-MM-DD')})}
                  />
                <h6 className="error-msg">{this.state.closure_date === '' ? this.state.closureDateError : ''}</h6>
                </FieldGroup>
              </FieldCol>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">
                    Reason for Termination
                    <sup className="reqStar"> * </sup>
                  </FieldLabel>
                  <FieldInput
                    value={this.state.closure_reason}
                    type={'edit'}
                    keyParam="closure_reason"
                    updateField={(type,value) => this.setState({closure_reason: value})}
                    maxLength={255}
                  />
                <h6 className="error-msg">{this.state.closure_reason === '' ? this.state.closureReasonError : ''}</h6>
                </FieldGroup>
              </FieldCol>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className="delete-logs">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button className="btn btn-danger"  onClick = {(e) => this.terminateContractFn({closure_date: this.state.closure_date, closure_reason: this.state.closure_reason, lease_status: 2},this.state.contractSlug)}>YES</button>
                </li>
                <li className="list-inline-item">
                  <button className="btn btn-secondary" onClick={() => this.setState({closeContractModal: false, closure_reason: '', closure_date: '', contractSlug: ''})}>NO</button>
                </li>
              </ul>
            </div>
          </ModalFooter>
        </Modal>
        {
          this.props.buyLicenceModal.flag ?
          <LicenceModal
            toggleLicence={() => this.props.buyLicence({
              flag: false,
              action: '',
              content: ''
            })}
            planId="6"
            licenceModal={this.props.buyLicenceModal}
            />
          :null
        }
      </div>

    )
  }
}
const mapStateToProps = state => ({
  lists: state.ContractListReducer.contractList,
  contractUsage: state.ContractListReducer.contractUsage,
  operators: state.sharedReducers.sOperatorList,
  lessees: state.sharedReducers.sLesseeList,
  contractListLoader: state.ContractListReducer.contractListLoader,
  techConstants: state.sharedReducers.techConstants,
	maintenanceProgGrp: state.TechSummaryReducer.maintenanceProgGrp,
  buyLicenceModal:state.sharedReducers.buyLicenceModal,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return{
    fetchContrList: () => dispatch(contrListAc(ownProps.params)),
    terminateContract: (data, contractSlug) => dispatch(terminateContractAc(ownProps.params, data, contractSlug)),
    fetchLesseeList: () => dispatch(sLesseeListAc()),
    fetchOperatorList: () => dispatch(sOperatorListAc()),
    fetchTechConstant : (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
    createContract : (data) => dispatch(contrAddAc(ownProps.params, data)),
    fetchMaintenanceGrp: (asset_type) => dispatch(aircraftMaintenanceAc(asset_type)),
    contractUsageInfo: () => dispatch(contractUsageAc(ownProps.params)),
    buyLicence: (modalObj) => dispatch({
      type: BUY_LICENCE,
      payload: modalObj
    })
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(ContractListing, ['contracts','contract_history','R'])));

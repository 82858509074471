import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { ContextMenu, Item, Separator, Submenu, ContextMenuProvider } from 'react-contexify';
import { getLocalStorageInfo } from '../../../utils';
import 'react-contexify/dist/ReactContexify.min.css';



class RecordsContextMenu extends Component{
  constructor(props){
    super(props);
  }

  render(){
    const {item} = this.props;
    return(

        <ContextMenu id={this.props.menuId}>
           <Item onClick={(evt) => this.props.previewRecord(item)}>{item.type == 'file' ? 'Preview' : 'Properties'}</Item>
             {
               ([0,1].includes(item.user_shared_folder_can_edit) || (item.remarks != 'stand_alone_folder' && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('DN') != -1 )) ||
               ([1].includes(item.user_shared_folder_can_edit) || (item.remarks != 'stand_alone_folder' && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('M') != -1))||
               ([1].includes(item.user_shared_folder_can_edit) || (item.remarks != 'stand_alone_folder' && item.type == 'file' && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('D') != -1)) ||
               ([1].includes(item.user_shared_folder_can_edit) || (item.remarks != 'stand_alone_folder' && item.type == 'folder' && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('D') != -1 && !(item.user_shared_folder && !item.user_shared_folder_can_edit))) ||
               (item.remarks != 'stand_alone_folder' && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('SH') != -1) ||
               ([1].includes(item.user_shared_folder_can_edit) || (item.remarks != 'stand_alone_folder' && item.type == 'file' && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('C') != -1)) ||
               ([1].includes(item.user_shared_folder_can_edit) || (item.remarks != 'stand_alone_folder' && item.type == 'folder' && this.props.createFolder && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('C') != -1)) ||
               ([1].includes(item.user_shared_folder_can_edit) || (item.remarks != 'stand_alone_folder' && this.props.type == "file" && getLocalStorageInfo().user.permission['records'].action_dock && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1 && item.status != "Published" && item.status != 'Reject')) ||
               ([1].includes(item.user_shared_folder_can_edit) || (this.props.type == "file" && getLocalStorageInfo().user.permission['records'].action_dock && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1 && item.status == "Published")) ||
               ([1].includes(item.user_shared_folder_can_edit) || (this.props.type == "file" && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('CAT') != -1 )) ||
               ([6,7,8,9].indexOf(this.props.assetType) === -1 && this.props.miniPackFolders && this.props.miniPackFolders.length && [1].includes(item.user_shared_folder_can_edit) || (this.props.type == "file" && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('CAT') != -1 && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('MPR') != -1 )) ||
               (this.props.type == "file" && getLocalStorageInfo().user.permission['records'].data_room && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('DN') != -1) ||
               (this.props.type == "file" && getLocalStorageInfo().user.permission['records'].data_room && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('DN') != -1)
               ?
               <Separator />
               :null
             }
         {[0,1].includes(item.user_shared_folder_can_edit) || (item.remarks != 'stand_alone_folder' && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('DN') != -1 )?
           <Item onClick={(evt) => this.props.downloadFiles(item)}>Download</Item>
           :null}
          {[1].includes(item.user_shared_folder_can_edit) || (item.remarks != 'stand_alone_folder' && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('M') != -1) ?
             <Item onClick={(evt) => this.props.moveRecord()}>Move to</Item>
             :null}
         {[1].includes(item.user_shared_folder_can_edit) || (item.remarks != 'stand_alone_folder' && item.type == 'file' && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('D') != -1) ?
           <Item className="remove-item" onClick={() => this.props.deleteRecord(item)}>Delete</Item>
           :null}
         {[1].includes(item.user_shared_folder_can_edit) || (item.remarks != 'stand_alone_folder' && item.type == 'folder' && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('D') != -1 && !(item.user_shared_folder && !item.user_shared_folder_can_edit)) ?
           <Item className="remove-item" onClick={() => this.props.deleteRecord(item)}>Delete</Item>
           :null}
         {item.remarks != 'stand_alone_folder' && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('SH') != -1 ?
           item.type == 'file' && item.status == "Published" ?
            <Item onClick={(evt) => this.props.shareRecord(evt)}>Share</Item>
           :item.remarks != 'stand_alone_folder' && item.type == 'folder' ?
            <Item onClick={(evt) => this.props.shareRecord(evt)}>Share</Item>
           :null
           :null
         }
         {[1].includes(item.user_shared_folder_can_edit) || (item.remarks != 'stand_alone_folder' && item.type == 'file' && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('C') != -1) ?
           <Item onClick={(evt) => this.props.renameRecord()}>Rename</Item>
           :null
         }
         {[1].includes(item.user_shared_folder_can_edit) || (item.remarks != 'stand_alone_folder' && item.type == 'folder' && this.props.createFolder && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('C') != -1) ?
           <Item onClick={(evt) => this.props.renameRecord()}>Rename</Item>
           :null
         }
           {[1].includes(item.user_shared_folder_can_edit) || (item.remarks != 'stand_alone_folder' && this.props.type == "file" && getLocalStorageInfo().user.permission['records'].action_dock && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1 && item.status != "Published" && item.status != 'Reject') ?
             <Submenu label="Action">
               {getLocalStorageInfo().user.permission['records'].action_dock && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1 && item.type == "file" ?
                 <Item onClick={(evt) => this.props.changeActionDockStatus({file: true, new_status: 3, objects:[item.uuid]})}>Publish</Item>
                 :null}
               {getLocalStorageInfo().user.permission['records'].action_dock && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('REJ') != -1 && item.type == "file"  ?
                 <Item onClick={(evt) => this.props.changeActionDockStatus({file: true, new_status: 4, objects:[item.uuid]})}>Reject</Item>
                 :null}
            </Submenu>: null}
           {[1].includes(item.user_shared_folder_can_edit) || (this.props.type == "file" && getLocalStorageInfo().user.permission['records'].action_dock && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1 && item.status == "Published") ?
             <Submenu label="Action">
              <Item onClick={(evt) => this.props.changeActionDockStatus({file: true, new_status: 1, objects:[item.uuid]})}>UnPublish</Item>
            </Submenu>: null}
            {
              [1].includes(item.user_shared_folder_can_edit) || (this.props.type == "file" && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('CAT') != -1 )?
                <Submenu label="Category">
                    <Item onClick={() => this.props.addToCategory(1, item.uuid)}>Marketing</Item>
                 <Item onClick={() => this.props.addToCategory(2, item.uuid)}>Engineering</Item>
                </Submenu>: null
            }

           {
             [6,7,8,9].indexOf(this.props.assetType) === -1 ?
            this.props.miniPackFolders && this.props.miniPackFolders.length && [1].includes(item.user_shared_folder_can_edit) || (this.props.type == "file" && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('CAT') != -1 && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('MPR') != -1 )?
             <Submenu label="Minipack">
               <div style={{height: '300px', overflow: 'auto'}}>
                 {
                   this.props.miniPackFolders.map(folder =>
                     <Item onClick={() => this.props.addFileToMiniPackBulk(folder.uuid, item.uuid)}>{folder.name}</Item>
                   )
                 }
               </div>
            </Submenu>
             :null:null
           }
           {this.props.type == "file" && getLocalStorageInfo().user.permission['records'].data_room && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('DN') != -1 ? <Separator /> : null}
           {this.props.type == "file" && getLocalStorageInfo().user.permission['records'].data_room && getLocalStorageInfo().user.permission['records']['data_room'].indexOf('DN') != -1 ?
             <Item onClick={this.props.addToBucket}>+ Add To Bucket</Item>
           :null}

        </ContextMenu>
    )
  }
}

export default RecordsContextMenu;

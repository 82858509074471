import React, { Component, Fragment } from 'react';
import Select from 'react-select';
import { withRouter } from 'react-router'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import 'react-select/dist/react-select.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { DeleteModal } from '../../../Elements';
import { ListLoader, ToolTipHover } from '../../../../../shared';
import { imgStoragePath, fieldDateFormat, backendDateFormat } from '../../../../../constants';
import { toastFlashMessage, getLocalStorageInfo } from '../../../../../utils';
import { downloadAllFilesApi, deleteFileApi } from '../apiServices';
import { sectionIds } from '../';
import closeIcon from '../../../../../User/assets/img/black_close.png'
class FinalSummeriesListV1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoader:false,
      modal:false,
      deleteFileModal:false,
      deleteFileId:'',
      item:{},
      type: false,
      textAreaChange: false,
      editable: null
    }
    this.downloadAllFilesApi = downloadAllFilesApi.bind(this);
    this.deleteFileApi = deleteFileApi.bind(this);
  }
  componentDidMount() {
    this.setState({
      editable: this.props.editable,
      item: this.props.item
    });
  }

  componentWillReceiveProps(nextProps){
    if((this.props.item.id !== nextProps.item.id) || (this.props.item.started_date !== nextProps.item.started_date) || (this.props.item.estimated_completion_date !== nextProps.item.estimated_completion_date) || (this.props.item.files.length !== nextProps.item.files.length)){
      this.setState(prevState => ({
        ...prevState,
        item:nextProps.item
      }))
    }
    if(nextProps.fullScreen === true){
      this.setState({type: true}, () => {
        [
          'operator_2_comments_',
          'operator_2_comments_',
          'acumen_comments_',
          'background_remarks_',
          'requirement_remarks_',
          'position_',
          'description_'
        ].map(item => {
          if(document.querySelector(`#${item}${this.props.index}`)){
            let element = document.querySelector(`#${item}${this.props.index}`)
            element.style.height = "1px";
            element.style.height = (20+element.scrollHeight)+"px";
          }
        })
      })
    }else{
      this.setState({type: false})
    }
  }

  deleteFilefn = (gapReportId, deleteFileId, sectionId, fileCount) => {
    this.deleteFileApi(this.props, gapReportId, deleteFileId, sectionId)
    .then(response => {
      if(response.data.statusCode === 200 || response.data.status_code === 200){
        // toastFlashMessage(response.data.message, 'success');
        this.setState({
          deleteFileModal:false,
          deleteFileId:'',
          item: {
            ...this.state.item,
            files: this.state.item.files.filter(file => file.id !== deleteFileId)
          }
        });
        if(fileCount === 1){
          this.setState({
            modal:false,
          });
        }
      }
    })
  }
  textAreaAdjust=(element) =>{
    element.target.style.height = "1px";
    element.target.style.height = (20+element.target.scrollHeight)+"px";
  }
  toggleType = () =>{
    this.setState(prevState => ({
      ...prevState,
      type: !prevState.type,
    }))
  }

  onChangeField = (value, key) =>{
    this.setState({
      item: {
        ...this.state.item,
        [key]:value
      }
    })
    this.props.onChangeField(value, key)
  }

  render(){
    const {index, editableRow, operatorFlag, toggleDelete, onChangeField, onUpdateField, uploadAttachment, sectionId, formInfo, editable, fullScreen, userList} = this.props;
    const options = userList.map(item => ({
      "value" : item.id,
      "label" : item.name
    }))
    const { modal,deleteFileModal, pageLoader, type, item } = this.state;
    var editablePermission = true
    if(getLocalStorageInfo().user.security_groups && getLocalStorageInfo().user.security_groups.filter(group => parseInt(group.id) === 17).length){
      editablePermission = false
    }
    return(
      <Fragment>
        <tr>
          <td>
            <input type="checkbox" checked={this.props.checked} onClick={() => this.props.selectItem()}/>
          </td>
           <td>
            {index+1}
           </td>
           <td>
              <Fragment>
                {
                  !fullScreen ?
                    <img id={`zoom-img-${index}`} className="table-zoom" onClick={() => this.toggleType()} src={this.state.type == false ? imgStoragePath + 'maximize.svg' : imgStoragePath + 'zoom-in.svg'} style={{ width: '15px', marginRight: '8px', cursor: 'pointer' }} alt="Zoom Icon" />
                  :null
                }
                {type == false && !fullScreen ? <ToolTipHover tagetId={`zoom-img-${index}`} placement= "top">Click to turn input fields into Expandable Format.</ToolTipHover> : !fullScreen ? <ToolTipHover tagetId={`zoom-img-${index}`} placement= "top">Click to turn input fields into Scrollable Format.</ToolTipHover> : null}
                {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editablePermission ?
                <img className="delete-icon" onClick={() => toggleDelete(item.id)} src={imgStoragePath + 'delete_red.png'} style={{ width: '15px', cursor: 'pointer' }} alt="Delete Icon" /> : null}
              </Fragment>
           </td>
           {
             [2,5,6,7,8,9,10,11,21,22,14,15,16,17,23,24,25,26,27,28,29,30,31,32,33,34,35].includes(sectionId) ?
               <td style={{width: '80px', display: 'block'}}>
                 {
                   editableRow && editablePermission ?
                   <div style={{ borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editablePermission ? '1px solid #717171': 'none'}}>
                     {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editablePermission ?
                     <textarea
                       style={{ resize:'inherit', height:'43px'}}
                       rows="1"
                       disabled={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? false : true}
                       value={item.ata}
                       onChange={(e) => this.onChangeField(e.target.value, 'ata')}
                       onBlur={(e) => onUpdateField(e.target.value, 'ata')}
                     /> : <div style={{width:'150px', wordBreak : 'break-word'}}>{item.ata ? item.ata  : "--"}</div>}
                   </div>
                   :null
                 }
               </td>
             :null
           }
          <td>
            { editableRow && type == false && editablePermission ?
              <div style={{ borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? '1px solid #717171': 'none', width:'150px'}}>
                {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?
                <textarea
                  style={{ resize:'inherit', height:'43px'}}
                  rows="4"
                  disabled={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? false : true}
                  value={item.description}
                  onChange={(e) => this.onChangeField(e.target.value, 'description')}
                  onBlur={(e) => onUpdateField(e.target.value, 'description')}
                /> : <div style={{width:'150px', wordBreak : 'break-word'}}>{item.description && item.description.length > 50 ? item.description.substring(0,47)+'...' : item.description ? item.description : "--"}</div>}
              </div>
              :
              editableRow == false ? <div style={{width:'150px', wordBreak : 'break-word'}}>{item.description ? item.description : "--"}</div> :
              <div style={{ borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editablePermission ? '1px solid #717171': 'none', width:'150px'}}>
                {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editablePermission ?
                <textarea
                  style={{ resize:'inherit', minHeight:'50px'}}
                  rows="4"
                  disabled={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? false : true}
                  value={item.description}
                  id={"description_" + index}
                  onChange={(e) => this.onChangeField(e.target.value, 'description')}
                  onBlur={(e) => onUpdateField(e.target.value, 'description')}
                  onKeyUp={this.textAreaAdjust}
                  onFocus={this.textAreaAdjust}
                /> : <div style={{width:'150px', wordBreak : 'break-word'}}>{item.description ? item.description  : "--"}</div>}
              </div>
            }
          </td>
          <td>
            <div className="file-upload" style={{width:'100px'}}>
              {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?
              <a style={{display: 'inline-block', position:'relative'}}>
                <img src={imgStoragePath+'file_upload.svg'} style={{width:'24px'}}/>
                <input
                  type="file"
                  multiple={true}
                  accept={".png, .jpg, .jpeg, .pdf, .xls, .xlsx"}
                  onChange={(e) => uploadAttachment(e.target.files)}
                  style={{width:'24px',position:'absolute',left:'0',opacity:'0', cursor:'pointer', padding:'12px 12px 0'}}
                />
              </a>:null}
              { item.files && item.files.length ?
                <span
                  onClick={() => this.setState({modal:true})}
                  style={{color:'#2F8CFF', verticalAlign:'bottom', marginLeft:'2px', cursor:'pointer'}}> ({item.files.length} Files)
                </span>
                : " "
              }
            </div>
          </td>
          <td>
            { editableRow && editablePermission ?
              <div className="outside-table enable-select-clear" style={{ borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? '1px solid #717171' : 'none' }}>
                {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?
                <Select
                  isclearable={false}
                  name="form-field-name"
                  value={item.priority ? item.priority : ''}
                  options={[{label:'P1',value:1},{label:'P2',value:2}]}
                  onChange={(priority) => { onUpdateField(priority? priority.value : '', 'priority'); this.onChangeField(priority ? priority.value : '', 'priority')}}
                  /> : <div style={{ width: '100px' }}>{item.priority && [{ label: 'P1', value: 1 }, { label: 'P2', value: 2 }].filter(pr => pr.value === item.priority).length ? [{ label: 'P1', value: 1 }, { label: 'P2', value: 2 }].filter(pr => pr.value === item.priority)[0].label : '--'}</div>}
              </div>
              : <div style={{ width: '100px' }}>{item.priority && [{ label: 'P1', value: 1 }, { label: 'P2', value: 2 }].filter(pr => pr.value === item.priority).length ? [{ label: 'P1', value: 1 }, { label: 'P2', value: 2 }].filter(pr => pr.value === item.priority)[0].label : '--'}</div>
            }
          </td>
          <td>{ editableRow && editablePermission ?
            <div className="outside-table" style={{ borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? '1px solid #717171' : 'none' }}>
              {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?
              <Select
                isClearable={false}
                name="form-field-name"
                disabled={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? false : true}
                value={item.disposition}
                options={item.disposition !== 0 ? [{label:'Reopen',value:2},{label:'Closed',value:1}] : [{label:'Open',value:0},{label:'Closed',value:1}]}
                onChange={(disposition) => {onUpdateField(disposition.value, 'disposition'); this.onChangeField(disposition.value, 'disposition')}}
                /> : <div style={{ width: '100px' }}>{item.disposition !== null && item.disposition !== '' && [{ label: 'Open', value: 0 }, { label: 'Closed', value: 1 }, {lable: 'Reopen', value: 2}].filter(ds => ds.value === item.disposition).length ? [{ label: 'Open', value: 0 }, { label: 'Closed', value: 1 }, {lable: 'Reopen', value: 2}].filter(ds => ds.value === item.disposition)[0].label : '--'}</div>}
            </div>
            : <div style={{ width: '100px' }}>{item.disposition !== null && item.disposition !== '' && [{ label: 'Open', value: 0 }, { label: 'Closed', value: 1 }, {lable: 'Reopen', value: 2}].filter(ds => ds.value === parseInt(item.disposition)).length ? [{ label: 'Open', value: 0 }, { label: 'Closed', value: 1 }, {lable: 'Reopen', value: 2}].filter(ds => ds.value === parseInt(item.disposition))[0].label : '--' }</div>
            }
          </td>
          { sectionIds.includes(sectionId) ?
            <Fragment>
              <td>
                { editableRow  && editablePermission?
                  <div style={{ borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? '1px solid #717171' : 'none', width:'150px'}}>
                    {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?
                    <textarea
                      style={{ resize:'inherit'}}
                      rows="4"
                      value={item.part_number}
                      disabled={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? false : true}
                      onChange={(e) => this.onChangeField(e.target.value, 'part_number')}
                      onBlur={(e) => onUpdateField(e.target.value, 'part_number')}
                    />: <div style={{ width: '100px' }}>{item.part_number ? item.part_number : "--"}</div>}
                  </div>
                  : <div style={{width:'150px'}}>{item.part_number ? item.part_number : '--'}</div>
                }
              </td>
              <td>
                { editableRow && editablePermission ?
                  <div style={{ borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? '1px solid #717171' : 'none', width:'150px'}}>
                    {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?
                    <textarea
                      style={{ resize:'inherit'}}
                      rows="4"
                      value={item.serial_number}
                      disabled={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? false : true}
                      onChange={(e) => this.onChangeField(e.target.value, 'serial_number')}
                      onBlur={(e) => onUpdateField(e.target.value, 'serial_number')}
                      maxLength={60}
                    />: <div style={{ width: '100px' }}>{item.serial_number ? item.serial_number : '--'}</div>}
                  </div>
                  :<div style={{width:'150px'}}>{item.serial_number ? item.serial_number : '--'}</div>
                }
              </td>
            </Fragment>:null
          }
          {
            sectionId === 26 ?
            <td>
              { editableRow && editablePermission ?
                <div style={{ borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? '1px solid #717171' : 'none', width:'150px'}}>
                  {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?
                  <textarea
                    style={{ resize:'inherit'}}
                    rows="4"
                    value={item.cycles_remaining}
                    disabled={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? false : true}
                    onChange={(e) => this.onChangeField(e.target.value, 'cycles_remaining')}
                    onBlur={(e) => onUpdateField(e.target.value, 'cycles_remaining')}
                  />: <div style={{ width: '100px' }}>{item.cycles_remaining ? item.cycles_remaining : "--"}</div>}
                </div>
                : <div style={{width:'150px'}}>{item.cycles_remaining ? item.cycles_remaining : '--'}</div>
              }
            </td>
            :null
          }
          <td>
            { editableRow && editablePermission ?
              <div className="outside-table" style={{ borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? '1px solid #717171' : 'none'}}>
              {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?
              <img src={item.estimated_completion_date?closeIcon:null} onClick={()=> onUpdateField('', 'started_date')} style={{position:'relative', top:'20px', zIndex: '1', width: '8px', float: 'right', cursor:'pointer'}}/>: null}
                {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?
                <DatePicker
                  dateFormat = {fieldDateFormat}
                  selected={ item.started_date && item.started_date != "Invalid date" && moment(item.started_date).isValid() ? moment(item.started_date): null }
                  onChange={(e) => {onUpdateField(moment(e).format(backendDateFormat), 'started_date')}}
                  showYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                  withPortal
                  disabled={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? false : true}
                />:<div style={{width:'100px'}}>{item.started_date ? item.started_date : "--"}</div>}
              </div>
              :<div style={{width:'100px'}}>{item.started_date ? item.started_date : "--"}</div>}
            </td>
            <td>{ editableRow && editablePermission ?
            <div className="outside-table" style={{ borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? '1px solid #717171' : 'none' }}>
            {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?
            <img src={item.estimated_completion_date?closeIcon:null} onClick={()=> onUpdateField('', 'estimated_completion_date')} style={{position:'relative', top:'20px', zIndex: '1', width: '8px', float: 'right', cursor:'pointer'}}/>: null}
              {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?
                <DatePicker
                  dateFormat = {fieldDateFormat}
                  selected={ item.estimated_completion_date && item.estimated_completion_date != "Invalid date" && moment(item.estimated_completion_date).isValid() ? moment(item.estimated_completion_date): null }
                  onChange={(e) => {onUpdateField(moment(e).format(backendDateFormat), 'estimated_completion_date')}}
                  showYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                  style={{ overflow:'hidden' }}
                  withPortal
                  disabled={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? false : true}
                /> : <div style={{width:'100px'}}>{item.estimated_completion_date ? item.estimated_completion_date : "--"}</div>}
              </div>
            :<div style={{width:'100px'}}>{item.estimated_completion_date ? item.estimated_completion_date : "--"}</div>}
          </td>
          { sectionIds.includes(sectionId) ?
            <Fragment>
            <td>
              { editableRow && editablePermission ?
                <div className="outside-table" style={{ borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? '1px solid #717171' : 'none'}}>
                {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?
                <img src={item.estimated_completion_date?closeIcon:null} onClick={()=> onUpdateField('', 'install_date')} style={{position:'relative', top:'20px', zIndex: '1', width: '8px', float: 'right', cursor:'pointer'}}/>: null}
                  {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?
                  <DatePicker
                    dateFormat = {fieldDateFormat}
                    selected={ item.install_date && item.install_date != "Invalid date" && moment(item.install_date).isValid() ? moment(item.install_date): null }
                    onChange={(e) => {onUpdateField(moment(e).format(backendDateFormat), 'install_date')}}
                    showYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                    withPortal
                    disabled={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? false : true}
                  />:<div style={{width:'100px'}}>{item.install_date ? item.install_date : "--"}</div>}
                </div>
                :<div style={{width:'100px'}}>{item.install_date ? item.install_date : "--"}</div>}
              </td>
              <td>
                { editableRow && type == false && editablePermission ?
                  <div style={{ borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? '1px solid #717171' : 'none', width:'150px'}}>
                    {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?
                    <textarea
                      style={{ resize:'inherit', height:'43px'}}
                      rows="4"
                      value={item.position}
                      disabled={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? false : true}
                      onChange={(e) => this.onChangeField(e.target.value, 'position')}
                      onBlur={(e) => onUpdateField(e.target.value, 'position')}
                    />: <div style={{ width: '100px' }}>{item.position ? item.position : '--'}</div>}
                  </div>
                  :
                  editableRow == false ? <div style={{width:'200px', wordBreak : 'break-word'}}>{item.requirement_remarks ? item.requirement_remarks : "--"}</div> :
                  <div style={{ borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? '1px solid #717171' : 'none',width: '150px'}}>
                    {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?
                    <textarea
                      style={{resize:'inherit', minHeight: '60px'}}
                      rows="4"
                      id={"position_" + index}
                      value={item.position}
                      disabled={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? false : true}
                      onChange={(e) => this.onChangeField(e.target.value, 'position')}
                      onBlur={(e) => onUpdateField(e.target.value, 'position')}
                      onKeyUp={this.textAreaAdjust}
                      onFocus={this.textAreaAdjust}
                    />: <div style={{width:'100px'}}> {item.position ? item.position : '--' }</div>}
                </div>
                }
              </td>
            </Fragment> :null
          }
          <td style={{padding: '0 10px'}}>
            <table >
              <tbody>
                <tr className="no-border" style={{background:'none'}}>
                  <td style={{wordBreak: 'break-all'}}>
                    { editableRow && type == false && editablePermission ?
                    <div style={{ borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editablePermission ? '1px solid #717171' : 'none',width: '200px'}}>
                      {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editablePermission ?
                      <textarea
                        style={{resize:'inherit', height:'43px'}}
                        rows="4"
                        value={item.requirement_remarks}
                        disabled={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? false : true}
                        onChange={(e) => this.onChangeField(e.target.value, 'requirement_remarks')}
                        onBlur={(e) => onUpdateField(e.target.value, 'requirement_remarks')}
                      />: <div style={{ width: '200px' , wordBreak: 'break-word'}}>{item.requirement_remarks && item.requirement_remarks.length > 50 ? item.requirement_remarks.substring(0,47)+'...' : item.requirement_remarks ? item.requirement_remarks : '--'}</div>}
                  </div>
                  :
                  editableRow == false ? <div style={{width:'200px', wordBreak : 'break-word'}}>{item.requirement_remarks ? item.requirement_remarks : "--"}</div> :
                  <div style={{ borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editablePermission ? '1px solid #717171' : 'none',width: '200px'}}>
                    {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editablePermission ?
                    <textarea
                      style={{resize:'inherit', minHeight: '60px'}}
                      rows="4"
                      id={"requirement_remarks_" + index}
                      value={item.requirement_remarks}
                      disabled={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? false : true}
                      onChange={(e) => this.onChangeField(e.target.value, 'requirement_remarks')}
                      onBlur={(e) => onUpdateField(e.target.value, 'requirement_remarks')}
                      onKeyUp={this.textAreaAdjust}
                      onFocus={this.textAreaAdjust}
                    />: <div style={{width:'200px'}}> {item.requirement_remarks ? item.requirement_remarks : '--' }</div>}
                </div> }
                  </td>
                  <td style={{wordBreak: 'break-all',borderRight:'none'}}>
                    { editableRow && type == false && editablePermission ?
                    <div style={{ borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editablePermission ? '1px solid #717171' : 'none',width: '200px'}}>
                      {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editablePermission ?
                      <textarea
                        style={{resize:'inherit', height:'43px'}}
                        rows="4"
                        value={item.background_remarks}
                        disabled={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? false : true}
                        onChange={(e) => this.onChangeField(e.target.value, 'background_remarks')}
                        onBlur={(e) => onUpdateField(e.target.value, 'background_remarks')}
                      />: <div style={{ width: '200px', wordBreak: 'break-word' }}>{item.background_remarks && item.background_remarks.length > 50 ? item.background_remarks.substring(0,47)+'...' : item.background_remarks ? item.background_remarks : '--'}</div>}
                    </div> :
                    editableRow == false ? <div style={{width:'200px', wordBreak : 'break-word'}}>{item.background_remarks ? item.background_remarks : "--"}</div> :
                    <div style={{ borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editablePermission ? '1px solid #717171' : 'none',width: '200px'}}>
                      {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editablePermission ?
                      <textarea
                        style={{resize:'inherit', minHeight: '60px'}}
                        rows="4"
                        id={"background_remarks_" + index}
                        value={item.background_remarks}
                        disabled={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? false : true}
                        onChange={(e) => this.onChangeField(e.target.value, 'background_remarks')}
                        onBlur={(e) => onUpdateField(e.target.value, 'background_remarks')}
                        onFocus={this.textAreaAdjust}
                        onKeyUp={this.textAreaAdjust}
                      />: <div style={{ width: '200px', wordBreak: 'break-word' }}>{item.background_remarks ? item.background_remarks : '--'}</div>}
                    </div> }
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
          <td>
          <table>
            <tbody>
              <tr className="no-border" style={{background:'none'}}>
                <td style={{wordBreak: 'break-all'}}>
                  {editableRow && type == false && editablePermission ?
                    <div style={{ borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editable == 0 ? '1px solid #717171' : 'none', width: '200px'}}>
                    {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editable == 0 ?
                    <textarea
                      style={{resize:'inherit', height:'43px'}}
                      rows="4"
                      value={item.acumen_comments}
                      disabled={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editable == 0 ? false : true}
                      onChange={(e) => this.onChangeField(e.target.value, 'acumen_comments')}
                      onBlur={(e) => onUpdateField(e.target.value, 'acumen_comments')}
                    />: <div style={{width:'200px', wordBreak: 'break-word'}}>{item.acumen_comments && item.acumen_comments.length > 50 ? item.acumen_comments.substring(0,47)+'...' : item.acumen_comments ? item.acumen_comments : "--"}</div>}
                  </div>:
                  editableRow == false ? <div style={{width:'200px', wordBreak : 'break-word'}}>{item.acumen_comments ? item.acumen_comments : "--"}</div> :
                  <div style={{ borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editable == 0 ? '1px solid #717171' : 'none', width: '200px'}}>
                  {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editable == 0 ?
                  <textarea
                    style={{resize:'inherit', minHeight: '60px'}}
                    rows="4"
                    id={"acumen_comments_" + index}
                    value={item.acumen_comments}
                    disabled={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editable == 0 ? false : true}
                    onChange={(e) => this.onChangeField(e.target.value, 'acumen_comments')}
                    onBlur={(e) => onUpdateField(e.target.value, 'acumen_comments')}
                    onKeyUp={this.textAreaAdjust}
                    onFocus={this.textAreaAdjust}
                  />: <div style={{width:'200px', wordBreak: 'break-word'}}>{ item.acumen_comments ? item.acumen_comments : "--"}</div>}
                </div>}
                </td>
                <td style={{wordBreak: 'break-all'}}>
                  { editableRow && type == false && editablePermission ?
                    <div style={{ borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editable == 1 ? '1px solid #717171' : 'none', width: '200px'}}>
                    {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editable == 1 ?
                    <textarea
                      style={{resize:'inherit', height:'43px'}}
                      rows="4"
                      value={item.operator_comments}
                      disabled={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editable == 1 ? false : true}
                      onChange={(e) => this.onChangeField(e.target.value, 'operator_comments')}
                      onBlur={(e) => onUpdateField(e.target.value, 'operator_comments')}
                    /> : <div style={{width:'200px', wordBreak: 'break-word'}}>{item.operator_comments && item.operator_comments.length > 50 ? item.operator_comments.substring(0,47)+'...' : item.operator_comments ? item.operator_comments : "--"}</div>}
                  </div> :
                  editableRow == false ? <div style={{width:'200px', wordBreak : 'break-word'}}>{item.operator_comments && item.operator_comments.length > 50 ? item.operator_comments.substring(0,47)+'...' : item.operator_comments ? item.operator_comments : "--"}</div> :
                  <div style={{ borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editable == 1 ? '1px solid #717171' : 'none', width: '200px'}}>
                  {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editable == 1 ?
                  <textarea
                    style={{resize:'inherit', minHeight: '60px'}}
                    rows="4"
                    id={"operator_comments_" + index}
                    value={item.operator_comments}
                    disabled={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editable == 1 ? false : true}
                    onChange={(e) => this.onChangeField(e.target.value, 'operator_comments')}
                    onBlur={(e) => onUpdateField(e.target.value, 'operator_comments')}
                    onKeyUp={this.textAreaAdjust}
                    onFocus={this.textAreaAdjust}
                  /> : <div style={{width:'200px', wordBreak: 'break-word'}}>{item.operator_comments ? item.operator_comments : "--"}</div>}
                </div>}
                </td>
                <td style={{borderRight:'none', wordBreak: 'break-all'}}>
                  {editableRow && type == false && editablePermission ?
                  <div style={{ borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editable == 2 ? '1px solid #717171' : 'none', width: '200px'}}>
                    {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editable == 2 ?
                    <textarea
                      style={{resize:'inherit', height:'43px'}}
                      rows="4"
                      value={item.operator_2_comments}
                      disabled={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editable == 2 ? false : true}
                      onChange={(e) => this.onChangeField(e.target.value, 'operator_2_comments')}
                      onBlur={(e) => onUpdateField(e.target.value, 'operator_2_comments')}
                    />: <div style={{width:'200px', wordBreak: 'break-word'}}>{item.operator_2_comments && item.operator_2_comments.length > 50 ? item.operator_2_comments.substring(0,47)+'...' : item.operator_2_comments ? item.operator_2_comments :  "--"}</div>}
                </div>:
                editableRow == false ? <div style={{width:'200px', wordBreak : 'break-word'}}>{item.operator_2_comments ? item.operator_2_comments : "--"}</div> :
                  <div style={{ borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editable == 2 ? '1px solid #717171' : 'none', width: '200px'}}>
                    {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editable == 2 ?
                    <textarea
                      style={{resize:'inherit', minHeight: '60px'}}
                      rows="4"
                      id={"operator_2_comments_" + index}
                      value={item.operator_2_comments}
                      disabled={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && !['completed','approved'].includes(formInfo.current_form.status.label.toLowerCase()) && editable == 2 ? false : true}
                      onChange={(e) => this.onChangeField(e.target.value, 'operator_2_comments')}
                      onBlur={(e) => onUpdateField(e.target.value, 'operator_2_comments')}
                      onKeyUp={this.textAreaAdjust}
                      onFocus={this.textAreaAdjust}
                    />: <div style={{width:'200px', wordBreak: 'break-word'}}>{item.operator_2_comments ? item.operator_2_comments :  "--"}</div>}
                  </div>}
                </td>
              </tr>
            </tbody>
          </table>
          </td>
          <td>{ editableRow ?
            <div className="outside-table" style={{ borderBottom: formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? '1px solid #717171' : 'none', width:'200px' }}>
              {formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ?
              <Select
                isClearable={false}
                name="form-field-name"
                disabled={formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save && operatorFlag && !['completed', 'approved'].includes(formInfo.current_form.status.label.toLowerCase()) ? false : true}
                value={item.assignee === null ? 0 : item.assignee && typeof item.assignee !== 'object' ? item.assignee : item.assignee && item.assignee.id ? item.assignee.id : 0}
                options={item.assignee && item.assignee.id && options.filter(opt => opt.value === item.assignee.id || opt.value == item.assignee).length ? options : [...options, {value: item.assignee && item.assignee.id ? item.assignee.id : item.assignee, label: item.assignee && item.assignee.name ? item.assignee.name : ''}]}
                onChange={(assignee) => {onUpdateField(assignee.value, 'assignee'); this.onChangeField(assignee.value, 'assignee')}}
                /> : <div style={{ width: '100px' }}>{item.assignee ? item.assignee && item.assignee.name : '--'}</div>}
            </div>
            : <div style={{ width: '100px' }}>{item.assignee && item.assignee.name ? item.assignee.name : 'None'}</div>
            }
          </td>
        </tr>
        <Modal isOpen={modal} className="files-list">
        <ModalHeader toggle={() =>  this.setState({modal:false})} >File(s) for item {item.files ? item.files.length : 0} <a style={{fontSize:'12px', color:'rgb(47, 140, 255)'}} onClick={() => this.downloadAllFilesApi(this.props, item.id)} ><br/>Download All <img src={imgStoragePath+'downloadresources.png'} style={{width:'14px',marginLeft:'5px', verticalAlign:'baseline'}}/></a></ModalHeader>
          <ModalBody>
            <ul class="lint-inline">
              {item.files ? item.files.map(file =>
                <li class="list-inline-item" style={{borderBottom: '1px solid rgb(243, 243, 243)', marginBottom: '10px', paddingBottom: '5px', width: '100%'}}>
                  <span style={{width:'90%', display:'inline-block',wordBreak: 'break-all'}}>{file.name}</span>
                  <a style={{right:'35px',position: 'absolute',cursor: 'pointer'}} href={file.file} download>
                    <img src={imgStoragePath+'downloadresources.png'}  style={{width: '14px'}} />
                  </a>
                  {
                    editablePermission ?
                      <img src={imgStoragePath+'delete_red.png'} onClick={() => this.setState({deleteFileModal:true, deleteFileId:file.id}) }  style={{width: '14px', marginTop: '5px', float: 'right', cursor: 'pointer'}} />
                    :null
                  }
                  <DeleteModal isOpen={deleteFileModal} toggle={() => this.setState({deleteFileModal: false})} title="Are you sure to delete ?" className="modal-tform-delete-pictures modal-dialog-centered">
                     <ul className="list-inline">
                       <li className="list-inline-item">
                         <button type="button" className="btn btn-danger" onClick={() => this.deleteFilefn(item.id, file.id, sectionId, item.files.length)} >Yes</button>
                       </li>
                       <li className="list-inline-item">
                         <button type="button" className="btn btn-primary" onClick={() => this.setState({deleteFileModal: false})}>No</button>
                       </li>
                     </ul>
                   </DeleteModal>
                </li>
              ): null}
            </ul>
          </ModalBody>
        </Modal>
        { pageLoader ? <ListLoader />:null}
      </Fragment>
    )
  }
}
export default withRouter(FinalSummeriesListV1)

// https://private-anon-4e50b7aab9-chmmv2.apiary-mock.com/
import { browserHistory } from 'react-router';
import { trackLogOut } from './mixpanel'
import axios from 'axios';
import { toastFlashMessage, getLocalStorageInfo, getGlobalCookie, setGlobalCookie, eraseGlobalCookie } from './index';
import { unlimitedBaseUrl } from '../constants';
require('es6-promise').polyfill();
var staticAxiosInst = axios.create();
staticAxiosInst.defaults.baseURL = unlimitedBaseUrl;
staticAxiosInst.interceptors.request.use(function (config) {
  let lessorAccess = JSON.parse(getGlobalCookie('lessorAccess'));
  if(lessorAccess){
      config['headers']['Authorization'] = lessorAccess.access;
    }
    return config;
  },function (error) {
    return Promise.reject(error);
  });
staticAxiosInst.interceptors.response.use(function (response) {
    if(response.data.statusCode == 200){
      return response;
    }else if(response.data.statusCode == 1001){
      if(!browserHistory.getCurrentLocation().pathname.includes('login') && !browserHistory.getCurrentLocation().pathname.includes('signup')){
        setGlobalCookie('redirectURILease', window.location.href, 3)
      }
      trackLogOut(getLocalStorageInfo())
      localStorage.clear();
      eraseGlobalCookie('lessorAccess')
      eraseGlobalCookie('domain')
      browserHistory.push('/login');
      toastFlashMessage('Login Token Expired', 'error');
    }else if(response.data.statusCode == 1002){
      trackLogOut(getLocalStorageInfo())
      localStorage.clear();
      browserHistory.push('/login');
      eraseGlobalCookie('lessorAccess')
      eraseGlobalCookie('domain')
      toastFlashMessage('Lessor Trial Peroid has been expired', 'error');
    }
    else if(response.data.statusCode == 3002){
      browserHistory.push('/assets-listing');
      toastFlashMessage(response.data.message, 'error');
    }
    else if(response.data.statusCode == 3001){
      toastFlashMessage(response.data.message, 'error');
    }
    else if(response.request.responseType == 'arraybuffer' && response.config.url.includes('data-room-report')){
      return response
    }
    else if(response.data.statusCode != 404 && response.request.responseType == 'blob'){
      if(response.data.type != 'application/json'){
        toastFlashMessage('File has been Exported Successfully', 'success');
      }
      return response;
    }else if(response.request.responseType == 'arraybuffer'){
      if(response.status == 200 && response.headers['content-type'] != 'application/json'){
        if(response.status === 200){
          if(window.location.href.includes('asset-view')){
            toastFlashMessage('Data Transfer Key has been downloaded successfully', 'success');
          }else {
            toastFlashMessage('Data has been Downloaded Successfully', 'success');
          }
        }else{
          toastFlashMessage('Unable to complete download')
          return
        }
      }else{

      }
      return response
    }else if(response.data.statusCode == 4004){
      return response;
    }else{
      toastFlashMessage(response.data.message, 'error', 10000);
    }
    return response;
  },function (error) {
    toastFlashMessage('Unable to Process the Request due to Technical Error', 'error', 10000);
    return error;
  });

export default staticAxiosInst;

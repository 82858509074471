import React from 'react';
import { imgStoragePath } from '../../constants';

const Footer = () => {
    return(
        <div className="footer text-center  ">
            <p><a href="https://www.acumen.aero/" target="_blank"><img src={imgStoragePath+'phase-2/acumen.png'} /></a></p>
            <p className="copyrights">© 2021 Acumen Aviation Europe Limited. All rights reserved. <a href="https://www.acumen.aero/" target="_blank">www.acumen.aero</a></p>
        </div>
    )
}

export default Footer;

import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import classnames from 'classnames';
import RoleBaseUser from './RoleBaseUser';
import Permissions from './Permissions';
import {TableUIComp} from '../../../shared';
import {userHd} from '../index';

const RolesDetail = ({roleDetail, toggleModalFn, closeModal, users, permissions}) => {
  const [activeTab, setActiveTab] = useState('1');
  const [modal, setModal] = useState(false);

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  var permissionHd = []
    permissions.permission_headers.map(key => {
      permissionHd.push({label: key.charAt(0).toUpperCase() + key.substr(1).toLowerCase(), sortKey: ''})
    })
  return (
    <div>
        <Modal isOpen={roleDetail.modal} toggle={() => closeModal()}>
        <ModalHeader toggle={() => closeModal()}>Records View ( Published Files Only )</ModalHeader>
        <ModalBody>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => { toggle('1'); }}
                    >
                        USER
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => { toggle('2'); }}
                    >
                        PERMISSION
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                <Row>
                    <Col md="12">
                        <TableUIComp
                            theads={userHd}
                            content={users.map((item, index) =>
                            <RoleBaseUser
                                item={item}
                                index={index}
                            />
                        )}
                        />
                    </Col>
                </Row>
                </TabPane>
                <TabPane tabId="2">
                <Row>
                    <Col md="12">
                        <TableUIComp
                            theads={[{label: '', sortKey: ''}, ...permissionHd, {label: '', sortKey: ''}]}
                            content={Object.keys(permissions.permission).map((item, index) =>
                            <>
                            <Permissions
                              name={item.replace(/_/g," ").charAt(0).toUpperCase() + item.replace(/_/g," ").substr(1).toLowerCase()}
                              permissions={permissions.permission[item]}
                              permission_headers={permissions.permission_headers}
                            />
                        </>
                        )}
                        />
                    </Col>
                </Row>
                </TabPane>
            </TabContent>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => closeModal()}>Okay</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default RolesDetail;

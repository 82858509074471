import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import EngineField from '../containers/EngineField';
import { engineValidator } from '../../../../formValidator/validMRFinanceGenGraph';
import { imgStoragePath } from '../../../../constants';
import { getLocalStorageInfo } from '../../../../utils';
export default class EngineMRDetail extends Component{
  constructor(props) {
    super(props);
    this.state = {
      engineExtraInfo: true,
      error: {}
    }
  }
  engineInfoCardFn = () => {
    this.setState(prevState => ({
      ...prevState,
      engineExtraInfo: !prevState.engineExtraInfo
    }));
  }
  updateErrorCode = (keyParam) => {
    this.setState(prevState => ({
      ...prevState,
      error: {
        ...prevState.error,
        [keyParam]: ''
      }
    }))
  }
  updateEngineExtraInfo = (item) => {
    let reviewMRReport = Object.assign({}, this.props.engines[item]);
    const validateEngineInput = engineValidator(reviewMRReport);
    if(Object.keys(validateEngineInput).every( k => {return validateEngineInput[k] === ''} )){
      this.setState(prevState => ({
        ...prevState,
        engineExtraInfo: true,
        error: {}
      }));
      this.props.fetchWorkScope(item);
    }else{
      this.setState({
        error: validateEngineInput
      });
    }
  }
  render(){
    const { item, engines, engineType, blendedMR, enginesError } = this.props;
    const { error } = this.state;
    const prettyNumberParams = {cssClass:["pretty-number-override-css"], justification:'L', precision: 2, currency: true, commafy: true}
    return(
      <div className="engine-usage-form">
        <div className="engine-tab-block" style={ this.state.engineExtraInfo ? {boxShadow: 'none'} : {boxShadow: '0 2px 4px rgba(0,0,0,0.4)'} }>
         <h6 className="engine-extra-data" onClick={this.engineInfoCardFn}>
           Update engine usage data to get shop visit dates and more
           <img src={imgStoragePath + (this.state.engineExtraInfo ? "pencil.png": "close_card.png")} alt="icon" />
         </h6>

         <div className="" style={ this.state.engineExtraInfo ? { display: 'none'}: { display: 'block', paddingBottom: '24px', paddingTop: '10px' } }>

           <Row className="fund-block">
              <EngineField
                errorMsg={ error.fund_date ? error.fund_date:''}
                col="3"
                label="Fund Date"
                keyParam="fund_date"
                outerKey={item}
                inputType="date"
                addSymbol = {false}
                updateErrorCode={this.updateErrorCode}
               />
               <EngineField
                 errorMsg={ error.fund ? error.fund:''}
                 col="3"
                 label="PRSV Paid Status as of date"
                 keyParam="fund"
                 outerKey={item}
                 inputType="text"
                 prefix = {true}
                 addSymbol = {true}
                 updateErrorCode={this.updateErrorCode}
                 />
               <EngineField
                 errorMsg={ error.fund_llp ? error.fund_llp:''}
                 col="3"
                 label="LLP Paid Status as of date"
                 keyParam="fund_llp"
                 outerKey={item}
                 inputType="text"
                 prefix = {true}
                 addSymbol = {true}
                 updateErrorCode={this.updateErrorCode}
                 />
            </Row>
            <Row>
              <EngineField
                errorMsg={ error.tsn ? error.tsn:''}
                col="3"
                label="Time Since New (TSN)"
                keyParam="tsn"
                outerKey={item}
                inputType="text"
                addSymbol = {false}
                updateErrorCode={this.updateErrorCode}
                />
              <EngineField
                errorMsg={ error.csn ? error.csn:''}
                col="3"
                label="Cycles Since New (CSN)"
                keyParam="csn"
                outerKey={item}
                inputType="text"
                addSymbol = {false}
                updateErrorCode={this.updateErrorCode}
                />
            </Row>
            <EngineField
              col="3"
              label="Match LLPs to Engine CSN"
              keyParam="engineLLPStatus"
              outerKey={item}
              inputType="checkbox"
              updateErrorCode={this.updateErrorCode}
              />
            <Link onClick={() => this.props.customCycleRemainFn('open', engineType.id, item)} className="edit-llp">Edit LLPs</Link>
            <div className="utilisation-block">
               <Row>
                  <EngineField
                     errorMsg={ error.hour_util ? error.hour_util:''}
                     col="3"
                     label = "Assumed Monthly Utilization in Hour"
                     keyParam="hour_util"
                     outerKey={item}
                     inputType="text"
                     addSymbol = {false}
                     updateErrorCode={this.updateErrorCode}
                  />
                  <Col md="3">
                    <label className="label">Assumed Monthly Utilization in Cycle</label>
                    <span>{engines[item].cycle_util?engines[item].cycle_util:'--'}</span>
                  </Col>
                  <EngineField
                     errorMsg={ error.actual_prsv_mr ? error.actual_prsv_mr:''}
                     col="3"
                     label = "Actual PRSV MR Rate"
                     keyParam="actual_prsv_mr"
                     outerKey={item}
                     inputType="text"
                     addSymbol = {false}
                     updateErrorCode={this.updateErrorCode}
                  />
                  <EngineField
                     errorMsg={ error.actual_llp_mr ? error.actual_llp_mr:''}
                     col="3"
                     label = "Actual LLP MR Rate"
                     keyParam="actual_llp_mr"
                     outerKey={item}
                     inputType="text"
                     addSymbol = {false}
                     updateErrorCode={this.updateErrorCode}
                  />
               </Row>
               <Row style={{marginTop: '15px'}}>
                 <EngineField
                   errorMsg={ error.engine_escalation ? error.engine_escalation:''}
                   col="3"
                   label = "Escalation Rate for Engine MR"
                   keyParam="engine_escalation"
                   outerKey={item}
                   inputType="text"
                   addSymbol = {true}
                   prefix = {false}
                   updateErrorCode={this.updateErrorCode}
                   />
                 <EngineField
                   errorMsg={ error.prsv_cost_escalation ? error.prsv_cost_escalation:''}
                   col="3"
                   label = "PRSV Cost Escalation"
                   keyParam="prsv_cost_escalation"
                   outerKey={item}
                   inputType="text"
                   addSymbol = {true}
                   prefix = {false}
                   updateErrorCode={this.updateErrorCode}
                   />
                 <EngineField
                   errorMsg={ error.llp_escalation ? error.llp_escalation:''}
                   col="3"
                   label = "Escalation Rate for LLP MR"
                   keyParam="llp_escalation"
                   outerKey={item}
                   inputType="text"
                   addSymbol = {true}
                   prefix = {false}
                   updateErrorCode={this.updateErrorCode}
                   />
                 <EngineField
                   errorMsg={ error.llp_cost_escalation ? error.llp_cost_escalation:''}
                   col="3"
                   label = "LLP Cost Escalation"
                   keyParam="llp_cost_escalation"
                   outerKey={item}
                   inputType="text"
                   addSymbol = {true}
                   prefix = {false}
                   updateErrorCode={this.updateErrorCode}
                   />
               </Row>
            </div>
            <div className="add-time-and-cycle">
              <EngineField label="Add Last Shop Visit Detail" keyParam="timeAndCycleStatus" outerKey={item} inputType="checkbox" updateErrorCode={this.updateErrorCode} />
              { engines[item]['timeAndCycleStatus'] ?
                <div className="add-lsv">
                  <Row className="fund-block">
                    <EngineField
                      errorMsg={ error.lsv_date ? error.lsv_date:''}
                      col="3"
                      label="Last Shop Visit Date"
                      keyParam="lsv_date"
                      outerKey={item}
                      inputType="date"
                      addSymbol = {false}
                      minDate = {moment()}
                      updateErrorCode={this.updateErrorCode}
                      />
                    <EngineField
                      errorMsg={ error.tsn_at_lsv ? error.tsn_at_lsv:''}
                      col="3"
                      label="TSN at Last Shop Visit"
                      keyParam="tsn_at_lsv"
                      outerKey={item}
                      inputType="text"
                      addSymbol = {false}
                      updateErrorCode={this.updateErrorCode}
                      />
                    <EngineField
                      errorMsg={ error.csn_at_lsv ? error.csn_at_lsv:''}
                      col="3"
                      label="CSN at Last Shop Visit"
                      keyParam="csn_at_lsv"
                      outerKey={item}
                      inputType="text"
                      addSymbol = {false}
                      updateErrorCode={this.updateErrorCode}
                      />
                  </Row>
                </div>:null
              }
            </div>
            <div><button className="btn btn-primary update-usage-info" onClick={() => this.updateEngineExtraInfo(item)}>Update Values</button></div>
         </div>
        </div>
        { Object.keys(engines[item]['workscopes']).length ?
          <div>
            <div className="llp-block">
             <button className="loi-btn" style={{marginLeft:0}} onClick={this.props.toggleModal}>LOI MR Table</button>
              <Row>{this.props.children}</Row>
            </div>
            <div className="blended-mr-card">
            { engines[item]['actual_prsv_mr'] && engines[item]['actual_prsv_mr']!=0 || engines[item]['actual_llp_mr'] && engines[item]['actual_llp_mr']!=0 ?
            <ul className="list-inline flex-centered">
            { engines[item]['actual_prsv_mr'] && engines[item]['actual_prsv_mr']!=0 ?
            <li className="list-inline-item">
                 <span className="caption-ui ">
                 Actual PRSV MR Rate
                 </span>
                 <p  className="para">
                 { engines[item]['actual_prsv_mr'] ?
                    <NumericLabel params={prettyNumberParams}>
                      {engines[item]['actual_prsv_mr']}
                    </NumericLabel> : '--' }
                 </p>
                 </li>:null}
                 {engines[item]['actual_llp_mr'] && engines[item]['actual_llp_mr']!=0?
                 <li className="list-inline-item">
                 <span className="caption-ui ">
                 Actual LLP MR Rate
                 </span>
                 <p  className="para">
                 { engines[item]['actual_llp_mr'] ?
                    <NumericLabel params={prettyNumberParams}>
                      {engines[item]['actual_llp_mr']}
                    </NumericLabel> : '--' }
                 </p>
                 </li>:null}
             </ul>:
              <ul className="list-inline flex-centered">
                { getLocalStorageInfo().defaultLessor.id != 24 ?
                  <li className="list-inline-item">
                    <span className="caption-ui">
                      Minimum MR Rate
                    </span>
                    <p className="para">
                       { blendedMR ?
                       <NumericLabel params={prettyNumberParams}>
                         {blendedMR.min}
                       </NumericLabel> : '--' }
                    </p>
                  </li>:null
                }

                <li className="list-inline-item">
                  <span className="caption-ui hightlight-block">
                    Blended MR Rate  <span style={{fontSize: '12px', color: '#8c9ba5'}}>
                    {getLocalStorageInfo().defaultLessor.id == 24 ? '(Rates as per '+ (blendedMR.year_of_cost ? blendedMR.year_of_cost : '2018') + ')':null}</span>
                  </span>
                  <p className="para ">
                     { blendedMR ?
                     <NumericLabel params={prettyNumberParams}>
                       {blendedMR.value}
                     </NumericLabel> : '--' }
                  </p>
                </li>
                { getLocalStorageInfo().defaultLessor.id != 24 ?
                  <li className="list-inline-item">
                    <span className="caption-ui ">
                      Maximum MR Rate
                    </span>
                    <p  className="para">
                    { blendedMR ?
                       <NumericLabel params={prettyNumberParams}>
                         {blendedMR.max}
                       </NumericLabel> : '--' }
                    </p>
                    </li>:null
                }
              </ul>

            }
            </div>
          </div>
          :
          null
        }
        </div>
      )
    }
}

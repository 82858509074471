import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import NumericLabel from 'react-pretty-numbers';
import { monthsObj, utilizationFormat, imgStoragePath } from '../../../../constants';
import { getLocalStorageInfo } from '../../../../utils';
import editIcon from '../../../../shared/assets/img/edit_icon.svg';
import viewIcon from '../../../../shared/assets/img/view_icon.svg';
export default class UtilsList extends Component{
  constructor(props){
    super(props);
  }
  render(){
    const { index, utils, utilLists } = this.props;
    return(
      <div className="asset-list">
        <Row key={index} className="flex-centered cashflow-row">
          <Col className={ index != 0 ? utilLists[index].month != utilLists[index-1].month ? 'para-ui month-block ' :'border-col' : 'para-ui month-block' }  >
            {
              index != 0 ? utilLists[index].month != utilLists[index - 1].month ? monthsObj[utils.month.slice(4,6)] + ' ' + utils.month.slice(0,4):'':monthsObj[utils.month.slice(4,6)] + ' ' + utils.month.slice(0,4)
            }
          </Col>
          <Col className="cash-blk cash-info para-ui border-col">
            {utils.name}
          </Col>
          <Col className="cash-blk cash-info para-ui border-col">
            <NumericLabel params={utilizationFormat}>
              {utils.hours}
            </NumericLabel>
          </Col>
          <Col className="cash-blk cash-info para-ui border-col">
            {utils.cycles}
          </Col>
          <Col className="cash-blk cash-info para-ui border-col">
            <NumericLabel params={utilizationFormat}>
              {utils.tsn}
            </NumericLabel>
          </Col>
          <Col className="cash-blk cash-info para-ui border-col">
            {utils.csn}
            {
              getLocalStorageInfo().user.permission['contracts']['utilization'].indexOf('U') != -1 ?
              <span className="hidden-xs" style={{cursor:"pointer"}} onClick={ getLocalStorageInfo().user.user_role != 'lessee' && (getLocalStorageInfo().user.permission['contracts']['utilization'].indexOf('U') != -1) ? this.props.editUtils : null}>
                {
                  getLocalStorageInfo().user.user_role != 'lessee' ?
                  utils.editable ? <img style={{width:'22px', float: 'right'}} src={editIcon} alt="" />  :<img style={{width:'16px', float: 'right'}} src={viewIcon} alt="" />
                :null
                }
              </span>:null
            }

          </Col>
        </Row>
      </div>
    )
  }
}

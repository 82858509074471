import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonDropdown } from 'reactstrap';
import { Link, withRouter, browserHistory } from 'react-router';
import { ShortHead, ContractBarLoader } from '../../../contracts/Elements';
import { loadAssetViewAc, fetchDbStatsAc } from '../../Dashboard/actionCreators';
import ActivityComponent from '../../Dashboard/components/ActivityComponent';
import { RecordsSideBar } from '../../Elements';
import { ListLoader  } from '../../../../shared';
import { dirTreeAc , getRecordsReportsAc, downloadRecordsReportsAc, getDeletedReportsAc, getRecordsActivityReportsAc } from '../actionCreators';
import FilterSideBar from './FilterSideBar';
import { ADD_TO_EXPORT, CLEAR_EXPORT, EXPORT_ALL } from '../actions';
import { getLocalStorageInfo } from '../../../../utils';
import ReportList from '../components/ReportList';
import ReportHeader from '../components/ReportHeader';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { imgStoragePath, assetsType } from '../../../../constants';
import BinList from '../../RecycleBin/components';
import { NoRecordFound } from '../../../../shared';
import { localTimeFn } from '../../../../utils';
import Pagination from "react-js-pagination";
import Select from 'react-select';
import moment from 'moment';
import { sGlobalConstantAc } from '../../../../shared/actionCreators';

class RecordReportInfo extends Component{

  constructor(props){
    super(props)
    this.state = {
      filterBar: false,
      currentFolder:{
        name:'',
        uuid: '',
      },
      level: 0,
      folderName: '',
      reportLoader: false,
      activeTab: 'inv',
      dropdownOpen: false,
      pageNumber: 1,
      activeFilter: false
    }
  }
  componentDidMount(){
    this.props.fetchAssetInfo();
    var location = browserHistory.getCurrentLocation();
    if(location.query.report != null && location.query.report != undefined && location.query.report != 'activity'){
      this.setState({
        activeTab: 'del',
      })
      this.props.getDeletedReports(location.query.report, 1, this.props.ctrShortInfo);
    }else if(location.query.report != null && location.query.report != undefined){
      this.setState({
        activeTab: 'activity',
      })
      this.props.getRecordsActivityReports(1, false, false, this.props.ctrShortInfo);
    }
    else{
      this.props.getRecordsReports([]);
    }
    this.props.fetchTechConstant({constant_types: ['file_extension']})
  }

  handlePageChange = (pageNumber) =>{
    this.setState({
      pageNumber: pageNumber
    });
    if(this.state.activeTab == 'del'){
      this.props.getDeletedReports(this.props.ctrShortInfo.records.technical_records.uuid, pageNumber, this.props.ctrShortInfo);
    }else {
      this.props.getRecordsActivityReports(pageNumber, this.state.activeFilter, false, this.props.ctrShortInfo);
    }
  }

  updateActivityFilter = (value) =>{
    this.setState({
      activeFilter: value
    });
    this.props.getRecordsActivityReports(1, value, false, this.props.ctrShortInfo);
  }

  switchTab = (tab) =>{
    this.setState({
      activeTab: tab
    })
    var location = browserHistory.getCurrentLocation();
    if(tab == 'inv'){
      location.query = {};
      this.props.getRecordsReports([]);
    }else if(tab == 'del') {
      location.query.report = this.props.ctrShortInfo.records && this.props.ctrShortInfo.records.technical_records ? this.props.ctrShortInfo.records.technical_records.uuid : '';
      this.props.getDeletedReports(this.props.ctrShortInfo.records.technical_records.uuid, 1);
    }else if(tab == 'activity'){
      location.query.report = 'activity';
      this.props.getRecordsActivityReports(1, this.state.activeFilter, false, this.props.ctrShortInfo);
    }
    browserHistory.push(location);
  }

  downloadReport = () => {
    this.props.downloadRecordsReports(this.props.exportList, this.props.ctrShortInfo.asset_type == 2 ? this.props.ctrShortInfo.esn : this.props.ctrShortInfo.asset_type == 1 ? this.props.ctrShortInfo.msn : this.props.ctrShortInfo.serial_number, this.props.ctrShortInfo.asset_type == 2 ? 'ESN ' : this.props.ctrShortInfo.asset_type == 1 ? 'MSN ' : this.props.ctrShortInfo.asset_type == 3 ? 'APU ' : this.props.ctrShortInfo.asset_type == 6 ? this.props.ctrShortInfo.esn : this.props.ctrShortInfo.asset_type == 7 ? this.props.ctrShortInfo.esn : this.props.ctrShortInfo.asset_type == 8 ? this.props.ctrShortInfo.esn : this.props.ctrShortInfo.asset_type == 9 ? this.props.ctrShortInfo.esn :'LG', this.props.ctrShortInfo);
  }
  getFilters = () => {
    this.props.fetchDirTree({id: this.props.ctrShortInfo.records.technical_records.uuid,type: this.props.params.type, aircraft_slug: this.props.params.aircraft_slug});
    this.setState({
      filterBar: !this.state.filterBar,
      currentFolder:{
        name: 'MSN' + this.props.ctrShortInfo.msn,
        uuid: this.props.ctrShortInfo.records.technical_records.uuid,
      },
      level: 0,
      folderName: this.props.ctrShortInfo.asset_type == 1 ? 'MSN' + this.props.ctrShortInfo.msn : this.props.ctrShortInfo.asset_type == 2 ? 'ESN '+ this.props.ctrShortInfo.esn : this.props.ctrShortInfo.asset_type == 3 ? 'APU ' + this.props.ctrShortInfo.serial_number : this.props.ctrShortInfo.asset_type == 4 ? 'LG ' + this.props.ctrShortInfo.serial_number : this.props.ctrShortInfo.asset_type == 5 ? 'PROPELLER ' + this.props.ctrShortInfo.serial_number : this.props.ctrShortInfo.asset_type == 6 ? 'FAN' + this.props.ctrShortInfo.esn : this.props.ctrShortInfo.asset_type == 7 ? 'HPC' + this.props.ctrShortInfo.esn : this.props.ctrShortInfo.asset_type == 8 ? 'HPT' + this.props.ctrShortInfo.esn:'LPT' + this.props.ctrShortInfo.esn
    })
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  toggleFilterBar = () =>{
    this.setState({
      filterBar: !this.state.filterBar
    })
  }

  fetchDirTree = (item) => {
    this.setState(prevState => ({
      ...prevState,
      level: this.props.recordDirTree.navigation.length,
    }))
    this.props.clearExportList();
    this.setState(prevState => ({
      ...prevState,
      currentFolder: this.props.recordDirTree.navigation.length > 1 ? this.props.recordDirTree.navigation[this.props.recordDirTree.navigation.filter(nav => item.uuid != nav.uuid).length - 1] : this.props.recordDirTree.navigation[0],
      folderName: item.name
    }));
    this.props.fetchDirTree({id: item.uuid,type: this.props.params.type, aircraft_slug: this.props.params.aircraft_slug});
  }

  changeMsn =(evt) =>{
    let url = "";
    if(evt.records){
      url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/records/report/';
    }else{
      url = '/asset-view/'+assetsType[evt.asset_type]+'/'+evt.slug;
    }
    browserHistory.push(url);
    this.props.params.type = assetsType[evt.asset_type];
    this.props.params.aircraft_slug = evt.slug;
    this.componentDidMount();
  }
  render(){
    const { ctrShortInfo, recordDirTree, reportLoader, reportsInfo, techConstants, notificationOpen } = this.props;
    var fileIcons = {folder: imgStoragePath + 'folder_icn3.png'}
    techConstants.map(item => {
      if(item.type == "file_extension"){
        fileIcons = {...fileIcons, [item.label]: imgStoragePath + item.remarks}
      }
    })
    let tableHeight = window.innerHeight - 240 + "px";
    return(
      <div className="record-report-con">
        <RecordsSideBar />
          { Object.keys(ctrShortInfo).length ?
            <ShortHead
              headingText="Records"
              changeMsn={this.changeMsn}
              subHeaderTopMargin={notificationOpen == true ? 74 : 40}
              contrAssetBasicInfo={ctrShortInfo} />: <ContractBarLoader />
          }
        <div className="records-report-layout" style={{marginLeft: '270px', width: window.innerWidth - 300}}>
          {
            !reportLoader ?
          <ul className="list-inline tab-left">
            { [{label: 'Inventory', activeTab: 'inv'}, {label: 'Deleted Files', activeTab: 'del'}, {label: 'Activities', activeTab: 'activity'}].map((item, index) =>
              <li className={this.state.activeTab == item.activeTab ? "list-inline-item active" : 'list-inline-item'} onClick={(e) => {reportLoader ? e.preventDefault():this.switchTab(item.activeTab) }}>
                {item.label}
              </li>
            )}
            <li className="list-inline-item">
              <ul className="list-inline tab-right flex-centered download-action-dock">
                {
                  getLocalStorageInfo().user.permission['records']['report'].indexOf('EXP') != -1 && reportsInfo && (reportsInfo.length || (reportsInfo.list && reportsInfo.list.length)) ?
                  <li className="list-inline-item download-block">
                      <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                          <DropdownToggle caret size="sm">
                             <img width="12" src={imgStoragePath+"export_icon.png"}  alt="export"/>  Export List
                          </DropdownToggle>
                          <DropdownMenu>
                              {/* <DropdownItem  onClick={() => this.exportActionDock('pdf')}><img src={imgStoragePath+"pdf_icon.png"} alt="img"/>PDF</DropdownItem> */}
                              {
                                (reportsInfo && reportsInfo.list && reportsInfo.list.length) || (reportsInfo && reportsInfo.length) ?
                                this.state.activeTab == 'inv' ?
                                <DropdownItem onClick={()=> this.downloadReport()}><img src={imgStoragePath+"excel_icon.png"} alt="img"/>Excel</DropdownItem>
                                  : this.state.activeTab == 'del' ? <DropdownItem onClick={() => this.props.getDeletedReports(this.props.ctrShortInfo.records.technical_records.uuid, 1, 'xls', this.props.ctrShortInfo.asset_type == 2 ? ctrShortInfo.esn : ctrShortInfo.msn, this.props.ctrShortInfo.asset_type == 2 ? 'ESN ' : this.props.ctrShortInfo.asset_type == 6 ? this.props.ctrShortInfo.esn : this.props.ctrShortInfo.asset_type == 7 ? this.props.ctrShortInfo.esn : this.props.ctrShortInfo.asset_type == 8 ? this.props.ctrShortInfo.esn : this.props.ctrShortInfo.asset_type == 9 ? this.props.ctrShortInfo.esn : 'MSN ')}><img src={imgStoragePath+"excel_icon.png"} alt="img"/>Excel</DropdownItem>
                                :<DropdownItem onClick={()=> this.props.getRecordsActivityReports(1, this.state.activeFilter, 'xls', ctrShortInfo)}><img src={imgStoragePath+"excel_icon.png"} alt="img"/>Excel</DropdownItem>:null
                              }
                          </DropdownMenu>
                      </ButtonDropdown>
                  </li>
                  :null
                }
                <li className="list-inline-item">
                  {
                    reportsInfo && reportsInfo.length && this.state.activeTab == 'inv' ? <span onClick={() => this.getFilters()}>Filter <i className="fa fa-filter"></i></span>: null
                  }
                </li>
              </ul>
            </li>
          </ul>:null}
        {
          reportLoader ? <ListLoader/>:null
        }
          {
            reportsInfo && reportsInfo.length && this.state.activeTab == 'inv' ?
            <div id="dataReport">
              <table>
                <thead>
                  {
                    reportsInfo && reportsInfo.length ?
                    reportsInfo.map((item, index) => index == 0 ? <ReportHeader key="tableHeaders" items={Object.keys(item)}/> : null):null
                  }
                </thead>
                <tbody style={{height: tableHeight}}>
                  {
                    reportsInfo && reportsInfo.length ?
                    reportsInfo.map((item, index) => <ReportList key={index} items={Object.values(item)}/>):null
                  }
                </tbody>
              </table>
            </div>
            :null
          }
          <div className="records-list-wrap" style={{marginLeft: '0px'}}>
            { reportsInfo && reportsInfo.list && reportsInfo.list.length && this.state.activeTab == 'del' ?
              <div className="asset-list-filter-sort">
                <Row className="filter-block">
                  { [{label: 'Name', size: 6}, {label: 'Size', size: 1}, {label: 'Deleted By', size: 2}, {label: 'Deleted At', size: 2}, {label: '', size: 1}].map((item, index) =>
                    <Col md={item.size}>
                      {item.label !=  '' ? <h6>{item.label}</h6>:null}
                    </Col>
                  )}
                </Row>
              </div>
              :null
            }
            <div className="list-wrapper">
              {
                this.state.activeTab == 'del' && reportsInfo && reportsInfo.list && reportsInfo.list.length ?
                    reportsInfo.list.map((item, index) =>
                      <BinList
                        key={index}
                        recycleBin= {true}
                        actions={true}
                        dataKit={false}
                        index={index}
                        listItem={item}
                        fileIcons={fileIcons}
                      />
                    )
                : this.state.activeTab == 'del' ? !reportLoader ? <NoRecordFound/> : null : null
              }
            </div>
          </div>
          { reportsInfo && reportsInfo.list && reportsInfo.list.length && this.state.activeTab == 'del' ?
              <Pagination
               activePage={reportsInfo.pagination.current_page ? parseInt(reportsInfo.pagination.current_page):1}
               itemsCountPerPage={20}
               totalItemsCount={reportsInfo.pagination.total}
               pageRangeDisplayed={10}
               onChange={this.handlePageChange}
             />
              :null
          }
          <div className="records-list-wrap" style={{marginLeft: '0px'}}>
            { reportsInfo && reportsInfo.list && reportsInfo.list.length && this.state.activeTab == 'activity' ?
              <div className="record-activity-table list-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th>Activity Info</th>
                      <th></th>
                      <th style={{textAlign: 'right'}}>Filter By:</th>
                      <th>
                        <Select
                          name="form-field-name"
                          value={this.state.activeFilter}
                          valueKey="value"
                          labelKey="label"
                          options={[{label: 'All', value: false},{label: 'Shared', value: true}]}
                          onChange={(target) => this.updateActivityFilter(target.value)}
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.activeTab == 'activity' && reportsInfo && reportsInfo.list && reportsInfo.list.length ?
                          reportsInfo.list.map((lastActivity, index) =>
                          <tr>
                            <ActivityComponent
                              key={index}
                              name={lastActivity.modified_by.name}
                              fileName={lastActivity.new_name}
                              type={lastActivity.folder ? 'folder':'file'}
                              count={lastActivity.count}
                              id={lastActivity.activity_type.id}
                              userId={lastActivity.modified_by.id}
                              fromFolder={lastActivity.from_folder}
                              toFolder={lastActivity.to_folder}
                              oldName={lastActivity.old_name}
                            />
                          <td style={{textAlign: 'right'}}>
                              <img className="profile-pic"  src={lastActivity.modified_by.profile_pic} alt="img"/>

                            </td>
                            <td style={{textAlign:'left'}}>{lastActivity.modified_by.name}</td>
                            <td style={{textAlign: 'right'}}>{moment(localTimeFn(lastActivity.activity_time)).fromNow()}</td>
                          </tr>
                          )
                       : null
                    }
                  </tbody>
                </table>
              </div>
              :this.state.activeTab == 'activity' && !reportLoader ? <NoRecordFound/> : null
            }
            { reportsInfo && reportsInfo.list && reportsInfo.list.length && this.state.activeTab == 'activity' ?
              <Pagination
               activePage={reportsInfo.pagination.current_page ? parseInt(reportsInfo.pagination.current_page):1}
               itemsCountPerPage={20}
               totalItemsCount={reportsInfo.pagination.total}
               pageRangeDisplayed={10}
               onChange={this.handlePageChange}
             />
              :null
            }
          </div>
          </div>
        <FilterSideBar
          filterLoader={this.props.filterLoader}
          clearExportList={this.props.clearExportList}
          viewReport={this.props.getRecordsReports}
          folderName={this.state.folderName}
          level={this.state.level}
          currentFolder={this.state.currentFolder}
          selectAll={() => this.props.selectAll(this.props.recordDirTree.list, this.state.level)}
          clearExportList={() => this.props.clearExportList()}
          addToExportList={this.props.addToExportList}
          exportList={this.props.exportList}
          fetchDirTree={this.fetchDirTree}
          toggleFilterBar={this.toggleFilterBar}
          active={this.state.filterBar}
          style={ this.state.filterBar ? {width: '50%', zIndex: '1000'} : {width: '0px', zIndex : '1000'}}
          recordDirTree={recordDirTree}/>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  recordsDbStats: state.RecordDBReducer.recordsDbStats,
  recordDirTree: state.DataRoomReducer.recordDirTree,
  exportList: state.ReportsReducer.exportList,
  filterLoader: state.ReportsReducer.filterLoader,
  reportLoader: state.ReportsReducer.reportLoader,
  reportsInfo: state.ReportsReducer.reportsInfo,
  techConstants: state.sharedReducers.techConstants,
  notificationOpen:state.sharedReducers.notificationOpen,
});
const mapDispatchToProps = (dispatch,ownProps) =>{
  return{
    fetchAssetInfo: () => dispatch(loadAssetViewAc(ownProps.params, 'Reports')),
    fetchDirTree: (params) => dispatch(dirTreeAc(params)),
    getRecordsReports: (uuid) => dispatch(getRecordsReportsAc(ownProps.params, uuid)),
    downloadRecordsReports: (uuid, msn,type, ctrShortInfo) => dispatch(downloadRecordsReportsAc(ownProps.params, uuid, msn,type, ctrShortInfo)),
    addToExportList: (uuid) => dispatch({
      type: ADD_TO_EXPORT,
      payload: uuid
    }),
    clearExportList: () => dispatch({
      type: CLEAR_EXPORT,
      payload: []
    }),
    selectAll: (recordDirTree, level) => dispatch({
      type: EXPORT_ALL,
      payload: recordDirTree,
      level: level
    }),
    getDeletedReports: (uuid, pageNumber, download, msn,type) => dispatch(getDeletedReportsAc(uuid, pageNumber, download, msn,type)),
    getRecordsActivityReports: (page, filter, download, ctrShortInfo) => dispatch(getRecordsActivityReportsAc(ownProps.params, page, filter, download, ctrShortInfo)),
    fetchTechConstant : (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(RecordReportInfo,['records','report','R']))

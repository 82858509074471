import React, { Component } from 'react';
import { Link } from 'react-router';
import { Row, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';
import { FieldCol, FieldGroup, FieldLabel, FieldInput, FieldSelect, FieldTextarea, FieldDate} from '../../Elements';
import { checkString, checkValidDate, checkNumber } from '../../../../formValidator';
import { imgStoragePath } from '../../../../constants';
export default class EditLeaseType extends Component{
  constructor(props){
    super(props);
    this.state = {
      contract: {
        lease_type:'',
        contractual_start_date:null,
        contractual_end_date:null,
        lessee_id:'',
        operator:'',
        lease_term:'',
        same_operator_lessee: false
      },
      mrRateFlag:false,
      rentalRateFlag:false,
      error: {},
      saveChange:false
    }
  }
  componentDidMount(){
    this.updateLeaseState(this.props.contract)
  }
  componentWillReceiveProps(nextProps){
    this.updateLeaseState(nextProps.contract)
  }
  updateLeaseState = (contract) => {
    let data = Object.assign({}, contract);
    data = {
      ...data,
      lease_type: data.lease_type.value,
      lease_type_accounting: data.lease_type_accounting.value
    }
    this.setState({
      contract: data
    });
  }
  updateForm = (type, value) => {
    if(type == 'contractual_start_date' || type == 'contractual_end_date'){
      this.updateDate(type, value);
      if(type =="contractual_end_date" && value > this.props.contract.contractual_end_date){
        this.setState({
          mrRateFlag:true,
          rentalRateFlag:true
        })
      }
      if(type == "contractual_end_date" && value < this.props.contract.contractual_end_date){
        this.setState({
          mrRateFlag:false,
          rentalRateFlag:false
        })
      }

    }else{
      this.setState(prevState => ({
        ...prevState,
        contract: {
          ...prevState.contract,
          [type]: value
        },
        error: {
          ...prevState.error,
          [type]: ''
        }
      }));
    }
  }
  handleRadio = (checked, key) => {
      this.setState(prevState => ({
        ...prevState,
        contract:{
          ...prevState.contract,
          [key]: checked
        }
      }))
  }
  updateDate = (type, value) => {
    let leaseTerm = this.state.lease_term;
    if(type == 'contractual_start_date'){
      leaseTerm = moment(this.state.contract.contractual_end_date).diff(moment(value), 'months', true);
    }else{
      leaseTerm = moment(value).diff(moment(this.state.contract.contractual_start_date), 'months', true);
    }
    this.setState(prevState => ({
      ...prevState,
      contract:{
        ...prevState.contract,
        [type]: value,
        lease_term: Math.round(leaseTerm)
      },
      error: {
        ...prevState.error,
        [type]: '',
        lease_term: ''
      }
    }))
  }

  updateHdlineInfo =  (e) => {
    e.preventDefault();
    let data = Object.assign({}, this.state.contract);
    data = {
      ...data,
      asset_id: this.state.contract.asset.id,
      lessor_id: this.state.contract.lessor.id,
      lessee_id: this.state.contract.lessee.id,
      operator_id: this.state.contract.operator ? this.state.contract.operator.id:null,
      lessor_portfolio: data.lessor_portfolio && data.lessor_portfolio.id ? data.lessor_portfolio.id:null,
      rental_escalation_interval: data.rental_escalation_interval && data.rental_escalation_interval.value? data.rental_escalation_interval.value:null,
      rental_interval: data.rental_interval && data.rental_interval.value? data.rental_interval.value:null,
      rental_type: data.rental_type && data.rental_type.value?data.rental_type.value:null,
      lease_status: data.lease_status && data.lease_status.value?data.lease_status.value:null
    }
    delete data['operator'];
    delete data['lessor'];
    delete data['asset'];
    delete data['lessee'];
    const validateNewInput = {
      lease_type: checkString({value: data.lease_type, required: true, minLength: '', maxLength: '', message: 'Please Select Lease Type'}),
      contractual_start_date: checkValidDate({value: data.contractual_start_date, required: true, minLength: '', maxLength: '', message: 'Please Enter Start Date'}),
      contractual_end_date: checkValidDate({value: data.contractual_end_date, required: true, minLength: '', maxLength: '', message: 'Please Enter End Date'}),

    };
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
      this.props.updateHdlineInfo(data);
      setTimeout(() => {
        this.props.toggleEditSideBar()
      }, 1000)
    }else{
      this.setState({
        error: validateNewInput
      });
    }

  }
  render(){
    const { type, techConstants, hdLineLoader } = this.props;
    const { contract, error, mrRateFlag, rentalRateFlag } = this.state;
    if(Object.keys(contract).length){
      return(
        <form onSubmit={(e) => this.updateHdlineInfo(e)}>
          <div style={{ height: (window.innerHeight - 157) + 'px', overflow:'auto',marginBottom:'60px' }}>
            <Row>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Lease Type <sup className="reqStar"> * </sup></FieldLabel>
                  <FieldSelect
                    placeholder='Select Lease Type'
                    keyParam="lease_type"
                    value={ contract.lease_type ? contract.lease_type: ''}
                    type={type}
                    options={techConstants.filter(item => item.type=="lease_type")}
                    updateField={this.updateForm}
                    labelKey= 'label'
                    valueKey='value'
                  />
                <h6 className="error-msg">{error.lease_type}</h6>
                </FieldGroup>
              </FieldCol>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Lease Type (Accounting)</FieldLabel>
                  <FieldSelect
                    placeholder='Select Lease type (Accouting)'
                    keyParam="lease_type_accounting"
                    value={contract.lease_type_accounting?contract.lease_type_accounting:''}
                    type={type}
                    options={techConstants.filter(item => item.type=="lease_type_accounting")}
                    updateField={this.updateForm}
                    labelKey= 'label'
                    valueKey='value'
                  />
                </FieldGroup>
              </FieldCol>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Lease Number</FieldLabel>
                  <FieldInput
                    value={contract.contract_number ? contract.contract_number: ''}
                    type={type}
                    keyParam="contract_number"
                    updateField={this.updateForm}
                    maxLength={20}
                  />
                </FieldGroup>
              </FieldCol>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Contractual Definition of Lease Period</FieldLabel>
                  <FieldTextarea
                    value={contract.contractual_definition_of_lease_period ? contract.contractual_definition_of_lease_period:''}
                    type={type}
                    keyParam="contractual_definition_of_lease_period"
                    updateField={this.updateForm}
                  />
                </FieldGroup>
              </FieldCol>

              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Lease Start Date <sup className="reqStar"> * </sup></FieldLabel>
                  <FieldDate
                    value={contract.contractual_start_date}
                    type={type}
                    keyParam="contractual_start_date"
                    updateField={this.updateForm}
                  />
                <h6 className="error-msg">{error.contractual_start_date}</h6>
                </FieldGroup>
              </FieldCol>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Lease End Date <sup className="reqStar"> * </sup></FieldLabel>
                  <FieldDate
                    value={contract.contractual_end_date}
                    type={type}
                    keyParam="contractual_end_date"
                    updateField={this.updateForm}
                    minDate={moment(contract.contractual_start_date).add(1, 'months')}
                  />
                <h6 className="error-msg">{error.contractual_end_date}</h6>
                </FieldGroup>
                {
                   mrRateFlag && rentalRateFlag ?
                  <FieldCol md="12">
                    <FieldGroup  style={{padding:'0 15px 10px 10px'}}>
                      <input type="checkbox"   onChange={(e) => this.handleRadio(e.target.checked, 'is_extend_mr_and_rental_date')}/>
                      <FieldLabel className="label" style={{marginRight:'10px', marginLeft:'5px'}}>Apply 'Lease end date' to MR and Rental Accounts
                        <img id="mrRateToolTip" src={imgStoragePath+'phase-2/info_grey.png'} style={{ position: 'relative', width: '14px' , left:'5px'}} /></FieldLabel>
                        <UncontrolledTooltip className="text-left" placement="right" target="mrRateToolTip">
                            <div style={{ textAlign: "left" }}>
                                <p>
                                  Selecting this option will change the 'MR Rate End Date' and 'Rental End Date' for applicable MR & Rental accounts of this contract
                                </p>
                            </div>
                        </UncontrolledTooltip>
                    </FieldGroup>
                  </FieldCol> : null
                }

              </FieldCol>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Lease Term (Months)</FieldLabel>
                  <FieldInput
                    value={contract.lease_term ? contract.lease_term: ''}
                    type={type}
                    keyParam="lease_term"
                    updateField={this.updateForm}
                    disable={true}
                    style={{background: "#fff"}}
                  />
                  <h6 className="error-msg">{error.lease_term}</h6>
                </FieldGroup>
              </FieldCol>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Conditions Precedent</FieldLabel>
                  <FieldInput
                    value={contract.conditions_precedence ? contract.conditions_precedence:''}
                    type={type}
                    keyParam="conditions_precedence"
                    updateField={this.updateForm}

                  />
                  <h6 className="error-msg">{error.first_name}</h6>
                </FieldGroup>
              </FieldCol>
              <FieldCol md="12">
                <FieldGroup className="form-group">
                  <FieldLabel className="label">Alternate Dispute Redressal</FieldLabel>
                  <FieldInput
                    value={contract.alternate_dispute_redressal ? contract.alternate_dispute_redressal:''}
                    type={type}
                    keyParam="alternate_dispute_redressal"
                    updateField={this.updateForm}
                  />
                  <h6 className="error-msg">{error.first_name}</h6>
                </FieldGroup>
              </FieldCol>
            </Row>
         </div>
         <div className="submit-block">
           <input type="submit" className="primary-btn" value={hdLineLoader ? 'Processing...':"Save Changes"}/>
           <Link onClick={() => this.props.toggleEditSideBar()} >Cancel</Link>
         </div>
       </form>
      )
    }else{
      return <h6>Loading ...</h6>
    }
  }
}

import React from 'react';
import { browserHistory } from 'react-router';
import moment from 'moment';
import PopoverItem from './PopoverItem';
import PopoverLeadInfo from './PopoverLeadInfo';
import { ToolTipHover } from '../../../../shared';
import { displayDateFormatShort, imgStoragePath } from '../../../../constants';
import { getLocalStorageInfo } from '../../../../utils';
import {isMobile} from 'react-device-detect';
const List = (props) => {
  return (
    <tr style={{cursor: 'pointer'}}>

      <td onClick={() => { browserHistory.push('/technical/project/view/'+props.data.slug) }}>
        <div className="project-info">
          <h6 style={{fontSize:'9px'}}>
           #{props.data.project_number}
          </h6>
          <h4>
            {props.data.name}
          </h4>
          <p id={'project'+ props.data['id']}><span style={{width: props.data.status && props.data.status.label == "Completed" ? "100%": props.data.progress+'%'}}></span></p>
          <ToolTipHover placement="top" tagetId={ 'project'+ props.data['id']}>
          {props.data.status && props.data.status.label == "Completed" ? "100%": props.data.progress+'%'}
          </ToolTipHover>
        </div>
      </td>
      <td onClick={() => {
          if(!isMobile){
            browserHistory.push('/technical/project/view/'+props.data.slug)
          }
        }}>
      <p style={{width:'70px'}}>
        { props.data && props.data.assets && props.data.assets.filter(asset => asset.asset_type == '1').length  ?
          props.data.assets.filter(asset => asset.asset_type == '1').length +' MSN' : ''
        }
        { props.data && props.data.assets && props.data.assets.filter(asset => asset.asset_type == '1').length  ?
          <PopoverItem
            placement= 'top'
            tagetId={'Popovera-'+props.data.id}
            assets = {props.data.assets.filter(asset => asset.asset_type == '1')}
          /> : null
        }
        </p>
        <p>
        { props.data && props.data.assets && props.data.assets.filter(asset => asset.asset_type == '2').length  ?
          props.data.assets.filter(asset => asset.asset_type == '2').length +' ESN' : ''
        }
        { props.data && props.data.assets && props.data.assets.filter(asset => asset.asset_type == '2').length  ?
          <PopoverItem
            placement= 'top'
            tagetId={'Popoverb-'+props.data.id}
            assets = {props.data.assets.filter(asset => asset.asset_type == '2')}
          /> : null
        }
        </p>
        <p>
        { props.data && props.data.assets && props.data.assets.filter(asset => asset.asset_type == '3').length  ?
          props.data.assets.filter(asset => asset.asset_type == '3').length +' APU' : ''
        }
        { props.data && props.data.assets && props.data.assets.filter(asset => asset.asset_type == '3').length  ?
          <PopoverItem
            placement= 'top'
            tagetId={'Popoverc-'+props.data.id}
            assets = {props.data.assets.filter(asset => asset.asset_type == '3')}
          /> : null
        }
        </p>
        <p>
        { props.data && props.data.assets && props.data.assets.filter(asset => asset.asset_type == '4').length  ?
          props.data.assets.filter(asset => asset.asset_type == '4').length +' LG' : ''
        }
        { props.data && props.data.assets && props.data.assets.filter(asset => asset.asset_type == '4').length  ?
          <PopoverItem
            placement= 'top'
            tagetId={'Popoverd-'+props.data.id}
            assets = {props.data.assets.filter(asset => asset.asset_type == '4')}
          /> : null
        }
        </p>
        <p>
        { props.data && props.data.assets && props.data.assets.filter(asset => asset.asset_type == '5').length  ?
          props.data.assets.filter(asset => asset.asset_type == '5').length +' PROPELLER' : ''
        }
        { props.data && props.data.assets && props.data.assets.filter(asset => asset.asset_type == '5').length  ?
          <PopoverItem
            placement= 'top'
            tagetId={'Popovere-'+props.data.id}
            assets = {props.data.assets.filter(asset => asset.asset_type == '5')}
          /> : null
        }
        </p>
      </td>
      <td onClick={() => { browserHistory.push('/technical/project/view/'+props.data.slug) }}>{props.data.location ? props.data.location : '--'}</td>
      <td onClick={() => { browserHistory.push('/technical/project/view/'+props.data.slug) }}>
        <div className="operator-info">
          { props.data.lessee && props.data.lessee.logo != '' ?
            <div>
              <img src={props.data.lessee.logo} alt="" id={'lesse-'+props.data.id} />
              <ToolTipHover placement="right" tagetId={'lesse-'+props.data.id}>
                {props.data.lessee.name}
              </ToolTipHover>
            </div>
            :'--'
          }
        </div>
      </td>
      <td onClick={() => { browserHistory.push('/technical/project/view/'+props.data.slug) }}>{props.data.start_date ? moment(props.data.start_date).format(displayDateFormatShort): '--'}</td>
      <td style={{width:'132px'}} onClick={() => { browserHistory.push('/technical/project/view/'+props.data.slug) }}>{props.data.expected_end_date ? moment(props.data.expected_end_date).format(displayDateFormatShort): '--'}</td>
      <td onClick={() => { browserHistory.push('/technical/project/view/'+props.data.slug) }}>
        <div className="lead-info">
          { props.data.leads.length > 1 ?
            <ul className="list-inline multi-leads flex-centered">
              { props.data.leads.map((lead, index) =>
               index <= 1 ?
                <li key={index} className={"list-inline-item " + index}>
                  <img src={lead.profile_pic ? lead.profile_pic : imgStoragePath + 'user.png'} alt="" id={'img-'+index+'-'+props.data.id} onClick = {(e) =>  { e.stopPropagation() ; props.getUserDetailFn(lead.slug, true)}}/>
                  <ToolTipHover placement="top" tagetId={'img-'+index+'-'+props.data.id}>
                   {lead.name}
                  </ToolTipHover>
                </li> : null
              )}
              { props.data.leads.filter(leads => leads).length > 2 ?
                <li  className="list-inline-item" >
                  <PopoverLeadInfo
                    placement = 'top'
                    tagetId={'Popover-img-'+props.data.id}
                    leadInfo = {props.data.leads}
                    getUserDetailFn = {props.getUserDetailFn}
                  />
                </li>
                : null
              }
            </ul>
            : props.data.leads.length  == 1 ?
            <ul className="list-inline">
              <li className="list-inline-item">
                { props.data.leads.map((data, index) =>
                      <span key={index} className="flex-centered" onClick = {(e) =>  { e.stopPropagation() ; props.getUserDetailFn(data.slug, true)}}>
                        { data.profile_pic ?
                          <div>
                            <img src={data.profile_pic} alt="logo" id={'img-'+props.data.id} />
                         </div>:<img src={imgStoragePath + 'user.png'} alt="logo"/>
                        }
                        { data.name ?
                          <span style={{marginLeft: '10px'}}> {data.name} </span>
                          : null
                        }
                      </span>
                   )
                }
              </li>
            </ul>: null
          }
        </div>
      </td>
      <td onClick={() => { browserHistory.push('/technical/project/view/'+props.data.slug) }}>
        { props.data.status && props.data.status.label ?  props.data.status && props.data.status.label : '--'}
        {
          props.data.archive_status === 1 ?
          <span className="archived-project">Archived</span>
          :null
        }
      </td>
      <td className="crud-block">
        <ul className="list-inline table-action-col">
          <li className="list-inline-item" onClick={() => { browserHistory.push('/technical/project/view/'+props.data.slug) }}> <img src={imgStoragePath + "blue_eye_icon.png"} width="22" /></li>
          { getLocalStorageInfo().user.permission.technical && getLocalStorageInfo().user.permission.technical.projects && getLocalStorageInfo().user.permission.technical.projects.includes('U') && props.data.status.id != 0 && props.data.archive_status == 0 ?
            <li className="list-inline-item" onClick={(e) =>{e.preventDefault();props.rowActivity('edit')} }>
              <img src={imgStoragePath + "blue_pencil_icon.png"} width="20" />
            </li>:null
          }
        </ul>
      </td>
    </tr>
  )
}

export default List;

import React, { Component } from 'react';
import moment from 'moment';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import ContractHdEl from './ContractHdEl';
import { getAccessToken, getLocalStorageInfo, toastFlashMessage } from '../../../utils';
import { displayDateFormat } from  '../../../constants';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
class ContractLeaseInfo extends Component {
  constructor(props){
    super(props)
    this.state = {
      contractCopy: false,
      contractCopyConfirm: false,
      selectedAsset: {}
    }
  }

  contractCopy = () =>{
    toastFlashMessage('Lease Copied to ' + this.state.selectedAsset.serial_number, 'success')
    this.setState({
      contractCopy: false,
      contractCopyConfirm: false,
      selectedAsset: {}
    })
  }

  render(){
    return(
      <ul className="list-inline generate-select-boxes flex-centered">
        {
          !window.location.href.includes('contracts') && !window.location.href.includes('dashboard') && !window.location.href.includes('mr-accrual') && !window.location.href.includes('cash-flow') && !window.location.href.includes('invoice') && !window.location.href.includes('utilization') ?
            <a className="btn btn-primary add-new" style={{marginRight: '10px', fontSize: '12px', color: '#3f51b5'}} onClick={() => this.setState({contractCopy: true})}>Copy Lease</a>
          :null
        }
        {!this.props.leaseInfo.same_operator_lessee ?
          <ContractHdEl title="Operator">
            {this.props.leaseInfo.operator.logo ?
              <img style={{ height: '28px',maxWidth:'102px' }} src={this.props.leaseInfo.operator.logo} alt={this.props.leaseInfo.operator.name} />
              : this.props.leaseInfo.operator.name
            }
          </ContractHdEl>:null
        }
        <ContractHdEl title="Lessor">
          <span>
          {this.props.leaseInfo.lessee.logo ?
            <img style={{ height: '28px',maxWidth:'102px' }} src={this.props.leaseInfo.lessee.logo} alt={this.props.leaseInfo.lessee.name} />
            : this.props.leaseInfo.lessee.name
          }
        </span>
        </ContractHdEl>
        <ContractHdEl title="Lease Start Date">
          <span>
            {this.props.leaseInfo.contractual_start_date ?
              moment(this.props.leaseInfo.contractual_start_date).format(displayDateFormat)
              :'--'
            }
          </span>
        </ContractHdEl>
        <ContractHdEl title="Lease End Date">
          <span>
            {this.props.leaseInfo.contractual_end_date ?
              moment(this.props.leaseInfo.contractual_end_date).format(displayDateFormat)
              :'--'
            }
          </span>
        </ContractHdEl>
        <ContractHdEl title="Lease Term">
          <span>{ this.props.leaseInfo.lease_term ? this.props.leaseInfo.lease_term + ' Months':'--' }</span>
        </ContractHdEl>
        <Modal isOpen={this.state.contractCopy} size="lg" toggle={() => this.setState({contractCopy: false})} className="share-record-modal">
          <ModalHeader toggle={() => this.setState({contractCopy: false})}>
            Copy Lease
          </ModalHeader>
          <ModalBody>
            <Select
              name="form-field-name"
              value={this.state.selectedAsset}
              onChange={(option) => this.setState({selectedAsset: option})}
              options={getLocalStorageInfo().defaultLessor.assets.map(asset => {
                let newAsset = asset
                if(asset.msn){
                  newAsset = {...newAsset, serial_number: "MSN " + asset.msn}
                }else if(asset.asset_type === 2){
                  newAsset = {...newAsset, serial_number: "ESN " + asset.esn}
                }else if (asset.asset_type === 3) {
                  newAsset = {...newAsset, serial_number: "APU " + asset.serial_number}
                }else if (asset.asset_type === 4) {
                  newAsset = {...newAsset, serial_number: "LG " + asset.serial_number}
                }else if (asset.asset_type === 5) {
                  newAsset = {...newAsset, serial_number: "PROPELLER " + asset.serial_number}
                }else{
                  newAsset = {...newAsset, serial_number: asset.esn}
                }
                return newAsset
              })}
              placeholder = 'Select Lessor'
              className="custom-select-block"
              labelKey = "serial_number"
              valueKey = "id"
            />
          </ModalBody>
          <ModalFooter>
            <div className="delete-logs">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button className="btn btn-danger"  onClick = {(e) => this.setState({contractCopyConfirm: true})}>Copy</button>
                </li>
                <li className="list-inline-item">
                  <button className="btn btn-secondary" onClick={() => this.setState({contractCopy: false, selectedAsset: {}, contractCopyConfirm: false})}>Cancel</button>
                </li>
              </ul>
            </div>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.contractCopyConfirm} size="lg" toggle={() => this.setState({contractCopyConfirm: false})} className="share-record-modal">
          <ModalHeader toggle={() => this.setState({contractCopyConfirm: false})}>
            Copy Lease to <stron>"{this.state.selectedAsset.serial_number}"</stron>
          </ModalHeader>
          <ModalBody>
            <p>Are you sure you want to copy this lease?</p>
          </ModalBody>
          <ModalFooter>
            <div className="delete-logs">
              <ul className="list-inline">
                <li className="list-inline-item">
                  <button className="btn btn-danger"  onClick = {(e) => this.contractCopy()}>Yes</button>
                </li>
                <li className="list-inline-item">
                  <button className="btn btn-secondary" onClick={() => this.setState({contractCopyConfirm: false})}>Cancel</button>
                </li>
              </ul>
            </div>
          </ModalFooter>
        </Modal>
      </ul>
    )
  }
}
export default ContractLeaseInfo

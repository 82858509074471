import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink, Modal, ModalHeader, ModalBody } from 'reactstrap';
import classnames from 'classnames';
import { mediaBaseUrl } from '../../../../constants';
import { getLocalStorageInfo, getAccessToken } from '../../../../utils';

import {
  MRFinanaceGraphWrapper,
  MRFundProjection,
  SummeryGraphCard,
  EngineSummeryCard,
  GraphCard,
  EngineHeader,
  EngineGraphTable,
  GrpALGAPUWrapper,
  EngineGenInfo,
  GrpEngineRate,
  GrpEventTopHd,
  LifeOnWings,
  DeRatePopUp,
  CashFlowModal
 } from '../components';
import { SidebarMntcCalc, ListLoader } from '../../../../shared';
import { loadCashFlowAssetViewAc } from '../../../contracts/CashFlows/actionCreators'
import { mrFinanceGraphAc, mrFinanceLifeOfWingsAc, getSimulateResultAc, deRateVariableAc,calculateDeRateAc, exportDeRateAc,cashFlowListPostAc,monthlyBreakDownExportPostAc } from '../actionCreators';
import { START_GRAPH_SIMULATION, UPDATE_AIRFRAME_PROGRAM, UPDATE_ENGINE_NUMBER, GRAPH_WIDGET_SUM, RESET_SIMULATION, DE_RATE_VAR, UPDATE_DE_RATE_OP, CLEAR_SIMULATION_DATA,MONTHLY_TOGGLE_DATA } from '../actions';
import '../assets/styles/mrGraph.scss';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
class MRFinanceGraph extends Component{
  constructor(props){
    super(props);
    this.state={
      tabIndex: '1',
      activeTab: '1',
      modal: false,
    };
  }
  componentDidMount(){
    this.props.fetchMrFinanceGraph(this.props.params.id);
    this.props.fetchMrFinanceLOW(this.props.params.id);
    this.props.fetchDeRate();
    // this.props.fetchAssetInfo();
  }
  componentWillUnmount(){
    this.props.clearGrpSimulation();
  }

  componentWillMount(){
    window.location.assign(`https://mr-calculator.sparta.aero/mr-calculator/financial-plots/${this.props.params.id}`)
  }

  monthlytoggleModal=(flag)=>{
    if(flag){
      this.props.fetchCashFlow(this.props.params.id,this.props.simulatedData);
    }else{
      this.props.cashFlowToggle();
    }
  }

  downloadGraphPostMonthly=(filter)=> {
    this.props.downloadGraphPostMonthly(this.props.params.id,this.props.simulatedData, filter);
  }



  toggleModal = (engineNo) => {
    if(!this.state.modal){
      this.props.updateDeRateOp({
        engineNo: engineNo,
        flag: false,
        data: []
      })
    }else{
      this.props.updateDeRateOp({
        engineNo: '',
        flag: false,
        data: []
      })
    }
    this.setState({
      modal: !this.state.modal
    });
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  tabIndex = (tabIndex) => {
    this.setState({
      tabIndex: tabIndex
    })
  }
  calculateDeRate = (data, type) => {
    let deRateObj = Object.assign({}, data);
    deRateObj = {
      ...deRateObj,
      engine_no: parseInt(this.props.deRateMatrix.engineNo)
    }
    delete deRateObj['incRatio'];
    if(type == 'table'){
      this.props.calculateDeRate(deRateObj, this.props.params.id);
    }else{
      this.props.exportDeRate(deRateObj, this.props.params.id);
    }
  }
  getSimulateResult = () => {
    this.props.getSimulateResult(this.props.params.id, this.props.mrFinanceGraph.data);
  }
  render(){
    const { mrFinanceGraph, regionsList, simulationType, simulatedData, lifeOnWings, simulateLoader, regionList, regionFlag, deRateList, deRateMatrix, ctrShortInfo } = this.props;
    const { airframe, engines, apu, landing_gear } = this.props.mrFinanceGraph.data;
    const { selectedAirframeEvent, selectedEngineNo } = this.props.mrFinanceGraph;
    return(
      <div className="contract-container" style={{marginTop: '0', position: 'relative', left: '-15px', marginRight: '-15px'}}>
        {
          regionFlag ? <div className="overlays" style={{ position: 'fixed', top: '0', left: '0', right: '0', bottom: '0', zIndex: '1000', background: 'rgba(0,0,0,0.4)' }}></div>:null
        }
        <SidebarMntcCalc path={this.props.route.path} />
          {simulateLoader ? <ListLoader />:null}
          {
            Object.keys(mrFinanceGraph.data).length ?
            <MRFinanaceGraphWrapper downloadGraphMonthly={this.props.downloadGraphMonthly}  checkFlag={this.props.checkFlag} monthlytoggleModal={this.monthlytoggleModal} downloadGraphSummery={this.props.downloadGraphSummery} updateGraphSum={this.props.updateGraphSum} simulationType={simulationType} mrFinanceGraph={mrFinanceGraph} id={this.props.params.id}>
              <MRFundProjection mrFinanceGraph={mrFinanceGraph}
               tabIndex={this.tabIndex}>
                <TabPane tabId="1">
                  <SummeryGraphCard
                    title="Summery"
                    color={'rgba(241,202,249,1)'}
                    newcolor="#BAB9D3"
                    lineColor="#BD10E0"
                    newlineColor="#150F78"
                    ctrShortInfo={ctrShortInfo}
                    simulationType={simulationType}
                    summeryCardData={mrFinanceGraph.data}
                    newsummeryCardData={ simulationType == 2 ? simulatedData : {} }
                  />
                  <GrpEventTopHd />
                  { airframe ? Object.keys(airframe).map((label,index) => <GrpALGAPUWrapper simulationType={simulationType} newprogramInfo={simulationType == 2 ? simulatedData.airframe[label] :{}} metrixType="airframe" programType={label} key={index} programInfo={airframe[label]} />):null }
                  { landing_gear ? <GrpALGAPUWrapper simulationType={simulationType} newprogramInfo={simulationType == 2 ? simulatedData.landing_gear :{}} metrixType="landing_gear" programType="" programInfo={landing_gear} /> : null }
                  { apu ? <GrpALGAPUWrapper simulationType={simulationType} newprogramInfo={simulationType == 2 ? simulatedData.apu :{}} metrixType="apu" programType="" programInfo={apu} /> :null }
                  { engines ? <EngineSummeryCard toggleModal={this.toggleModal} simulationType={simulationType} newenginesInfo={simulationType == 2 ? simulatedData.engines.general_info :{}} enginesInfo={engines.general_info} newengines={simulationType == 2 ? simulatedData.engines.engine_data :{}} engines={engines.engine_data} />:null }
                </TabPane>
                { airframe ?
                  <TabPane tabId="2">
                    <ul className="list-inline flex-centered other-options">
                      { Object.keys(airframe).map((label, index) => {
                        return(<li style={{marginBottom: '15px'}} key={index} className="list-inline-item"><label><input onChange={() => this.props.updateAirframeProgram(label) } checked={ label == selectedAirframeEvent ? true:false} type="radio" name="airframe" />{airframe[label].name}</label></li>)
                      } )}
                    </ul>
                    <GraphCard
                      simulationType={simulationType}
                      type="airframe"
                      color={'rgba(255,218,218,1)'}
                      lineColor="#FF5E5E"
                      events={airframe[selectedAirframeEvent].events}
                      plots={airframe[selectedAirframeEvent].plots}
                      newevents={simulationType == 2 ? simulatedData.airframe[selectedAirframeEvent].events :{}}
                      newplots={simulationType == 2 ? simulatedData.airframe[selectedAirframeEvent].plots :{}}
                    />
                    <GrpEventTopHd />
                    <GrpALGAPUWrapper simulationType={simulationType} newprogramInfo={simulationType == 2 ? simulatedData.airframe[selectedAirframeEvent] :{}} metrixType="airframe" programType={selectedAirframeEvent} programInfo={airframe[selectedAirframeEvent]} />
                  </TabPane>:null
                }
                { engines ?
                  <TabPane tabId="3">
                    <Row>
                      <Col sm="12">
                        <ul className="list-inline flex-centered other-options">
                          { Object.keys(engines.engine_data).map((label, index) => {
                            return(
                              <li key={index} className="list-inline-item">
                                <label>
                                  <input onChange={() => this.props.updateEngineNo(label)} checked={label == selectedEngineNo ? true:false } type="radio" name="engine" />
                                  {engines.engine_data[label].name ? engines.engine_data[label].name: 'Engine ' + (index+1)}
                                </label>
                              </li>)
                          } )}
                        </ul>
                        <Nav tabs>
                          <NavItem>
                            <NavLink
                              className={classnames({ active: this.state.activeTab === '1' })}
                              onClick={() => { this.toggle('1'); }}
                            >
                              PRSV
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              className={classnames({ active: this.state.activeTab === '2' })}
                              onClick={() => { this.toggle('2'); }}
                            >
                              LLP
                            </NavLink>
                          </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                          <TabPane tabId="1">
                            <GraphCard
                              simulationType={simulationType}
                              type="engines"
                              color='#F9CAE3'
                              lineColor="#E010A4"
                              events={engines.engine_data[selectedEngineNo].shop_visits}
                              plots={engines.engine_data[selectedEngineNo].prsv_plots}
                              newplots={simulationType == 2 ? simulatedData.engines.engine_data[selectedEngineNo].prsv_plots :{}}
                              newevents={simulationType == 2 ? simulatedData.engines.engine_data[selectedEngineNo].shop_visits :{}}
                            />
                          </TabPane>
                          <TabPane tabId="2">
                            <GraphCard
                              simulationType={simulationType}
                              type="engines"
                              color='#F9CAE3'
                              lineColor="#E010A4"
                              events={engines.engine_data[selectedEngineNo].shop_visits}
                              plots={engines.engine_data[selectedEngineNo].llp_plots}
                              newplots={simulationType == 2 ? simulatedData.engines.engine_data[selectedEngineNo].llp_plots :{}}
                              newevents={simulationType == 2 ? simulatedData.engines.engine_data[selectedEngineNo].shop_visits :{}}
                            />
                          </TabPane>
                        </TabContent>

                        <div className="shop-visit-block">
                          <EngineGenInfo simulationType={simulationType} metrixType="engines" programType="general_info" newenginesInfo={simulationType == 2 ? simulatedData.engines.general_info:{}} enginesInfo={engines.general_info} />
                          <button className="loi-btn" onClick={() => this.toggleModal(selectedEngineNo)}>LOI MR Table</button>
                          <GrpEngineRate simulationType={simulationType} metrixType="engines" programType={selectedEngineNo} newengine={simulationType == 2 ? simulatedData.engines.engine_data[selectedEngineNo]:{}} engine={engines.engine_data[selectedEngineNo]} />
                            <table>
                              <EngineHeader />
                              <tbody>
                                { Object.keys(engines.engine_data[selectedEngineNo]['shop_visits']).map((label, index) =>
                                  <EngineGraphTable
                                    metrixType="engines"
                                    programType={selectedEngineNo}
                                    simulationType={simulationType}
                                    key={index}
                                    label={label}
                                    newshopVisit={ simulationType == 2 ? simulatedData.engines.engine_data[selectedEngineNo]['shop_visits'][label]: {}}
                                    shopVisit={engines.engine_data[selectedEngineNo]['shop_visits'][label]} /> )}
                              </tbody>
                            </table>

                        </div>
                      </Col>
                    </Row>
                  </TabPane>:null
                }
                { landing_gear ?
                  <TabPane tabId="4">
                    <GraphCard
                      simulationType={simulationType}
                      type="landing_gear"
                      color='#D4F5F1'
                      lineColor="#41D6C3"
                      events={landing_gear.events}
                      plots={landing_gear.plots}
                      newplots={simulationType == 2 ? simulatedData.landing_gear.plots :{}}
                      newevents={simulationType == 2 ? simulatedData.landing_gear.events :{}}
                    />
                    <GrpEventTopHd />

                    <GrpALGAPUWrapper simulationType={simulationType} newprogramInfo={simulationType == 2 ? simulatedData.landing_gear :{}} metrixType="landing_gear" programType="" programInfo={landing_gear} />
                  </TabPane>:null
                }
                { apu ?
                  <TabPane tabId="5">
                    <GraphCard
                      simulationType={simulationType}
                      type="apu"
                      color='#D5E8FF'
                      lineColor="#2F8CFF"
                      events={apu.events}
                      plots={apu.plots}
                      newplots={simulationType == 2 ? simulatedData.apu.plots :{}}
                      newevents={simulationType == 2 ? simulatedData.apu.events :{}}
                    />
                    <GrpEventTopHd />
                    <GrpALGAPUWrapper simulationType={simulationType} newprogramInfo={simulationType == 2 ? simulatedData.apu :{}} metrixType="apu" programType="" programInfo={apu} />
                  </TabPane>:null
                }
              </MRFundProjection>
              {
                simulationType == 0 ?
                <button className="simulate-scenarios" onClick={() => this.props.startSimulation(1)}>Simulate Scenarios</button>
                :
                <div className="simulate-now">
                  <ul className="list-inline">
                    <li className="list-inline-item" onClick={() => this.props.resetSimlute('reset')}>Reset to default values</li>
                    <li className="list-inline-item">
                      {
                        simulationType == 1 ?
                        <button onClick={this.getSimulateResult} disabled={simulateLoader}>{ simulateLoader ? 'processing...':'Apply →'}</button>
                        :
                        <button onClick={() => this.props.resetSimlute('restart')}>Simulate Another Scenarios →</button>
                      }

                    </li>
                  </ul>
                </div>
              }
              { Object.keys(lifeOnWings).length && (this.state.tabIndex == '1' || this.state.tabIndex == '3') ?
                <LifeOnWings simulationType={simulationType} simulatedData={simulatedData} lifeOnWings={lifeOnWings} />:null
              }
              {
                deRateList.length ?
                <div>
                  <Modal size="lg" isOpen={this.state.modal} toggle={this.toggleModal} centered={true} className={this.props.className}>
                    <ModalBody>
                      <DeRatePopUp toggleModal={this.toggleModal} exportDeRate={this.props.exportDeRate} updateDeRateOp={this.props.updateDeRateOp} calculateDeRate={this.calculateDeRate} deRateMatrix={deRateMatrix} deRateList={deRateList} engineInfo={engines ? engines.general_info:{} }  />
                    </ModalBody>
                  </Modal>
                </div>:null
              }



            </MRFinanaceGraphWrapper>:<ListLoader />
          }
          <Modal size="lg" className="monthly-cashflow-modal" isOpen={this.props.monthlyModal} toggle={()=>this.monthlytoggleModal(false)} centered={true} style={{width:"60%"}} >
                    <ModalBody>
                    <CashFlowModal
                       cashFlow={this.props.cashflowMonthlyBreakup}
                       paddingSpace = {22}
                       monthlytoggleModal={this.monthlytoggleModal}
                       downloadGraphPostMonthly={this.downloadGraphPostMonthly}
                      />
                    </ModalBody>
            </Modal>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  mrFinanceGraph: state.MRFinanceGraphReducer.mrFinanceGraph,
  simulatedData: state.MRFinanceGraphReducer.simulatedData,
  simulationType: state.MRFinanceGraphReducer.simulationType,
  lifeOnWings: state.MRFinanceGraphReducer.lifeOnWings,
  simulateLoader: state.MRFinanceGraphReducer.simulateLoader,
  regionFlag: state.MRFinanceGraphReducer.regionFlag,
  deRateList: state.MRFinanceGraphReducer.deRateList,
  deRateMatrix: state.MRFinanceGraphReducer.deRateMatrix,
  cashflowMonthlyBreakup: state.MRFinanceGraphReducer.cashflowMonthlyBreakup,
  checkFlag: state.MRFinanceGraphReducer.checkFlag,
  monthlyModal:state.MRFinanceGraphReducer.monthlyModal,
  regionsList: state.MRFinanaceRptReducer.regionsList,
});
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchMrFinanceGraph: (id) => dispatch(mrFinanceGraphAc(id)),
    fetchMrFinanceLOW: (id) => dispatch(mrFinanceLifeOfWingsAc(id)),
    getSimulateResult: (id, data) => dispatch(getSimulateResultAc(id, data)),
    fetchDeRate: () => dispatch(deRateVariableAc()),
    downloadGraphPostMonthly: (id,data, filter) => dispatch(monthlyBreakDownExportPostAc(id, data, filter)),
    fetchCashFlow: (id,data) => dispatch(cashFlowListPostAc(id,data)),
    calculateDeRate: (data, id) => dispatch(calculateDeRateAc(data,id)),
    exportDeRate: (data, id) => dispatch(exportDeRateAc(data,id)),
    fetchAssetInfo: () => dispatch(loadCashFlowAssetViewAc(ownProps.params)),
    cashFlowToggle:() => dispatch({
      type: MONTHLY_TOGGLE_DATA,
      payload:false
    }),
    clearGrpSimulation: () => dispatch({
      type: CLEAR_SIMULATION_DATA,
      payload:''
    }),
    updateAirframeProgram: (program) => dispatch({
      type: UPDATE_AIRFRAME_PROGRAM,
      payload: {program}
    }),
    updateEngineNo: (engineNumber) => dispatch({
      type: UPDATE_ENGINE_NUMBER,
      payload: {engineNumber}
    }),
    startSimulation: (simulationType) => dispatch({
      type: START_GRAPH_SIMULATION,
      payload: simulationType
    }),
    updateGraphSum: (date) => dispatch({
      type: GRAPH_WIDGET_SUM,
      payload: date
    }),
    resetSimlute: (type) => dispatch({
      type: RESET_SIMULATION,
      payload: type
    }),
    updateDeRateOp: (data) => dispatch({
      type: UPDATE_DE_RATE_OP,
      payload: data
    })
  }
}
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(RoleBasedAccess(MRFinanceGraph, ['mr_calculator', 'analyze_mr_cash_flow','R'])))

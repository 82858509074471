import React, {Component} from 'react';
import { connect } from 'react-redux';
import { AirframeInfCard, EngineInfCard, RegionSidebar,  SelectAssemblies }  from '../components'
import { SidebarMntcCalc, LicenceModal } from '../../../../shared/'
import '../assets/styles/MRFinance.scss';
import { browserHistory } from 'react-router';
import { USE_CDB_ALGO, UPDATE_AIRFRAME_FIELD, UPDATE_FINANCE_METRIX, UPDATE_ENGINE_FIELD, UPDATE_LESSOR_ID, GEN_FINANCE_LOADER, UPDATE_VAR_CONS_IDS, CLEAR_FINANACE_DATA } from '../actions';
import { validGenFinanceRpt } from '../../../../formValidator/genFinanceRptValidator';
import { getLocalStorageInfo } from '../../../../utils'
import {
  mrCalculationAc,
  aircraftMaintenanceAc,
  modifyRegionValAc,
  aircraftTypeAc,
  engineTypeAc,
  regionOfOpAc,
  clientListAc,
  aircraftEngineTypeAc,
  regionInfoAc,
  getMRUsageAc,
} from '../actionCreators';
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { BUY_LICENCE } from '../../../../shared/actions'
class GenFinanceRpt extends Component{
    constructor(props){
      super(props);
      this.state = {
        regionOpen: false,
        errors: {},
        overlay: false
      }
    }

    componentDidMount() {
      this.props.fetchAircraftType();
      this.props.fetchEngineType();
      this.props.fetchClientList();
      if(this.props.genFinanceInfo.engine && this.props.genFinanceInfo.engine.regionOfOperation){
        this.props.fetchRegionStats(this.props.genFinanceInfo.engine.regionOfOperation, this.props.genFinanceInfo.engine.engineType);
      }else{
        this.props.fetchRegionType();
      }
      if(getLocalStorageInfo().user.permission.mr_calculator && getLocalStorageInfo().user.permission.mr_calculator.is_saas){
  			this.props.getMRUsage();
  		}
    }
    // componentWillUnmount(){
    //   this.props.clearFinanaceData();
    // }

    componentWillMount(){
      window.location.assign(`https://mr-calculator.sparta.aero/mr-calculator/initiate`)
    }

    updateErrorCode = (type) => {
      if(type == 'hourlyUtlization' || type == 'cycleUtlization'){
        this.setState(prevState => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            [type]: '',
            utlizationRatioAir:''
          }
        }));
      }else{
        this.setState(prevState => ({
          ...prevState,
          errors: {
            ...prevState.errors,
            [type]: ''
          }
        }));
      }
    }
    toggleRegion = (flag) => {
      this.setState(prevState => ({
        regionOpen: flag
      }));
    }
    updateRegionCutomValFn = () => {
      this.setState({
        regionOpen: false
      });
      this.props.updateRegionCutomValFn();
    }
    updateMntAndAirCraft = (value, type) => {
      if(type == 'aircraftType'){
        this.props.fetchMainProgram(value);
        this.props.fetchAircraftEngineType(value);
      }
      this.props.updategenFinanceInfo(value, type);
    }
    checkAirframeAndGEn = (metricsInfo) => {
      if(metricsInfo.indexOf('airframe') != -1){
        return (
          <AirframeInfCard
            airframeAndGen={this.props.genFinanceInfo.airframeAndGen}
            title="Enter Airframe Information"
            type="airframe"
            aircraftTypeList={this.props.aircraftTypeList}
            changeAircraftType = {this.updateMntAndAirCraft}
            aircraftMainPrg = {this.props.aircraftMainPrg}
            errors= {this.state.errors}
            updateErrorCode={this.updateErrorCode}
           />
        )
      }else if( (metricsInfo.indexOf('apu') != -1) || (metricsInfo.indexOf('lg') != -1) ){
        return <AirframeInfCard
         airframeAndGen={this.props.genFinanceInfo.airframeAndGen}
         title="Enter General Information"
         type=""
         aircraftTypeList={this.props.aircraftTypeList}
         changeAircraftType = {this.updateMntAndAirCraft}
         aircraftMainPrg = {[]}
         errors= {this.state.errors}
         updateErrorCode={this.updateErrorCode}
          />
      }else{
        return null;
      }
    }

    mrCalculation = () => {
      if(getLocalStorageInfo().user.permission.mr_calculator && getLocalStorageInfo().user.permission.mr_calculator.is_saas){
        if(this.props.genFinanceInfo.metricsInfo.indexOf('engine') != -1 && (this.props.genFinanceInfo.metricsInfo.indexOf('airframe') != -1 || this.props.genFinanceInfo.metricsInfo.indexOf('apu') != -1 || this.props.genFinanceInfo.metricsInfo.indexOf('lg') != -1)){
          if((this.props.mrUsage.aircraft.available == this.props.mrUsage.aircraft.used) && (this.props.mrUsage.engine.available == this.props.mrUsage.engine.used)){
            this.props.buyLicence({
              flag: true,
              action: '/licenses/mr-calculator',
              content: "License for generating logs in Engine, Airframe, APUs and LGs is expired. Please upgrade your license to generate more logs."
            })
            return;
          }else if(this.props.genFinanceInfo.metricsInfo.indexOf('airframe') != -1 || this.props.genFinanceInfo.metricsInfo.indexOf('apu') != -1 || this.props.genFinanceInfo.metricsInfo.indexOf('lg') != -1){
            if(this.props.mrUsage.aircraft.available == this.props.mrUsage.aircraft.used){
              this.props.buyLicence({
                flag: true,
                action: '/licenses/mr-calculator',
                content: "License for generating logs in Airframe, APUs and LGs is expired. Please upgrade your license to generate more logs."
              })
              return;
            }
          }else if(this.props.genFinanceInfo.metricsInfo.indexOf('engine') != -1){
            if(this.props.mrUsage.engine.available == this.props.mrUsage.engine.used){
              this.props.buyLicence({
                flag: true,
                action: '/licenses/mr-calculator',
                content: "License for generating logs in Engine is expired. Please upgrade your license to generate more logs."
              })
              return;
            }
          }
        }else if(this.props.genFinanceInfo.metricsInfo.indexOf('airframe') != -1 || this.props.genFinanceInfo.metricsInfo.indexOf('apu') != -1 || this.props.genFinanceInfo.metricsInfo.indexOf('lg') != -1){
          if(this.props.mrUsage.aircraft.available == this.props.mrUsage.aircraft.used){
            this.props.buyLicence({
              flag: true,
              action: '/licenses/mr-calculator',
              content: "License for generating logs in Airframe, APUs and LGs is expired. Please upgrade your license to generate more logs."
            })
            return;
          }
        }else if(this.props.genFinanceInfo.metricsInfo.indexOf('engine') != -1){
          if(this.props.mrUsage.engine.available == this.props.mrUsage.engine.used){
            this.props.buyLicence({
              flag: true,
              action: '/licenses/mr-calculator',
              content: "License for generating logs in Engine is expired. Please upgrade your license to generate more logs."
            })
            return;
          }
        }else {
          if(this.props.mrUsage.aircraft.available == this.props.mrUsage.aircraft.used){
            this.props.buyLicence({
              flag: true,
              action: '/licenses/mr-calculator',
              content: "License for generating logs in Airframe, APUs and LGs is expired. Please upgrade your license to generate more logs."
            })
            return;
          }
        }
      }
      const validateNewInput = validGenFinanceRpt(this.props.genFinanceInfo);
      if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
        this.props.genFinanceLoaderFn(true);
        this.props.mrCalculation(this.props.genFinanceInfo);
        this.setState({
          errors: {}
        })
      }else{
        this.setState({
          errors: validateNewInput
        });
      }
    }
    render() {
      const  { genFinanceInfo, regionsList, engineTypeList, aircraftEngineTypeList, engineUtilRatio, regionCondition, clients, customizeRegionType, genFinanceLoader } = this.props;
      return(
        <div className="genFinanceRpt-blk">
            {this.state.regionOpen ? <div style={{position:'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 999, background: 'rgba(0,0,0,0.6)'}}></div>:null}
            <SidebarMntcCalc path={this.props.route.path} />
            <SelectAssemblies mrUsage={this.props.mrUsage} errors={this.state.errors} changeLessorType={this.props.changeLessorType} genFinanceInfo={genFinanceInfo} metrixChange={this.props.updateMatrix} useCDBAlgo={this.props.useCDBAlgo} clients={clients} />
            { this.checkAirframeAndGEn(this.props.genFinanceInfo.metricsInfo) }
            { genFinanceInfo.metricsInfo.indexOf('engine') != -1 ?
              <EngineInfCard
                engineInfo={genFinanceInfo.engine}
                engineTypeList={engineTypeList}
                aircraftEngineTypeList={aircraftEngineTypeList}
                regionsList={regionsList}
                engineUtilRatio={engineUtilRatio}
                regionCondition={regionCondition}
                noOfEngineUpdate = {this.props.noOfEngineUpdate}
                toggleRegion = {this.toggleRegion}
                metricsInfo = {genFinanceInfo.metricsInfo}
                resetCustomRegion = {this.props.resetCustomRegion}
                errors= {this.state.errors}
                updateErrorCode={this.updateErrorCode}
              /> : null }
              <RegionSidebar
                toggleRegion = {this.toggleRegion}
                toggleStatus={this.state.regionOpen}
                regionsList={regionsList}
                regionCondition = {regionCondition}
                mode="edit"
                updateRegionCutomValFn = {this.updateRegionCutomValFn}
                />

            {
              (genFinanceInfo.metricsInfo.length) ?
              <div className="submit-block">
                <input className="primary-btn generate-mr-data" onClick={this.mrCalculation} disabled={genFinanceLoader}  type="submit" value={genFinanceLoader ? 'Processing...': 'Generate MR Data'}/>
              </div>:null
            }
            {
              this.props.buyLicenceModal.flag ?
              <LicenceModal
                toggleLicence={() => this.props.buyLicence({
                  flag: false,
                  action: '',
                  content: ''
                })}
                planId="4"
                licenceModal={this.props.buyLicenceModal}
                />
              :null
            }
        </div>
      );
    }
}

const mapStateToProps = state => ({
  genFinanceInfo: state.MRFinanaceRptReducer.genFinanceInfo,
  regionsList: state.MRFinanaceRptReducer.regionsList,
  aircraftTypeList: state.MRFinanaceRptReducer.aircraftTypeList,
  clients: state.MRFinanaceRptReducer.clients,
  aircraftMainPrg: state.MRFinanaceRptReducer.aircraftMainPrg,
  engineTypeList: state.MRFinanaceRptReducer.engineTypeList,
  aircraftEngineTypeList: state.MRFinanaceRptReducer.aircraftEngineTypeList,
  engineUtilRatio: state.MRFinanaceRptReducer.engineUtilRatio,
  regionCondition: state.MRFinanaceRptReducer.regionCondition,
  genFinanceLoader: state.MRFinanaceRptReducer.genFinanceLoader,
  mrUsage: state.MRFinanaceRptReducer.mrUsage,
  buyLicenceModal:state.sharedReducers.buyLicenceModal,
});

const mapDispatchToProps = dispatch => {
  return {
    fetchAircraftType: () => dispatch(aircraftTypeAc()),
    fetchEngineType: () => dispatch(engineTypeAc()),
    fetchRegionType: () => dispatch(regionOfOpAc()),
    fetchClientList: () => dispatch(clientListAc()),
    mrCalculation: (mrCalObj) => dispatch(mrCalculationAc(mrCalObj)),
    updateMaintenance: (value) => dispatch(aircraftMaintenanceAc(value)),
    fetchMainProgram: (id) => dispatch(aircraftMaintenanceAc(id)),
    fetchAircraftEngineType: (id) => dispatch(aircraftEngineTypeAc(id)),
    resetCustomRegion: (region_id, engine_type) => dispatch(regionInfoAc(region_id, engine_type)),
    updateRegionInfoFn: (value, type) => dispatch({
      type: UPDATE_ENGINE_FIELD,
      payload: {value, type},
    }),
    genFinanceLoaderFn: (flag) => dispatch({
      type: GEN_FINANCE_LOADER,
      payload: flag
    }),
    updateRegionCutomValFn: () => dispatch({
      type: UPDATE_VAR_CONS_IDS,
      payload: ''
    }),
    updateMatrix: (value) => dispatch({
      type: UPDATE_FINANCE_METRIX,
      payload: value,
    }),
    useCDBAlgo: (value) => dispatch({
      type: USE_CDB_ALGO,
      payload: value,
    }),
    changeLessorType: (lessorId) => dispatch({
      type: UPDATE_LESSOR_ID,
      payload: lessorId
    }),
    updategenFinanceInfo: (value, type) => dispatch({
      type: UPDATE_AIRFRAME_FIELD,
      payload: {value, type}
    }),
    noOfEngineUpdate: (value, type) => dispatch({
      type: UPDATE_ENGINE_FIELD,
      payload: {value, type},
    }),
    clearFinanaceData: () => dispatch({
      type: CLEAR_FINANACE_DATA,
      payload: ''
    }),
    getMRUsage: () => dispatch(getMRUsageAc()),
    buyLicence: (modalObj) => dispatch({
      type: BUY_LICENCE,
      payload: modalObj
    }),
    fetchRegionStats: (region_id, engine_type) => dispatch(regionInfoAc(region_id, engine_type))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(GenFinanceRpt,['mr_calculator', 'dashboard','R']));

import React, { Component } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import NumericLabel from 'react-pretty-numbers';
import moment from 'moment';
import { displayDateFormat, imgStoragePath } from '../../../../constants';
import { numberWithCommas, getLocalStorageInfo } from '../../../../utils';
require('highcharts/modules/exporting')(Highcharts);
export default class GraphCard extends Component {
  constructor(props){
    super(props);
  }

  componentWillReceiveProps(nextProps){
    let graphLabel = this.props.graphLabel
    if(nextProps.graphLabel != this.props.graphLabel && nextProps.airframe){
      document.querySelectorAll('.highcharts-label')[1].innerHTML = '<rect fill="none" class="highcharts-label-box" x="0.5" y="0.5" width="250" height="33" stroke="silver" stroke-width="1" rx="2" ry="2"></rect><text x="5" style="font-size:9px;" y="15"><tspan style="padding-right: 5px">' + nextProps.graphLabel + '</tspan><tspan x="5" dy="12">Lessor: ' + getLocalStorageInfo().defaultLessor.name + '</tspan></text>'
    }
  }

  render() {
    const {
      type,
      color,
      lineColor,
      plots,
      newplots,
      events,
      newevents,
      simulationType,
      graphLabel,
      ctrShortInfo
    } = this.props;
    let url = ''
    if(type == 'airframe'){
      url =  'url(' + imgStoragePath + 'airframe_marker.png' + ')';
    }else if(type == 'landing_gear'){
      url =  'url(' + imgStoragePath + 'lg_marker.png' + ')';
    }else if(type == 'apu'){
      url =  'url(' + imgStoragePath + 'apu_marker.png' + ')';
    }else if(type == 'engines'){
      url =  'url(' + imgStoragePath + 'engines_marker.png' + ')';
    }else if(type == 'low'){
      url =  'url(' + imgStoragePath +  'engines_marker.png' + ')';
    }
    let data = [], newdata = [], eventFlags = [], neweventFlags = [],newMarker=[];
    if(type == 'engines'){
      Object.keys(events).map(event => {
        let firstKey = Object.keys(events[event])[0]
        eventFlags.push(moment(events[event][firstKey].eventDate).format('YYYYMM'));
      })
    }else{
      Object.keys(events).map(event => {
        eventFlags.push(moment(events[event].eventDate).format('YYYYMM'));
      });
    }
    Object.keys(plots).map(year => {
      let obj = {};
      if(eventFlags.indexOf(year) != -1 ){
        obj = {x: new Date(year.slice(4,6) + '/01/' + year.slice(0,4)).getTime(), y: plots[year], marker: {symbol: url, width: 30, height: 30, enabled: true}};
      }else{
        obj = {x: new Date(year.slice(4,6) + '/01/' + year.slice(0,4)).getTime(), y: plots[year]};
      }
      data.push(obj);
    });
    if(simulationType == 2){
      if(type == 'engines'){
        Object.keys(newevents).map(event => {
          let firstKey = Object.keys(newevents[event])[0]
          neweventFlags.push(moment(newevents[event][firstKey].eventDate).format('YYYYMM'));
        })
      }else{
        Object.keys(newevents).map(event => {
          neweventFlags.push(moment(newevents[event].eventDate).format('YYYYMM'));
        });
      }
      Object.keys(newplots).map(year => {
        let newobj = {};
        if(neweventFlags.indexOf(year) != -1){
          newobj = {x: new Date(year.slice(4,6) + '/01/' + year.slice(0,4)).getTime(), y: newplots[year], marker: {symbol: url, width: 30, height: 30, enabled: true}};
        }else{
          newobj = {x: new Date(year.slice(4,6) + '/01/' + year.slice(0,4)).getTime(), y: newplots[year]};
        }
        newdata.push(newobj);
        /*let newd = [];
        let newobj = {};
        if(neweventFlags.indexOf(year) != -1){
          newobj = {x: new Date(year.slice(4,6) + '/01/' + year.slice(0,4)).getTime(), y: newplots[year], marker: {symbol: url, width: 30, height: 30, enabled: true}};
        }
        newMarker.push(newobj);

        newd.push(new Date(year.slice(4,6) + '/01/' + year.slice(0,4)).getTime());
        newd.push(newplots[year]);
        newdata.push(newd);*/
        //
      });
    }
    function customToolTip(points){
      if(points.length == 1){
        return '<b>Date</b>: ' + moment(points[0].x).format('MMM YYYY') + '<br/><b>Fund</b>: $' + numberWithCommas(points[0].y);
      }else{
        let tlTip = '';
        points.map(point => {
          tlTip += '<b>' + point.series.name + '</b>:<br/>';
          tlTip += '<b>Date</b>: ' + moment(point.x).format('MMM YYYY') + '<br/>';
          tlTip += '<br/><b>Fund</b>: $' +  numberWithCommas(point.y) + '<br/>'
        });
        return tlTip
      }
    }
    var staticXAxisData = []
    staticXAxisData = data.map(xItem => {
      let item = xItem
      if(xItem.y > 0){
        item = {...item, y: item.y - (item.y * 2)}
      }
      return item
    })
    var options = {
      chart: {
        type: 'area',
        zoomType: 'x',
        marginTop: 60,
        events: {
          load: function() {
            if(graphLabel){
              if(ctrShortInfo && ctrShortInfo.lessor_name){
                var label = this.renderer.label( graphLabel + (ctrShortInfo.asset_type == 1 ? ' MSN ' + ctrShortInfo.msn : ' ESN ' + ctrShortInfo.esn ) + "<br/>Lessor: " + ctrShortInfo.lessor_name)
              }else{
                var label = this.renderer.label( graphLabel + (ctrShortInfo.asset_type == 1 ? ' MSN ' + ctrShortInfo.msn : ' ESN ' + ctrShortInfo.esn ) + "<br/>Lessor: " + getLocalStorageInfo().defaultLessor.name)
              }
                label.css({
                  width: '400px',
                  fontSize: '9px'
                })
                .attr({
                  'stroke': 'silver',
                  'stroke-width': 1,
                  'r': 2,
                  'padding': 5,
                })
                .add();

              label.align(Highcharts.extend(label.getBBox(), {
                align: 'left',
                x: 0, // offset
                verticalAlign: 'bottom',
                y: -340 // offset
              }), null, 'spacingBox');
            }
          }
        }
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Asset Life ( Year )'
        }
      },
      yAxis:{
        title: {
          text: 'Maintenance Reserve Paid `000 ( USD)'
        },
        labels: {
          formatter: function () {
              return  '$' + this.value/1000;
          }
        }
      },
      tooltip: {
       formatter: function(){
         return customToolTip(this.points)
       },
       shared: true
      },
      credits: {
        enabled: false
      },
      scrollbar: {
        enabled: true
      },
      reflow:true,
      turboThreshold: 10000,
      series:  [
        {
          boostThreshold: 0,
          turboThreshold:0,
          showInLegend: true,
          name: 'Calculated Paid Accruals',
          data: staticXAxisData,
          color: color,
          lineColor: lineColor,
        },
        {
          boostThreshold: 0,
          turboThreshold:0,
          showInLegend: newdata.length ? true:false,
          name: 'Simulated Paid Accruals',
          data: newdata,
          color: '#BAB9D3',
          lineColor: '#150F78',
          points:newMarker
        }
      ],
      exporting: {
        buttons: {
          contextButton: {
            text: 'Export',
            menuItems: [
              {
                text: 'Export as PNG',
                onclick: function () {
                  this.exportChart();
                }
              },
              {
                text: 'Export as SVG',
                onclick: function () {
                  this.exportChart({ type: 'image/svg+xml' });
                }
              },
              {
                text: 'Export as PDF',
                onclick: function () {
                  this.exportChart({ type: 'application/pdf' });
                }
              }
            ]
          }
        }
      }
    };
    return(
      <div className="finance-graph-blk">
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          oneToOne={true}
          ref="chartComponent"
        />
      </div>
    )
  }
}

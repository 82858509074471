import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, browserHistory } from 'react-router';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, Modal, ModalHeader, ModalBody, ButtonDropdown } from 'reactstrap';
import InqueueLst from '../components/InqueueLst';
import { loadAssetViewAc } from '../../Dashboard/actionCreators';
import { ADD_TO_PUBLISH_ALL, ADD_TO_PUBLISH, INQUE_LIST } from '../actions';
import { getActionDockListAc, changeActionDockStatusAc, publishAllFilesAc, publishFilesAc, renameRecordAc, changeOCRPriorityAc, restoreRejectedRecordAc, exportActionDockAc, deleteInqueueAc } from '../actionCreators';
import { sGlobalConstantAc } from '../../../../shared/actionCreators';
import { ShortHead, ContractBarLoader } from '../../../contracts/Elements';
import { RecordsSideBar } from '../../Elements';
import { FileInfo } from '../../DataRoom/components';
import '../../assets/styles/records_v.scss';
import { NoRecordFound, ListLoader  } from '../../../../shared';
import Pagination from "react-js-pagination";
import RoleBasedAccess from '../../../../utils/RoleBasedAccess';
import { getLocalStorageInfo } from '../../../../utils';
import { imgStoragePath, assetsType } from '../../../../constants';

class InQueue extends Component{
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.getDockList = this.getDockList.bind(this);
    this.renameFile = this.renameFile.bind(this);
    this.updateField = this.updateField.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.state = {
      dropdownOpen: false,
      queType: 1,
      modalFlag: false,
      previewCard: {},
      pageNumber: 1,
      error: '',
      delFlag:false,
      delType: 'single',
      typeFlag: 'status',
      restoreFlag: false,
      filter:{
        sort: '',
        sort_by: ''
      },
      searchText: ''
    };
  }

  updateSearchText = (value) =>{
    this.setState(prevState =>({
      ...prevState,
      searchText: value
    }))
  }

  deleteFile = (type) => {
    this.setState(prevState => ({
      ...prevState,
      delFlag: !this.state.delFlag,
      delType: type
    }));
  }

  searchActionDock = (evt) =>{
    evt.preventDefault();
    var searchText = evt.target.actionDockSearch.value;
    this.setState(prevState =>({
      ...prevState,
      searchText: searchText
    }))
    if(evt.target.actionDockSearch.value != null && evt.target.actionDockSearch.value != ''){
      this.props.getActionDockList(this.state.queType, 1, this.state.typeFlag, { sort: 'asc',sort_by: this.state.filter.sort == '' ? this.state.filter.sort_by == 'asc' ? 'desc' : 'asc' : 'asc' }, searchText);
    }
  }

  deleteInqueue = (uuid) =>{
    this.props.deleteInqueue(uuid, this.state.queType, 1, this.state.typeFlag, { sort: 'asc',sort_by: this.state.filter.sort == '' ? this.state.filter.sort_by == 'asc' ? 'desc' : 'asc' : 'asc' }, '');
  }

  filterActionDock = (type) =>{
    this.setState(prevState => ({
      ...prevState,
      filter:{
        sort: type,
        sort_by: prevState.filter.sort == type ? prevState.filter.sort_by == 'asc' ? 'desc' : 'asc' : 'asc'
      },
      pageNumber: 1
    }));
    this.props.getActionDockList(this.state.queType, 1, this.state.typeFlag, { sort: type,sort_by: this.state.filter.sort == type ? this.state.filter.sort_by == 'asc' ? 'desc' : 'asc' : 'asc' }, this.state.searchText);
  }
  updateField(evt){
    if(evt.target.value != '' && evt.target.value != null && evt.target.value != undefined){
      this.setState({
        previewCard:{...this.state.previewCard,name: evt.target.value},
        error: '',
      });
    }else {
      this.setState({
        error: 'File Name can not be empty'
      })
    }
  }

  componentWillReceiveProps (nextProps){
    let location = browserHistory.getCurrentLocation();
    if(location.query.quetype && location.query.quetype != this.state.queType){
      this.componentDidMount()
    }else{
      if(location.query.quetype && location.query.quetype == 1){
        if(location.query.status && location.query.status != this.state.typeFlag){
          this.componentDidMount()
        }
      }
    }
  }

  componentDidMount(){
    this.props.fetchAssetInfo();
    this.props.addToPublishAll(false);
    this.props.fetchTechConstant({constant_types: ['file_extension']})
    let location = browserHistory.getCurrentLocation();
    if(location.query.quetype != null && location.query.quetype != undefined){
      this.setState({
        queType: location.query.quetype,
        typeFlag: location.query.status ? location.query.status : 'status'
      })
      if(location.query.status != null && location.query.status != undefined && location.query.status == 'ocr_status'){
        this.props.getActionDockList(location.query.quetype, 1, 'ocr_status', {sort: '', sort_by: 'asc'}, this.state.searchText);
      }else {
        this.props.getActionDockList(location.query.quetype, 1, 'status', {sort: '', sort_by: 'asc'}, this.state.searchText);
      }
    }else {
      getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('INQTAB') != -1 ?
      this.getDockList(1, 'status'):
      getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('RENTAB') != -1 ?
      this.getDockList(2, 'status'):
      getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('OCRTAB') != -1 ?
      this.getDockList(location.query.quetyp === 1 ? 1 : 4, 'ocr_status'):
      getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('REJTAB') != -1 ?
      this.getDockList(4, 'status'):
      this.props.getActionDockList(this.state.queType, 1, this.state.typeFlag, {sort: '', sort_by: 'asc'}, this.state.searchText);
    }
  }

  exportActionDock = (type) =>{
    this.props.exportActionDock(this.state.queType, this.state.pageNumber, this.state.typeFlag, { ...this.state.filter, search: this.state.searchText }, this.props.ctrShortInfo.asset_type == 2 ? this.props.ctrShortInfo.esn : this.props.ctrShortInfo.msn, type, this.props.ctrShortInfo.asset_type == 2 ? 'ESN ' : this.props.ctrShortInfo.asset_type == 6 ? this.props.ctrShortInfo.esn : this.props.ctrShortInfo.asset_type == 7 ? this.props.ctrShortInfo.esn : this.props.ctrShortInfo.asset_type == 8 ? this.props.ctrShortInfo.esn : this.props.ctrShortInfo.asset_type == 9 ? this.props.ctrShortInfo.esn:'MSN ');
  }

  handlePageChange = (pageNumber) =>{
    this.setState({
      pageNumber: pageNumber
    });
    this.props.getActionDockList(this.state.queType, pageNumber, this.state.typeFlag, this.state.filter, this.state.searchText);
  }
  getDockList(queType, typeFlag){
    this.props.addToPublishAll(false);
    if(!this.props.dockLoader){
      this.setState({
        queType: queType,
        pageNumber: 1,
        typeFlag: typeFlag,
        filter:{
          sort: '',
          sort_by: ''
        }
      });
      this.props.getActionDockList(queType, 1, typeFlag, this.state.filter, this.state.searchText);
      let location = browserHistory.getCurrentLocation();
      location.query.quetype = queType;
      location.query.status = typeFlag;
      browserHistory.push(location);
    }
  }

  refeshActionList = () => {
    this.props.getActionDockList(this.state.queType, this.state.pageNumber, this.state.typeFlag, this.state.filter, this.state.searchText);
  }

  renameFile(item, pageNumber){
    this.setState({
      modalFlag: !this.state.modalFlag,
      previewCard: item,
      error: '',
      pageNumber: pageNumber
    });
  }


  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }
  changeMsn =(evt) =>{
    let url = "";
    if(evt.records){
      url = '/'+assetsType[evt.asset_type]+'/'+evt.slug+'/records/action-dock/';
      this.props.params.type = assetsType[evt.asset_type];
      this.props.params.aircraft_slug = evt.slug;
      let location = browserHistory.getCurrentLocation();
      if(location.query.quetype != null && location.query.quetype != undefined){
        this.setState({
          queType: location.query.quetype,
          typeFlag: location.query.status ? location.query.status : 'status'
        })
        if(location.query.status != null && location.query.status != undefined && location.query.status == 'ocr_status'){
          this.props.getActionDockList(location.query.quetype, 1, 'ocr_status', {sort: '', sort_by: 'asc'}, this.state.searchText);
        }else {
          this.props.getActionDockList(location.query.quetype, 1, 'status', {sort: '', sort_by: 'asc'}, this.state.searchText);
        }
      }else {
        this.props.getActionDockList(this.state.queType, 1, this.state.typeFlag, {sort: '', sort_by: 'asc'}, this.state.searchText);
      }
    }else{
      url = '/asset-view/'+this.props.params.type+'/'+evt.slug;
    }
    browserHistory.push(url);
    this.props.fetchAssetInfo();
  }

  restoreRejectedRecord = (type, fileObject) => {
    let obj = {};
    obj['uuid'] = fileObject['uuid'];
    obj['type'] = fileObject['type'];
     this.props.restoreRejectedRecordFn(type, obj);
  }


  render(){
    const { ctrShortInfo, techConstants, inQueList, dockLoader, publishList, publishables, pagination, notificationOpen } = this.props;
    var fileIcons = {}
    techConstants.map(item => {
      if(item.type == "file_extension"){
        fileIcons = {...fileIcons, [item.label]: imgStoragePath + item.remarks}
      }
    })
    return(
      <div className="action-doc-container">
        <RecordsSideBar />
        { Object.keys(ctrShortInfo).length ?
          <ShortHead
            headingText="Records"
            changeMsn={this.changeMsn}
            subHeaderTopMargin={notificationOpen == true ? 74 : 40}
            contrAssetBasicInfo={ctrShortInfo} />: <ContractBarLoader />
        }
        <div className="asset-listing-wrap action-dock-block" style={{marginTop: '79px'}}>
          <Row>
            <Col md="12">
              <div style={{position: 'relative'}}>
                <ul className="list-inline tab-left">
                { getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('INQTAB') != -1 ?
                    <li onClick={() => this.getDockList(1, 'status')}
                    className={this.state.queType == 1 && this.state.typeFlag == 'status' ? "list-inline-item active" : "list-inline-item"}>In Queue({inQueList.files_count ? `${inQueList.files_count.in_queue}` : 0})</li>
                    :null}
                  { getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('RENTAB') != -1 ?
                    <li
                      onClick={() => this.getDockList(2, 'status')}
                      className={this.state.queType == 2 && this.state.typeFlag == 'status' ? "list-inline-item active" : "list-inline-item"}>
                      To be Renamed ({ (inQueList && inQueList.files_count) ? `${inQueList.files_count.rename}` : 0})
                    </li>
                    :null
                  }
                  { getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('OCRTAB') != -1 ?
                    <li
                      onClick={() => this.getDockList(1, 'ocr_status')}
                      className={this.state.queType == 1  && this.state.typeFlag == 'ocr_status' ? "list-inline-item active" : "list-inline-item"}>
                      OCR Queue ({ (inQueList && inQueList.files_count) ? `${inQueList.files_count.ocr_queue}` : 0})
                    </li>
                    :null
                  }
                  { getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('REJTAB') != -1 ?
                    <li
                      onClick={() => this.getDockList(4, 'ocr_status')}
                      className={this.state.queType == 4 && this.state.typeFlag == 'ocr_status' ? "list-inline-item active" : "list-inline-item"}>
                      OCR Rejected ({ inQueList && inQueList.files_count && inQueList.files_count.ocr_rejected ? `${inQueList.files_count.ocr_rejected}` : 0})
                    </li>
                    :null
                  }
                  { getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('REJTAB') != -1 ?
                    <li
                      onClick={() => this.getDockList(4, 'status')}
                      className={this.state.queType == 4 && this.state.typeFlag == 'status' ? "list-inline-item active" : "list-inline-item"}>
                      Rejected ({ inQueList && inQueList.files_count ? `${inQueList.files_count.rejected}` : 0})
                    </li>
                    :null
                  }
                  <li className="list-inline-item">
                    <ul className="list-inline tab-right flex-centered download-action-dock">
                      <li className="list-inline-item">
                        <span onClick={this.refeshActionList}> Refresh <img style={{width: '16px'}}  src={imgStoragePath+"refresh_blue.png"} alt="refesh" /></span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row style={{position: 'relative', paddingTop: '8px'}}>
            <ul className="list-inline" style={{position: 'absolute', top: '-22px', right: '24px'}}>
              {
                inQueList && inQueList.list && inQueList.list.length ?
                <li className="list-inline-item download-block">
                  <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                    <DropdownToggle caret size="sm">
                       <span><img width="12" src={imgStoragePath+"export_icon.png"}  alt="export"/>  Export List </span>
                    </DropdownToggle>
                    <DropdownMenu>
                      { inQueList.files_count ? this.state.queType==1 && inQueList.files_count.in_queue >= 10000?
                          null:
                          (this.state.queType==2 && inQueList.files_count.rename >= 10000?
                            null:
                           ( this.state.queType==1 && inQueList.files_count.ocr_queue >= 10000?
                            null:
                            ( this.state.queType==4 && inQueList.files_count.rejected >= 10000?
                              null:
                          <DropdownItem  onClick={() => this.exportActionDock('pdf')}><img src={imgStoragePath+"pdf_icon.png"} alt="img"/>PDF</DropdownItem>))):
                        <DropdownItem  onClick={() => this.exportActionDock('pdf')}><img src={imgStoragePath+"pdf_icon.png"} alt="img"/>PDF</DropdownItem> }
                        <DropdownItem onClick={() => this.exportActionDock('xls')} ><img src={imgStoragePath+"excel_icon.png"} alt="img"/>Excel</DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </li>
                :null
              }
            </ul>
            <Col md="4">
              <div className='action-dock-search'>
                <form onSubmit={(evt) => this.searchActionDock(evt)} id="actionDockSearchForm">
                  <input onChange={(evt) => this.updateSearchText(evt.target.value)} type="text" placeholder='Search...' name="actionDockSearch" id="actionDockSearch"/>
                  <button type="submit"><img src={`${imgStoragePath}search_icn.png`} alt="icon"/></button>
                </form>
              </div>
            </Col>
          {
            inQueList && inQueList.list && inQueList.list.length ?
                (this.state.queType == 1 || this.state.queType == 2) && this.state.typeFlag == 'status' ?
                <Col md="8" className="status-block text-right" style={{overflow:'hidden', marginBottom: '15px'}}>
                   { publishList.length && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('REJ') != -1 && (this.state.queType == 1 || this.state.queType == 2) ?
                           <Link className="reject" onClick={() => this.props.publishFiles(publishList, 4)} style={{display: 'inline-block'}}>Reject</Link>
                      : null
                  }
                   { publishList.length && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1 && (this.state.queType == 1 || this.state.queType == 2)  ?
                        <Link className="publish" onClick={() => this.props.publishFiles(publishList, 3)} style={{display: 'inline-block'}}>Publish({publishList.length})</Link>
                    : null
                  }
                  { inQueList && inQueList.list && inQueList.list.length && getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1 && (this.state.queType ==1 || this.state.queType == 2) ?
                    <Link  onClick={() => this.props.publishAllFiles()} className="primary-btn" style={{display: 'inline-block'}}>Publish All</Link>:null
                  }
                </Col>
                :<Col md="8" className="status-block text-right">
                  <span onClick={() => this.deleteFile('all')} style={{color:'#dc3545',cursor:'pointer',fontSize:'14px'}}> Delete All </span>
                </Col>
            :null
          }
        </Row>
        <div className="action-doc-listing-wrp">
          {
            this.state.queType == 4 && this.state.typeFlag == 'ocr_status' ?
            <span className="warning-info-text" style={{marginBottom: '10px'}}>
              <p><strong>Cause of rejection:</strong></p>
              - Encrypted & Corrupted -  If file is encrypted or corrupted , then OCR scanner cannot be performed.<br/>
              - Poor quality & Handwritten document - If file has a poor resolution or handwritten content, then OCR Scanner is unable to recognise the text contained in the document.
            </span>
            :null
          }
        <div className="asset-list-filter-sort" >
          <Row className="filter-block" style={ this.state.recordPreview ? {marginRight:'350px'}:{marginRight:'0px'}}>
            <Col md="5">
                <h6>
                  {((this.state.queType == 1 || this.state.queType == 2) && this.state.typeFlag == 'status' && publishables.length > 0 && (getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('REJ') != -1 || getLocalStorageInfo().user.permission['records']['action_dock'].indexOf('P') != -1 ))?
                    <input type="checkbox" checked = { (publishables.length == publishList.length && publishables.length != 0) ? 'checked' : '' } onClick={(evt) => this.props.addToPublishAll(evt.target.checked)}/>
                    :null
                  } Name
                  <sup onClick={() => this.filterActionDock('name')} className="asc-sort" style={(this.state.filter.sort == 'name' && this.state.filter.sort_by == 'asc') ? {color: '#000'} : {color: '#dfdfdf'} }> &#9650; </sup>
                  <sub onClick={() => this.filterActionDock('name')} className="desc-sort" style={(this.state.filter.sort == 'name' && this.state.filter.sort_by == 'desc') ? {color: '#000'} : {color: '#dfdfdf'} }>&#9660;</sub>
                </h6>
            </Col>
            <Col md="1" onClick={() => this.filterActionDock('size')}>
                <h6><span>Size</span>
                  <sup className="asc-sort" style={(this.state.filter.sort == 'size' && this.state.filter.sort_by == 'asc') ? {color: '#000'} : {color: '#dfdfdf'} }> &#9650; </sup>
                  <sub className="desc-sort" style={(this.state.filter.sort == 'size' && this.state.filter.sort_by == 'desc') ? {color: '#000'} : {color: '#dfdfdf'} }>&#9660;</sub>
                </h6>
            </Col>
            <Col md="2">
                <h6>
                  <span>
                    { this.state.queType == 1 || this.state.queType == 2 ?
                      'Uploaded By' : this.state.queType == 6 || this.state.queType == 8 ?
                      'Assigned' : this.state.queType == 4 ? 'Modified By': ''
                    }
                  </span>
                </h6>
            </Col>
            <Col md="1">
                <h6>
                  <span>
                    Last Modified At
                  </span>
                </h6>
            </Col>
            {
              this.state.typeFlag == 'ocr_status' && parseInt(this.state.queType) !== 4 ?
              <Col md="2">
                  <h6><span>Priority</span></h6>
              </Col>:
              <Col md="2">
                  <h6><span>Status</span></h6>
              </Col>
            }
            {
              this.state.queType==1 ?
              <Col md="1">
                <h6><span>OCR Status</span></h6>
              </Col>:null
            }
          </Row>
        </div>
        {
          dockLoader ?
          <ListLoader/> :
          inQueList.list && inQueList.list.length > 0 && !dockLoader ?
          inQueList.list.map((listItem, index) => <InqueueLst
          index={index}
          changeOCRPriority={() => this.props.changeOCRPriority(listItem.uuid, listItem.ocr_priority)}
          style={ this.state.recordPreview ? {marginRight:'350px'}:{marginRight:'0px'}} previewRecord={() => {this.previewRecord(listItem)}} queType={this.state.queType} typeFlag={this.state.typeFlag}
          renameFile={() => this.renameFile(listItem, this.state.pageNumber)}
          addToPublish = { () => this.props.addToPublish(listItem.uuid)}
          publishList={publishList} changeActionDockStatus={this.props.changeActionDockStatus}
          listItem={listItem} key={listItem.uuid}
          restoreRejectedRecord = {this.restoreRejectedRecord}
          deleteInqueue={() => this.deleteInqueue(listItem.uuid)}
          fileIcons={fileIcons}
          />

          )
          :   <NoRecordFound/>
        }
        </div>
        {
          (Object.keys(pagination).length && inQueList.list && inQueList.list.length > 0) ?
            <Pagination
            activePage={pagination.current_page ? parseInt(pagination.current_page):1}
            itemsCountPerPage={20}
            totalItemsCount={pagination.total}
            pageRangeDisplayed={10}
            onChange={this.handlePageChange}
          />
          :null
        }
        {
          this.state.previewCard.folder && Object.keys(this.state.previewCard.folder).length ?
          <Modal isOpen={this.state.modalFlag} centered={true} toggle={this.renameFile} className={this.props.className}>
            <ModalHeader toggle={this.renameFile}>Rename {this.state.previewCard.type}</ModalHeader>
            <ModalBody>
              <div className="delete-logs">
                <Input type="text" ref="file_name" defaultValue={this.state.previewCard.name} onChange={(evt) => this.updateField(evt)}/>
                <p style={{fontSize:"10px",textAlign:"left",marginLeft:"5px",marginTop:"3px"}}>{this.state.previewCard.folder && this.state.previewCard.folder.file_naming_conventions ? "Format:": null}</p>
                    <ul className="list-unstyled">
                    {this.state.previewCard.folder && this.state.previewCard.folder.file_naming_conventions ? this.state.previewCard.folder.file_naming_conventions.map(format => <li style={{fontSize:"10px",textAlign:"left",marginLeft:"5px",marginTop:"3px"}}>{format}</li> ) : null}
                    </ul>
                  <ul className="list-inline">
                  <li className="list-inline-item">
                    <button className="btn btn-success" disabled={this.state.error != '' ? true : false} onClick={() => {this.props.renameRecord(this.state.previewCard, this.state.pageNumber); this.renameFile(this.state.previewCard, this.state.pageNumber);}}>Save</button>
                    <h6 className="error-msg">{this.state.error}</h6>
                  </li>
                </ul>
              </div>
            </ModalBody>
          </Modal>
          :null
        }

        <Modal isOpen={this.state.delFlag} centered={true}  className={this.props.className}>
         <ModalHeader toggle={() => this.deleteFile('single')}>Delete { this.state.delType === 'all' ? 'Files' : 'File'}</ModalHeader>
         <ModalBody>
           <div className="delete-logs">
             <h4>Are you sure, {this.state.delType === 'all' ? `you want to delete all${this.state.typeFlag === 'ocr_status' ? ' OCR' : ''} rejected files?` : 'you want to delete selected files?'}</h4>
             <ul className="list-inline">
               <li className="list-inline-item">
                  <button className="btn btn-danger" onClick={() => {this.props.deleteInqueue('all_rejected_tab_files',4,1,'status', this.state.filter, this.state.searchText); this.deleteFile()}}>YES</button>
               </li>
               <li className="list-inline-item">
                 <button className="btn btn-secondary" onClick={() => this.deleteFile('single')}>NO</button>
               </li>
             </ul>
           </div>
         </ModalBody>
       </Modal>



      </div>
    </div>
    )
  }
}
const mapStateToProps = state => ({
  ctrShortInfo: state.ContractListReducer.ctrShortInfo,
  inQueList: state.ActionDockReducer.inQueList,
  publishables: state.ActionDockReducer.publishables,
  publishList: state.ActionDockReducer.publishList,
  dockLoader: state.ActionDockReducer.dockLoader,
  pagination: state.ActionDockReducer.pagination,
  techConstants: state.sharedReducers.techConstants,
  notificationOpen:state.sharedReducers.notificationOpen,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchAssetInfo: () => dispatch(loadAssetViewAc(ownProps.params)),
    addToPublishAll: (flag) => dispatch({
      type: ADD_TO_PUBLISH_ALL,
      payload: flag
    }),
    addToPublish: (uuid) => dispatch({
      type: ADD_TO_PUBLISH,
      payload: uuid
    }),
    clearQueueList: () => dispatch({
        type: INQUE_LIST,
        payload: []
    }),
    restoreRejectedRecordFn: (type, fileInfo) => dispatch(restoreRejectedRecordAc(ownProps.params, type, fileInfo)),
    publishAllFiles: () => dispatch(publishAllFilesAc(ownProps.params)),
    renameRecord: (fileObject, pageNumber) => dispatch(renameRecordAc(ownProps.params, fileObject, pageNumber)),
    publishFiles: (files, status) => dispatch(publishFilesAc(ownProps.params, files, status)),
    changeOCRPriority: (uuid, status) => dispatch(changeOCRPriorityAc(ownProps.params, uuid, status)),
    changeActionDockStatus: (fileObject) => dispatch(changeActionDockStatusAc(ownProps.params,fileObject)),
    getActionDockList: (fileStatus, pageNumber, typeFlag, filter, searchText) => dispatch(getActionDockListAc(ownProps.params, fileStatus, pageNumber, typeFlag, filter, searchText)),
    deleteInqueue: (uuid, fileStatus, pageNumber, typeFlag, filter, searchText) => dispatch(deleteInqueueAc(ownProps.params, uuid, fileStatus, pageNumber, typeFlag, filter, searchText)),
    exportActionDock: (fileStatus, pageNumber, typeFlag, filter, msn, type,name) => dispatch(exportActionDockAc(ownProps.params, fileStatus, pageNumber, typeFlag, filter, msn, type,name)),
    fetchTechConstant : (constantTypes) => dispatch(sGlobalConstantAc(constantTypes)),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RoleBasedAccess(InQueue,['records','action_dock','R'])))

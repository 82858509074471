import React, { Component } from 'react';
import { Row, Col, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Link, browserHistory } from 'react-router';
import  GetInputField  from '../../../applications/technicalInspection/Elements/GetInputField';
import { checkLoginForm } from '../../../formValidator/loginForm';
import { globalSignUpPostService} from '../../../globalServices';
import { toastFlashMessage, setGlobalCookie, checkEnvironment } from '../../../utils'

class LoginForm extends Component {
  constructor(props){
    super(props);
    this.state = {
        userInfo: {
            email:'',
            password: '',
        },
        error: {},
        loader: false,
        showPassword:false,
        showMaintenancePopup: false
    };
  }

  handleUserChange = (key, value) =>{
    value = value.trim();
    this.setState(prevState => ({
        ...prevState,
        userInfo: {
            ...prevState.userInfo,
            [key]: value
        },
        error: {
            ...prevState.error,
            [key]: ''
        }
    }));
    if(key === 'email'){
      this.setState(prevState => ({
        ...prevState,
        error: {
            ...prevState.error,
            emailMsg: ''
        }
    }));
    }
    if(key === 'password'){
      this.setState(prevState => ({
        ...prevState,
        error: {
            ...prevState.error,
            passwordMsg: ''
        }
    }));
    }
  }

  loginSubmit = (e) => {
    e.preventDefault();
    const { userInfo } = this.state;
    const validateNewInput = checkLoginForm({email: userInfo.email, password: userInfo.password});
    if (Object.keys(validateNewInput).every((k) => { return validateNewInput[k] === '' })) {
        this.setState(prevState => ({
            ...prevState,
            loader: true,
        }));
        globalSignUpPostService('/api/login/',userInfo)
        .then( response => {
          this.setState(prevState => ({
              ...prevState,
              loader: false,
          }));
          if(response.data.statusCode == 200){
            localStorage.setItem('userInfo', JSON.stringify(response.data.data));
            if(response.data.data.lessor_list.length){
              let baseDomain = '.sparta.aero';
              let expireAfter = new Date();
              expireAfter.setDate(expireAfter.getDate() + 3);
              let cookieData = JSON.stringify({access:response.data.data.access, environment: checkEnvironment()})
              setGlobalCookie("lessorAccess", cookieData, 3)
              setGlobalCookie("domain", baseDomain, 3)
              localStorage.setItem('userInfo', JSON.stringify(response.data.data));
            }
            if(response.data.data.lessor_list.length > 1){
              browserHistory.push('/select-lessor');
            }else{
              this.props.authorization(response.data.data.lessor_list[0])
            }
          }else if(response.data.statusCode == 1012){
            toastFlashMessage(response.data.message, 'success');
            browserHistory.push('/otp-verification/' + response.data.data.token)
            localStorage.setItem('userEmail', userInfo.email)
          }else if(response.data.statusCode == 1008){
            this.setState({
              error: {
                emailMsg : response.data.validation_error.email ? response.data.validation_error.email : "",
                passwordMsg : response.data.validation_error.password ? response.data.validation_error.password : "",
              }
            });
          }
        });
    }else{
      this.setState({
        error: validateNewInput
      });
    }
  }

  confirmMaintenanceNotice = () =>{
    this.setState({showMaintenancePopup: false})
  }

  render(){
    const { userInfo, error } = this.state;
    return(
      <div className="login-form">
          <span className="warning-info-text" style={{fontSize: '16px', display: 'none'}}>We are doing scheduled maintenance, will be back in 30 minutes.</span>
            <h2 className="form-title">Welcome Back!</h2>
            <p className="form-subtitle">Sign In to Continue to SPARTA</p>
            <div>
                <form onSubmit={this.loginSubmit}>
                    <Row>
                        <Col md="12">
                            {
                                GetInputField(
                                    'string',
                                    <label className="for-label">Registered Email Address</label>,
                                    { type: "add",
                                      value: userInfo.email,
                                      error: error.emailMsg,
                                      updateForm: (value) => this.handleUserChange('email', value)
                                    }
                                )
                            }
                        </Col>
                        <Col md="12" className="password-grid">
                            <label className="for-label">Password</label>
                                <input
                                type={this.state.showPassword ? "text" : "password" }
                                value={userInfo.password}
                                onChange={(e) => {this.handleUserChange('password', e.target.value)}}
                            />
                            {!this.state.showPassword ?<i  onClick={(e) => {this.setState({showPassword: !this.state.showPassword})}} className="fa fa-eye-slash" aria-hidden="true"></i> : <i  onClick={(e) => {this.setState({showPassword: !this.state.showPassword})}} className="fa fa-eye" aria-hidden="true"></i>}
                            {error.passwordMsg ? <h6 className="error-msg">{error.passwordMsg}</h6> : null }
                            <Link to="https://sparta.aero/forgot-password" target="_blank" className="forgot-password">Forgot password ?</Link>
                        </Col>
                        <Col md="12" className="text-center">
                            <p className="terms-and-conditions">
                                By clicking Sign In, you agree to our
                                <a href="/termscondition" > Terms, </a>
                                <a href="/data-policy" >Data Policy </a> and <br/>
                                <a href="https://acumen.aero/privacy-policy/" target="_blank"> Privacy Policy</a>.
                            </p>
                            <input type="submit" style={ this.state.loader ? { pointerEvents: 'none' } : {pointerEvents: 'unset'}}   value={ this.state.loader ? "Processing..." : "Sign In"  } />
                        </Col>
                    </Row>
                </form>
            </div>
            <Modal isOpen={this.state.showMaintenancePopup} toggle={() => this.setState({showMaintenancePopup: true})}>
              <ModalHeader>Attention</ModalHeader>
              <ModalBody>
                <p>We acknowledge the inconvenience caused by the sudden disruption of the SPARTA platform from 30 May - 31 May 2021. It was caused due to an outage of a critical service component at the service provider’s data centre. As of today, the issue has been resolved and SPARTA system functions are running as smoothly as before. However, the technical team is continuing its efforts to ensure that the issue does not repeat, While this is not expected to cause complete disruption to the SPARTA System, you may experience intermittency for brief period. We appreciate your kind support and patience in this regard. </p>
              </ModalBody>
              <ModalFooter>
                <button style={{float: 'right'}} onClick={() => this.confirmMaintenanceNotice()} type="button" className="btn btn-primary">Okay</button>
              </ModalFooter>
            </Modal>
      </div>
    )
  }
}

export default  LoginForm
